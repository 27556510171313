"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _index = _interopRequireDefault(require("@/store/index"));

var routes = [{
  path: '/vente-creance/commandes',
  name: 'VenteCreance.Commandes',
  order: 1,
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  meta: {
    title: 'Commandes'
  },
  redirect: {
    name: 'VenteCreance.Commandes.CommandeIndex'
  },
  children: [// Contenu par défaut
  {
    path: 'selection-affaire',
    name: 'VenteCreance.Commandes.CommandeIndex',
    order: 1,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vente-creance/commandes/CommandeIndex'));
      });
    },
    meta: {
      title: 'Sélection d\'une affaire',
      activeMenu: '/vente-creance/commandes'
    }
  }, {
    path: ':affaireId(\\d+)',
    name: 'VenteCreance.Commandes.ListByAffaire',
    order: 2,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vente-creance/commandes/CommandeByAffaire'));
      });
    },
    meta: {
      title: 'Commandes de l\'affaire',
      activeMenu: '/vente-creance/commandes'
    },
    props: true,
    // Chargement des données de l'affaire avant d'entrer dans le composant
    beforeEnter: function beforeEnter(routeTo, routeFrom, next) {
      _index.default.dispatch('affaire/getAffaireById', routeTo.params.affaireId).then(function (results) {
        var affaire = _index.default.getters.affaire.affaire;

        if (affaire) {
          routeTo.meta.title = "Commandes de l'affaire ".concat(affaire.num_affaire);
          routeTo.params.affaire = affaire;
          next();
        } else {
          next({
            name: '404'
          });
        }
      }).catch(function (_) {
        next({
          name: 'VenteCreance.Commandes.CommandeIndex'
        });
      });
    }
  }]
}, {
  path: '/vente-creance/factures',
  name: 'VenteCreance.Factures',
  order: 2,
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  meta: {
    title: 'Factures de vente'
  },
  redirect: {
    name: 'VenteCreance.Factures.FactureIndex'
  },
  children: [// Contenu par défaut
  {
    path: 'selection-affaire',
    name: 'VenteCreance.Factures.FactureIndex',
    order: 1,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vente-creance/factures/FactureIndex'));
      });
    },
    meta: {
      title: 'Sélection d\'une affaire',
      activeMenu: '/vente-creance/factures'
    }
  }, {
    path: ':affaireId(\\d+)',
    name: 'VenteCreance.Factures.ListByAffaire',
    order: 2,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vente-creance/factures/FactureByAffaire'));
      });
    },
    meta: {
      title: 'Factures de l\'affaire',
      activeMenu: '/vente-creance/factures'
    },
    props: true,
    // Chargement des données de l'affaire avant d'entrer dans le composant
    beforeEnter: function beforeEnter(routeTo, routeFrom, next) {
      _index.default.dispatch('affaire/getAffaireById', routeTo.params.affaireId).then(function (results) {
        var affaire = _index.default.getters.affaire.affaire;

        if (affaire) {
          routeTo.meta.title = "Factures de l'affaire ".concat(affaire.num_affaire);
          routeTo.params.affaire = affaire;
          next();
        } else {
          next({
            name: '404'
          });
        }
      }).catch(function (_) {
        next({
          name: 'VenteCreanceFactures.FactureIndex'
        });
      });
    }
  }]
}, {
  path: '/vente-creance/creances',
  name: 'VenteCreance.Creances',
  order: 3,
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  meta: {
    title: 'Créances clients'
  },
  redirect: {
    name: 'VenteCreance.Creances.CreancesIndex'
  },
  children: [// Contenu par défaut
  {
    path: 'selection-affaire',
    name: 'VenteCreance.Creances.CreancesIndex',
    order: 1,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vente-creance/creances/CreanceIndex'));
      });
    },
    meta: {
      title: 'Liste des créances clients',
      activeMenu: '/vente-creance/creances'
    }
  }, {
    path: ':factureId(\\d+)',
    name: 'VenteCreance.Creances.Reglements.ListByFacture',
    order: 2,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vente-creance/creances/ReglementByFacture'));
      });
    },
    meta: {
      title: 'Règlements de la facture',
      activeMenu: '/vente-creance/creances'
    },
    props: true,
    // Chargement des données de l'affaire avant d'entrer dans le composant
    beforeEnter: function beforeEnter(routeTo, routeFrom, next) {
      _index.default.dispatch('facturation/loadFactureById', routeTo.params.factureId).then(function (results) {
        var facture = _index.default.getters['facturation/getFactureById'](parseInt(routeTo.params.factureId));

        if (!facture) {
          facture = results[0];
        }

        if (facture) {
          routeTo.meta.title = "R\xE8glements de la facture ".concat(facture.numero);
          routeTo.params.facture = facture;
          routeTo.params.idFacture = parseInt(routeTo.params.factureId);
          next();
        } else {
          next({
            name: '404'
          });
        }
      }).catch(function (_) {
        next({
          name: 'VenteCreance.Creances.CreancesIndex'
        });
      });
    }
  }]
}, {
  path: '/vente-creance/synthese',
  name: 'VenteCreance.Synthese',
  order: 4,
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  meta: {
    title: 'Synthèse générale'
  },
  redirect: {
    name: 'VenteCreance.Synthese.SyntheseIndex'
  },
  children: [// Contenu par défaut
  {
    path: 'selection-affaire',
    name: 'VenteCreance.Synthese.SyntheseIndex',
    order: 1,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vente-creance/synthese/SyntheseIndex'));
      });
    },
    meta: {
      title: 'Synthèse générale',
      activeMenu: '/vente-creance/synthese'
    }
  }
  /*,
  {
   path: ':factureId(\\d+)',
   name: 'VenteCreance.Synthese.Reglements.ListByFacture',
   order: 2,
   hidden: true,
   component: () => import('@/views/vente-creance/synthese/ReglementByFacture'),
   meta: { title: 'Règlements de la facture', activeMenu: '/vente-creance/synthese' },
   props: true,
   // Chargement des données de l'affaire avant d'entrer dans le composant
   beforeEnter (routeTo, routeFrom, next) {
     store.dispatch('facturation/loadFactureById', routeTo.params.factureId)
       .then(results => {
         let facture = store.getters['facturation/getFactureById'](parseInt(routeTo.params.factureId))
         console.log('1. facture', facture)
         if (!facture) {
           facture = results[0]
           console.log('2. facture', facture)
         }
         if (facture) {
           routeTo.meta.title = `Règlements de la facture ${facture.numero}`
           routeTo.params.facture = facture
           routeTo.params.idFacture = parseInt(routeTo.params.factureId)
           next()
         } else {
           next({ name: '404' })
         }
       })
       .catch(_ => {
         next({ name: 'VenteCreance.Synthese.SyntheseIndex' })
       })
   }
  }
  */
  ]
}, {
  path: '/vente-creance/sage',
  name: 'VenteCreance.Sage',
  order: 5,
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  meta: {
    title: 'Sage ligne 1000'
  },
  redirect: {
    name: 'VenteCreance.Sage.SageIndex'
  },
  children: [// Contenu par défaut
  {
    path: 'export',
    name: 'VenteCreance.Sage.SageIndex',
    order: 1,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/vente-creance/sage/SageIndex'));
      });
    },
    meta: {
      title: 'Sage ligne 1000',
      activeMenu: '/vente-creance/sage'
    }
  }]
}];
var _default = routes;
exports.default = _default;