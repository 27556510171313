var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    directives: [
      { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
    ],
    attrs: {
      options: _vm.listUsers,
      props: { expandTrigger: "hover", emitPath: false },
      "show-all-levels": false,
      clearable: _vm.clearable ? _vm.clearable : false,
      filterable: "",
      "filter-method": _vm.handleFilter,
      size: "mini"
    },
    on: {
      change: function($event) {
        return _vm.onChange($event)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var node = ref.node
          var data = ref.data
          return [
            _c("span", [_vm._v(_vm._s(data.label))]),
            _vm._v(" "),
            !node.isLeaf
              ? _c("span", [_vm._v(" (" + _vm._s(data.children.length) + ") ")])
              : _vm._e()
          ]
        }
      }
    ]),
    model: {
      value: _vm.idUser,
      callback: function($$v) {
        _vm.idUser = $$v
      },
      expression: "idUser"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }