var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          attrs: { icon: "el-icon-arrow-left", size: "mini" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        },
        [_vm._v("Retour")]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { margin: "10px 0 10px 0" } },
        [
          _c("el-col", { attrs: { span: 10 } }, [
            _c("h1", [_vm._v(_vm._s(_vm.title))])
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { staticStyle: { "text-align": "right" }, attrs: { span: 14 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-check"
                  },
                  on: { click: _vm.validateForm }
                },
                [_vm._v("Enregistrer")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.resetForm } },
                [_vm._v("Reset")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ElForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "220px",
            size: "mini"
          }
        },
        [
          _c("el-form-item", { attrs: { label: "Société" } }, [
            _vm._v(_vm._s(_vm.$store.getters.user.societe_name))
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Nom", prop: "lastname" } },
            [
              _c("el-input", {
                staticStyle: { width: "500px" },
                attrs: { type: "string", autocomplete: "no", maxlength: "50" },
                model: {
                  value: _vm.formData.lastname,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "lastname", $$v)
                  },
                  expression: "formData.lastname"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Prénom", prop: "firstname" } },
            [
              _c("el-input", {
                staticStyle: { width: "500px" },
                attrs: { type: "string", autocomplete: "no", maxlength: "50" },
                model: {
                  value: _vm.formData.firstname,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "firstname", $$v)
                  },
                  expression: "formData.firstname"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Email", prop: "email" } },
            [
              _c(
                "el-input",
                {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: { alias: "email" },
                      expression: "{ alias: 'email' }"
                    }
                  ],
                  staticStyle: { width: "500px" },
                  attrs: {
                    type: "string",
                    autocomplete: "no",
                    maxlength: "256"
                  },
                  model: {
                    value: _vm.formData.email,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "email", $$v)
                    },
                    expression: "formData.email"
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-message",
                    attrs: { slot: "prefix" },
                    slot: "prefix"
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Actif", prop: "enabled" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": Number(1),
                  "inactive-value": Number(0),
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "Oui",
                  "inactive-text": "Non"
                },
                model: {
                  value: _vm.formData.enabled,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "enabled", $$v)
                  },
                  expression: "formData.enabled"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Fonction", prop: "key_role" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    "value-key": "formData.key_role",
                    filterable: "",
                    clearable: "",
                    placeholder: "Selection",
                    autocomplete: "no"
                  },
                  model: {
                    value: _vm.formData.key_role,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "key_role", $$v)
                    },
                    expression: "formData.key_role"
                  }
                },
                _vm._l(_vm.roles, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.key_role }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Mot de passe", prop: "pwd1" } },
            [
              _c(
                "el-input",
                {
                  ref: "pwd1",
                  staticStyle: { width: "500px" },
                  attrs: {
                    type: _vm.passwordType,
                    autocomplete: "off",
                    tabindex: "1",
                    maxlength: "50"
                  },
                  model: {
                    value: _vm.pwd1,
                    callback: function($$v) {
                      _vm.pwd1 = $$v
                    },
                    expression: "pwd1"
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-key",
                    attrs: { slot: "prefix" },
                    slot: "prefix"
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.genPassword }
                },
                [_vm._v("Générer")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Confirmation mot de passe", prop: "pwd2" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    type: _vm.passwordType,
                    autocomplete: "off",
                    tabindex: "2",
                    maxlength: "50"
                  },
                  model: {
                    value: _vm.pwd2,
                    callback: function($$v) {
                      _vm.pwd2 = $$v
                    },
                    expression: "pwd2"
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-key",
                    attrs: { slot: "prefix" },
                    slot: "prefix"
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", [
            _c(
              "div",
              { staticStyle: { "font-size": "12px", "line-height": "14px" } },
              [
                _vm._v(
                  "\n        Le mot de passe doit respecter les règles suivantes :"
                ),
                _c("br"),
                _vm._v("\n        • 1 minuscule"),
                _c("br"),
                _vm._v("\n        • 1 majuscule"),
                _c("br"),
                _vm._v("\n        • 1 chiffre"),
                _c("br"),
                _vm._v("\n        • 8 caractères minimum"),
                _c("br")
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }