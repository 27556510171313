var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { key: "tool_" + _vm.refresh, attrs: { slot: "tool" }, slot: "tool" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v(" \n      "),
            !_vm.$root.modeEdit
              ? _c(
                  "div",
                  [
                    _vm.userCanDoAction
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:vente_initiale",
                                value: "editer",
                                expression: "'editer'",
                                arg: "vente_initiale"
                              }
                            ],
                            attrs: {
                              icon: "el-icon-circle-plus-outline",
                              type: "primary",
                              size: "mini"
                            },
                            on: { click: _vm.handleNew }
                          },
                          [_vm._v("Ajouter un lot\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("Workflow", {
                      attrs: { affaire: _vm.affaire, ecran: "vente_initiale" },
                      on: { handleAction: _vm.handleMixinAction }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12, align: "right" } },
            [
              _vm._v(" \n      "),
              !_vm.$root.modeEdit && _vm.userCanDoAction
                ? _c("Modele", {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:vente_initiale",
                        value: "editer",
                        expression: "'editer'",
                        arg: "vente_initiale"
                      }
                    ]
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "printer" } },
        [
          _c(
            "div",
            { staticStyle: { display: "none" }, attrs: { id: "print-header" } },
            [
              _c(
                "el-row",
                { staticClass: "btn-back" },
                [
                  _c("el-col", { attrs: { span: 5 } }, [
                    _vm._v("Vente initiale")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 14, align: "center" } }, [
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v(" " + _vm._s(_vm.periodeGestion()) + " ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 5, align: "right" } }, [
                    _vm._v(" ")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 5, align: "left" } }, [
                    _c("h2", { staticClass: "numAffaire" }, [
                      _vm._v(_vm._s(_vm.affaire.affaire.num_affaire))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 14, align: "center" } }, [
                    _c("h2", { staticClass: "nomAffaire" }, [
                      _vm._v(_vm._s(_vm.affaire.affaire.nom))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 5, align: "right" } }, [
                    _vm._v(_vm._s(this.$store.getters.affaire.societe.name))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "SyncScrollBars",
            [
              _vm._l(_vm.affaire.lots, function(lot, index) {
                return _c(
                  "el-row",
                  { key: lot.id + "_" + _vm.refresh },
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 22 } }, [
                          _c("h1", { staticStyle: { "margin-top": "10px" } }, [
                            _vm._v(
                              "Lot " +
                                _vm._s(index + 1) +
                                " " +
                                _vm._s(lot.libelle)
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "text-align": "right",
                              "padding-top": "4px",
                              "white-space": "nowrap"
                            },
                            attrs: { span: 2 }
                          },
                          [
                            !_vm.$root.modeEdit && _vm.userCanDoAction
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "can",
                                        rawName: "v-can:vente_initiale",
                                        value: "editer",
                                        expression: "'editer'",
                                        arg: "vente_initiale"
                                      }
                                    ]
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Éditer le contenu du lot",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            type: "text",
                                            icon: "el-icon-s-tools"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleModeEdit(lot)
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Modifier libellé du lot",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            type: "text",
                                            icon: "el-icon-edit"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleEdit(lot)
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Supprimer le lot",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            type: "text",
                                            icon: "el-icon-delete"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleDelete(lot)
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("LotDetail", {
                          key: _vm.refresh,
                          attrs: {
                            idLot: lot.id,
                            listeDeboursesIndirects: _vm.listeDeboursesIndirects
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-row",
                { key: "row_" + _vm.refresh },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("AffaireTotaux", {
                        key: "Totaux_" + _vm.refresh,
                        attrs: { userCanDoAction: _vm.userCanDoAction }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }