"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.array.from");

var _toConsumableArray2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.set");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _vue = _interopRequireDefault(require("vue"));

var _store = _interopRequireDefault(require("@/store"));

var _inputmask = _interopRequireDefault(require("inputmask"));

_vue.default.directive('mask', {
  // https://github.com/RobinHerbots/Inputmask#usage
  // https://robinherbots.github.io/Inputmask/
  bind: function bind(el, binding) {
    (0, _inputmask.default)(binding.value).mask(el.getElementsByTagName('INPUT')[0]);
  }
}); // initialise la valeur d'un champ
// ex: <input v-model="subscribed" type="checkbox" v-init:subscribed="false"  />


_vue.default.directive('init', {
  bind: function bind(el, binding, vnode) {
    vnode.context[binding.arg] = binding.value;
  }
});

_vue.default.directive('permission', {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var role = _store.default.getters && _store.default.getters.user && _store.default.getters.user.role;

    if (value && value instanceof Array && value.length > 0) {
      var permissionRoles = value;
      var hasPermission = permissionRoles.includes(role);

      if (!hasPermission && el.parentNode) {
        el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("need roles! Like v-permission=\"['admin','editor']\"");
    }
  }
}); // renvoit l'intersection entre 2 tableaux (elements en commun)


function intersect(a, b) {
  var setA = new Set(a);
  var setB = new Set(b);
  var intersection = new Set((0, _toConsumableArray2.default)(setA).filter(function (x) {
    return setB.has(x);
  }));
  var res = Array.from(intersection);
  return res;
} // exemple d'appel pour tester 1 valeur: v-can:myrule="'edit'"
// exemple d'appel pour tester plusieurs valeurs: v-can:myrule="'edit,validate'"


_vue.default.directive('can', {
  inserted: function inserted(el, binding, vnode) {
    var permission = _store.default.getters && _store.default.getters.rules && _store.default.getters.rules.length > 0 && JSON.parse(_store.default.getters.rules);
    var userRules = permission && permission[binding.arg] && permission[binding.arg].split(',');
    var testRules = binding.value && binding.value.split(',') || [];
    var intersections = intersect(userRules, testRules);

    if ((!intersections || intersections.length === 0) && vnode.elm && vnode.elm.parentElement) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  }
}); // exemple d'appel pour tester 1 valeur: v-cant:myrule="'edit'"
// exemple d'appel pour tester plusieurs valeurs: v-cant:myrule="'edit,validate'"


_vue.default.directive('cant', {
  inserted: function inserted(el, binding, vnode) {
    var permission = _store.default.getters && _store.default.getters.rules && _store.default.getters.rules.length > 0 && JSON.parse(_store.default.getters.rules);
    var userRules = permission && permission[binding.arg] && permission[binding.arg].split(',');
    var testRules = binding.value && binding.value.split(',') || [];
    var intersections = intersect(userRules, testRules);

    if (intersections && intersections.length > 0 && vnode.elm && vnode.elm.parentElement) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  }
});

_vue.default.directive('numberonly', {
  bind: function bind(el, binding, vnode) {
    el.addEventListener('keydown', function (e) {
      // pour controler les touches autorisées du clavier
      var key = e.key;
      var allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '.', 'Home', 'End', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab']; // un seul signe negatif et decimal

      var tirets = key === '-' && e.target.value.indexOf('-') !== -1;
      var points = key === '.' && e.target.value.indexOf('.') !== -1;

      if (key && (!allowedKeys.includes(key) || tirets || points)) {
        e.preventDefault();
        return false;
      }
    });
    el.addEventListener('input', function (e) {
      var nbDec = 2; // valeur par défaut du nombre de décimale à controler

      nbDec = binding.modifiers['dec2'] ? 2 : nbDec;
      nbDec = binding.modifiers['dec3'] ? 3 : nbDec; // pour valider un numerique, 2 chiffres après la virgule uniquement, positif ou negatif

      var reg = new RegExp('^-?([0-9]+.?([0-9]{1,' + nbDec + '})?)?$', 'g');

      if (!reg.test(e.target.value)) {
        e.target.value = vnode.data.model.value;
      }

      var s = e.target.value;

      if (s.indexOf('-') > -1) {
        e.target.value = '-' + s.replace(/-/, '');
      } // e.target.value = isNaN(e.target.value) ? 0 : e.target.value


      vnode.data.model.value = e.target.value;
    }, true);
  }
}); // Registering a global custom directive called `v-focus`


_vue.default.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function inserted(el) {
    // Focus the element
    el.focus();
  }
});

_vue.default.directive('test', {
  bind: function bind(el, binding) {// el.class = binding.arg
  }
}); // appel par: v-save-input:users
// donner un identifiant unique derriere v-save-input:


_vue.default.directive('save-input', {
  bind: function bind(el, binding, vnode) {
    var tag = binding.rawName || 'v-save-input';

    function setModelValue(vnode, value) {
      // standard v-model
      if (vnode.data.model) {
        return vnode.data.model.callback(value);
      } // component with custom v-model
      // eslint-disable-next-line no-eval


      return eval("vnode.context.$data.".concat(vnode.data.directives.find(function (directive) {
        return directive.name === 'model';
      }).expression, " = value"));
    }

    var storage = window.localStorage.getItem(tag) || '';

    if (storage !== '') {
      setModelValue(vnode, storage); // console.log('getItem.localStorage', tag, storage)
    }
  },
  update: function update(el, binding, vnode) {
    var tag = binding.rawName || 'v-save-input';
    var storage = window.localStorage.getItem(tag) || '';

    if (vnode.data.model && storage !== vnode.data.model.value) {
      window.localStorage.setItem(tag, vnode.data.model.value); // console.log('setItem.localStorage', tag, vnode.data.model.value)
    }
  }
});