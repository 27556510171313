"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var apiCommentaire = _interopRequireWildcard(require("@/api/commentaire"));

var _dlgEditor = _interopRequireDefault(require("./dlgEditor"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Commentaires',
  components: {
    dlgEditor: _dlgEditor.default
  },
  data: function data() {
    return {
      reverse: false,
      dlgEditorVisible: false,
      action: '',
      data: [],
      commentaire: {}
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user']), {
    idAffaire: function idAffaire() {
      return this.$route.params.id;
    }
  }),
  methods: {
    formatDate: function formatDate(date) {
      return (0, _moment.default)(date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY à HH:mm');
    },
    handleModifyComment: function handleModifyComment(commentaire) {
      this.commentaire = commentaire;
      this.action = 'edit';
      this.dlgEditorVisible = true;
    },
    handleNewComment: function handleNewComment() {
      this.action = 'new';
      this.dlgEditorVisible = true;
    },
    loadData: function loadData() {
      var _this = this;

      this.$loader(true);
      this.data = [];
      apiCommentaire.getByAffaireId(this.idAffaire).then(function (results) {
        if (results && results.length > 0) {
          _this.data = results;
        }

        _this.$loader(false);
      }).catch(function (error) {
        _this.$message(error);

        _this.$loader(false);
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.row = val;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;

      this.$confirm('Voulez-vous supprimer ce commentaire', 'Confirmer la suppression ?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this2.$loader(true);

        apiCommentaire.deleteById(row.id).then(function (results) {
          _this2.data.splice(_this2.data.indexOf(row), 1);

          _this2.$message('Suppression effectuée avec succès');

          _this2.$loader(false);
        }).catch(function (err) {
          _this2.$message(err);

          _this2.$loader(false);
        });
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this3 = this;

      var msg = this.multipleSelection.length > 1 ? 'les commentaires sélectionnées' : 'le commentaire sélectionné';
      this.$confirm('Voulez-vous supprimer ' + msg + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this3.$loader(true);

        var selection = _this3.multipleSelection.map(function (u) {
          return u.id;
        }).join(',');

        apiCommentaire.bulkDelete(selection).then(function (results) {
          _this3.$message('Suppression effectuée avec succès');

          _this3.loadData();

          _this3.$loader(false);
        }).catch(function (error) {
          _this3.$message(error);

          _this3.$loader(false);
        });
      });
    }
  }
};
exports.default = _default;