"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _eventBus = require("@/helpers/event-bus.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'LotDetailTotauxSynthMensuelle',
  props: ['idLot'],
  data: function data() {
    return {
      refresh: 0,
      totauxMmoins1: [],
      totaux: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.totauxMmoins1 = _this.$store.getters.affaire.calculsMmoins1;
      _this.totaux = _this.$store.getters.affaire.calculs.affaire;
    });

    this.refresh++;
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  },
  methods: {
    pcTotalMmoins1: function pcTotalMmoins1() {
      var mtBgtAff = Number(this.totauxMmoins1.mtTotalGeneralBudgetGlobalAffaire);
      var mtBgtDeb = Number(this.totauxMmoins1.mtTotalGeneralBudgetDebloque);
      return mtBgtAff !== 1 ? mtBgtDeb / mtBgtAff : 0;
    },
    pcTotalM: function pcTotalM() {
      return this.pcTotalCumulM() - this.pcTotalMmoins1();
    },
    pcTotalCumulM: function pcTotalCumulM() {
      var mtBgtAff = Number(this.totaux.mtTotalGeneralBudgetGlobalAffaire);
      var mtBgtDeb = Number(this.totaux.mtTotalGeneralBudgetDebloque);
      return mtBgtAff !== 1 ? mtBgtDeb / mtBgtAff : 0;
    }
  }
};
exports.default = _default;