"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.array.find");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _vuex = require("vuex");

var _utils = require("@/helpers/utils");

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'ReglementInfos',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  inject: ['getFacture' // Permet de récupérer la facture parente éventuellement sélectionnée
  ],
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    facture: {
      type: Object,
      required: true
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      dataLoaded: true,
      row: [],
      multipleSelection: [],
      columns: {
        'date_reglement': {
          key: 1,
          prop: 'date_reglement',
          label: 'Date',
          width: 125
        },
        'mode': {
          key: 2,
          prop: 'mode',
          label: 'Mode',
          width: 100
        },
        'imputation': {
          key: 3,
          prop: 'imputation',
          label: 'Imputation',
          width: 150
        },
        'type': {
          key: 4,
          prop: 'type',
          label: 'Type',
          width: 225
        },
        'montant': {
          key: 5,
          prop: 'montant',
          label: 'Montant',
          width: 150
        }
      },
      sortMethod: {
        imputation: function imputation(a, b) {
          if (a === null && b === null) {
            return 0;
          }

          if (a === null) {
            return -1;
          }

          if (b === null) {
            return 1;
          }

          return a.numero < b.numero;
        }
      },
      filters: [{
        value: '',
        prop: ['mode', 'type', 'date_reglement', 'montant'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'imputation':
                return row[prop] !== null && String(row[prop].numero).toLowerCase().indexOf(filter.value) > -1;

              case 'mode':
                return row[prop] !== null && (0, _utils.getLabelModeReglement)(row[prop]).toLowerCase().indexOf(filter.value) > -1;

              case 'type':
                return row[prop] !== null && (0, _utils.getLabelTypeReglement)(row[prop]).toLowerCase().indexOf(filter.value) > -1;

              case 'date_reglement':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                return (0, _moment.default)(String(row[prop])).format('DD/MM/YYYY').indexOf(filter.value) > -1;

              case 'montant':
                return String((0, _utils.toCurrencyFilter)(row[prop])).toLowerCase().indexOf(filter.value.toLowerCase()) > -1;

              default:
                return false;
            }
          });
        }
      }],
      filterDateReglement: [],
      filterModeReglement: [],
      filterTypeReglement: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['affaire']), {}, (0, _vuex.mapGetters)('facturation', ['getFactureListForCurrentAffaire', 'getReglementList']), {
    /* ---------------------------------------
     *  Forcer l'idFacture parent
     * ---------------------------------------
     *   - pré-filtre de la liste des règlements,
     *   - création d'un nouveau règlement
     */
    idFacture: function idFacture() {
      return this.facture.id;
    },
    data: function data() {
      var data = this.getReglementList(this.idFacture); // ---
      // Initialisation des valeurs du filtre pour la colonne "date de règlement"

      var filterDateReglement = [];
      data.forEach(function (row) {
        if (!filterDateReglement.find(function (x) {
          return x.value === row.date_reglement;
        })) {
          if (row.date_reglement && typeof row.date_reglement !== 'undefined') {
            var item = {
              text: row.date_reglement,
              value: row.date_reglement
            };
            item.text = (0, _moment.default)(String(row.date_reglement)).format('DD/MM/YYYY');
            filterDateReglement.push(item);
          }
        }
      });
      this.setFilter('date_reglement', filterDateReglement); // ---
      // Initialisation des valeurs du filtre pour la colonne "mode de règlement"

      var filterModeReglement = (0, _utils.getListModeReglement)();
      this.setFilter('mode_reglement', filterModeReglement); // ---
      // Initialisation des valeurs du filtre pour la colonne "type de règlement"

      var filterTypeReglement = (0, _utils.getLisTypeReglement)();
      this.setFilter('type_reglement', filterTypeReglement);
      return data;
    },
    total: function total() {
      return this.data.length;
    }
  }),
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)({}, (0, _vuex.mapActions)('facturation', {
    initFacturation: 'init'
  }), {}, (0, _vuex.mapActions)('facturation', ['updateReglement', 'deleteReglement', 'bulkDeleteReglement']), {
    // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
    colWidth: function colWidth(index) {
      if (this.columns[index] === undefined) {
        return;
      }

      return this.columns[index].width;
    },
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (column.property === 'date_reglement') {
          sums[index] = 'Total';
          return;
        }

        if (!['montant'].includes(column.property)) {
          sums[index] = '';
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        sums[index] = (0, _utils.getTotal)(values); // Formattage

        if (column.property === 'montant') {
          sums[index] = (0, _utils.toCurrency)(sums[index]);
        }
      });
      return sums;
    },
    setFilter: function setFilter(prop, filter) {
      switch (prop) {
        case 'date_reglement':
          this.filterDateReglement = filter;
          break;

        case 'mode_reglement':
          this.filterModeReglement = filter;
          break;

        case 'type_reglement':
          this.filterTypeReglement = filter;
          break;
      }
    },
    handleFilter: function handleFilter(value, row, column) {
      var property = column['property'];
      return row[property] === value;
    }
  }) // 11. Rendu
  // --

};
exports.default = _default;