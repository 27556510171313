"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var routes = [{
  path: '/test-example',
  name: 'TestExample',
  meta: {
    title: 'Test',
    icon: 'eye'
  },
  redirect: '/test',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout'));
    });
  },
  children: [{
    path: '/test',
    name: 'Test',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/test'));
      });
    },
    meta: {
      title: 'Page de test',
      activeMenu: '/test',
      breadcrumb: false
    }
  }]
}];
var _default = routes;
exports.default = _default;