var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Form", {
        key: _vm.idSociete,
        attrs: {
          formData: _vm.data,
          idSociete: _vm.idSociete,
          title: _vm.title,
          action: _vm.action
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }