var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sync-scroll-bars" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _vm.modeEdit &&
              _vm.numAvenant === null &&
              _vm.affaire.affaire.etape ===
                _vm.CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value &&
              _vm.affaire.affaire.status !==
                _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:avenant",
                          value: "creer",
                          expression: "'creer'",
                          arg: "avenant"
                        }
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-plus"
                      },
                      on: {
                        click: function($event) {
                          _vm.dlgNewLineVisible = true
                        }
                      }
                    },
                    [_vm._v("Nouvelle ligne\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: _vm.refresh,
          ref: "dataTable",
          staticClass: "avenants",
          attrs: {
            size: "mini",
            data: _vm.lotsDetail,
            "data-sync-scrollbar": "main"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "ordre",
              label: "#",
              fixed: "left",
              width: "40",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { attrs: { title: "id lot: " + scope.row.id } },
                      [_vm._v(_vm._s(scope.$index + 1))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "libelle",
              label: "Lots",
              fixed: "left",
              width: "250"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.libelle))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "prestataire",
              label: "Prestataire",
              fixed: "left",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.prestataire))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Montants des marchés initiaux\\n(1)",
              width: "130",
              align: "right",
              "render-header": _vm.renderHeader
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.calculs[scope.$index]
                              .mtDernierePropositionPresta
                          )
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { label: "Avenants au marché principal", align: "center" }
            },
            [
              _vm._l(_vm.nbCol, function(col) {
                return _c(
                  "el-table-column",
                  {
                    key: col,
                    attrs: { width: "120", align: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              !_vm.modeEdit ||
                              _vm.numAvenant === null ||
                              col !== _vm.numAvenant
                                ? _c("div", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            _vm.calculs[scope.$index]
                                              .mtAvenantMarchePrincipal[col]
                                              ? _vm.calculs[scope.$index]
                                                  .mtAvenantMarchePrincipal[col]
                                                  .mt_avenant
                                              : ""
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ])
                                : _vm.calculs[scope.$index]
                                    .mtAvenantMarchePrincipal[col]
                                ? _c(
                                    "div",
                                    [
                                      _vm.canAddAvenant(scope)
                                        ? _c("el-input", {
                                            directives: [
                                              {
                                                name: "numberonly",
                                                rawName: "v-numberonly"
                                              }
                                            ],
                                            staticClass: "currency",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              size: "mini",
                                              tabindex: "2",
                                              autocomplete: "no"
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.handleInput(
                                                  scope,
                                                  col,
                                                  _vm.calculs[scope.$index]
                                                    .mtAvenantMarchePrincipal[
                                                    col
                                                  ].mt_avenant,
                                                  "refresh_calcul"
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.calculs[scope.$index]
                                                  .mtAvenantMarchePrincipal[col]
                                                  .mt_avenant,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.calculs[scope.$index]
                                                    .mtAvenantMarchePrincipal[
                                                    col
                                                  ],
                                                  "mt_avenant",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "calculs[scope.$index].mtAvenantMarchePrincipal[col].mt_avenant"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _vm.canAddAvenant(scope)
                                        ? _c("el-input", {
                                            directives: [
                                              {
                                                name: "numberonly",
                                                rawName: "v-numberonly"
                                              }
                                            ],
                                            staticClass: "currency",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              size: "mini",
                                              tabindex: "2",
                                              autocomplete: "no"
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.handleInput(
                                                  scope,
                                                  col,
                                                  scope.row.mt_avenant,
                                                  "refresh_calcul"
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.mt_avenant,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "mt_avenant",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.mt_avenant"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _vm._v(
                        "\n          Avenant n°" +
                          _vm._s(col) +
                          " " +
                          _vm._s(_vm._f("toDate")(_vm.dateCol(col))) +
                          "\n        "
                      )
                    ])
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _vm.modeEdit && _vm.numAvenant === null
                ? _c("el-table-column", {
                    attrs: {
                      label: "Avenant n° " + (_vm.nbCol + 1),
                      width: "120",
                      align: "right"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm.canAddAvenant(scope)
                                ? _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "currency",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      tabindex: "2",
                                      autocomplete: "no"
                                    },
                                    on: {
                                      input: function($event) {
                                        _vm.handleInput(
                                          scope,
                                          _vm.nbCol + 1,
                                          scope.row.mt_avenant,
                                          "refresh_calcul"
                                        )
                                      }
                                    },
                                    model: {
                                      value: scope.row.mt_avenant,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "mt_avenant", $$v)
                                      },
                                      expression: "scope.row.mt_avenant"
                                    }
                                  })
                                : _c("span")
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1785905974
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Total Avenant\\n(2)",
                  width: "120",
                  align: "right",
                  "render-header": _vm.renderHeader
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("toCurrency")(
                                _vm.calculs[scope.$index]
                                  .mtTotalLotDetailLigneAvenantMarchePrincipal
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "TOTAL\\n(1) + (2)",
              width: "130",
              align: "right",
              "render-header": _vm.renderHeader
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return (
                      _vm.calculs[scope.$index]
                        .mtTotalLotDetailLigneAvenantMarchePrincipal +
                      _vm.calculs[scope.$index].mtDernierePropositionPresta
                    ).toString() !== "null"
                      ? [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("toCurrency")(
                                  Number(
                                    _vm.calculs[scope.$index]
                                      .mtTotalLotDetailLigneAvenantMarchePrincipal
                                  ) +
                                    Number(
                                      _vm.calculs[scope.$index]
                                        .mtDernierePropositionPresta
                                    )
                                )
                              ) +
                              "\n      "
                          )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: "TotalLot_" + _vm.refresh,
          staticClass: "avenants",
          attrs: {
            data: _vm.totaux,
            size: "mini",
            "row-class-name": "total",
            border: "",
            "data-sync-scrollbar": "footer"
          }
        },
        [
          _c("el-table-column", { attrs: { width: "40", fixed: "left" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { width: "250", fixed: "left" } }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { width: "150", fixed: "left", align: "right" } },
            [_vm._v("S/TOTAL")]
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Montants des marchés initiaux",
              width: "130",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.totaux[scope.$index]
                            .mtTotalLotDernierePropositionPresta
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _vm._l(_vm.nbCol, function(col) {
            return _c("el-table-column", {
              key: "Tot_" + col,
              attrs: { width: "120", align: "right" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.mtTotalLotColonneAvenant(scope.$index, col)
                            )
                          )
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _vm._v(" "),
          _vm.modeEdit && _vm.numAvenant === null
            ? _c("el-table-column", {
                attrs: { width: "120", align: "right" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm._f("toCurrency")(
                                _vm.mtTotalLotColonneAvenant(
                                  scope.$index,
                                  _vm.nbCol + 1
                                )
                              )
                            )
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  985259709
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Total Avenant", width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.mtTotalLotLigneAvenant(scope.$index)
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Total", width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.mtTotalLotLigneAvenant(scope.$index) +
                              _vm.totaux[scope.$index]
                                .mtTotalLotDernierePropositionPresta
                          )
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("dlgNewLineAvenant", {
        attrs: {
          idLot: _vm.idLot,
          ordre: _vm.maxOrdre,
          visible: _vm.dlgNewLineVisible
        },
        on: {
          "update:visible": function($event) {
            _vm.dlgNewLineVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }