"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllByAffaireId = getAllByAffaireId;
exports.insert = insert;
exports.getCurrentPeriodeGestMensByAffaireId = getCurrentPeriodeGestMensByAffaireId;
exports.getAllPeriodeGestMensByAffaireId = getAllPeriodeGestMensByAffaireId;

var _request = _interopRequireDefault(require("@/helpers/request"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

function getAllByAffaireId(id) {
  return (0, _request.default)({
    url: '/historique/affaire/' + id,
    method: 'get'
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/historique',
    method: 'post',
    data: data
  });
}

function getCurrentPeriodeGestMensByAffaireId(id) {
  var data = {
    id: id,
    status: CONST.AFFAIRE.STATUS.VALIDSYNTHESE.value,
    etape: CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value
  };
  return (0, _request.default)({
    url: '/historique/gestmens/currentperiode',
    method: 'post',
    data: data
  });
}

function getAllPeriodeGestMensByAffaireId(id) {
  var data = {
    id: id,
    status: CONST.AFFAIRE.STATUS.VALIDSYNTHESE.value,
    etape: CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value
  };
  return (0, _request.default)({
    url: '/historique/gestmens/allperiode',
    method: 'post',
    data: data
  });
}