var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lots && _vm.lots.length > 0
    ? _c(
        "div",
        [
          _c(
            "el-table",
            {
              key: "TotalGeneral_" + _vm.refresh,
              attrs: {
                data: [{}],
                size: "mini",
                "row-class-name": "total",
                border: "",
                "data-sync-scrollbar": "summaries"
              }
            },
            [
              _c(
                "el-table-column",
                { attrs: { width: "330", fixed: "left", align: "right" } },
                [_vm._v("TOTAL GENERAL")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    width: "120",
                    align: "right",
                    label: "Déboursés étude"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire.mtTotalGeneralDebourseEtudeHT
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { width: "190", align: "right" } },
                [_vm._v(" ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticStyle: {
                "margin-top": "40px",
                background: "#F5F7FA",
                border: "1px solid #EBEEF5",
                padding: "20px"
              }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-row",
                    { key: "totalGeneralVenteInitiale_" + _vm.refresh },
                    [
                      _c("el-col", { staticClass: "col1" }, [
                        _vm._v("Total vente initiale HT")
                      ]),
                      _vm._v(" "),
                      _c("el-col", { staticClass: "col2" }, [
                        (!_vm.$root.modeEdit || _vm.editFields) &&
                        !_vm.changesRemiseCommerciale &&
                        _vm.userCanDoAction &&
                        [
                          _vm.CONST.AFFAIRE.STATUS.CREATED.value,
                          _vm.CONST.AFFAIRE.STATUS.DENIED1.value,
                          _vm.CONST.AFFAIRE.STATUS.DENIED2.value
                        ].includes(_vm.affaire.status) &&
                        _vm.affaire.etape ===
                          _vm.CONST.AFFAIRE.ETAPE.VENTE_INITIALE.value
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "can",
                                    rawName: "v-can:vente_initiale",
                                    value: "editer",
                                    expression: "'editer'",
                                    arg: "vente_initiale"
                                  }
                                ]
                              },
                              [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "numberonly",
                                      rawName: "v-numberonly"
                                    }
                                  ],
                                  ref: "totalGeneralVenteInitiale",
                                  staticClass: "numeric",
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    "data-error":
                                      _vm.errorTotalGeneralVenteInitiale,
                                    size: "mini",
                                    autocomplete: "no"
                                  },
                                  on: {
                                    input:
                                      _vm.handleInputTotalGeneralVenteInitiale
                                  },
                                  model: {
                                    value: _vm.totalGeneralVenteInitiale,
                                    callback: function($$v) {
                                      _vm.totalGeneralVenteInitiale = $$v
                                    },
                                    expression: "totalGeneralVenteInitiale"
                                  }
                                }),
                                _vm._v(" €\n          ")
                              ],
                              1
                            )
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    _vm.totalGeneralVenteInitiale
                                  )
                                )
                              )
                            ])
                      ]),
                      _vm._v(" "),
                      (!_vm.$root.modeEdit || _vm.editFields) &&
                      !_vm.changesRemiseCommerciale &&
                      [
                        _vm.CONST.AFFAIRE.STATUS.CREATED.value,
                        _vm.CONST.AFFAIRE.STATUS.DENIED1.value,
                        _vm.CONST.AFFAIRE.STATUS.DENIED2.value
                      ].includes(_vm.affaire.status) &&
                      _vm.affaire.etape ===
                        _vm.CONST.AFFAIRE.ETAPE.VENTE_INITIALE.value
                        ? _c(
                            "el-col",
                            { staticClass: "col3" },
                            [
                              _vm.changesTotalGeneralVenteInitiale
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-check",
                                        size: "mini",
                                        type: "text"
                                      },
                                      on: {
                                        click:
                                          _vm.handleSaveTotalGeneralVenteInitiale
                                      }
                                    },
                                    [_vm._v("Enregistrer")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.changesTotalGeneralVenteInitiale
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-close",
                                        size: "mini",
                                        type: "text"
                                      },
                                      on: {
                                        click:
                                          _vm.handleCancelTotalGeneralVenteInitiale
                                      }
                                    },
                                    [_vm._v("Annuler")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { key: "remiseCommerciale_" + _vm.refresh },
                    [
                      _c("el-col", { staticClass: "col1" }, [
                        _vm._v("Remise commerciale")
                      ]),
                      _vm._v(" "),
                      _c("el-col", { staticClass: "col2" }, [
                        (!_vm.$root.modeEdit || _vm.editFields) &&
                        !_vm.changesTotalGeneralVenteInitiale &&
                        _vm.userCanDoAction &&
                        [
                          _vm.CONST.AFFAIRE.STATUS.CREATED.value,
                          _vm.CONST.AFFAIRE.STATUS.DENIED1.value,
                          _vm.CONST.AFFAIRE.STATUS.DENIED2.value
                        ].includes(_vm.affaire.status) &&
                        _vm.affaire.etape ===
                          _vm.CONST.AFFAIRE.ETAPE.VENTE_INITIALE.value
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "can",
                                    rawName: "v-can:vente_initiale",
                                    value: "editer",
                                    expression: "'editer'",
                                    arg: "vente_initiale"
                                  }
                                ]
                              },
                              [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "numberonly",
                                      rawName: "v-numberonly"
                                    }
                                  ],
                                  ref: "remiseCommerciale",
                                  staticClass: "numeric",
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    "data-error": _vm.errorRemiseCommerciale,
                                    size: "mini",
                                    autocomplete: "no"
                                  },
                                  on: {
                                    input: _vm.handleInputRemiseCommerciale
                                  },
                                  model: {
                                    value: _vm.remiseCommerciale,
                                    callback: function($$v) {
                                      _vm.remiseCommerciale = $$v
                                    },
                                    expression: "remiseCommerciale"
                                  }
                                }),
                                _vm._v(" €\n          ")
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #999"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(_vm.remiseCommerciale)
                                  )
                                )
                              ]
                            )
                      ]),
                      _vm._v(" "),
                      (!_vm.$root.modeEdit || _vm.editFields) &&
                      !_vm.changesTotalGeneralVenteInitiale &&
                      [
                        _vm.CONST.AFFAIRE.STATUS.CREATED.value,
                        _vm.CONST.AFFAIRE.STATUS.DENIED1.value,
                        _vm.CONST.AFFAIRE.STATUS.DENIED2.value
                      ].includes(_vm.affaire.status) &&
                      _vm.affaire.etape ===
                        _vm.CONST.AFFAIRE.ETAPE.VENTE_INITIALE.value
                        ? _c(
                            "el-col",
                            { staticClass: "col3" },
                            [
                              _vm.changesRemiseCommerciale
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-check",
                                        size: "mini",
                                        type: "text"
                                      },
                                      on: {
                                        click: _vm.handleSaveRemiseCommerciale
                                      }
                                    },
                                    [_vm._v("Enregistrer")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.changesRemiseCommerciale
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-close",
                                        size: "mini",
                                        type: "text"
                                      },
                                      on: {
                                        click: _vm.handleCancelRemiseCommerciale
                                      }
                                    },
                                    [_vm._v("Annuler")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { key: "mtTotalGeneralVenteFinaleHT_" + _vm.refresh },
                    [
                      _c("el-col", { staticClass: "col1" }, [
                        _c(
                          "span",
                          {
                            attrs: {
                              title:
                                "Total vente finale HT = Total vente initiale - Remise commerciale"
                            }
                          },
                          [_vm._v("Total vente finale HT")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { staticClass: "col2" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.calculs.affaire.mtTotalGeneralVenteFinaleHT
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { key: "mtTotalGeneralDebourseEtudeHT_" + _vm.refresh },
                    [
                      _c("el-col", { staticClass: "col1" }, [
                        _c(
                          "span",
                          {
                            attrs: {
                              title:
                                "Total déboursés étude HT = TOTAL GENERAL Déboursés étude"
                            }
                          },
                          [_vm._v("Total déboursés étude HT")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { staticClass: "col2" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.calculs.affaire.mtTotalGeneralDebourseEtudeHT
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      key: "mtTotalGeneralMargeBruteInitialeHT_" + _vm.refresh
                    },
                    [
                      _c("el-col", { staticClass: "col1" }, [
                        _c(
                          "span",
                          {
                            attrs: {
                              title:
                                "Marge brute initiale HT = Total vente finale HT - Total déboursées étude HT"
                            }
                          },
                          [_vm._v("Marge brute initiale HT")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { staticClass: "col2" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.calculs.affaire
                                .mtTotalGeneralMargeBruteInitialeHT
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { key: "pcTotalGeneralInitialeMargeBrute_" + _vm.refresh },
                    [
                      _c("el-col", { staticClass: "col1" }, [
                        _c(
                          "span",
                          {
                            attrs: {
                              title:
                                "% initial de marge brute = Marge brute initiale HT / Total vente finale HT"
                            }
                          },
                          [_vm._v("% initial de marge brute")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("el-col", { staticClass: "col2" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              "margin-right": "-5px"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("toPercentage")(
                                  _vm.calculs.affaire
                                    .pcTotalGeneralInitialeMargeBrute
                                )
                              )
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { key: "coefRemiseCommerciale_" + _vm.refresh },
                    [
                      _c("el-col", { staticClass: "col1" }, [
                        _vm._v("% de remise commerciale")
                      ]),
                      _vm._v(" "),
                      _c("el-col", { staticClass: "col2" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              "margin-right": "-5px"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("toPercentage")(
                                  _vm.calculs.affaire.coefRemiseCommerciale
                                )
                              )
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }