var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.envNode }, [
    _c(
      "div",
      {
        staticClass: "sidebar-logo-container",
        class: { collapse: _vm.collapse }
      },
      [
        _c(
          "transition",
          { attrs: { name: "sidebarLogoFade" } },
          [
            _c(
              "router-link",
              { staticClass: "sidebar-logo-link", attrs: { to: "/" } },
              [
                _c("h1", { staticClass: "sidebar-title" }, [
                  !_vm.collapse
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$store.state.settings.siteTitle))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "env" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.envNode === "development" ? "[DEV]" : "") +
                        "\n          " +
                        _vm._s(_vm.envNode === "staging" ? "[TEST]" : "") +
                        "\n        "
                    )
                  ])
                ])
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }