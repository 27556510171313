"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var routes = [{
  path: '/affaire',
  name: 'Affaire',
  meta: {
    title: 'Gestion des affaires',
    icon: 'affaire'
  },
  redirect: '/affaire/list',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout'));
    });
  },
  children: [{
    path: '/affaire/list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/affaire'));
      });
    },
    name: 'ListAffaire',
    meta: {
      title: 'Liste des affaires',
      activeMenu: '/affaire/list'
    }
  }, {
    path: '/affaire/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/affaire/view'));
      });
    },
    name: 'ViewAffaire',
    meta: {
      title: 'Consultation',
      activeMenu: '/affaire/list'
    }
  }, {
    path: '/affaire/new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/affaire/new'));
      });
    },
    name: 'NewAffaire',
    meta: {
      title: 'Création',
      activeMenu: '/affaire/list'
    }
  }, {
    path: '/affaire/edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/app/affaire/edit'));
      });
    },
    name: 'EditAffaire',
    meta: {
      title: 'Édition',
      activeMenu: '/affaire/list'
    }
  }]
}];
var _default = routes;
exports.default = _default;