"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SageList = _interopRequireDefault(require("@/components/SageList"));

//
//
//
//
//
//
//
//
//
// import Cookies from 'js-cookie'
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'SageIndex',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    SageList: _SageList.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      activeMenu: 'sage_ligne_1000',
      factureId: null
    };
  },
  // 9. Évènements
  // --
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // Suppression du cookie mémorisant le filtre sur les affaires
    // if (['VenteCreance.Synthese.CreancesIndex'].includes(from.name)) {
    //   Cookies.remove('venteCreances.synthese.filtersValues', { path: '' })
    // }
    next();
  },
  // 10. Propriétés non réactives
  // --
  methods: {} // 11. Rendu
  // --

};
exports.default = _default;