"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.number.parse-float");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var apiTva = _interopRequireWildcard(require("@/api/tva"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'TvaForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new', 'edit'].includes(value);
      }
    },
    formData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      validator: function validator(value) {
        return value.length > 0;
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      rules: {
        compte_general: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        taux: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      }
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this.action) {
            case 'new':
              _this.actionNew();

              break;

            case 'edit':
              _this.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this2 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiTva.insert(data).then(function (results) {
        _this2.$message('Création effectuée avec succès');

        _this2.resetForm();

        _this2.$loader(false);

        _this2.$router.push('/admin/tva');
      }).catch(function (error) {
        _this2.$message(error);

        _this2.$loader(false);
      });
    },
    actionEdit: function actionEdit() {
      var _this3 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiTva.update(this.formData.id, data).then(function (results) {
        _this3.$message('Mise à jour effectuée avec succès');

        _this3.$loader(false);

        _this3.$router.push('/admin/tva');
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    getFormData: function getFormData() {
      return {
        taux: Number.parseFloat(this.formData.taux).toFixed(2),
        compte_general: this.formData.compte_general,
        idc_defaut: this.formData.idc_defaut
      };
    }
  } // 11. Rendu
  // --

};
exports.default = _default;