var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dateDuJour" },
    [
      _vm._v("\n  Simulation date du jour"),
      _c("br"),
      _vm._v(" "),
      _c("el-date-picker", {
        staticClass: "dtPicker",
        attrs: {
          type: "date",
          format: "dd/MM/yyyy",
          "value-format": "dd/MM/yyyy",
          placeholder: "Sélectionnez une date",
          size: "mini"
        },
        on: {
          change: function($event) {
            return _vm.onChange($event)
          }
        },
        model: {
          value: _vm.curDate,
          callback: function($$v) {
            _vm.curDate = $$v
          },
          expression: "curDate"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }