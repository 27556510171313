var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataLoaded
    ? _c(
        "div",
        [
          _vm.idCommande
            ? _c("h1", [
                _vm.total === 0
                  ? _c("span", [
                      _vm._v(
                        "Aucune facture pour la commande n°" +
                          _vm._s(_vm.commande.numero)
                      )
                    ])
                  : _vm.total === 1
                  ? _c("span", [
                      _vm._v(
                        "Facture de la commande n°" +
                          _vm._s(_vm.commande.numero)
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        "Liste des " +
                          _vm._s(_vm.total) +
                          " factures de la commande n°" +
                          _vm._s(_vm.commande.numero)
                      )
                    ])
              ])
            : _c("h1", [
                _vm.total === 0
                  ? _c("span", [_vm._v("Aucune facture pour l'affaire")])
                  : _vm.total === 1
                  ? _c("span", [_vm._v("Facture de l'affaire")])
                  : _c("span", [
                      _vm._v(
                        "Liste des " +
                          _vm._s(_vm.total) +
                          " factures de l'affaire"
                      )
                    ])
              ]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _vm.affaire.affaire.status !==
                  _vm.CONST.AFFAIRE.STATUS.CLOSED.value &&
                _vm.affaire.affaire.status !==
                  _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-circle-plus-outline",
                              size: "mini",
                              type: "primary"
                            },
                            on: { click: _vm.handleNewFacture }
                          },
                          [_vm._v("\n          Nouvelle facture\n        ")]
                        ),
                        _vm._v(" "),
                        _vm.multipleSelection.length > 0
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "can",
                                    rawName: "v-can:facturation",
                                    value: "administrer",
                                    expression: "'administrer'",
                                    arg: "facturation"
                                  }
                                ],
                                attrs: {
                                  icon: "el-icon-delete",
                                  size: "mini",
                                  type: "danger"
                                },
                                on: { click: _vm.handleDeleteSelection }
                              },
                              [
                                _vm._v(
                                  "\n          Supprimer la sélection\n        "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticStyle: { "white-space": "nowrap" },
                  attrs: { span: 12, align: "right" }
                },
                [
                  _vm._v("\n      Date de facturation du: \n      "),
                  _c("el-date-picker", {
                    staticStyle: { width: "125px" },
                    attrs: {
                      format: _vm.dateFormat,
                      clearable: "",
                      placeholder: "",
                      size: "mini",
                      type: "date",
                      "value-format": "yyyy-MM-dd"
                    },
                    on: { change: _vm.handleChangeDateMin },
                    model: {
                      value: _vm.filters[1].value,
                      callback: function($$v) {
                        _vm.$set(_vm.filters[1], "value", $$v)
                      },
                      expression: "filters[1].value"
                    }
                  }),
                  _vm._v("\n       \n      au: \n      "),
                  _c("el-date-picker", {
                    staticStyle: { width: "125px" },
                    attrs: {
                      format: _vm.dateFormat,
                      clearable: "",
                      placeholder: "",
                      size: "mini",
                      type: "date",
                      "value-format": "yyyy-MM-dd"
                    },
                    on: { change: _vm.handleChangeDateMax },
                    model: {
                      value: _vm.filters[2].value,
                      callback: function($$v) {
                        _vm.$set(_vm.filters[2], "value", $$v)
                      },
                      expression: "filters[2].value"
                    }
                  }),
                  _vm._v("\n       \n      "),
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        clearable: "",
                        placeholder: "Rechercher",
                        size: "mini"
                      },
                      model: {
                        value: _vm.filters[0].value,
                        callback: function($$v) {
                          _vm.$set(_vm.filters[0], "value", $$v)
                        },
                        expression: "filters[0].value"
                      }
                    },
                    [_vm._v("\n        >\n      ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "data-tables",
            {
              directives: [
                {
                  name: "fixed-header",
                  rawName: "v-fixed-header",
                  value: { startFixed: 150 },
                  expression: "{ startFixed: 150 }"
                }
              ],
              ref: "dataTable",
              attrs: {
                layout: "tool, table",
                data: _vm.data,
                filters: _vm.filters,
                "sort-method": _vm.sortMethod,
                "table-props": {
                  showSummary: true,
                  summaryMethod: _vm.getSummaries,
                  defaultSort: { prop: "date_facture", order: "descending" }
                },
                total: _vm.total
              },
              on: {
                "row-click": function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.handleCurrentChange($event)
                },
                "selection-change": _vm.handleSelectionChange
              }
            },
            [
              _c("el-table-column", {
                directives: [
                  {
                    name: "can",
                    rawName: "v-can:facturation",
                    value: "administrer",
                    expression: "'administrer'",
                    arg: "facturation"
                  }
                ],
                attrs: { type: "selection", width: "45" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "70" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("el-button", {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:facturation",
                                value: "administrer",
                                expression: "'administrer'",
                                arg: "facturation"
                              }
                            ],
                            attrs: { icon: "el-icon-delete", type: "text" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleDeleteFacture(
                                  scope.$index,
                                  scope.row
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("el-button", {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:facturation",
                                value: "administrer",
                                expression: "'administrer'",
                                arg: "facturation"
                              }
                            ],
                            attrs: { icon: "el-icon-edit", type: "text" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleEditFacture(
                                  scope.$index,
                                  scope.row
                                )
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4024412368
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["numero"].key,
                attrs: {
                  sortable: "custom",
                  label: "Numéro",
                  prop: "numero",
                  width: _vm.colWidth("numero")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row["numero"]))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1968099927
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["date_facture"].key,
                attrs: {
                  label: "Date",
                  prop: "date_facture",
                  sortable: "custom",
                  filters: _vm.filterDateFacture,
                  "filter-method": _vm.handleFilter,
                  width: _vm.colWidth("date_facture")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toDate")(scope.row["date_facture"])
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  650460086
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["libelle"].key,
                attrs: {
                  sortable: "custom",
                  label: "Libellé",
                  prop: "libelle",
                  width: _vm.colWidth("libelle")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row["libelle"]))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1511577598
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["montant_ht"].key,
                attrs: {
                  align: "right",
                  sortable: "custom",
                  label: "Montant HT",
                  prop: "montant_ht",
                  width: _vm.colWidth("montant_ht")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(scope.row["montant_ht"])
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2382162076
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["retenue_garantie"].key,
                attrs: {
                  align: "right",
                  sortable: "custom",
                  label: "RG",
                  prop: "retenue_garantie",
                  width: _vm.colWidth("retenue_garantie")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  scope.row["retenue_garantie"]
                                )
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1495332664
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["mt_avant_paiement_direct_ttc"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["mt_avant_paiement_direct_ttc"].label,
                  prop: _vm.columns["mt_avant_paiement_direct_ttc"].prop,
                  width: _vm.colWidth("mt_avant_paiement_direct_ttc"),
                  align: "right"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  scope.row.mt_avant_paiement_direct_ttc
                                )
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3347314016
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["mt_paiement_direct_st"].key,
                attrs: {
                  align: "right",
                  sortable: "custom",
                  label: "Paiement direct",
                  prop: "mt_paiement_direct_st",
                  width: _vm.colWidth("mt_paiement_direct_st")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  scope.row["mt_paiement_direct_st"]
                                )
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2485817055
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["mt_a_percevoir_ttc"].key,
                attrs: {
                  align: "right",
                  sortable: "custom",
                  label: "TTC après P. Direct",
                  prop: "mt_a_percevoir_ttc",
                  width: _vm.colWidth("mt_a_percevoir_ttc")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  scope.row["mt_a_percevoir_ttc"]
                                )
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2389163163
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["date_echeance"].key,
                attrs: {
                  sortable: "custom",
                  filters: _vm.filterDateEcheance,
                  "filter-method": _vm.handleFilter,
                  label: "Échéance",
                  prop: "date_echeance",
                  width: _vm.colWidth("date_echeance")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toDate")(scope.row["date_echeance"])
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1151515430
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["commande"].key,
                attrs: {
                  sortable: "custom",
                  label: "Commande",
                  prop: "commande",
                  width: _vm.colWidth("commande")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                scope.row.commande
                                  ? scope.row.commande.numero
                                  : "n/c"
                              )
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3340426486
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["emetteur"].key,
                attrs: {
                  sortable: "custom",
                  label: "Emetteur",
                  prop: "emetteur",
                  width: _vm.colWidth("emetteur")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("div", [_vm._v(_vm._s(scope.row.emetteur))])]
                      }
                    }
                  ],
                  null,
                  false,
                  598424094
                )
              }),
              _vm._v(" "),
              _vm.$route.name === "ViewAffaire"
                ? _c("el-table-column", {
                    attrs: { align: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-dropdown",
                                { on: { command: _vm.handleCommand } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "el-dropdown-link",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v("\n            Actions "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right"
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            icon: "el-icon-circle-plus",
                                            command: {
                                              action: "paiement",
                                              index: scope.$index,
                                              row: scope.row
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              Ajouter un paiement\n            "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            icon: "el-icon-edit-outline",
                                            command: {
                                              action: "commentaire",
                                              row: scope.row
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                !scope.row.commentaire
                                                  ? "Ajouter un commentaire"
                                                  : "Modifier le commentaire"
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            icon: "el-icon-document",
                                            command: {
                                              action: "visualiser",
                                              row: scope.row
                                            },
                                            disabled: !scope.row.fichier
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              Visualiser\n            "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            icon: "el-icon-money",
                                            divided: "",
                                            command: {
                                              action: "reglements",
                                              index: scope.$index,
                                              row: scope.row
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              Gérer les réglements\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3893487649
                    )
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: { title: "Détail", visible: _vm.dialogDetailVisible },
              on: {
                "update:visible": function($event) {
                  _vm.dialogDetailVisible = $event
                }
              }
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("Commande")]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _vm.row.commande
                        ? [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.row.commande.numero) +
                                " du " +
                                _vm._s(
                                  _vm._f("toDate")(
                                    _vm.row.commande.date_commande
                                  )
                                ) +
                                " pour " +
                                _vm._s(
                                  _vm._f("toCurrency")(_vm.row.commande.montant)
                                ) +
                                "\n        "
                            )
                          ]
                        : [_vm._v("n/c")]
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("strong", [_vm._v("Numéro")])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.row.numero))])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("Libellé")]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(_vm._s(_vm.row.libelle))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("Émetteur")]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(_vm._s(_vm.row.emetteur))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v("Date de facturation")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c("i", { staticClass: "el-icon-date" }),
                    _vm._v(" " + _vm._s(_vm._f("toDate")(_vm.row.date_facture)))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v("Date d'échéance")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c("i", { staticClass: "el-icon-date" }),
                    _vm._v(
                      " " + _vm._s(_vm._f("toDate")(_vm.row.date_echeance))
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("Montant")]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.row.montant)))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v("Révision / Actualisation HT")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.row.rev_actu)))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("Montant HT")]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.row.montant_ht)))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("Taux de TVA")]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("toPercentage")(
                            _vm.row.tva ? _vm.row.tva.taux / 100 : ""
                          )
                        ) +
                        " \n        ("
                    ),
                    _c("span", { staticStyle: { "font-style": "italic" } }, [
                      _vm._v(
                        "compte n° " +
                          _vm._s(_vm.row.tva ? _vm.row.tva.compte_general : "")
                      )
                    ]),
                    _vm._v(")\n      ")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v("Retenue de garantie TTC")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(_vm.row.retenue_garantie))
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("Divers TTC")]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.row.divers_ttc)))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v("TTC avant Paiement Direct")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.row.mt_avant_paiement_direct_ttc
                        )
                      )
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v("Montant Paiement Direct sous-traitant TTC")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(_vm.row.mt_paiement_direct_st)
                      )
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v("TTC après Paiement Direct")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(_vm.row.mt_a_percevoir_ttc))
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("Fichier")]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm.row.fichier
                      ? _c("div", [
                          _c(
                            "a",
                            {
                              staticClass: "el-button el-button--text",
                              attrs: { title: "voir le fichier" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.handleViewFile(_vm.row)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "el-icon-document" }),
                              _vm._v("LA " + _vm._s(_vm.row.nom_original))
                            ]
                          )
                        ])
                      : _c("div", [_c("span", [_vm._v("n/c")])])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("Commentaire")]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(_vm._s(_vm.row.commentaire))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v("Nb règlement")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(_vm._s(Number(_vm.row.nb_reglement)))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v("Montant réglé")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(_vm.row.montant_regle_moins_impute)
                      )
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "Commentaire pour la facture n°" + _vm.row.numero,
                visible: _vm.dialogFactureCommentaireVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFactureCommentaireVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.row } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Commentaire" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "150",
                          "show-word-limit": "",
                          type: "textarea"
                        },
                        model: {
                          value: _vm.row.commentaire,
                          callback: function($$v) {
                            _vm.$set(_vm.row, "commentaire", $$v)
                          },
                          expression: "row.commentaire"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFactureCommentaireVisible = false
                        }
                      }
                    },
                    [_vm._v("\n        Annuler\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.actionEditCommentaire }
                    },
                    [_vm._v("\n        Enregistrer\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }