"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var apiSysPermissions = _interopRequireWildcard(require("@/api/sys_permissions"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ListPermissions',
  props: ['rules'],
  data: function data() {
    return {
      data: [],
      list: [],
      selection: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.list = [];

    if (this.rules) {
      var rules = JSON.parse(this.rules);

      if (rules) {
        Object.keys(rules).map(function (key) {
          _this.list[key] = rules[key].split(',');
        });
      }
    }
  },
  beforeMount: function beforeMount() {
    var _this2 = this;

    apiSysPermissions.getAll().then(function (results) {
      _this2.data = results;
    }).catch(function (error) {
      _this2.$message(error);
    });
  },
  methods: {
    itemList: function itemList(key) {
      var array = this.data.find(function (x) {
        return x.key === key;
      });

      if (array.rules) {
        return array.rules.split(',');
      } else {
        return [];
      }
    },
    onChange: function onChange(keyname, row) {
      var _this3 = this;

      var selection = {};
      Object.keys(this.list).map(function (key) {
        var rules = _this3.list[key].join(',');

        if (rules) {
          selection[key] = rules;
        }
      });
      this.$emit('update:rules', JSON.stringify(selection));
    }
  }
};
exports.default = _default;