"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var apiSecteurGeo = _interopRequireWildcard(require("@/api/secteurgeo"));

//
//
//
//
//
//
//
//
var _default = {
  name: 'SecteurGeo',
  props: ['idSociete', 'idSecteur', 'secteurGeo'],
  data: function data() {
    return {
      secteurs: [],
      id_secteur: this.idSecteur
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    apiSecteurGeo.getAllActifBySocieteId(this.idSociete).then(function (results) {
      _this.secteurs = results;

      var secteur = _this.secteurs.find(function (x) {
        return x.id === _this.idSecteur;
      });

      if (secteur) {
        _this.$emit('update:secteurGeo', secteur);
      }
    }).catch(function (error) {
      _this.$message(error);
    });
  },
  methods: {
    onClear: function onClear() {
      this.$emit('update:idSecteur', null);
      this.$emit('update:secteurGeo', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:idSecteur', index);
        this.$emit('update:secteurGeo', this.secteurs.find(function (x) {
          return x.id === index;
        }));
      }
    }
  }
};
exports.default = _default;