"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var apiUser = _interopRequireWildcard(require("@/api/user"));

var apiPermission = _interopRequireWildcard(require("@/api/permission"));

var validate = _interopRequireWildcard(require("@/helpers/validate"));

var _utils = require("@/helpers/utils");

var apiMail = _interopRequireWildcard(require("@/api/mail"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    formData: Object,
    title: String,
    action: String
  },
  data: function data() {
    var _this = this;

    var validPwd1 = function validPwd1(rule, value, callback) {
      if (_this.action === 'new' && _this.pwd1 === '') {
        callback(new Error('Mot de passe obligatoire'));
      } else if (_this.pwd1 !== '' && !validate.isStrongPassword(_this.pwd1)) {
        callback(new Error('Le mot de passe ne respecte pas les règles de sécurité'));
      }

      callback();
    };

    var validPwd2 = function validPwd2(rule, value, callback) {
      if (_this.pwd1 !== '' && _this.pwd2 !== _this.pwd1) {
        callback(new Error('Le mot de passe de confirmation n\'est pas correct'));
      }

      callback();
    };

    var validEmail = function validEmail(rule, value, callback) {
      value = value || '';

      if (_this.action === 'edit' && _this.oldEmail === '') {
        _this.oldEmail = value;
        callback();
      } else {
        if (!validate.validEmail(value)) {
          callback(new Error('Adresses email non valide'));
        } else {
          if (_this.oldEmail !== _this.formData.email) {
            var user = {
              id_societe: _this.$store.getters.user.id_societe,
              email: value
            };
            apiUser.getBySocieteEmail(user).then(function (results) {
              if (results.length > 0) {
                callback(new Error('Adresse email déjà utilisée pour cette société'));
              }

              callback();
            });
          } else {
            callback();
          }
        }
      }
    };

    return {
      rules: {
        firstname: [{
          required: true,
          message: 'Prénom non valide'
        }],
        lastname: [{
          required: true,
          message: 'Nom non valide'
        }],
        email: [{
          required: true,
          validator: validEmail,
          trigger: ['change', 'blur']
        }],
        pwd1: [{
          validator: validPwd1,
          required: this.action === 'new'
        }],
        pwd2: [{
          validator: validPwd2,
          required: this.action === 'new'
        }],
        key_role: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      },
      roles: [],
      oldEmail: '',
      oldPassword: '',
      passwordType: '',
      pwd1: '',
      pwd2: ''
    };
  },
  created: function created() {
    var _this2 = this;

    apiPermission.getAllBySocieteId(this.$store.getters.user.id_societe).then(function (results) {
      _this2.roles = results;
    }).catch(function (error) {
      _this2.$message(error);
    });
  },
  methods: {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this3 = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          var pwd = _this3.pwd1 || '';

          if (pwd !== '') {
            _this3.$confirm('Voulez-vous envoyer par mail les informations de connexion à cet utilisateur ?', 'Confirmation', {
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              type: 'warning'
            }).then(function () {
              _this3.setAction(true);
            }).catch(function () {
              _this3.setAction(false);
            });
          } else {
            _this3.setAction(false);
          }
        }
      });
    },
    setAction: function setAction(sendmail) {
      var user = {
        id_societe: this.$store.getters.user.id_societe,
        firstname: this.formData.firstname,
        lastname: this.formData.lastname.toUpperCase(),
        email: this.formData.email,
        enabled: Number(this.formData.enabled) || 0,
        key_role: this.formData.key_role
      };
      var newPassword = this.pwd1 || '';

      if (newPassword !== '') {
        user.password = (0, _utils.encodePassword)(newPassword);
      }

      switch (this.action) {
        case 'new':
          this.actionNew(user, sendmail);
          break;

        case 'edit':
          this.actionEdit(user, sendmail);
          break;
      }
    },
    actionNew: function actionNew(user, sendmail) {
      var _this4 = this;

      this.$loader(true);
      apiUser.insert(user).then(function (results) {
        if (sendmail) {
          _this4.sendMail();
        }

        _this4.$message('Création effectuée avec succès');

        _this4.resetForm();

        _this4.pwd1 = '';
        _this4.pwd2 = '';

        _this4.$loader(false);
      }).catch(function (error) {
        _this4.$message(error);

        _this4.$loader(false);
      });
    },
    actionEdit: function actionEdit(user, sendmail) {
      var _this5 = this;

      this.$loader(true);
      apiUser.update(this.formData.id, user).then(function (results) {
        if (sendmail) {
          _this5.sendMail();
        }

        _this5.pwd1 = '';
        _this5.pwd2 = '';
        _this5.oldEmail = _this5.formData.email;

        _this5.$message('Mise à jour effectuée avec succès');

        _this5.$loader(false);

        _this5.$router.go(-1);
      }).catch(function (error) {
        _this5.$message(error);

        _this5.$loader(false);

        _this5.$router.go(-1);
      });
    },
    genPassword: function genPassword() {
      var newPwd = (0, _utils.generatePassword)();
      this.pwd1 = this.pwd2 = newPwd;
      this.passwordType = '';
      this.$refs.pwd1.focus();
    },
    sendMail: function sendMail() {
      var data = {
        to: this.formData.email,
        bcc: this.$store.getters.user.email,
        user_societe: this.$store.getters.user.societe_name,
        user_firstname: this.formData.firstname,
        user_lastname: this.formData.lastname,
        user_email: this.formData.email,
        user_password: this.pwd1
      };
      apiMail.newUser(data);
    }
  }
};
exports.default = _default;