"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var routes = [{
  path: '/user-notification',
  name: 'UserNotification',
  meta: {
    title: 'Mes Notifications',
    icon: 'bell'
  },
  redirect: '/notification',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout'));
    });
  },
  children: [{
    path: '/notification',
    name: 'Notification',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/notification'));
      });
    },
    meta: {
      title: 'Notifications',
      activeMenu: '/notification',
      breadcrumb: false
    }
  }]
}];
var _default = routes;
exports.default = _default;