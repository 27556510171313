"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'LotDetailTotauxGestMensuelle',
  props: ['idLot'],
  data: function data() {
    return {
      refresh: 0,
      totaux: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.totaux = _this.$store.getters.affaire.calculs.lots.filter(function (x) {
        return x.id === _this.idLot;
      });
    });

    this.refresh++;
  },
  methods: {
    cellClassName: function cellClassName(_ref) {
      var columnIndex = _ref.columnIndex;

      if (columnIndex >= 11 && columnIndex <= 13) {
        return 'tb-pattern';
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  }
};
exports.default = _default;