"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.parse-float");

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'FactureInfos',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    facture: {
      type: Object
    },
    montantReglement: {
      type: Number,
      default: 0
    },
    montantReglementInitial: {
      type: Number,
      default: 0
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      spanCol1: 8,
      spanCol2: 16,
      spanCol3: 3,
      spanCol4: 6
    };
  },
  computed: {
    /* ---------------------------------------
     * Paiement et Remboursement de trop-perçus
     * ---------------------------------------
     *
     */
    montantCumuleRegle: function montantCumuleRegle() {
      return this.facture.montant_regle - this.montantReglementInitial;
    },
    montantRestantDu: function montantRestantDu() {
      return this.facture.mt_a_percevoir_ttc - this.montantCumuleRegle + this.facture.montant_impute;
    },
    solde: function solde() {
      return Number.parseFloat(this.montantRestantDu).toFixed(2) - Number.parseFloat(this.montantReglement).toFixed(2);
    },
    // TODO Voir pour indiquer le montant de trop-perçu éventuellement
    montantTropPercu: function montantTropPercu() {
      return 0;
    }
  } // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  // 11. Rendu
  // --

};
exports.default = _default;