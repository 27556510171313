"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var apiClient = _interopRequireWildcard(require("@/api/client"));

//
//
//
//
//
//
//
//
var _default = {
  name: 'Client',
  props: ['idClient', 'client'],
  data: function data() {
    return {
      clients: [],
      id_client: this.idClient
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    apiClient.getAllActif().then(function (results) {
      _this.clients = results.filter(function (x) {
        return x.id_societe === _this.$store.getters.user.id_societe;
      }); // on filtre sur la societe

      var client = _this.clients.find(function (x) {
        return x.id === _this.idClient;
      });

      if (client) {
        _this.$emit('update:client', client);
      }
    }).catch(function (error) {
      _this.$message(error);
    });
  },
  methods: {
    onClear: function onClear() {
      this.$emit('update:idClient', null);
      this.$emit('update:client', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:idClient', index);
        this.$emit('update:client', this.clients.find(function (x) {
          return x.id === index;
        }));
      }
    }
  }
};
exports.default = _default;