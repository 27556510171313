var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { attrs: { id: "content-affaire" } }, [
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.idFacture) +
              "\n      " +
              _vm._s(_vm.facture) +
              "\n      "
          ),
          _c("reglement-module", { attrs: { "id-facture": _vm.idFacture } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }