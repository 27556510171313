var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showMenu
    ? _c("div", { staticStyle: { "min-width": "1100px!important" } }, [
        _c(
          "div",
          { attrs: { id: "header-affaire" } },
          [
            _c(
              "el-row",
              { staticClass: "btn-back" },
              [
                _c(
                  "el-col",
                  { attrs: { span: 5 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-arrow-left", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.$router.go(-1)
                          }
                        }
                      },
                      [_vm._v("Retour")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    key: "periode_" + _vm.refresh,
                    attrs: { span: 14, align: "center" }
                  },
                  [
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v(" " + _vm._s(_vm.periodeGestion()) + " ")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 5, align: "right" } }, [
                  _vm.$root.modeEdit
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _c("i", { staticClass: "el-icon-edit" }),
                        _vm._v(" Edition en cours...")
                      ])
                    : _vm._e()
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 6, align: "left" } }, [
                  _c("h2", { staticClass: "numAffaire" }, [
                    _vm._v(_vm._s(_vm.affaire.num_affaire))
                  ])
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 12, align: "center" } }, [
                  _c("h2", { staticClass: "nomAffaire" }, [
                    _vm._v(_vm._s(_vm.affaire.nom))
                  ])
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 6, align: "right" } }, [
                  _vm.showPeriode
                    ? _c("h2", { staticClass: "statusAffaire" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-block",
                              "padding-top": "5px"
                            }
                          },
                          [_vm._v(_vm._s(_vm.status.caption))]
                        ),
                        _vm._v(" "),
                        _vm.allPeriodeGestMens.length > 0 &&
                        _vm.currentPeriodeGestMens
                          ? _c(
                              "div",
                              { attrs: { id: "selPeriodeGestMens" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    class: _vm.canEdit() ? "current" : "",
                                    attrs: {
                                      "value-key": "currentPeriodeGestMens",
                                      size: "mini"
                                    },
                                    on: {
                                      change: _vm.handleChangePeriodeGestMens
                                    },
                                    model: {
                                      value: _vm.currentPeriodeGestMens,
                                      callback: function($$v) {
                                        _vm.currentPeriodeGestMens = $$v
                                      },
                                      expression: "currentPeriodeGestMens"
                                    }
                                  },
                                  _vm._l(_vm.allPeriodeGestMens, function(
                                    periode,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        value: periode.value,
                                        label: periode.label
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _c("h2", [
                        _c("small", { staticStyle: { color: "#409EFF" } }, [
                          _vm._v("Chargement en cours...")
                        ])
                      ])
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-menu",
              {
                key: "menu_" + _vm.refresh,
                ref: "ElMenu",
                staticClass: "el-menu-demo",
                staticStyle: { "border-top": "1px solid #eee" },
                attrs: {
                  "default-active": _vm.highlightedActiveMenu,
                  mode: "horizontal"
                },
                on: { select: _vm.handleMenuSelect }
              },
              [
                _c("el-menu-item", { attrs: { index: "informations" } }, [
                  _vm._v("Informations affaire")
                ]),
                _vm._v(" "),
                _c(
                  "el-menu-item",
                  {
                    attrs: {
                      index: "venteInitiale",
                      disabled: _vm.isDisabled(
                        _vm.CONST.AFFAIRE.ETAPE.VENTE_INITIALE.value
                      )
                    }
                  },
                  [_vm._v("Vente initiale")]
                ),
                _vm._v(" "),
                _vm.CONST.AFFAIRE.ETAPE.OBJECTIF_DEBOURSE.value ===
                _vm.affaire.etape
                  ? _c(
                      "el-submenu",
                      {
                        attrs: {
                          index: "idx-objectifsDebourses",
                          disabled: _vm.isDisabled(
                            _vm.CONST.AFFAIRE.ETAPE.OBJECTIF_DEBOURSE.value
                          )
                        }
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v("Objectifs déboursés")
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          {
                            staticStyle: {
                              "border-bottom": "1px solid #efefef"
                            },
                            attrs: { index: "objectifsDebourses" }
                          },
                          [_vm._v("Objectifs déboursés")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          { attrs: { index: "provisions-objectifsDebourses" } },
                          [_vm._v("Aléas")]
                        )
                      ],
                      2
                    )
                  : _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index: "objectifsDebourses",
                          disabled: _vm.isDisabled(
                            _vm.CONST.AFFAIRE.ETAPE.OBJECTIF_DEBOURSE.value
                          )
                        }
                      },
                      [_vm._v("Objectifs déboursés\n      ")]
                    ),
                _vm._v(" "),
                _vm.CONST.AFFAIRE.ETAPE.BUDGET_AFFAIRE.value ===
                _vm.affaire.etape
                  ? _c(
                      "el-submenu",
                      {
                        attrs: {
                          index: "idx-budgetAffaire",
                          disabled: _vm.isDisabled(
                            _vm.CONST.AFFAIRE.ETAPE.BUDGET_AFFAIRE.value
                          )
                        }
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v("Budget affaire")
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          {
                            staticStyle: {
                              "border-bottom": "1px solid #efefef"
                            },
                            attrs: { index: "budgetAffaire" }
                          },
                          [_vm._v("Budget affaire")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          { attrs: { index: "provisions-budgetAffaire" } },
                          [_vm._v("Aléas")]
                        )
                      ],
                      2
                    )
                  : _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index: "budgetAffaire",
                          disabled: _vm.isDisabled(
                            _vm.CONST.AFFAIRE.ETAPE.BUDGET_AFFAIRE.value
                          )
                        }
                      },
                      [_vm._v("Budget affaire\n      ")]
                    ),
                _vm._v(" "),
                _c(
                  "el-submenu",
                  {
                    attrs: {
                      index: "idx",
                      disabled: _vm.isDisabled(
                        _vm.CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value
                      )
                    }
                  },
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v("Gestion mensuelle")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        staticStyle: { "border-bottom": "1px solid #efefef" },
                        attrs: { index: "gestionMensuelle" }
                      },
                      [_vm._v("Gestion mensuelle")]
                    ),
                    _vm._v(" "),
                    _c("el-menu-item", { attrs: { index: "avenants" } }, [
                      _vm._v("Avenants")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      { attrs: { index: "provisions-gestionMensuelle" } },
                      [_vm._v("Aléas")]
                    ),
                    _vm._v(" "),
                    _c("el-menu-item", { attrs: { index: "budgetscomp" } }, [
                      _vm._v("Budgets complémentaires")
                    ]),
                    _vm._v(" "),
                    _c("el-menu-item", { attrs: { index: "transferts" } }, [
                      _vm._v("Transferts")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      { attrs: { index: "provisions-risques" } },
                      [_vm._v("Provisions")]
                    ),
                    _vm._v(" "),
                    _c("li", { staticClass: "el-menu-divider" }),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      { attrs: { index: "syntheseMensuelle" } },
                      [_vm._v("Synthèse mensuelle")]
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "commentaire" } }, [
                  _vm._v("Commentaires")
                ]),
                _vm._v(" "),
                _c(
                  "el-submenu",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:facturation",
                        value: "suivre",
                        expression: "'suivre'",
                        arg: "facturation"
                      }
                    ],
                    attrs: {
                      index: "idx_2",
                      disabled: _vm.isDisabled(
                        _vm.CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value
                      )
                    }
                  },
                  [
                    _c("template", { slot: "title" }, [_vm._v("Facturation")]),
                    _vm._v(" "),
                    _c("el-menu-item", { attrs: { index: "commandes" } }, [
                      _vm._v("Commandes")
                    ]),
                    _vm._v(" "),
                    _c("el-menu-item", { attrs: { index: "factures" } }, [
                      _vm._v("Factures")
                    ]),
                    _vm._v(" "),
                    _c("el-menu-item", { attrs: { index: "reglements" } }, [
                      _vm._v("Règlements")
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "historique" } }, [
                  _vm._v("Historique")
                ]),
                _vm._v(" "),
                false
                  ? _c("el-menu-item", { attrs: { index: "debug" } }, [
                      _vm._v("Debug")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            ["venteInitiale", "budgetAffaire", "gestionMensuelle"].includes(
              _vm.activeMenu
            )
              ? _c("div", [_c("Zoomer")], 1)
              : _vm._e(),
            _vm._v(" "),
            [
              "venteInitiale",
              "budgetAffaire",
              "gestionMensuelle",
              "syntheseMensuelle"
            ].includes(_vm.activeMenu)
              ? _c(
                  "div",
                  [_c("Printer", { key: "printer_" + _vm.activeMenu })],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { attrs: { id: "content-affaire" } }, [
          _c("div", { staticStyle: { "margin-top": "10px" } }, [
            _vm.activeMenu === "informations"
              ? _c(
                  "div",
                  [
                    _c("infos-affaire-top"),
                    _vm._v(" "),
                    _c("infos-affaire-bottom")
                  ],
                  1
                )
              : _vm.activeMenu === "venteInitiale"
              ? _c("div", [_c("vente-initiale")], 1)
              : _vm.activeMenu === "objectifsDebourses"
              ? _c("div", [_c("objectifs")], 1)
              : _vm.activeMenu === "budgetAffaire"
              ? _c("div", [_c("budget-affaire")], 1)
              : _vm.activeMenu === "gestionMensuelle"
              ? _c("div", [_c("gest-mensuelle")], 1)
              : _vm.activeMenu === "syntheseMensuelle"
              ? _c("div", [_c("synth-mensuelle")], 1)
              : _vm.activeMenu === "avenants"
              ? _c("div", [_c("avenants")], 1)
              : _vm.activeMenu === "avenants_travaux_suppl"
              ? _c("div", [_c("avenants-travaux-suppl")], 1)
              : _vm.activeMenu === "avenants_marche_princ"
              ? _c("div", [_c("avenants-marche-princ")], 1)
              : _vm.activeMenu === "budgetscomp"
              ? _c("div", [_c("budgets-comp")], 1)
              : [
                  "provisions-objectifsDebourses",
                  "provisions-budgetAffaire",
                  "provisions-gestionMensuelle"
                ].includes(_vm.activeMenu)
              ? _c("div", [_c("provisions")], 1)
              : _vm.activeMenu === "provisions-risques"
              ? _c("div", [_c("provisions-risques")], 1)
              : _vm.activeMenu === "transferts"
              ? _c("div", [_c("transferts")], 1)
              : _vm.activeMenu === "commentaire"
              ? _c("div", [_c("commentaire")], 1)
              : _vm.activeMenu === "commandes"
              ? _c("div", [_c("commande-module")], 1)
              : _vm.activeMenu === "factures"
              ? _c("div", [_c("facture-module")], 1)
              : _vm.activeMenu === "factures_by_commande"
              ? _c(
                  "div",
                  [
                    _c("facture-module", {
                      attrs: { "id-commande": _vm.id_commande }
                    })
                  ],
                  1
                )
              : _vm.activeMenu === "reglements"
              ? _c("div", [_c("reglement-module")], 1)
              : _vm.activeMenu === "reglements_by_facture"
              ? _c(
                  "div",
                  [
                    _c("reglement-module", {
                      attrs: { "id-facture": _vm.id_facture }
                    })
                  ],
                  1
                )
              : _vm.activeMenu === "historique"
              ? _c("div", [_c("historique")], 1)
              : _vm.activeMenu === "debug"
              ? _c("div", [_c("debug")], 1)
              : _vm._e()
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }