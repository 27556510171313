"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var apiSecteurAct = _interopRequireWildcard(require("@/api/secteuract"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SecteurAct',
  props: ['idSociete', 'idSecteur', 'secteurAct'],
  data: function data() {
    return {
      secteurs: [],
      id_secteur: this.idSecteur,
      show: false
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    apiSecteurAct.getAllActifBySocieteId(this.idSociete).then(function (results) {
      _this.secteurs = results;

      _this.onChange(_this.idSecteur);
    }).then(function () {
      _this.show = true;
    }).catch(function (error) {
      _this.$message(error);
    });
  },
  methods: {
    onClear: function onClear() {
      this.$emit('update:idSecteur', null);
      this.$emit('update:secteurAct', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:idSecteur', index);
        this.$emit('update:secteurAct', this.secteurs.find(function (x) {
          return x.id === index;
        }));
      }
    }
  }
};
exports.default = _default;