"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _eventBus = require("@/helpers/event-bus.js");

var _CommandeList = _interopRequireDefault(require("./CommandeList"));

var _CommandeNew = _interopRequireDefault(require("./CommandeNew"));

var _CommandeEdit = _interopRequireDefault(require("./CommandeEdit"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'CommandeModule',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    CommandeList: _CommandeList.default,
    CommandeNew: _CommandeNew.default,
    CommandeEdit: _CommandeEdit.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      CONST: {
        // Mode d'affichage : list, new, edit
        MODE_VIEW: {
          LIST: 'list',
          NEW: 'new',
          EDIT: 'edit'
        }
      },
      // Mode d'affichage actif
      modeView: '',
      // Identifiant de la commande à éditer
      idCommande: 0
    };
  },
  computed: {
    /* ---------------------------------------
     *  Mode saisie des commandes
     * ---------------------------------------
     *   - true si modeView = 'new' || 'edit'
     *   - false si modeView = 'list'
     */
    modeEdit: function modeEdit() {
      return [this.CONST.MODE_VIEW.NEW, this.CONST.MODE_VIEW.EDIT].includes(this.modeView);
    }
  },
  // 9. Évènements
  // --
  created: function created() {
    // Mode d'affichage actif
    this.modeView = this.CONST.MODE_VIEW.LIST;
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('COMMANDE_NEW', function () {
      _this.modeView = _this.CONST.MODE_VIEW.NEW;
    });

    _eventBus.EventBus.$on('COMMANDE_EDIT', function (row) {
      _this.idCommande = row.id;
      _this.modeView = _this.CONST.MODE_VIEW.EDIT;
    });

    _eventBus.EventBus.$on('COMMANDE_SAVE', function () {
      _this.idCommande = 0;
      _this.modeView = _this.CONST.MODE_VIEW.LIST;
    });

    _eventBus.EventBus.$on('COMMANDE_RETURN', function () {
      _this.idCommande = 0;
      _this.modeView = _this.CONST.MODE_VIEW.LIST;
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('COMMANDE_NEW');

    _eventBus.EventBus.$off('COMMANDE_EDIT');

    _eventBus.EventBus.$off('COMMANDE_SAVE');

    _eventBus.EventBus.$off('COMMANDE_RETURN');
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    handleReturn: function handleReturn() {
      _eventBus.EventBus.$emit('COMMANDE_RETURN');
    }
  } // 11. Rendu
  // --

};
exports.default = _default;