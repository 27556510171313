"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.sort");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var apiEnseigne = _interopRequireWildcard(require("@/api/enseigne"));

var apiClient = _interopRequireWildcard(require("@/api/client"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      row: [],
      dialogDetailVisible: false,
      // -----
      total: 0,
      clients: [],
      data: [],
      multipleSelection: [],
      columns: [{
        prop: 'client',
        label: 'Client'
      }, {
        prop: 'libelle',
        label: 'Nom enseigne'
      }, {
        prop: 'code',
        label: 'Code enseigne',
        width: '200'
      }, {
        prop: 'interlocuteur',
        label: 'Interlocuteur'
      }, {
        prop: 'code_postal',
        label: 'Code Postal'
      }, {
        prop: 'ville',
        label: 'Ville'
      }, {
        prop: 'actif',
        label: 'Actif'
      }],
      filters: [{
        value: '',
        prop: ['client', 'code', 'libelle', 'interlocuteur', 'code_postal', 'ville']
      }]
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user']), {
    idSociete: function idSociete() {
      return this.user.id_societe;
    }
  }),
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;

      this.$loader(true);
      this.clients = [];
      this.data = [];
      apiClient.getAllBySocieteId(this.idSociete).then(function (results) {
        _this.clients = results;
        apiEnseigne.getAll().then(function (results) {
          var aSort = results.sort(function (a, b) {
            return a.code + a.libelle < b.code + b.libelle ? -1 : 1;
          });
          aSort.forEach(function (row) {
            var client = _this.clients.find(function (x) {
              return x.id === row.id_client;
            });

            if (client) {
              row.client = client ? client.code : '';
              row.interlocuteur = row.nom_interlocuteur ? row.nom_interlocuteur : '';
              row.interlocuteur += row.prenom_interlocuteur ? ' ' + row.prenom_interlocuteur : '';
              row.actif = row.actif === 1 ? '✓' : '✗';

              _this.data.push(row);
            }
          });
          _this.data.total = results.length;

          _this.$loader(false);
        }).catch(function (error) {
          _this.$message(error);

          _this.$loader(false);
        });
      });
    },
    ColWidth: function ColWidth(index) {
      // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
      if (index === 0) {
        // client
        return 350;
      } else if (index === 1) {
        // nom enseigne
        return 350;
      } else if (index === 2) {
        // code enseigne
        return 150;
      } else if (index === 4) {
        // code postal
        return 125;
      } else if (index === 6) {
        return 70;
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.row = val;
      this.dialogDetailVisible = true;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;

      this.$confirm('Voulez-vous supprimer l\'enseigne ' + row.libelle + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this2.$loader(true);

        apiEnseigne.deleteById(row.id).then(function (results) {
          _this2.data.splice(_this2.data.indexOf(row), 1);

          _this2.$message('Suppression effectuée avec succès');

          _this2.$loader(false);
        }).catch(function (err) {
          _this2.$message(err);

          _this2.$loader(false);
        });
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this3 = this;

      var msg = this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée';
      this.$confirm('Voulez-vous supprimer ' + msg + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this3.$loader(true);

        var selection = _this3.multipleSelection.map(function (u) {
          return u.id;
        }).join(',');

        apiEnseigne.bulkDelete(selection).then(function (results) {
          _this3.$message('Suppression effectuée avec succès');

          _this3.loadData();

          _this3.$loader(false);
        }).catch(function (error) {
          _this3.$message(error);

          _this3.$loader(false);
        });
      });
    }
  }
};
exports.default = _default;