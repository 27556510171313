"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DateDuJour',
  props: ['dateDuJour'],
  data: function data() {
    return {
      curDate: (0, _moment.default)().format('DD/MM/YYYY')
    };
  },
  methods: {
    onChange: function onChange(dt) {
      if (dt) {
        this.$emit('update:dateDuJour', dt);
      }
    }
  }
};
exports.default = _default;