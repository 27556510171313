"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'listEtape',
  props: ['valueEtapeSelected', 'etape', 'placeholder'],
  data: function data() {
    return {
      etapes: [],
      valueEtape: this.valueEtapeSelected,
      show: false
    };
  },
  beforeMount: function beforeMount() {
    this.etapes = CONST.AFFAIRE.ETAPE;
    this.onChange(this.valueEtapeSelected);
    this.show = true;
  },
  methods: {
    onClear: function onClear() {
      this.$emit('update:value-etape-selected', null);
      this.$emit('update:etape', null);
    },
    onChange: function onChange(value) {
      for (var etape in this.etapes) {
        if (Number(this.etapes[etape].value) === Number(value)) {
          this.$emit('update:value-etape-selected', value);
          this.$emit('update:etape', this.etapes[etape]);
        }
      }
    }
  }
};
exports.default = _default;