var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lots && _vm.lots.length > 0
    ? _c(
        "div",
        { staticClass: "sync-scroll-bars" },
        [
          _c(
            "el-table",
            {
              key: "TotalGeneral_" + _vm.refresh,
              staticClass: "budaff",
              attrs: {
                data: [{}],
                size: "mini",
                "row-class-name": "total",
                "data-sync-scrollbar": "summaries"
              }
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "left", width: "40", align: "right" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "",
                  width: "20",
                  fixed: "left",
                  align: "center"
                }
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { fixed: "left", width: "250", align: "right" } },
                [_vm._v("TOTAL GENERAL")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "Objectif Affaire", align: "center" } },
                [
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        width: "120",
                        align: "right",
                        label: "Obj débours exécution"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.calculs.affaire.mtTotalGeneralObjDebourseExec
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "separator", width: "15" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "Budget Affaire", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: { width: "190", label: "Prestataire" }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        width: "120",
                        label: "Dernière proposition",
                        align: "right"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.calculs.affaire
                              .mtTotalGeneralDernierePropositionPresta
                          )
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    { attrs: { width: "120", label: "Écart", align: "right" } },
                    [
                      _vm.calculs.affaire.mtTotalGeneralEcart > 0
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    _vm.calculs.affaire.mtTotalGeneralEcart
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    _vm.calculs.affaire.mtTotalGeneralEcart
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    { attrs: { width: "120", label: "Aléas", align: "right" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.calculs.affaire.mtTotalGeneralAleas
                            )
                          ) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-table-column", { attrs: { width: "45" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: "SyntheseL1_" + _vm.refresh,
              staticClass: "budaff",
              attrs: {
                data: [{}],
                size: "mini",
                "data-sync-scrollbar": "summaries",
                "header-row-class-name": "budaffHeader"
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-desc",
                    width: "310",
                    fixed: "left",
                    align: "right"
                  }
                },
                [_vm._v("Rappel vente initiale HT")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire.mtTotalGeneralVenteFinaleHT
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "separator", width: "15" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-desc",
                    width: "190",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "Budget final HT = TOTAL GENERAL Dernières propositions presta + Aléas"
                      }
                    },
                    [_vm._v("Budget final HT")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire.mtTotalGeneralBudgetFinalHT
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "120" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "120" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "45" } },
                [_vm._v(" ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: "SyntheseL3_" + _vm.refresh,
              staticClass: "budaff",
              attrs: {
                data: [{}],
                size: "mini",
                "data-sync-scrollbar": "summaries",
                "header-row-class-name": "budaffHeader"
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-desc",
                    width: "310",
                    fixed: "left",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "TOTAL GENERAL Objectifs débours exécution + Aléas"
                      }
                    },
                    [_vm._v("Budget objectif initial HT")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire
                          .mtTotalGeneralBudgetObjectifInitialHT
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "separator", width: "15" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-desc",
                    width: "190",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "MB finale = Rappel vente initiale HT - Budget final HT"
                      }
                    },
                    [_vm._v("MB finale")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm._f("toCurrency")(_vm.calculs.affaire.mtMBFinale))
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "120" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "120" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "45" } },
                [_vm._v(" ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: "SyntheseL4_" + _vm.refresh,
              staticClass: "budaff",
              attrs: {
                data: [{}],
                size: "mini",
                "data-sync-scrollbar": "summaries",
                "header-row-class-name": "budaffHeader"
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-desc",
                    width: "310",
                    fixed: "left",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "Objectif initial de MB = Rappel vente initiale HT - Budget objectif initial HT"
                      }
                    },
                    [_vm._v("Objectif initial de MB")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire.mtObjectifInitialMB
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "separator", width: "15" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-desc",
                    width: "190",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "% MB finale = MB finale / Rappel vente initiale HT"
                      }
                    },
                    [_vm._v("% MB finale")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toPercentage")(_vm.calculs.affaire.pcMBFinale)
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "120" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "120" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "45" } },
                [_vm._v(" ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: "SyntheseL5_" + _vm.refresh,
              staticClass: "budaff",
              attrs: {
                data: [{}],
                size: "mini",
                "data-sync-scrollbar": "summaries",
                "header-row-class-name": "budaffHeader"
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-desc",
                    width: "310",
                    fixed: "left",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "% MB initiale = Objectif initial de MB / Rappel vente initiale HT"
                      }
                    },
                    [_vm._v("% MB initiale")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toPercentage")(
                        _vm.calculs.affaire.pcObjectifInitialMB
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "separator", width: "15" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-desc",
                    width: "190",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title: "Écart sur budget objectif = TOTAL GENERAL Écart"
                      }
                    },
                    [_vm._v("Écart sur budget objectif")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm.calculs.affaire.mtTotalGeneralEcart > 0
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm._f("toCurrency")(
                                _vm.calculs.affaire.mtTotalGeneralEcart
                              )
                            ) +
                            "\n      "
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm._f("toCurrency")(
                                _vm.calculs.affaire.mtTotalGeneralEcart
                              )
                            ) +
                            "\n      "
                        )
                      ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "120" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "120" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "tb-pattern", width: "45" } },
                [_vm._v(" ")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }