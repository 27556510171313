"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var _moment = _interopRequireDefault(require("moment"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Synthse',
  data: function data() {
    return {
      CONST: CONST,
      refresh: 0,
      totaux: [],
      data: [],
      dataMmoins1: [],
      avenantMarchePrincipal: [],
      dataSave: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.data = _this.$store.getters.affaire.calculs.affaire;
      _this.dataMmoins1 = _this.$store.getters.affaire.calculsMmoins1;
      _this.totaux = _this.$store.getters.affaire.calculs.affaire;
      _this.avenantMarchePrincipal = _this.$store.getters.affaire.calculs.avenantsMarchePrincipalEntetes;
      _this.gestmensAffaire = _this.$store.getters.affaire.gestmensAffaire.filter(function (x) {
        return x.anneemois === _this.data.YYYYMM;
      })[0] || [];
    });

    this.refresh++;
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  },
  computed: {
    currentYYYYMM: function currentYYYYMM() {
      return (0, _moment.default)().format('YYYYMM');
    },
    mtMoisFacturationClient: function mtMoisFacturationClient() {
      return this.data.mtFacturationClient || 0;
    },
    mtMoisFactureMerite: function mtMoisFactureMerite() {
      var pv = this.dataMmoins1.mtPmPrixDeVente || 0;
      return this.data.mtPmPrixDeVente - pv;
    }
  }
};
exports.default = _default;