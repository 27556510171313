"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var apiClient = _interopRequireWildcard(require("@/api/client"));

//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ListClientMultiple',
  props: ['data'],
  data: function data() {
    return {
      clients: [],
      values: this.data
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user']), {
    idSociete: function idSociete() {
      return this.user.id_societe;
    }
  }),
  beforeMount: function beforeMount() {
    var _this = this;

    this.clients = [];
    apiClient.getAllBySocieteId(this.idSociete).then(function (results) {
      _this.clients = results;
    }).catch(function (error) {
      _this.$message(error);
    });
  },
  methods: {
    onClear: function onClear() {
      this.values = [];
    },
    onChange: function onChange(selection) {
      if (selection.includes(0)) {
        this.values = this.clients.map(function (x) {
          return x.id;
        });
      } else if (this.selection !== undefined) {
        this.values.push(this.selection);
      }

      this.$emit('update:data', this.values);
      this.$emit('update:change', this.values); // hack pour gérer un 2ieme event
    }
  }
};
exports.default = _default;