"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableauRecapDeboursesIndirects',
  props: ['data', 'year'],
  data: function data() {
    return {
      dspData: []
    };
  },
  beforeMount: function beforeMount() {
    for (var data in this.data) {
      this.dspData.push(this.data[data]);
    }
  }
};
exports.default = _default;