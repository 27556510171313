var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticStyle: { margin: "10px 0 10px 0" } },
        [
          _c("el-col", { attrs: { span: 10 } }, [
            _c("h1", [_vm._v("Synthèse de Gestion")])
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { staticStyle: { "text-align": "right" }, attrs: { span: 14 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-view"
                  },
                  on: { click: _vm.validateForm }
                },
                [_vm._v("Rechercher")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.resetForm } },
                [_vm._v("Reset")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-d-arrow-right",
                    type: "primary",
                    size: "mini",
                    loading: _vm.btnExportLoading.excel,
                    disabled: !(_vm.rptData && _vm.rptData.length > 0)
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleExport("excel")
                    }
                  }
                },
                [_vm._v("\n          Export Excel\n        ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          key: "Form_" + _vm.refresh,
          ref: "ElForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "250px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { margin: "10px 0 10px 0" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Secteur d'activité :",
                        prop: "secteursact"
                      }
                    },
                    [
                      _c("listSecteurActMultiple", {
                        attrs: { data: _vm.secteursact },
                        on: {
                          "update:data": function($event) {
                            _vm.secteursact = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Client :", prop: "clients" } },
                    [
                      _c("listClientMultiple", {
                        attrs: { data: _vm.clients },
                        on: {
                          "update:data": [
                            function($event) {
                              _vm.clients = $event
                            },
                            function($event) {
                              _vm.enseignes = []
                              _vm.refreshEnseigne++
                            }
                          ]
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Enseigne :", prop: "enseignes" } },
                    [
                      _c("listEnseigneMultiple", {
                        key: "Enseigne_" + _vm.refreshEnseigne,
                        attrs: { data: _vm.enseignes, idsClients: _vm.clients },
                        on: {
                          "update:data": function($event) {
                            _vm.enseignes = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Secteur géographique :",
                        prop: "secteursgeo"
                      }
                    },
                    [
                      _c("listSecteurGeoMultiple", {
                        attrs: { data: _vm.secteursgeo },
                        on: {
                          "update:data": function($event) {
                            _vm.secteursgeo = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Conducteur :", prop: "conducteur" } },
                    [
                      _c("listCascaderUtilisateur", {
                        attrs: {
                          idUserSelected: _vm.conducteur,
                          idSociete: _vm.user.id_societe,
                          user: _vm.conducteur,
                          clearable: true
                        },
                        on: {
                          "update:idUserSelected": function($event) {
                            _vm.conducteur = $event
                          },
                          "update:id-user-selected": function($event) {
                            _vm.conducteur = $event
                          },
                          "update:user": function($event) {
                            _vm.conducteur = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Classement :", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "Sélection",
                            size: "mini",
                            multiple: "",
                            clearable: "",
                            filtreable: ""
                          },
                          model: {
                            value: _vm.status,
                            callback: function($$v) {
                              _vm.status = $$v
                            },
                            expression: "status"
                          }
                        },
                        _vm._l(_vm.options, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Gestion mensuelle de :",
                        prop: "currentDate"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          format: "MM/yyyy",
                          "value-format": "yyyyMM",
                          size: "mini",
                          type: "month",
                          placeholder: "MM/AAAA"
                        },
                        model: {
                          value: _vm.currentDate,
                          callback: function($$v) {
                            _vm.currentDate = $$v
                          },
                          expression: "currentDate"
                        }
                      }),
                      _vm._v(" "),
                      _vm.showError
                        ? _c(
                            "el-tag",
                            {
                              attrs: {
                                size: "small",
                                type: "danger",
                                effect: "dark"
                              }
                            },
                            [
                              _vm._v(
                                "\n            Aucune donnée pour la période de gestion mensuelle sélectionnée\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.rptData,
            size: "mini",
            "summary-method": _vm.getSummaries,
            "show-summary": "",
            height: "calc(100vh - 370px)"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "num_affaire",
              label: "Code affaire",
              width: "250",
              fixed: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      { attrs: { href: "/#/affaire/" + scope.row.id } },
                      [_vm._v(_vm._s(scope.row.num_affaire))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Données générales affaire", align: "center" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "nom",
                  label: "Affaire",
                  width: "300",
                  fixed: "left"
                }
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "ca_initial_ht",
                      label: "CA initial HT",
                      width: "120",
                      align: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtCAinitialHT,
                                    "ca_initial_ht",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "mb_etude",
                      label: "MB étude",
                      width: "120",
                      align: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtMB_Etude,
                                    "mb_etude",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pc_mb",
                      label: "%MB",
                      width: "120",
                      align: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toPercentage")(
                                  _vm._f("toSumPc")(
                                    scope.row.pcMB_Etude,
                                    "pc_mb",
                                    "ca_initial_ht",
                                    "mb_etude",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ca_fdc_ht",
                      label: "CA Fdc HT",
                      width: "120",
                      align: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtCaFdcHT,
                                    "ca_fdc_ht",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "mb_exe",
                      label: "MB Exé",
                      width: "120",
                      align: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtMB_Exe,
                                    "mb_exe",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pc_mb_exe",
                      label: "%MB",
                      width: "120",
                      align: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toPercentage")(
                                  _vm._f("toSumPc")(
                                    scope.row.pcMB_Exe,
                                    "pc_mb_exe",
                                    "ca_fdc_ht",
                                    "mb_exe",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Données affaire", align: "center" } },
            [
              _c(
                "el-table-column",
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "% Avancement cumulé",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toPercentage")(
                                  scope.row.pcAvancementGlobal
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "engage",
                      label: "Engagés",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtEngages,
                                    "engage",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Gestion N-1", align: "center" } },
            [
              _c(
                "el-table-column",
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "conso_pm",
                      label: "PM",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtPM_ConsommeNmoins1,
                                    "conso_pm",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "conso_mb",
                      label: "MB",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtMB_ConsommeNmoins1,
                                    "conso_mb",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pc_conso_mb",
                      label: "%MB",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toPercentage")(
                                  _vm._f("toSumPc")(
                                    scope.row.pcMB_ConsommeNmoins1,
                                    "pc_conso_mb",
                                    "conso_pm",
                                    "conso_mb",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Gestion M", align: "center" } },
            [
              _c(
                "el-table-column",
                { attrs: { label: "Mensuelle", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "mens_pm",
                      label: "PM",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtPM_M,
                                    "mens_pm",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "mens_mb",
                      label: "MB",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtMB_M,
                                    "mens_mb",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pc_mens_mb",
                      label: "%MB",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toPercentage")(
                                  _vm._f("toSumPc")(
                                    scope.row.pcMB_M,
                                    "pc_mens_mb",
                                    "mens_pm",
                                    "mens_mb",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "separator", width: "15" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "Cumulée N", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "solde_pm",
                      label: "PM",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtSoldeN_PM,
                                    "solde_pm",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "solde_mb",
                      label: "MB",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtSoldeN_MB,
                                    "solde_mb",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pc_solde_mb",
                      label: "%MB",
                      align: "right",
                      with: "110"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toPercentage")(
                                  _vm._f("toSumPc")(
                                    scope.row.pcSoldeN_MB,
                                    "pc_solde_mb",
                                    "solde_pm",
                                    "solde_mb",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "separator", width: "15" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "Cumulée totale", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "cumul_pm",
                      label: "PM",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtPM_Cumule,
                                    "cumul_pm",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cumul_mb",
                      label: "MB",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtMB_Cumule,
                                    "cumul_mb",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pc_cumul_mb",
                      label: "%MB",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toPercentage")(
                                  _vm._f("toSumPc")(
                                    scope.row.pcMB_Cumule,
                                    "pc_cumul_mb",
                                    "cumul_pm",
                                    "cumul_mb",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Solde à produire", align: "center" } },
            [
              _c(
                "el-table-column",
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "solde_prod_pm",
                      label: "PM",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtSoldeAProduire_PM,
                                    "solde_prod_pm",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "solde_prod_mb",
                      label: "MB",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("toSumMt")(
                                    scope.row.mtSoldeAProduire_MB,
                                    "solde_prod_mb",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pc_solde_prod_mb",
                      label: "%MB",
                      align: "right",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("toPercentage")(
                                  _vm._f("toSumPc")(
                                    scope.row.pcSoldeAProduire_MB,
                                    "pc_solde_prod_mb",
                                    "solde_prod_pm",
                                    "solde_prod_mb",
                                    _vm.sums
                                  )
                                )
                              )
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }