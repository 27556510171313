"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

//
//
//
//
//
var _default = {
  name: 'SyncScrollBars',
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.setEvents();
    });
  },
  methods: {
    setEvents: function setEvents() {
      var _this2 = this;

      var elmnts = document.querySelectorAll('.sync-scroll-bars div.el-table__body-wrapper');
      elmnts.forEach(function (element) {
        element.addEventListener('scroll', _this2.synchronize);
      });
    },
    synchronize: function synchronize(e) {
      var scrollBarPosition = e.srcElement.scrollLeft;
      var elmntsTB = document.querySelectorAll('.sync-scroll-bars div.el-table__body-wrapper');
      elmntsTB.forEach(function (element) {
        element.scrollLeft = scrollBarPosition;
      });
      var elmntsTH = document.querySelectorAll('.sync-scroll-bars div.el-table__header-wrapper');
      elmntsTH.forEach(function (element) {
        element.scrollLeft = scrollBarPosition;
      });
    }
  }
};
exports.default = _default;