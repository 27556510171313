"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AffaireTotaux',
  data: function data() {
    return {
      refresh: 0,
      calculs: [],
      lots: [],
      modeEdit: false,
      nbCol: 0
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.nbCol = this.getNbCol();

    _eventBus.EventBus.$on('REFRESH', function (params) {
      _this.calculs = _this.$store.getters.affaire.calculs;
      _this.lots = _this.$store.getters.affaire.lots;
      _this.refresh++;

      if (params) {
        _this.nbCol = _this.affaire.nbColAvenantMarchePrincipal;
      }
    });

    _eventBus.EventBus.$on('AVENANT_MARCHE_PRINC_NEW', function () {
      _this.modeEdit = true;
    });

    _eventBus.EventBus.$on('AVENANT_MARCHE_PRINC_CANCEL', function () {
      _this.modeEdit = false;
    });

    _eventBus.EventBus.$on('AVENANT_MARCHE_PRINC_SAVE', function () {
      _this.modeEdit = false;
      _this.nbCol = _this.getNbCol();
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');

    _eventBus.EventBus.$off('AVENANT_MARCHE_PRINC_NEW');

    _eventBus.EventBus.$off('AVENANT_MARCHE_PRINC_CANCEL');

    _eventBus.EventBus.$off('AVENANT_MARCHE_PRINC_SAVE');
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['affaire'])),
  methods: {
    getNbCol: function getNbCol() {
      return this.affaire.nbColAvenantMarchePrincipal;
      /*
      const avenantsMarchePrincipal = this.affaire.avenantsMarchePrincipal
      return avenantsMarchePrincipal.length > 0 ? Math.max.apply(Math, avenantsMarchePrincipal.map(function (o) { return o.num_colonne })) : 0
      */
    },
    dateCol: function dateCol(numCol) {
      if (this.affaire.calculs.affaire) {
        return this.affaire.calculs.affaire.dateColonneAvenantMarchePrincipal[numCol];
      }
    }
  }
};
exports.default = _default;