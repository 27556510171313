var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { key: "Top_" + _vm.refresh, staticStyle: { "margin-bottom": "20px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12, align: "left" } },
            [
              _vm.isDraft()
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-check"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$router.push(
                            "/affaire/edit/" + _vm.affaire.affaire.id
                          )
                        }
                      }
                    },
                    [_vm._v("Modifier affaire\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canAskSleeping()
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:affaire",
                          value: "demander_mise_en_sommeil",
                          expression: "'demander_mise_en_sommeil'",
                          arg: "affaire"
                        }
                      ],
                      attrs: {
                        type: "success",
                        size: "mini",
                        icon: "el-icon-moon"
                      },
                      on: { click: _vm.handleAskSleeping }
                    },
                    [_vm._v("Demander mise en sommeil\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canSleeping()
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:affaire",
                          value: "mettre_en_sommeil",
                          expression: "'mettre_en_sommeil'",
                          arg: "affaire"
                        }
                      ],
                      attrs: {
                        type: "success",
                        size: "mini",
                        icon: "el-icon-moon"
                      },
                      on: { click: _vm.handleSleeping }
                    },
                    [_vm._v("Mettre en sommeil\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canAskWakeUp()
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:affaire",
                          value: "demander_reveil",
                          expression: "'demander_reveil'",
                          arg: "affaire"
                        }
                      ],
                      attrs: {
                        type: "success",
                        size: "mini",
                        icon: "el-icon-alarm-clock"
                      },
                      on: { click: _vm.handleAskWakeUp }
                    },
                    [_vm._v("Demander réveil\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canWakeUp()
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:affaire",
                          value: "reveiller",
                          expression: "'reveiller'",
                          arg: "affaire"
                        }
                      ],
                      attrs: {
                        type: "success",
                        size: "mini",
                        icon: "el-icon-alarm-clock"
                      },
                      on: { click: _vm.handleWakeUp }
                    },
                    [_vm._v("Réveiller affaire\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.affaire.affaire.etape >=
                _vm.CONST.AFFAIRE.ETAPE.VENTE_INITIALE.value &&
              this.affaire.affaire.status !==
                _vm.CONST.AFFAIRE.STATUS.CLOSED.value &&
              this.affaire.affaire.status !==
                _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:affaire",
                          value: "cloturer",
                          expression: "'cloturer'",
                          arg: "affaire"
                        }
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-lock"
                      },
                      on: { click: _vm.handleClosed }
                    },
                    [_vm._v("Clôturer affaire\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.affaire.affaire.status ===
              _vm.CONST.AFFAIRE.STATUS.CLOSED.value
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:affaire",
                          value: "rouvrir",
                          expression: "'rouvrir'",
                          arg: "affaire"
                        }
                      ],
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-document-add"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleOpen()
                        }
                      }
                    },
                    [_vm._v("Rouvrir affaire\n      ")]
                  )
                : _vm._e(),
              _vm._v("\n       \n    ")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 1, align: "center" } },
            [
              _vm._v("\n       \n      "),
              _vm.isDraft()
                ? _c(
                    "el-tag",
                    {
                      attrs: {
                        type: "warning",
                        size: "mini",
                        icon: "el-icon-tickets"
                      }
                    },
                    [_vm._v("Brouillon")]
                  )
                : _vm._e(),
              _vm._v("\n       \n    ")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 11, align: "right" } },
            [
              _vm._v(" \n\n      "),
              _vm.canAskArchiving()
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:affaire",
                          value: "demander_archivage",
                          expression: "'demander_archivage'",
                          arg: "affaire"
                        }
                      ],
                      attrs: {
                        type: "danger",
                        size: "mini",
                        icon: "el-icon-box"
                      },
                      on: { click: _vm.handleAskArchiving }
                    },
                    [_vm._v("Demander archivage\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canArchiving()
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:affaire",
                          value: "archiver",
                          expression: "'archiver'",
                          arg: "affaire"
                        }
                      ],
                      attrs: {
                        type: "danger",
                        size: "mini",
                        icon: "el-icon-box"
                      },
                      on: { click: _vm.handleArchiving }
                    },
                    [_vm._v("Archiver affaire\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "can",
                      rawName: "v-can:affaire",
                      value: "dupliquer",
                      expression: "'dupliquer'",
                      arg: "affaire"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-document-copy"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleDuplicate()
                    }
                  }
                },
                [_vm._v("Dupliquer affaire\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("Société :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm._v(
                      _vm._s(
                        _vm.affaire.societe ? _vm.affaire.societe.name : ""
                      )
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("\n          Nom affaire : \n          "),
                    !_vm.editNOM &&
                    ![
                      _vm.CONST.AFFAIRE.STATUS.SLEEPING.value,
                      _vm.CONST.AFFAIRE.STATUS.CLOSED.value,
                      _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                    ].includes(_vm.affaire.affaire.status)
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ],
                            staticStyle: { display: "inline" }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                size: "mini",
                                circle: "",
                                icon: "el-icon-edit"
                              },
                              on: { click: _vm.handleChangeNOM }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm.editNOM
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ],
                            staticClass: "editNOM"
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                clearable: "",
                                autocomplete: "no",
                                maxlength: "100"
                              },
                              model: {
                                value: _vm.affaire.affaire.nom,
                                callback: function($$v) {
                                  _vm.$set(_vm.affaire.affaire, "nom", $$v)
                                },
                                expression: "affaire.affaire.nom"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-close"
                                },
                                on: { click: _vm.handleCancelNOM }
                              },
                              [_vm._v("Annuler")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-check"
                                },
                                on: { click: _vm.handleSaveNOM }
                              },
                              [_vm._v("Enregistrer")]
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.affaire.affaire.nom) +
                              "\n          "
                          )
                        ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("Secteur d'activité :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm._v(
                      _vm._s(
                        _vm.affaire.secteurAct
                          ? _vm.affaire.secteurAct.libelle
                          : ""
                      )
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("\n          Client : \n          "),
                    !_vm.editCLIENT &&
                    ![
                      _vm.CONST.AFFAIRE.STATUS.SLEEPING.value,
                      _vm.CONST.AFFAIRE.STATUS.CLOSED.value,
                      _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                    ].includes(_vm.affaire.affaire.status) &&
                    !_vm.workflow.client.required &&
                    _vm.workflow.client.visible
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ],
                            staticStyle: { display: "inline" }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                size: "mini",
                                circle: "",
                                icon: "el-icon-edit"
                              },
                              on: { click: _vm.handleChangeCLIENT }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm.editCLIENT
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ]
                          },
                          [
                            _c("listClient", {
                              key: "client_" + _vm.formData.id_client,
                              attrs: {
                                idClient: _vm.formData.id_client,
                                client: _vm.selection.client
                              },
                              on: {
                                "update:idClient": function($event) {
                                  return _vm.$set(
                                    _vm.formData,
                                    "id_client",
                                    $event
                                  )
                                },
                                "update:id-client": function($event) {
                                  return _vm.$set(
                                    _vm.formData,
                                    "id_client",
                                    $event
                                  )
                                },
                                "update:client": function($event) {
                                  return _vm.$set(
                                    _vm.selection,
                                    "client",
                                    $event
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-close"
                                },
                                on: { click: _vm.handleCancelCLIENT }
                              },
                              [_vm._v("Annuler")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-check"
                                },
                                on: { click: _vm.handleSaveCLIENT }
                              },
                              [_vm._v("Enregistrer")]
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.affaire.client
                                  ? _vm.affaire.client.libelle
                                  : ""
                              ) +
                              "\n          "
                          )
                        ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("\n          Enseigne : \n          "),
                    !_vm.editENSEIGNE &&
                    ![
                      _vm.CONST.AFFAIRE.STATUS.SLEEPING.value,
                      _vm.CONST.AFFAIRE.STATUS.CLOSED.value,
                      _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                    ].includes(_vm.affaire.affaire.status) &&
                    !_vm.workflow.enseigne.required &&
                    _vm.workflow.enseigne.visible
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ],
                            staticStyle: { display: "inline" }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                size: "mini",
                                circle: "",
                                icon: "el-icon-edit"
                              },
                              on: { click: _vm.handleChangeENSEIGNE }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm.editENSEIGNE
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ]
                          },
                          [
                            _c("listEnseigne", {
                              key: "enseigne_" + _vm.formData.id_enseigne,
                              attrs: {
                                idEnseigne: _vm.formData.id_enseigne,
                                idClient: _vm.formData.id_client,
                                enseigne: _vm.selection.enseigne
                              },
                              on: {
                                "update:idEnseigne": function($event) {
                                  return _vm.$set(
                                    _vm.formData,
                                    "id_enseigne",
                                    $event
                                  )
                                },
                                "update:id-enseigne": function($event) {
                                  return _vm.$set(
                                    _vm.formData,
                                    "id_enseigne",
                                    $event
                                  )
                                },
                                "update:idClient": function($event) {
                                  return _vm.$set(
                                    _vm.formData,
                                    "id_client",
                                    $event
                                  )
                                },
                                "update:id-client": function($event) {
                                  return _vm.$set(
                                    _vm.formData,
                                    "id_client",
                                    $event
                                  )
                                },
                                "update:enseigne": function($event) {
                                  return _vm.$set(
                                    _vm.selection,
                                    "enseigne",
                                    $event
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-close"
                                },
                                on: { click: _vm.handleCancelENSEIGNE }
                              },
                              [_vm._v("Annuler")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-check"
                                },
                                on: { click: _vm.handleSaveENSEIGNE }
                              },
                              [_vm._v("Enregistrer")]
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.affaire.enseigne
                                  ? _vm.affaire.enseigne.libelle
                                  : ""
                              ) +
                              "\n          "
                          )
                        ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("\n          Secteur géographique : \n          "),
                    !_vm.editSECTGEO &&
                    ![
                      _vm.CONST.AFFAIRE.STATUS.SLEEPING.value,
                      _vm.CONST.AFFAIRE.STATUS.CLOSED.value,
                      _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                    ].includes(_vm.affaire.affaire.status) &&
                    ![_vm.CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value].includes(
                      _vm.affaire.affaire.etape
                    ) &&
                    _vm.workflow.secteurGeo.modifiable
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ],
                            staticStyle: { display: "inline" }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                size: "mini",
                                circle: "",
                                icon: "el-icon-edit"
                              },
                              on: { click: _vm.handleChangeSECTGEO }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm.editSECTGEO
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ]
                          },
                          [
                            _c("listSecteurGeo", {
                              key: "secteurGeo_" + _vm.formData.id_secteur_geo,
                              attrs: {
                                idSociete: _vm.affaire.affaire.id_societe,
                                idSecteur: _vm.formData.id_secteur_geo,
                                secteurGeo: _vm.selection.secteurGeo
                              },
                              on: {
                                "update:idSecteur": function($event) {
                                  return _vm.$set(
                                    _vm.formData,
                                    "id_secteur_geo",
                                    $event
                                  )
                                },
                                "update:id-secteur": function($event) {
                                  return _vm.$set(
                                    _vm.formData,
                                    "id_secteur_geo",
                                    $event
                                  )
                                },
                                "update:secteurGeo": function($event) {
                                  return _vm.$set(
                                    _vm.selection,
                                    "secteurGeo",
                                    $event
                                  )
                                },
                                "update:secteur-geo": function($event) {
                                  return _vm.$set(
                                    _vm.selection,
                                    "secteurGeo",
                                    $event
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-close"
                                },
                                on: { click: _vm.handleCancelSECTGEO }
                              },
                              [_vm._v("Annuler")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-check"
                                },
                                on: { click: _vm.handleSaveSECTGEO }
                              },
                              [_vm._v("Enregistrer")]
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.affaire.secteurGeo
                                  ? _vm.affaire.secteurGeo.libelle
                                  : ""
                              ) +
                              "   " +
                              _vm._s(
                                String(_vm.affaire.secteurGeo.code).padStart(
                                  2,
                                  "0"
                                )
                              ) +
                              "  \n          "
                          )
                        ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("\n          Délai de paiement : \n          "),
                    !_vm.editDelaiPaiement &&
                    _vm.canEdit() &&
                    ![
                      _vm.CONST.AFFAIRE.STATUS.SLEEPING.value,
                      _vm.CONST.AFFAIRE.STATUS.CLOSED.value,
                      _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                    ].includes(_vm.affaire.affaire.status)
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ],
                            staticStyle: { display: "inline" }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                size: "mini",
                                circle: "",
                                icon: "el-icon-edit"
                              },
                              on: { click: _vm.handleChangeDelaiPaiement }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm.editDelaiPaiement
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ]
                          },
                          [
                            _c("delai-paiement-select-list", {
                              key:
                                "delai_paiement_" + _vm.formData.delai_paiement,
                              staticStyle: { width: "175px" },
                              attrs: {
                                "delai-paiement-selected":
                                  _vm.formData.delai_paiement
                              },
                              on: {
                                "update:delaiPaiementSelected": function(
                                  $event
                                ) {
                                  return _vm.$set(
                                    _vm.formData,
                                    "delai_paiement",
                                    $event
                                  )
                                },
                                "update:delai-paiement-selected": function(
                                  $event
                                ) {
                                  return _vm.$set(
                                    _vm.formData,
                                    "delai_paiement",
                                    $event
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-close"
                                },
                                on: { click: _vm.handleCancelDelaiPaiement }
                              },
                              [_vm._v("Annuler")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-check"
                                },
                                on: { click: _vm.handleSaveDelaiPaiement }
                              },
                              [_vm._v("Enregistrer")]
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("getLabelDelaiPaiement")(
                                  _vm.affaire.affaire.delai_paiement
                                )
                              ) +
                              "\n          "
                          )
                        ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("N° de l'affaire :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.affaire.affaire.num_affaire))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("Affaire créée par :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm._v(
                      _vm._s(
                        _vm.affaire.user ? _vm.affaire.user.firstname : ""
                      ) +
                        " " +
                        _vm._s(
                          _vm.affaire.user ? _vm.affaire.user.lastname : ""
                        )
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("Date de création :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toDateTime")(
                          _vm.affaire.affaire.date_creation_affaire
                        )
                      )
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.workflow.ingenieurEtude.visible,
                      expression: "workflow.ingenieurEtude.visible"
                    }
                  ]
                },
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("\n          Ingénieur d'étude : \n          "),
                    !_vm.editING &&
                    ![
                      _vm.CONST.AFFAIRE.STATUS.SLEEPING.value,
                      _vm.CONST.AFFAIRE.STATUS.CLOSED.value,
                      _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                    ].includes(_vm.affaire.affaire.status)
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ],
                            staticStyle: { display: "inline" }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                size: "mini",
                                circle: "",
                                icon: "el-icon-edit"
                              },
                              on: { click: _vm.handleChangeING }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm.editING
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ]
                          },
                          [
                            _c("listCascaderUtilisateur", {
                              key:
                                "ing_" + _vm.affaire.affaire.id_ingenieur_etude,
                              attrs: {
                                idSociete: _vm.affaire.affaire.id_societe,
                                idUserSelected:
                                  _vm.affaire.affaire.id_ingenieur_etude,
                                user: _vm.selection.ing
                              },
                              on: {
                                "update:idUserSelected": function($event) {
                                  return _vm.$set(
                                    _vm.affaire.affaire,
                                    "id_ingenieur_etude",
                                    $event
                                  )
                                },
                                "update:id-user-selected": function($event) {
                                  return _vm.$set(
                                    _vm.affaire.affaire,
                                    "id_ingenieur_etude",
                                    $event
                                  )
                                },
                                "update:user": function($event) {
                                  return _vm.$set(_vm.selection, "ing", $event)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-close"
                                },
                                on: { click: _vm.handleCancelING }
                              },
                              [_vm._v("Annuler")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-check"
                                },
                                on: { click: _vm.handleSaveING }
                              },
                              [_vm._v("Enregistrer")]
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.affaire.ingenieurEtude
                                  ? _vm.affaire.ingenieurEtude.firstname
                                  : ""
                              ) +
                              " " +
                              _vm._s(
                                _vm.affaire.ingenieurEtude
                                  ? _vm.affaire.ingenieurEtude.lastname
                                  : ""
                              ) +
                              "  \n          "
                          )
                        ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.workflow.chefProjet.visible,
                      expression: "workflow.chefProjet.visible"
                    }
                  ]
                },
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.workflow.chefProjet.label) +
                        " : \n          "
                    ),
                    !_vm.editCDP &&
                    _vm.canEdit() &&
                    ![
                      _vm.CONST.AFFAIRE.STATUS.SLEEPING.value,
                      _vm.CONST.AFFAIRE.STATUS.CLOSED.value,
                      _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                    ].includes(_vm.affaire.affaire.status)
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ],
                            staticStyle: { display: "inline" }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                size: "mini",
                                circle: "",
                                icon: "el-icon-edit"
                              },
                              on: { click: _vm.handleChangeCDP }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm.editCDP
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ]
                          },
                          [
                            _c("listCascaderUtilisateur", {
                              key: "cdp_" + _vm.affaire.affaire.id_chef_projet,
                              attrs: {
                                idSociete: _vm.affaire.affaire.id_societe,
                                idUserSelected:
                                  _vm.affaire.affaire.id_chef_projet,
                                user: _vm.selection.cdp
                              },
                              on: {
                                "update:idUserSelected": function($event) {
                                  return _vm.$set(
                                    _vm.affaire.affaire,
                                    "id_chef_projet",
                                    $event
                                  )
                                },
                                "update:id-user-selected": function($event) {
                                  return _vm.$set(
                                    _vm.affaire.affaire,
                                    "id_chef_projet",
                                    $event
                                  )
                                },
                                "update:user": function($event) {
                                  return _vm.$set(_vm.selection, "cdp", $event)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-close"
                                },
                                on: { click: _vm.handleCancelCDP }
                              },
                              [_vm._v("Annuler")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-check"
                                },
                                on: { click: _vm.handleSaveCDP }
                              },
                              [_vm._v("Enregistrer")]
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.affaire.chefProjet
                                  ? _vm.affaire.chefProjet.firstname
                                  : ""
                              ) +
                              " " +
                              _vm._s(
                                _vm.affaire.chefProjet
                                  ? _vm.affaire.chefProjet.lastname
                                  : ""
                              ) +
                              "  \n          "
                          )
                        ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.workflow.conducteurTravaux.visible,
                      expression: "workflow.conducteurTravaux.visible"
                    }
                  ]
                },
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("\n          Conducteur des travaux : \n          "),
                    !_vm.editCDT &&
                    _vm.canEdit() &&
                    ![
                      _vm.CONST.AFFAIRE.STATUS.SLEEPING.value,
                      _vm.CONST.AFFAIRE.STATUS.CLOSED.value,
                      _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                    ].includes(_vm.affaire.affaire.status)
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ],
                            staticStyle: { display: "inline" }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                size: "mini",
                                circle: "",
                                icon: "el-icon-edit"
                              },
                              on: { click: _vm.handleChangeCDT }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm.editCDT
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ]
                          },
                          [
                            _c("listCascaderUtilisateur", {
                              key:
                                "cdt_" +
                                _vm.affaire.affaire.id_conducteur_travaux,
                              attrs: {
                                idSociete: _vm.affaire.affaire.id_societe,
                                idUserSelected:
                                  _vm.affaire.affaire.id_conducteur_travaux,
                                user: _vm.selection.cdt
                              },
                              on: {
                                "update:idUserSelected": function($event) {
                                  return _vm.$set(
                                    _vm.affaire.affaire,
                                    "id_conducteur_travaux",
                                    $event
                                  )
                                },
                                "update:id-user-selected": function($event) {
                                  return _vm.$set(
                                    _vm.affaire.affaire,
                                    "id_conducteur_travaux",
                                    $event
                                  )
                                },
                                "update:user": function($event) {
                                  return _vm.$set(_vm.selection, "cdt", $event)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-close"
                                },
                                on: { click: _vm.handleCancelCDT }
                              },
                              [_vm._v("Annuler")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-check"
                                },
                                on: { click: _vm.handleSaveCDT }
                              },
                              [_vm._v("Enregistrer")]
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.affaire.conducteurTravaux
                                  ? _vm.affaire.conducteurTravaux.firstname
                                  : ""
                              ) +
                              " " +
                              _vm._s(
                                _vm.affaire.conducteurTravaux
                                  ? _vm.affaire.conducteurTravaux.lastname
                                  : ""
                              ) +
                              "  \n          "
                          )
                        ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.workflow.responsableSecteur.visible,
                      expression: "workflow.responsableSecteur.visible"
                    }
                  ]
                },
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("\n          Responsable de secteur : \n          "),
                    !_vm.editCDT &&
                    _vm.canEdit() &&
                    ![
                      _vm.CONST.AFFAIRE.STATUS.SLEEPING.value,
                      _vm.CONST.AFFAIRE.STATUS.CLOSED.value,
                      _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                    ].includes(_vm.affaire.affaire.status)
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ],
                            staticStyle: { display: "inline" }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                size: "mini",
                                circle: "",
                                icon: "el-icon-edit"
                              },
                              on: { click: _vm.handleChangeRSECT }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm.editRSECT
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "affaire"
                              }
                            ]
                          },
                          [
                            _c("listCascaderUtilisateur", {
                              key:
                                "cdt_" +
                                _vm.affaire.affaire.id_responsable_secteur,
                              attrs: {
                                idSociete: _vm.affaire.affaire.id_societe,
                                idUserSelected:
                                  _vm.affaire.affaire.id_responsable_secteur,
                                user: _vm.selection.cdt
                              },
                              on: {
                                "update:idUserSelected": function($event) {
                                  return _vm.$set(
                                    _vm.affaire.affaire,
                                    "id_responsable_secteur",
                                    $event
                                  )
                                },
                                "update:id-user-selected": function($event) {
                                  return _vm.$set(
                                    _vm.affaire.affaire,
                                    "id_responsable_secteur",
                                    $event
                                  )
                                },
                                "update:user": function($event) {
                                  return _vm.$set(_vm.selection, "cdt", $event)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-close"
                                },
                                on: { click: _vm.handleCancelRSECT }
                              },
                              [_vm._v("Annuler")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "mini",
                                  icon: "el-icon-check"
                                },
                                on: { click: _vm.handleSaveRSECT }
                              },
                              [_vm._v("Enregistrer")]
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.affaire.responsableSecteur
                                  ? _vm.affaire.responsableSecteur.firstname
                                  : ""
                              ) +
                              " " +
                              _vm._s(
                                _vm.affaire.responsableSecteur
                                  ? _vm.affaire.responsableSecteur.lastname
                                  : ""
                              ) +
                              "  \n          "
                          )
                        ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("Dates des travaux :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.affaire.affaire.date_debut_travaux ||
                              _vm.affaire.affaire.date_fin_travaux,
                            expression:
                              "affaire.affaire.date_debut_travaux || affaire.affaire.date_fin_travaux"
                          }
                        ]
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("toDate")(
                                _vm.affaire.affaire.date_debut_travaux
                              )
                            ) +
                            " au " +
                            _vm._s(
                              _vm._f("toDate")(
                                _vm.affaire.affaire.date_fin_travaux
                              )
                            ) +
                            " | " +
                            _vm._s(_vm.dureeTravaux) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24, align: "right" } },
            [
              _vm._v(" \n      "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "can",
                      rawName: "v-can:affaire",
                      value: "supprimer",
                      expression: "'supprimer'",
                      arg: "affaire"
                    }
                  ],
                  attrs: { type: "danger", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.handleDelete()
                    }
                  }
                },
                [_vm._v("Supprimer affaire\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }