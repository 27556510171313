"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _utils = require("@/helpers/utils");

var _eventBus = require("@/helpers/event-bus.js");

var apiAffaire = _interopRequireWildcard(require("@/api/affaire"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'InfosAffaireBottom',
  data: function data() {
    return {
      CONST: CONST,
      spanCol1: '5',
      spanCol2: '19',
      editDateOuvertureChantier: false,
      editDateReception: false,
      formData: {
        date_ouverture_chantier: null,
        date_reception: null
      },
      refresh: 0,
      rules: {}
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.refresh++;
    });
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user']), {
    affaire: function affaire() {
      return this.$store.getters.affaire;
    }
  }),
  methods: {
    canEdit: function canEdit() {
      var cond1 = this.affaire.YYYYMM === this.affaire.histoLastGestMens && this.affaire.histoLastGestMens && this.affaire.YYYYMM === this.affaire.lastGestMens;
      var cond2 = [CONST.AFFAIRE.STATUS.CLOSED.value, CONST.AFFAIRE.STATUS.SLEEPING.value, CONST.AFFAIRE.STATUS.ARCHIVED.value].includes(this.affaire.affaire.status);
      return this.affaire.YYYYMM ? cond1 && !cond2 : true;
    },
    handleChangeDateOuvertureChantier: function handleChangeDateOuvertureChantier() {
      this.editDateOuvertureChantier = true;
    },
    handleCancelDateOuvertureChantier: function handleCancelDateOuvertureChantier() {
      this.editDateOuvertureChantier = false;
    },
    handleChangeDateReception: function handleChangeDateReception() {
      this.editDateReception = true;
    },
    handleCancelDateReception: function handleCancelDateReception() {
      this.editDateReception = false;
    },
    handleSaveDateOuvertureChantier: function handleSaveDateOuvertureChantier() {
      var _this2 = this;

      this.$loader(true);
      apiAffaire.update(this.affaire.affaire.id, {
        date_ouverture_chantier: (0, _utils.dateToSQL)(this.formData.date_ouverture_chantier)
      }).then(function (results) {
        _this2.editDateOuvertureChantier = false;

        _this2.$store.dispatch('affaire/getAffaireById', _this2.affaire.affaire.id).then(function () {
          _this2.$store.dispatch('affaire/setHistorique', (0, _objectSpread2.default)({}, _this2.affaire.affaire, {
            message: 'Nouvelle date d\'ouverture de chantier: ' + _this2.formData.date_ouverture_chantier
          }));

          _this2.$loader(false);
        });
      }).catch(function (error) {
        _this2.$loader(false);

        _this2.$message(error);
      });
    },
    handleSaveDateReception: function handleSaveDateReception() {
      var _this3 = this;

      this.$loader(true);
      apiAffaire.update(this.affaire.affaire.id, {
        date_reception: (0, _utils.dateToSQL)(this.formData.date_reception)
      }).then(function (results) {
        _this3.editDateReception = false;

        _this3.$store.dispatch('affaire/getAffaireById', _this3.affaire.affaire.id).then(function () {
          _this3.$store.dispatch('affaire/setHistorique', (0, _objectSpread2.default)({}, _this3.affaire.affaire, {
            message: 'Nouvelle date de réception: ' + _this3.formData.date_reception
          }));

          _this3.$loader(false);
        });
      }).catch(function (error) {
        _this3.$loader(false);

        _this3.$message(error);
      });
    }
  }
};
exports.default = _default;