"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _form = _interopRequireDefault(require("./components/form"));

//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      data: {},
      title: 'Ajouter une société',
      action: 'new'
    };
  },
  components: {
    Form: _form.default
  }
};
exports.default = _default;