"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

require("@/mixins/workflow");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AffaireTotaux',
  props: ['userCanDoAction'],
  data: function data() {
    return {
      refresh: 0,
      affaire: [],
      calculs: [],
      lots: [],
      // --
      changesTotalGeneralVenteInitiale: false,
      oldTotalGeneralVenteInitiale: null,
      focusTotalGeneralVenteInitiale: false,
      // --
      changesRemiseCommerciale: false,
      oldRemiseCommerciale: null,
      focusRemiseCommerciale: false,
      // --
      CONST: CONST,
      editFields: false
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      // console.log('refresh totaux')
      _this.affaire = _this.$store.getters.affaire.affaire;
      _this.calculs = _this.$store.getters.affaire.calculs;
      _this.lots = _this.$store.getters.affaire.lots; // this.refresh++

      if (_this.focusTotalGeneralVenteInitiale && _this.$refs.totalGeneralVenteInitiale) {
        _this.setFocusTotalGeneralVenteInitiale();

        _this.focusTotalGeneralVenteInitiale = false;
      }

      if (_this.focusRemiseCommerciale && _this.$refs.remiseCommerciale) {
        _this.setFocusRemiseCommerciale();

        _this.focusRemiseCommerciale = false;
      }
    });

    _eventBus.EventBus.$on('MODE_EDIT', function (modeEdit) {
      // pour verouiller/deverouiller les champs vente initiale et remise commerciale si on passe en edition des lots
      _this.editFields = !modeEdit;
    });

    _eventBus.EventBus.$on('SAVE_CANCEL', function (modeEdit) {
      // appelé quans on saisit une valeur dans un des champs mt vente initiale et remise commerciale ou si on clic sur enregistrer/save
      _this.$root.modeEdit = modeEdit; // pour déverouiller les champs de saisie mt vente commerciale et remise commerciale

      _this.editFields = modeEdit;
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  },
  computed: {
    errorTotalGeneralVenteInitiale: function errorTotalGeneralVenteInitiale() {
      var v = this.affaire.mt_total_vente_initiale;
      return v === '' || isNaN(v);
    },
    totalGeneralVenteInitiale: {
      get: function get() {
        return this.affaire.mt_total_vente_initiale;
      },
      set: function set(value) {
        // pour gérer le clic sur le bouton annuler
        if (this.changesTotalGeneralVenteInitiale === false && this.oldTotalGeneralVenteInitiale === null) {
          this.changesTotalGeneralVenteInitiale = true; // pour afficher les boutons enregistrer ou annuler

          this.oldTotalGeneralVenteInitiale = this.affaire.mt_total_vente_initiale; // pour annuler et restaurer l'ancienne valeur
        }

        this.affaire.mt_total_vente_initiale = value;
      }
    },
    // --
    errorRemiseCommerciale: function errorRemiseCommerciale() {
      var v = this.affaire.mt_remise_commerciale;
      return v === '' || isNaN(v);
    },
    remiseCommerciale: {
      get: function get() {
        return this.affaire.mt_remise_commerciale;
      },
      set: function set(value) {
        // pour gérer le clic sur le bouton annuler
        if (this.changesRemiseCommerciale === false && this.oldRemiseCommerciale === null) {
          this.changesRemiseCommerciale = true; // pour afficher les boutons enregistrer ou annuler

          this.oldRemiseCommerciale = this.affaire.mt_remise_commerciale; // pour annuler et restaurer l'ancienne valeur
        }

        this.affaire.mt_remise_commerciale = value;
      }
    }
  },
  methods: {
    setFocusTotalGeneralVenteInitiale: function setFocusTotalGeneralVenteInitiale() {
      var _this2 = this;

      if (this.$refs.totalGeneralVenteInitiale) {
        this.$nextTick(function () {
          _this2.$refs.totalGeneralVenteInitiale.focus();
        });
      }
    },
    handleInputTotalGeneralVenteInitiale: function handleInputTotalGeneralVenteInitiale() {
      _eventBus.EventBus.$emit('REFRESH_CALCULS');

      this.focusTotalGeneralVenteInitiale = true; // envoi un signal pour verouiller le champs remise commerciale et masquer les boutons d'actions

      _eventBus.EventBus.$emit('SAVE_CANCEL', true);
    },
    handleCancelTotalGeneralVenteInitiale: function handleCancelTotalGeneralVenteInitiale() {
      this.affaire.mt_total_vente_initiale = this.oldTotalGeneralVenteInitiale;
      this.changesTotalGeneralVenteInitiale = false;
      this.oldTotalGeneralVenteInitiale = null;

      _eventBus.EventBus.$emit('REFRESH_CALCULS'); // envoi un signal pour deverouiller le champs remise commerciale et afficher les boutons d'actions


      _eventBus.EventBus.$emit('SAVE_CANCEL', false);
    },
    handleSaveTotalGeneralVenteInitiale: function handleSaveTotalGeneralVenteInitiale() {
      if (!this.errorTotalGeneralVenteInitiale) {
        this.actionSaveTotalGeneralVenteInitiale(); // envoi un signal pour deverouiller le champs remise commerciale et afficher les boutons d'actions

        _eventBus.EventBus.$emit('SAVE_CANCEL', false);
      }
    },
    actionSaveTotalGeneralVenteInitiale: function actionSaveTotalGeneralVenteInitiale() {
      var _this3 = this;

      this.$loader(true);
      var data = {
        mt_total_vente_initiale: this.affaire.mt_total_vente_initiale
      }; // apiAffaire.update(this.affaire.id, data)

      this.$store.dispatch('affaire/updateAffaire', {
        id: this.affaire.id,
        data: data
      }).then(function (results) {
        if (results.affectedRows > 0) {
          _this3.changesTotalGeneralVenteInitiale = false;
          _this3.oldTotalGeneralVenteInitiale = null;

          _this3.$message('Modification enregistrée avec succès');

          _eventBus.EventBus.$emit('REFRESH_CALCULS');
        }

        _this3.$loader(false);
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    // --
    setFocusRemiseCommerciale: function setFocusRemiseCommerciale() {
      var _this4 = this;

      if (this.$refs.remiseCommerciale) {
        this.$nextTick(function () {
          _this4.$refs.remiseCommerciale.focus();
        });
      }
    },
    handleInputRemiseCommerciale: function handleInputRemiseCommerciale() {
      _eventBus.EventBus.$emit('REFRESH_CALCULS');

      this.focusRemiseCommerciale = true; // envoi un signal pour verouiller le champs mt vente initiale et masquer les boutons d'actions

      _eventBus.EventBus.$emit('SAVE_CANCEL', true);
    },
    handleCancelRemiseCommerciale: function handleCancelRemiseCommerciale() {
      this.affaire.mt_remise_commerciale = this.oldRemiseCommerciale;
      this.changesRemiseCommerciale = false;
      this.oldRemiseCommerciale = null;

      _eventBus.EventBus.$emit('REFRESH_CALCULS'); // envoi un signal pour deverouiller le champs mt vente initiale et afficher les boutons d'actions


      _eventBus.EventBus.$emit('SAVE_CANCEL', false);
    },
    handleSaveRemiseCommerciale: function handleSaveRemiseCommerciale() {
      if (!this.errorRemiseCommerciale) {
        this.actionSaveRemiseCommerciale(); // envoi un signal pour deverouiller le champs mt vente initiale et afficher les boutons d'actions

        _eventBus.EventBus.$emit('SAVE_CANCEL', false);
      }
    },
    actionSaveRemiseCommerciale: function actionSaveRemiseCommerciale() {
      var _this5 = this;

      this.$loader(true);
      var data = {
        mt_remise_commerciale: this.affaire.mt_remise_commerciale
      }; // apiAffaire.update(this.affaire.id, data)

      this.$store.dispatch('affaire/updateAffaire', {
        id: this.affaire.id,
        data: data
      }).then(function (results) {
        if (results.affectedRows > 0) {
          _this5.changesRemiseCommerciale = false;
          _this5.oldRemiseCommerciale = null;

          _this5.$message('Modification enregistrée avec succès');

          _eventBus.EventBus.$emit('REFRESH_CALCULS');
        }

        _this5.$loader(false);
      }).catch(function (error) {
        _this5.$message(error);

        _this5.$loader(false);
      });
    }
  }
};
exports.default = _default;