"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AffaireTotauxAvenants',
  data: function data() {
    return {
      refresh: 0,
      calculs: [],
      lots: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.calculs = _this.$store.getters.affaire.calculs;
      _this.lots = _this.$store.getters.affaire.lots;
      _this.refresh++;
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['affaire'])),
  methods: {
    dateColAvenant: function dateColAvenant(numCol) {
      if (this.affaire.calculs.affaire) {
        return this.affaire.calculs.affaire.dateColonneAvenant[numCol];
      }
    },
    dateColAvenantMarchePrincipal: function dateColAvenantMarchePrincipal(numCol) {
      if (this.affaire.calculs.affaire) {
        return this.affaire.calculs.affaire.dateColonneAvenantMarchePrincipal[numCol];
      }
    }
  }
};
exports.default = _default;