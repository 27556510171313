var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { slot: "tool" }, slot: "tool" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("    \n      "),
            _vm.affaire.affaire.etape ===
              _vm.CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value &&
            _vm.affaire.affaire.status !==
              _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
              ? _c(
                  "div",
                  [
                    _vm.canEdit() && _vm.userCanDoAction && !_vm.modeEdit
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:provision_risque",
                                value: "creer",
                                expression: "'creer'",
                                arg: "provision_risque"
                              }
                            ],
                            attrs: {
                              type: "success",
                              size: "mini",
                              icon: "el-icon-plus"
                            },
                            on: { click: _vm.handleNewProvisionRisque }
                          },
                          [_vm._v("Nouvelle provision\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.modeEdit
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "warning",
                              size: "mini",
                              icon: "el-icon-close"
                            },
                            on: { click: _vm.handleCancelProvisionRisque }
                          },
                          [_vm._v("Annuler")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.modeEdit
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-check"
                            },
                            on: { click: _vm.handleSaveProvisionRisque }
                          },
                          [_vm._v("Enregistrer")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.modeEdit
        ? _c(
            "el-row",
            {
              staticStyle: { width: "auto" },
              attrs: { id: "EnteteProvisionRisque" }
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "background-color": "#f2f2f2",
                    padding: "10px",
                    "border-bottom": "1px solid #ddd"
                  },
                  attrs: { span: 20, align: "left" }
                },
                [_vm._v("Nouvelle provision")]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "background-color": "#efefef",
                    "padding-top": "10px"
                  },
                  attrs: { span: 20 }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ElForm",
                      staticClass: "ElForm",
                      attrs: {
                        model: _vm.formData,
                        "label-width": "300px",
                        size: "mini"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Nature de la provision",
                            prop: "lib_nature"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              key: JSON.stringify(_vm.options),
                              staticStyle: { width: "300px" },
                              attrs: {
                                filterable: "",
                                "allow-create": "",
                                placeholder: "libellé",
                                size: "mini"
                              },
                              model: {
                                value: _vm.formData.lib_nature,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "lib_nature", $$v)
                                },
                                expression: "formData.lib_nature"
                              }
                            },
                            _vm._l(_vm.options, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "SyncScrollBars",
        [
          _vm._l(_vm.affaire.lots, function(lot, index) {
            return _c(
              "div",
              { key: lot.id + "_" + _vm.refresh },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("h1", { staticStyle: { "margin-top": "20px" } }, [
                          _vm._v(
                            "LOT " +
                              _vm._s(index + 1) +
                              ". " +
                              _vm._s(lot.libelle)
                          )
                        ]),
                        _vm._v(" "),
                        _c("LotDetail", {
                          key: _vm.refresh,
                          attrs: { idLot: lot.id }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-row",
            { key: "e2_" + _vm.refresh, staticStyle: { margin: "20px 0" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [_c("AffaireTotaux", { key: _vm.refresh })],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }