"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var apiPermission = _interopRequireWildcard(require("@/api/permission"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'listUserProfil',
  props: ['idSociete', 'keyRoleSelected', 'profil', 'placeholder'],
  data: function data() {
    return {
      profils: [],
      keyRole: this.keyRoleSelected,
      show: false
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    apiPermission.getAllBySocieteId(this.idSociete).then(function (results) {
      _this.profils = results;

      _this.onChange(_this.keyRole);
    }).then(function () {
      _this.show = true;
    }).catch(function (error) {
      _this.$message(error);
    });
  },
  methods: {
    onClear: function onClear() {
      this.$emit('update:key-role-selected', null);
      this.$emit('update:profil', null);
    },
    onChange: function onChange(value) {
      if (value !== '') {
        this.$emit('update:key-role-selected', value);
        this.$emit('update:profil', this.profils.find(function (x) {
          return x.key_role === value;
        }));
      }
    }
  }
};
exports.default = _default;