"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllByModeleId = getAllByModeleId;
exports.insert = insert;
exports.update = update;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAllByModeleId(idModele) {
  return (0, _request.default)({
    url: '/modele_lot/modele/' + idModele,
    method: 'get'
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/modele_lot',
    method: 'post',
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/modele_lot/' + id,
    method: 'put',
    data: data
  });
}