var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lots && _vm.lots.length > 0
    ? _c(
        "div",
        { staticClass: "sync-scroll-bars" },
        [
          _c(
            "el-table",
            {
              key: "TotalGeneral_" + _vm.refresh,
              staticClass: "objdeb",
              attrs: {
                data: [{}],
                size: "mini",
                "row-class-name": "total",
                "data-sync-scrollbar": "summaries"
              }
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "left", width: "40", align: "right" }
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { fixed: "left", width: "250", align: "right" } },
                [_vm._v("TOTAL GENERAL")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    width: "120",
                    align: "right",
                    label: "Déboursés étude"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire.mtTotalGeneralDebourseEtudeHT
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "separator", width: "15" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c("el-table-column", { attrs: { width: "190" } }),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { "class-name": "separator", width: "15" } },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c("el-table-column", { attrs: { width: "120" } }, [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    width: "120",
                    align: "right",
                    label: "Obj débours exécution"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire.mtTotalGeneralObjDebourseExec
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "Aléas", width: "120", align: "right" } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.calculs.affaire.mtTotalGeneralAleas
                        )
                      ) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: "SyntheseL1_" + _vm.refresh,
              staticClass: "objdeb",
              attrs: {
                data: [{}],
                size: "mini",
                "data-sync-scrollbar": "summaries",
                "header-row-class-name": "objdebHeader"
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-desc",
                    width: "290",
                    align: "right"
                  }
                },
                [_vm._v("Total vente initiale HT")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire.mtTotalGeneralTotalVenteInitialeHT
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "separator",
                    width: "15"
                  }
                },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "left",
                  "class-name": "tb-pattern",
                  width: "325"
                }
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-desc",
                    width: "240",
                    align: "center"
                  }
                },
                [_vm._v("Objectifs de résultats")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: "SyntheseL2_" + _vm.refresh,
              staticClass: "objdeb",
              attrs: {
                data: [{}],
                size: "mini",
                "data-sync-scrollbar": "summaries",
                "header-row-class-name": "objdebHeader"
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-desc",
                    width: "290",
                    align: "right"
                  }
                },
                [_vm._v("Remise commerciale")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(_vm.affaire.mt_remise_commerciale)
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "separator",
                    width: "15"
                  }
                },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "left",
                  "class-name": "tb-pattern",
                  width: "325"
                }
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-desc",
                    width: "120",
                    align: "center"
                  }
                },
                [_vm._v("Déboursés")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-desc",
                    width: "120",
                    align: "center"
                  }
                },
                [_vm._v("Aléas")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: "SyntheseL3_" + _vm.refresh,
              staticClass: "objdeb",
              attrs: {
                data: [{}],
                size: "mini",
                "data-sync-scrollbar": "summaries",
                "header-row-class-name": "objdebHeader"
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-desc",
                    width: "290",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "Total vente finale HT = Total vente initiale HT - Remise commerciale"
                      }
                    },
                    [_vm._v("Total vente finale HT")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire.mtTotalGeneralVenteFinaleHT
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "separator",
                    width: "15"
                  }
                },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-desc",
                    width: "326",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "Total déboursés objectifs HT = TOTAL GENERAL Obj débours exécution"
                      }
                    },
                    [_vm._v("Total déboursés HT")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire.mtTotalGeneralDeboursesObjectifsHT
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire.mtTotalGeneralAleas
                      )
                    )
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: "SyntheseL4_" + _vm.refresh,
              staticClass: "objdeb",
              attrs: {
                data: [{}],
                size: "mini",
                "data-sync-scrollbar": "summaries",
                "header-row-class-name": "objdebHeader"
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-desc",
                    width: "290",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "Marge brute étude commerciale = Total vente finale HT - TOTAL GENERAL déboursés étude"
                      }
                    },
                    [_vm._v("Marge brute étude commerciale")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire
                          .mtTotalGeneralMargeBruteEtudeCommerciale
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "separator",
                    width: "15"
                  }
                },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-desc",
                    width: "326",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "Total Mage brute = Total vente finale HT - TOTAL GENERAL Obj débours exécution - Total Aléas"
                      }
                    },
                    [_vm._v("Total Marge brute")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-data",
                    width: "240",
                    align: "center"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toCurrency")(
                        _vm.calculs.affaire
                          .mtTotalGeneralMargeBruteObjectifsDeboursesHT
                      )
                    )
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: "SyntheseL5_" + _vm.refresh,
              staticClass: "objdeb",
              attrs: {
                data: [{}],
                size: "mini",
                "data-sync-scrollbar": "summaries",
                "header-row-class-name": "objdebHeader"
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-desc",
                    width: "290",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "% Marge brute étude commerciale= Marge brute étude commerciale / Total vente finale HT"
                      }
                    },
                    [_vm._v("% Marge brute étude commerciale")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-data",
                    width: "120",
                    align: "right"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toPercentage")(
                        _vm.calculs.affaire
                          .pcTotalGeneralMargeBruteEtudeCommerciale
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "separator",
                    width: "15"
                  }
                },
                [_vm._v(" ")]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-desc",
                    width: "326",
                    align: "right"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        title:
                          "% de Marge brute objectif = Marge brute avec objectifs de déboursés HT / Total vente finale HT"
                      }
                    },
                    [_vm._v("% Marge brute")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    fixed: "left",
                    "class-name": "tb-data",
                    width: "240",
                    align: "center"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("toPercentage")(
                        _vm.calculs.affaire
                          .pcTotalGeneralMargeBruteObjectifsDeboursesHT
                      )
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }