"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFactureFichierUri = getFactureFichierUri;

require("core-js/modules/es6.regexp.split");

function getFactureFichierUri(facture) {
  var withBaseUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var uri = '';

  if (withBaseUrl === true) {
    uri += process.env.VUE_APP_BASE_API;
  }

  uri += "/facture/".concat(facture.id, "-").concat(facture.fichier.split('.')[0], "/file/").concat(facture.nom_original);
  return uri;
}