"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _eventBus = require("@/helpers/event-bus");

var _CreanceList = _interopRequireDefault(require("@/components/CreanceList"));

var _ReglementModule = _interopRequireDefault(require("@/views/app/reglements/ReglementModule"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'CreanceIndex',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    CreanceList: _CreanceList.default,
    ReglementModule: _ReglementModule.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      activeMenu: 'creances_liste',
      factureId: null
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('facturation', ['getFactureById'])),
  // 9. Évènements
  // --
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // Suppression du cookie mémorisant le filtre sur les affaires
    if (['VenteCreance.Creances.CreancesIndex'].includes(from.name)) {
      _jsCookie.default.remove('venteCreances.creances.filtersValues', {
        path: ''
      });
    }

    next();
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('CREANCES_REGLEMENTS_RETURN', function () {
      // this.$router.push({ name: 'VenteCreance.Creances' })
      _this.activeMenu = 'creances_liste';
      _this.factureId = null;
      _this.$route.meta.title = 'Liste des créances clients';
    });

    _eventBus.EventBus.$on('CREANCES_REGLEMENTS', function (key, row) {
      var nextTickEventName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var factureId = parseInt(row.facture.id);

      _this.loadFactureById(factureId).then(function (results) {
        var facture = _this.getFactureById(factureId);

        if (!facture) {
          facture = results[0];
        }

        if (facture) {
          _this.$route.meta.title = "R\xE8glements de la facture ".concat(facture.numero);
          _this.factureId = factureId;
          _this.activeMenu = key; // Attente du prochain rendu du DOM pour déclencher un nouvel événement

          if (nextTickEventName) {
            _this.$nextTick(function () {
              _eventBus.EventBus.$emit(nextTickEventName);
            });
          }
        } else {
          _this.$router.push({
            name: '404'
          });
        }
      }).catch(function (_) {
        _eventBus.EventBus.$emit('CREANCES_REGLEMENTS_RETURN');
      });
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('CREANCES_REGLEMENTS_RETURN');

    _eventBus.EventBus.$off('CREANCES_REGLEMENTS');
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)({}, (0, _vuex.mapActions)('facturation', ['loadFactureById'])) // 11. Rendu
  // --

};
exports.default = _default;