"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

var _slicedToArray2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

require("core-js/modules/es7.object.entries");

var _typeof2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.number.parse-float");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _vuex = require("vuex");

var _utils = require("@/helpers/utils");

var _moment = _interopRequireDefault(require("moment"));

var _listSecteurGeoMultiple = _interopRequireDefault(require("@/components/listSecteurGeoMultiple"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'SageList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    listSecteurGeoMultiple: _listSecteurGeoMultiple.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      activeFilter: '',
      columns: {
        // affaire
        'affaire.code_analytique': {
          key: 0,
          prop: 'affaire_code_analytique',
          label: 'Code analytique'
        },
        'affaire.secteur_act_code': {
          key: 1,
          prop: 'secteur_act_code',
          label: 'Code act.'
        },
        'affaire.secteur_geo_code': {
          key: 2,
          prop: 'secteur_geo_code',
          label: 'Code ets.'
        },
        'affaire.secteur_geo_libelle': {
          key: 3,
          prop: 'secteur_geo_libelle',
          label: 'Code ets.'
        },
        'affaire.compte_auxiliaire': {
          key: 4,
          prop: 'affaire_compte_auxiliaire',
          label: 'Compte tiers'
        },
        // tva
        'tva.compte_general': {
          key: 5,
          prop: 'tva_compte_general',
          label: 'Compte TVA'
        },
        'tva.taux': {
          key: 6,
          prop: 'tva_taux',
          label: 'Taux TVA'
        },
        // type de vente
        'type_vente.code': {
          key: 7,
          prop: 'type_vente_code',
          label: 'Type de vente'
        },
        // facture
        'facture.date_facture': {
          key: 8,
          prop: 'facture_date_facture',
          label: 'Date'
        },
        'facture.date_echeance': {
          key: 9,
          prop: 'facture_date_echeance',
          label: 'Echéance'
        },
        'facture.libelle': {
          key: 10,
          prop: 'facture_libelle',
          label: 'Libellé'
        },
        'facture.montant_ht': {
          key: 11,
          prop: 'facture_montant_ht',
          label: 'HT facturé'
        },
        'facture.montant_tva': {
          key: 12,
          prop: 'facture_montant_tva',
          label: 'TVA facturé'
        },
        'facture.mt_avant_paiement_direct_ttc': {
          key: 13,
          prop: 'facture_mt_avant_paiement_direct_ttc',
          label: 'TTC avant P.D.'
        },
        'facture.mt_paiement_direct_st': {
          key: 14,
          prop: 'facture_mt_paiement_direct_st',
          label: 'P.D.'
        },
        'facture.numero': {
          key: 15,
          prop: 'facture_numero',
          label: 'Numéro'
        },
        'facture.retenue_garantie': {
          key: 16,
          prop: 'facture_retenue_garantie',
          label: 'R.G.'
        }
      },
      csvSageData: [],
      csvSageLabels: {
        facture_date_facture: 'Date facture',
        facture_date_echeance: 'Date echeance',
        type_vente_code: 'Type de vente',
        facture_numero: 'Num facture',
        facture_libelle: 'Libelle facture',
        affaire_code_analytique: 'Num affaire (code analytique)',
        // Etablissement: Code secteur géographique
        secteur_geo_code: 'Etablissement',
        // Compte tiers: compte auxiliaire de l'affaire
        affaire_compte_auxiliaire: 'Compte tiers',
        tva_compte_general: 'Compte TVA',
        tva_taux: 'Taux TVA',
        facture_montant_ht: 'Montant HT',
        facture_montant_tva: 'Montant TVA',
        facture_retenue_garantie: 'RG',
        facture_mt_avant_paiement_direct_ttc: 'Montant TTC avant paiement direct',
        secteur_act_code: 'Code secteur Activites'
      },
      dialogDetailVisible: false,
      filters: [// Filtre #0 : filtre sur secteur géographique
      {
        value: [],
        prop: ['secteur_geo_id'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'secteur_geo_id':
                if (filter.value.length === 0) {
                  return true;
                }

                return filter.value.includes(row.affaire.secteur_geo.id);

              default:
                return false;
            }
          });
        }
      }, // Filtre #1 : si la date de facture est >= date de début de période
      {
        value: '',
        prop: ['facture_date_facture'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'facture_date_facture':
                if (filter.value === null) {
                  return true;
                }

                var date = row.facture.date_facture;

                if (!date || typeof date === 'undefined') {
                  return false;
                }

                return (0, _moment.default)(String(date), 'YYYY-MM-DD').isSameOrAfter(filter.value);

              default:
                return false;
            }
          });
        }
      }, // Filtre #2 : si la date de facture est <= date de fin de période
      {
        value: '',
        prop: ['facture_date_facture'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'facture_date_facture':
                if (filter.value === null) {
                  return true;
                }

                var date = row.facture.date_facture;

                if (!date || typeof date === 'undefined') {
                  return false;
                }

                return (0, _moment.default)(String(date), 'YYYY-MM-DD').isSameOrBefore(filter.value);

              default:
                return false;
            }
          });
        }
      }, // Filtre #3 : si le montant du P.D. de la facture est >= montant saisie
      {
        value: '',
        prop: ['facture_mt_paiement_direct_st'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'facture_mt_paiement_direct_st':
                if (filter.value === null) {
                  return true;
                }

                var montant = row.facture.mt_paiement_direct_st;
                return montant >= Number.parseFloat(filter.value).toFixed(2);

              default:
                return false;
            }
          });
        }
      }, // Filtre #4 : si le montant du P.D. de la facture est <= montant saisie
      {
        value: '',
        prop: ['facture_mt_paiement_direct_st'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'facture_mt_paiement_direct_st':
                if (filter.value === null) {
                  return true;
                }

                var montant = row.facture.mt_paiement_direct_st;
                return montant <= Number.parseFloat(filter.value).toFixed(2);

              default:
                return false;
            }
          });
        }
      }, // Filtre #5 : si la date d'échéance est >= date de début de période
      {
        value: '',
        prop: ['facture_date_echeance'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'facture_date_echeance':
                if (filter.value === null) {
                  return true;
                }

                var date = row.facture.date_echeance;

                if (!date || typeof date === 'undefined') {
                  return false;
                }

                return (0, _moment.default)(String(date), 'YYYY-MM-DD').isSameOrAfter(filter.value);

              default:
                return false;
            }
          });
        }
      }, // Filtre #2 : si la date d'échéance est <= date de fin de période
      {
        value: '',
        prop: ['facture_date_echeance'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'facture_date_echeance':
                if (filter.value === null) {
                  return true;
                }

                var date = row.facture.date_echeance;

                if (!date || typeof date === 'undefined') {
                  return false;
                }

                return (0, _moment.default)(String(date), 'YYYY-MM-DD').isSameOrBefore(filter.value);

              default:
                return false;
            }
          });
        }
      }],
      multipleSelection: [],
      row: [],
      rowComment: {},
      rowSpan: {
        label: 4,
        selection: 1,
        field: 5
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user']), {}, (0, _vuex.mapGetters)('facturation', ['getSyntheseList']), {
    csvSageColumns: function csvSageColumns() {
      return Object.keys(this.csvSageLabels);
    },
    dateFormat: function dateFormat() {
      return this.user.date_format; // dd/MM/yyyy
    },
    data: function data() {
      // console.log('data called !!!')
      this.$loader(true); // Récupération des créances

      var data = this.getSyntheseList;
      this.$loader(false);
      return data;
    },
    filterExists: function filterExists() {
      var filterExists = false;
      this.filters.forEach(function (x, index) {
        // console.log(index + '# typeof', x.value, typeof x.value)
        switch ((0, _typeof2.default)(x.value)) {
          case 'string':
            if (x.value !== null && x.value !== '') {
              filterExists = true;
            }

            break;

          case 'number':
            if (x.value !== null && x.value > 0) {
              filterExists = true;
            }

            break;

          case 'object':
            if (x.value && x.value.length > 0) {
              filterExists = true;
            }

            break;
        }
      });
      return filterExists;
    },
    total: function total() {
      return this.data.length;
    }
  }),
  // 9. Évènements
  // --
  beforeMount: function beforeMount() {
    this.loadSyntheseList();
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)({}, (0, _vuex.mapActions)('facturation', ['loadSyntheseList']), {
    // TODO Refactoriser colWidth en utilisant columns de data
    // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
    colWidth: function colWidth(index) {
      if (this.columns[index] === undefined) {
        return;
      } // Variable temporaire pour calculer la largeur optimale
      // de chaque colonne en fonction de son contenu


      var columns = {
        // affaire
        'affaire.code_analytique': {
          prop: 'affaire_code_analytique',
          width: 1
        },
        'affaire.secteur_act_code': {
          prop: 'secteur_act_code',
          width: 65
        },
        'affaire.secteur_geo_libelle': {
          prop: 'secteur_geo_libelle',
          width: 65
        },
        // tva
        'tva.compte_general': {
          prop: 'tva_compte_general',
          width: 1
        },
        'tva.taux': {
          prop: 'tva_taux',
          width: 1
        },
        // type de vente
        'type_vente.code': {
          prop: 'type_vente_code',
          width: 65
        },
        // facture
        'facture.date_facture': {
          prop: 'facture_date_facture',
          width: 1
        },
        'facture.date_echeance': {
          prop: 'facture_date_echeance',
          width: 1
        },
        'facture.libelle': {
          prop: 'facture_libelle',
          width: 1
        },
        'facture.montant_ht': {
          prop: 'facture_montant_ht',
          width: 1
        },
        'facture.montant_tva': {
          prop: 'facture_montant_tva',
          width: 1
        },
        'facture.mt_avant_paiement_direct_ttc': {
          prop: 'facture_mt_avant_paiement_direct_ttc',
          width: 1
        },
        'facture.mt_paiement_direct_st': {
          prop: 'facture_mt_paiement_direct_st',
          width: 1
        },
        'facture.numero': {
          prop: 'facture_numero',
          width: 1
        },
        'facture.retenue_garantie': {
          prop: 'facture_retenue_garantie',
          width: 1
        }
      };
      var amountColumns = ['facture.montant_ht', 'facture.montant_tva', 'facture.mt_avant_paiement_direct_ttc', 'facture.mt_paiement_direct_st', 'facture.retenue_garantie'];
      this.data.forEach(function (row) {
        for (var _i = 0, _Object$entries = Object.entries(columns); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = (0, _slicedToArray2.default)(_Object$entries[_i], 2),
              key = _Object$entries$_i[0],
              value = _Object$entries$_i[1];

          // console.log('key:', key, 'value.prop:', value.prop)
          var v = row[value.prop] === null ? '' : row[value.prop].toString();
          var l = (v.length + 2) * 8 + 10;

          if (amountColumns.includes(key)) {
            v = row[value.prop] === null ? '' : (0, _utils.toCurrency)(row[value.prop]).toString();
            l = (v.length + 1) * 8;
          }

          var s = parseInt(columns[key].width);
          s = isNaN(s) ? 0 : s; // console.log('l:', l, 's:', s)

          columns[key].width = l > s ? l : s;
        }
      }); // Mise à jour dynamique de la largeur de chaque colonne
      // en fonction de son contenu

      for (var _i2 = 0, _Object$entries2 = Object.entries(columns); _i2 < _Object$entries2.length; _i2++) {
        var _Object$entries2$_i = (0, _slicedToArray2.default)(_Object$entries2[_i2], 2),
            key = _Object$entries2$_i[0],
            value = _Object$entries2$_i[1];

        this.columns[key].width = value.width;
      } // console.log(' --> colwidth for index "', index, '" = ', this.columns[index].width)


      return this.columns[index].width;
    },
    initPreparedDataForExport: function initPreparedDataForExport() {
      // On utilise les données pré-filtrées par le dataTable
      this.csvSageData = this.$refs.dataTable.tableData;
    },
    showNbReglement: function showNbReglement(reglements) {
      return "".concat(reglements.length, " r\xE8glement").concat(reglements.length > 1 ? 's' : '');
    }
  }),
  watch: {
    // Changement de sélection du filtre sur les clients
    'filters.1.value': function filters1Value(newVal, oldVal) {
      // Si on déselectionne le client
      if (newVal === null) {
        // On vide aussi la liste des enseignes liées
        this.filters[2].value = null;
      } // console.log('idClient has changed', newVal, oldVal)

    }
  } // 11. Rendu
  // --

};
exports.default = _default;