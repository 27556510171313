"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

var _auth = require("@/helpers/auth");

var _router = require("@/router");

var state = {
  token: (0, _auth.getToken)(),
  user: ''
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USER: function SET_USER(state, user) {
    state.user = user;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      var user = {
        id_societe: userInfo.id_societe,
        email: userInfo.email.trim(),
        password: userInfo.password
      };
      (0, _user.login)(user).then(function (response) {
        commit('SET_TOKEN', response.token);
        (0, _auth.setToken)(response.token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      commit('SET_TOKEN', '');
      commit('SET_USER', '');
      (0, _auth.removeToken)();
      (0, _router.resetRouter)();
      resolve();
    });
  },
  // get user info
  getUserInfo: function getUserInfo(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getUserInfo)(state.token).then(function (response) {
        var user = response[0];

        if (user.id > 0) {
          commit('SET_USER', user);
          resolve(response[0]);
        } else {
          reject(new Error('Verification failed, please Login again.'));
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_USER', '');
      (0, _auth.removeToken)();
      resolve();
    });
  },
  // user password reminder
  passwordReminder: function passwordReminder(_ref5, userInfo) {
    var commit = _ref5.commit;
    var user = {
      id_societe: userInfo.id_societe,
      email: userInfo.email
    };
    return new Promise(function (resolve, reject) {
      (0, _user.passwordReminder)(user).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user password reset
  passwordReset: function passwordReset(_ref6, userInfo) {
    var commit = _ref6.commit;
    return new Promise(function (resolve, reject) {
      (0, _user.passwordReset)(userInfo).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;