"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _eventBus = require("@/helpers/event-bus.js");

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'dlgNewLineBudgetComp',
  props: ['idLot', 'ordre', 'visible'],
  data: function data() {
    return {
      formLabelWidth: '120px',
      formData: {},
      rules: {
        libelle: {
          required: true,
          message: 'Champ obligatoire'
        },
        prestataire: {
          required: true,
          message: 'Champ obligatoire'
        }
      }
    };
  },
  methods: {
    handleOpened: function handleOpened() {
      this.formData = {};
      this.$refs['ElForm'].resetFields();
    },
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
    },
    handleAdd: function handleAdd() {
      var _this = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          var lotdetail = {
            id_lot: _this.idLot,
            type_lot_detail: CONST.LOT_DETAIL.TYPE_LIGNE.BUDGET_COMP,
            ordre: _this.ordre + 1 || 1,
            libelle: _this.formData.libelle,
            prestataire: _this.formData.prestataire
          };

          _this.$store.dispatch('affaire/insertLotDetail', lotdetail).then(function () {
            _this.$store.dispatch('affaire/setHistorique', (0, _objectSpread2.default)({}, _this.$store.getters.affaire.affaire, {
              message: 'Nouvelle ligne de budget complémentaire: ' + lotdetail.libelle.trim() + '/' + lotdetail.prestataire.trim()
            }));

            _this.$store.dispatch('affaire/setLotDetail', lotdetail).then(function () {
              _this.handleClose();

              _eventBus.EventBus.$emit('REFRESH_CALCULS');
            });
          }).catch(function (error) {
            _this.$message(error);
          });
        }
      });
    }
  }
};
exports.default = _default;