"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var _LotDetail = _interopRequireDefault(require("./components/LotDetail"));

var _AffaireTotaux = _interopRequireDefault(require("./components/AffaireTotaux"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Avenants',
  components: {
    LotDetail: _LotDetail.default,
    AffaireTotaux: _AffaireTotaux.default
  },
  data: function data() {
    return {
      // pour rafraichir l'affichage apres ajout/modification/suppression d'un lot
      refresh: 0,
      // bus
      EventBus: _eventBus.EventBus
    };
  },
  computed: {
    affaire: function affaire() {
      return this.$store.getters.affaire;
    }
  },
  mounted: function mounted() {
    _eventBus.EventBus.$emit('REFRESH_CALCULS');
  }
};
exports.default = _default;