var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-collapse",
        {
          attrs: { accordion: "" },
          model: {
            value: _vm.activeFilter,
            callback: function($$v) {
              _vm.activeFilter = $$v
            },
            expression: "activeFilter"
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: "filters" } },
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticStyle: { width: "50%" } }, [
                  _vm._v("Créances clients")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      display: "inline",
                      "text-align": "right",
                      "margin-right": "15px"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.filterExists ? "danger" : "success",
                          size: "mini"
                        }
                      },
                      [_vm._v("Filtres")]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { "label-width": "250px", size: "mini" } },
                [
                  _c(
                    "el-row",
                    { staticStyle: { margin: "10px 0 10px 0" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("p", [
                            _vm._v(
                              "\n              Les créances clients sont des factures non soldées."
                            ),
                            _c("br"),
                            _vm._v(
                              "\n              Via le détail d'une affaire vous avez la possibilité de consulter toutes les factures qui y sont liées (soldées / non soldées).\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "filter-title",
                                  attrs: { span: 24, align: "left" }
                                },
                                [
                                  _vm._v(
                                    "\n                Affaires\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Client : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c("list-client", {
                                    key: "id_client_" + _vm.filters[10].value,
                                    staticStyle: { width: "100%" },
                                    attrs: { idClient: _vm.filters[10].value },
                                    on: {
                                      "update:idClient": function($event) {
                                        return _vm.$set(
                                          _vm.filters[10],
                                          "value",
                                          $event
                                        )
                                      },
                                      "update:id-client": function($event) {
                                        return _vm.$set(
                                          _vm.filters[10],
                                          "value",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Enseigne : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c("list-enseigne", {
                                    key:
                                      "id_enseigne_" +
                                      _vm.filters[10].value +
                                      "_" +
                                      _vm.filters[11].value,
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      idClient: _vm.filters[10].value,
                                      idEnseigne: _vm.filters[11].value,
                                      disabled: !_vm.filters[10].value
                                    },
                                    on: {
                                      "update:idClient": function($event) {
                                        return _vm.$set(
                                          _vm.filters[10],
                                          "value",
                                          $event
                                        )
                                      },
                                      "update:id-client": function($event) {
                                        return _vm.$set(
                                          _vm.filters[10],
                                          "value",
                                          $event
                                        )
                                      },
                                      "update:idEnseigne": function($event) {
                                        return _vm.$set(
                                          _vm.filters[11],
                                          "value",
                                          $event
                                        )
                                      },
                                      "update:id-enseigne": function($event) {
                                        return _vm.$set(
                                          _vm.filters[11],
                                          "value",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                N° Affaire : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        "remote-method":
                                          _vm.searchAffaireByNumero,
                                        loading: _vm.affaires.loading,
                                        clearable: "",
                                        filterable: "",
                                        placeholder: "Entrez un n° d'affaire",
                                        remote: "",
                                        "reserve-keyword": "",
                                        size: "mini"
                                      },
                                      model: {
                                        value: _vm.filters[5].value,
                                        callback: function($$v) {
                                          _vm.$set(_vm.filters[5], "value", $$v)
                                        },
                                        expression: "filters[5].value"
                                      }
                                    },
                                    _vm._l(_vm.affaires.options, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "filter-title",
                                  attrs: { span: 24, align: "left" }
                                },
                                [
                                  _vm._v(
                                    "\n                Factures\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.field,
                                    offset: _vm.rowSpan.label
                                  }
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.filters[12].value,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filters[12],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "filters[12].value"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "non-soldee" } },
                                        [
                                          _vm._v(
                                            "non-soldées (solde différent de 0)"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "soldee" } },
                                        [_vm._v("soldées (solde égal à 0)")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "line-height": "27px" },
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Date de facture : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                du \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[1].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[1], "value", $$v)
                                      },
                                      expression: "filters[1].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                au \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[2].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[2], "value", $$v)
                                      },
                                      expression: "filters[2].value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "line-height": "27px" },
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Date d'échéance : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                du \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[8].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[8], "value", $$v)
                                      },
                                      expression: "filters[8].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                au \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[9].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[9], "value", $$v)
                                      },
                                      expression: "filters[9].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "line-height": "27px" },
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Montant TTC propre : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[6].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[6], "value", $$v)
                                      },
                                      expression: "filters[6].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                à  \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[7].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[7], "value", $$v)
                                      },
                                      expression: "filters[7].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "filter-title",
                                  attrs: { span: 24, align: "left" }
                                },
                                [
                                  _vm._v(
                                    "\n                Règlements\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "line-height": "27px" },
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Date de règlement : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                du \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[3].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[3], "value", $$v)
                                      },
                                      expression: "filters[3].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                au \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[4].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[4], "value", $$v)
                                      },
                                      expression: "filters[4].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "filter-title",
                                  attrs: { span: 24, align: "left" }
                                },
                                [
                                  _vm._v(
                                    "\n                Légende\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c("span", {
                                  staticClass: "legende-item echeance-depassee"
                                }),
                                _vm._v(
                                  " Date d'échéance dépassée et solde différent de 0\n              "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-row",
            { attrs: { id: "anchor-table-site" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-d-arrow-right",
                        type: "primary",
                        size: "mini",
                        loading: _vm.btnExportLoading.excel,
                        disabled: _vm.total === 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleExport("excel")
                        }
                      }
                    },
                    [_vm._v("\n          Export Excel\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "data-tables",
        {
          directives: [
            {
              name: "fixed-header",
              rawName: "v-fixed-header",
              value: { startFixed: 48 },
              expression: "{ startFixed: 48 }"
            }
          ],
          ref: "dataTable",
          attrs: {
            layout: "tool, table",
            data: _vm.data,
            filters: _vm.filters,
            "sort-method": _vm.sortMethod,
            "table-props": {
              fit: true,
              size: "mini",
              spanMethod: _vm.objectSpanMethod,
              rowClassName: _vm.tableRowClassName,
              showSummary: true,
              summaryMethod: _vm.getSummaries,
              defaultSort: { prop: "date_facture", order: "descending" }
            },
            total: _vm.total
          },
          on: {
            "row-click": function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleCurrentChange($event)
            },
            "selection-change": _vm.handleSelectionChange
          }
        },
        [
          _c("el-table-column", {
            key: _vm.columns["affaire.num_affaire"].key,
            attrs: {
              sortable: "custom",
              label: _vm.columns["affaire.num_affaire"].label,
              prop: _vm.columns["affaire.num_affaire"].prop,
              width: _vm.colWidth("affaire.num_affaire"),
              fixed: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.affaire.num_affaire))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["commande"].key,
            attrs: {
              sortable: "custom",
              label: _vm.columns["commande"].label,
              prop: _vm.columns["commande"].prop,
              width: _vm.colWidth("commande")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.commande.numero))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Facture" } },
            [
              _c("el-table-column", {
                key: _vm.columns["facture.date_facture"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["facture.date_facture"].label,
                  prop: _vm.columns["facture.date_facture"].prop,
                  width: _vm.colWidth("facture.date_facture"),
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toDate")(scope.row.facture.date_facture)
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["facture.numero"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["facture.numero"].label,
                  prop: _vm.columns["facture.numero"].prop,
                  width: _vm.colWidth("facture.numero")
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.facture.numero))])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["facture.libelle"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["facture.libelle"].label,
                  prop: _vm.columns["facture.libelle"].prop,
                  width: _vm.colWidth("facture.libelle")
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.facture.libelle))])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["facture.retenue_garantie"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["facture.retenue_garantie"].label,
                  prop: _vm.columns["facture.retenue_garantie"].prop,
                  width: _vm.colWidth("facture.retenue_garantie"),
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toCurrency")(
                                scope.row.facture.retenue_garantie
                              )
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["facture.mt_avant_paiement_direct_ttc"].key,
                attrs: {
                  sortable: "custom",
                  label:
                    _vm.columns["facture.mt_avant_paiement_direct_ttc"].label,
                  prop:
                    _vm.columns["facture.mt_avant_paiement_direct_ttc"].prop,
                  width: _vm.colWidth("facture.mt_avant_paiement_direct_ttc"),
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toCurrency")(
                                scope.row.facture.mt_avant_paiement_direct_ttc
                              )
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["facture.mt_a_percevoir_ttc"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["facture.mt_a_percevoir_ttc"].label,
                  prop: _vm.columns["facture.mt_a_percevoir_ttc"].prop,
                  width: _vm.colWidth("facture.mt_a_percevoir_ttc"),
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toCurrency")(
                                scope.row.facture.mt_a_percevoir_ttc
                              )
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["facture.date_echeance"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["facture.date_echeance"].label,
                  prop: _vm.columns["facture.date_echeance"].prop,
                  width: _vm.colWidth("facture.date_echeance"),
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toDate")(scope.row.facture.date_echeance)
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Règlement" } },
            [
              _c("el-table-column", {
                key: _vm.columns["reglement.date"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["reglement.date"].label,
                  prop: _vm.columns["reglement.date"].prop,
                  width: _vm.colWidth("reglement.date"),
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.reglements.length === 0
                          ? _c("div", [_vm._v("aucun règlement")])
                          : scope.row.reglements.length === 1
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toDate")(scope.row.reglements[0].date)
                                )
                              )
                            ])
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.showNbReglement(scope.row.reglements)
                                )
                              )
                            ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["facture.mt_regle"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["facture.mt_regle"].label,
                  prop: _vm.columns["facture.mt_regle"].prop,
                  width: _vm.colWidth("facture.mt_regle"),
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toCurrency")(scope.row.facture.mt_regle)
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["reglement.mode"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["reglement.mode"].label,
                  prop: _vm.columns["reglement.mode"].prop,
                  width: _vm.colWidth("reglement.mode"),
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.reglements.length === 0
                          ? _c("div", [_vm._v("-")])
                          : scope.row.reglements.length === 1
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getLabelModeReglement")(
                                    scope.row.reglements[0].mode
                                  )
                                )
                              )
                            ])
                          : _c("div", [
                              _vm._v(
                                "multiple (" +
                                  _vm._s(scope.row.reglements.length) +
                                  ")"
                              )
                            ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["facture.solde"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["facture.solde"].label,
                  prop: _vm.columns["facture.solde"].prop,
                  width: _vm.colWidth("facture.solde"),
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toCurrency")(scope.row.facture.solde)
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["facture.commentaire"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["facture.commentaire"].label,
                  prop: _vm.columns["facture.commentaire"].prop,
                  width: _vm.colWidth("facture.commentaire")
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          scope.row.facture.commentaire
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "can",
                                      rawName: "v-can:facturation",
                                      value: "suivre",
                                      expression: "'suivre'",
                                      arg: "facturation"
                                    }
                                  ]
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      circle: "",
                                      icon: "el-icon-info",
                                      size: "mini",
                                      type: "info"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleCommand({
                                          action: "commentaire",
                                          row: Object.assign({}, scope.row)
                                        })
                                      }
                                    }
                                  }),
                                  _vm._v("\n               \n              ")
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.facture.commentaire) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Affaire" } },
            [
              _c("el-table-column", {
                key: _vm.columns["secteur_geo"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["secteur_geo"].label,
                  prop: _vm.columns["secteur_geo"].prop,
                  width: _vm.colWidth("secteur_geo"),
                  formatter: _vm.formatSecteurGeo
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: _vm.columns["affaire"].key,
                attrs: {
                  sortable: "custom",
                  label: _vm.columns["affaire"].label,
                  prop: _vm.columns["affaire"].prop,
                  width: _vm.colWidth("affaire")
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push(
                                      "/affaire/" + scope.row.affaire.id
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.affaire.nom) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["enseigne"].key,
            attrs: {
              sortable: "custom",
              label: _vm.columns["enseigne"].label,
              prop: _vm.columns["enseigne"].prop,
              width: _vm.colWidth("enseigne")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.affaire.enseigne.libelle))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["client"].key,
            attrs: {
              sortable: "custom",
              label: _vm.columns["client"].label,
              prop: _vm.columns["client"].prop,
              width: _vm.colWidth("client")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.affaire.client.libelle))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", fixed: "right", width: "125" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-dropdown",
                      {
                        attrs: { size: "mini" },
                        on: { command: _vm.handleCommand }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "el-dropdown-link",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [
                            _vm._v("\n            Actions "),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  icon: "el-icon-circle-plus",
                                  command: {
                                    action: "paiement",
                                    index: scope.$index,
                                    row: scope.row
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              Ajouter un paiement\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "can",
                                    rawName: "v-can:facturation",
                                    value: "administrer",
                                    expression: "'administrer'",
                                    arg: "facturation"
                                  }
                                ],
                                attrs: {
                                  icon: "el-icon-edit-outline",
                                  command: {
                                    action: "commentaire",
                                    row: scope.row
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      !scope.row.facture.commentaire
                                        ? "Ajouter un commentaire"
                                        : "Modifier le commentaire"
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  icon: "el-icon-document",
                                  command: {
                                    action: "visualiser",
                                    row: scope.row
                                  },
                                  disabled: !scope.row.facture.fichier
                                }
                              },
                              [
                                _vm._v(
                                  "\n              Visualiser\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  icon: "el-icon-money",
                                  divided: "",
                                  command: {
                                    action: "reglements",
                                    index: scope.$index,
                                    row: scope.row
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              Gérer les réglements\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Commentaire pour la facture n°" + _vm.rowComment.numero,
            visible: _vm.dialogFactureCommentaireVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFactureCommentaireVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.rowComment } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Commentaire" } },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:facturation",
                        value: "administrer",
                        expression: "'administrer'",
                        arg: "facturation"
                      }
                    ],
                    attrs: {
                      maxlength: "150",
                      "show-word-limit": "",
                      type: "textarea"
                    },
                    model: {
                      value: _vm.rowComment.commentaire,
                      callback: function($$v) {
                        _vm.$set(_vm.rowComment, "commentaire", $$v)
                      },
                      expression: "rowComment.commentaire"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "cant",
                        rawName: "v-cant:facturation",
                        value: "administrer",
                        expression: "'administrer'",
                        arg: "facturation"
                      }
                    ],
                    domProps: { innerHTML: _vm._s("<br>" + _vm.rowCommentHtml) }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFactureCommentaireVisible = false
                    }
                  }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "cant",
                          rawName: "v-cant:facturation",
                          value: "administrer",
                          expression: "'administrer'",
                          arg: "facturation"
                        }
                      ]
                    },
                    [_vm._v("\n          Fermer\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:facturation",
                          value: "administrer",
                          expression: "'administrer'",
                          arg: "facturation"
                        }
                      ]
                    },
                    [_vm._v("\n          Annuler\n        ")]
                  ),
                  _vm._v(">\n      ")
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "can",
                      rawName: "v-can:facturation",
                      value: "administrer",
                      expression: "'administrer'",
                      arg: "facturation"
                    }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.actionEditCommentaire }
                },
                [_vm._v("\n        Enregistrer\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }