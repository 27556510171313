"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiEnseigne = _interopRequireWildcard(require("@/api/enseigne"));

var _listClient = _interopRequireDefault(require("@/components/listClient"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import * as validate from '@/helpers/validate'
var _default = {
  props: {
    formData: Object,
    title: String,
    action: String
  },
  components: {
    Client: _listClient.default
  },
  data: function data() {
    /*
    const validEmail = (rule, value, callback) => {
      value = value || ''
      if (value !== '' && !validate.validEmail(value)) {
        callback(new Error('Adresses email non valide'))
      }
      callback()
    }
    */
    return {
      rules: {
        code: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        libelle: [{
          required: true,
          message: 'Champ obligatoire'
        }]
        /*
        nom_interlocuteur: [
          { required: true, message: 'Champ obligatoire' }
        ],
        prenom_interlocuteur: [
          { required: true, message: 'Champ obligatoire' }
        ],
        code_postal: [
          { required: true, message: 'Champ obligatoire' }
        ],
        ville: [
          { required: true, message: 'Champ obligatoire' }
        ],
        email: [
          { type: 'email', validator: validEmail }
        ]
        */

      }
    };
  },
  methods: {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this.action) {
            case 'new':
              _this.actionNew();

              break;

            case 'edit':
              _this.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this2 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiEnseigne.insert(data).then(function (results) {
        _this2.$message('Création effectuée avec succès');

        _this2.resetForm();

        _this2.$loader(false);
      }).catch(function (error) {
        _this2.$message(error);

        _this2.$loader(false);
      });
    },
    actionEdit: function actionEdit() {
      var _this3 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiEnseigne.update(this.formData.id, data).then(function (results) {
        _this3.$message('Mise à jour effectuée avec succès');

        _this3.$loader(false);
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    getFormData: function getFormData() {
      return {
        id_client: this.formData.id_client,
        code: this.formData.code,
        libelle: this.formData.libelle,
        nom_interlocuteur: this.formData.nom_interlocuteur,
        prenom_interlocuteur: this.formData.prenom_interlocuteur,
        telephone: this.formData.telephone,
        adresse1: this.formData.adresse1,
        adresse2: this.formData.adresse2,
        code_postal: this.formData.code_postal,
        ville: this.formData.ville,
        email: this.formData.email,
        actif: this.formData.actif
      };
    }
  }
};
exports.default = _default;