"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _typeof2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/typeof"));

require("core-js/modules/es7.object.values");

require("core-js/modules/web.dom.iterable");

var _vuex = require("vuex");

var _AffaireList = _interopRequireDefault(require("@/components/AffaireList"));

var _listClient = _interopRequireDefault(require("@/components/listClient"));

var _listEnseigne = _interopRequireDefault(require("@/components/listEnseigne"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'FactureIndex',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    ListClient: _listClient.default,
    ListEnseigne: _listEnseigne.default,
    AffaireList: _AffaireList.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      activeFilter: '',
      filters: {
        idClient: null,
        idEnseigne: null,
        numeroAffaire: null
      }
    };
  },
  computed: {
    infoIsVisible: function infoIsVisible() {
      return this.activeFilter === '';
    },
    showAffaireList: function showAffaireList() {
      _jsCookie.default.set('venteCreances.factures.filters', this.filters, {
        path: ''
      });

      return this.filters.idClient || this.filters.idEnseigne || this.filters.numeroAffaire;
    },
    filterExists: function filterExists() {
      var filterExists = false;
      Object.values(this.filters).forEach(function (x, index) {
        // console.log(index + '# typeof', x, typeof x)
        switch ((0, _typeof2.default)(x)) {
          case 'string':
            if (x !== null && x !== '') {
              // console.log('A.' + index + '# typeof', x, typeof x)
              filterExists = true;
            }

            break;

          case 'number':
            if (x !== null && x > 0) {
              // console.log('B.' + index + '# typeof', x, typeof x)
              filterExists = true;
            }

            break;

          case 'object':
            if (x && x.length > 0) {
              // console.log('C.' + index + '# typeof', x, typeof x)
              filterExists = true;
            }

            break;
        }
      });
      return filterExists;
    }
  },
  // 9. Évènements
  // --
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // Suppression du cookie mémorisant les filtres
    if (!['VenteCreance.Factures.CommandeIndex', 'VenteCreance.Factures.ListByAffaire'].includes(to.name)) {
      _jsCookie.default.remove('venteCreances.factures.filters', {
        path: ''
      });
    }

    next();
  },
  created: function created() {
    this.getAffaires();
  },
  beforeMount: function beforeMount() {
    var defaultFilters = {
      idClient: null,
      idEnseigne: null,
      numeroAffaire: null
    };
    this.filters = _jsCookie.default.getJSON('venteCreances.factures.filters') || defaultFilters;
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)({}, (0, _vuex.mapActions)('affaire', ['getAffaires']), {
    handleManageFacture: function handleManageFacture(_ref) {
      var index = _ref.index,
          row = _ref.row,
          nextTickEventName = _ref.nextTickEventName;
      this.$loader(true);
      this.$router.push({
        name: 'VenteCreance.Factures.ListByAffaire',
        params: {
          affaireId: row.id,
          nextTickEventName: nextTickEventName
        }
      });
    }
  }) // 11. Rendu
  // --

};
exports.default = _default;