"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin-parametre',
  name: 'AdminParametre',
  order: 6,
  meta: {
    title: 'Gestion des Paramètres',
    icon: 'parametre'
  },
  redirect: '/admin/parametre',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '/admin/parametre',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/parametre/ParametreList'));
      });
    },
    name: 'ListParametre',
    meta: {
      title: 'Paramètres',
      activeMenu: '/admin/parametre'
    }
  }, {
    path: '/admin/parametre/new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/parametre/ParametreNew'));
      });
    },
    name: 'NewParametre',
    meta: {
      title: 'Ajouter un paramètres',
      activeMenu: '/admin/parametre'
    }
  }, {
    path: '/admin/parametre/edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/parametre/ParametreEdit'));
      });
    },
    name: 'EditParametre',
    meta: {
      title: 'Modifier un paramètres',
      activeMenu: '/admin/parametre'
    }
  }]
}];
var _default = routes;
exports.default = _default;