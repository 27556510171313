"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TestExample',
  data: function data() {
    return {
      options: [{
        value: 'Risque facturation gros béton',
        label: 'Risque facturation gros béton'
      }, {
        value: 'Risque tassement - Reprise fissures ',
        label: 'Risque tassement - Reprise fissures '
      }],
      value: []
    };
  }
};
exports.default = _default;