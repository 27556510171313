"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.array.find");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'FactureSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    // Identifiant de la facture sélectionnée
    idFactureSelected: {
      type: Number
    },
    // Facture sélectionnée
    facture: {
      type: Object
    },
    // Désactiver la SelectList
    disabled: {
      type: Boolean,
      default: false
    },
    // Filtrer uniquement les facture imputables
    imputable: {
      type: Boolean,
      default: false
    },
    // Identifiant de la facture à ne pas inclure dans la SelectList
    // il s'agit généralement de la facture parente sélectionnée dans
    // le cas d'un règlement par imputation
    idFactureToSkip: {
      type: Number,
      default: 0
    },
    // Identifiant de la facture à conserver dans la SelectList
    // il s'agit de la facture initialement sélectionné dans le cas d'un edit
    idFactureToKeep: {
      type: Number,
      default: 0
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      factures: [],
      idFacture: this.idFactureSelected
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('facturation', ['getFactureListForCurrentAffaire'])),
  // 9. Évènements
  // --
  watch: {
    idFactureToSkip: function idFactureToSkip(newValue, oldValue) {
      this.init();
    }
  },
  beforeMount: function beforeMount() {
    var _this = this;

    this.init();
    var facture = this.factures.find(function (x) {
      return x.id === _this.idFactureSelected;
    });

    if (facture) {
      this.$emit('update:facture', facture);
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    init: function init() {
      var _this2 = this;

      var factures = this.getFactureListForCurrentAffaire; // Filtrer uniquement les factures imputables (elles ont un trop-perçu)

      if (this.imputable === true) {
        factures = factures.filter(function (x) {
          return x.idc_imputable === true || x.id === _this2.idFactureSelected || x.id === _this2.idFactureToKeep;
        });
      } // Ne pas inclure une facture particulière


      if (this.idFactureToSkip > 0) {
        factures = factures.filter(function (x) {
          return x.id !== _this2.idFactureToSkip;
        });
      } // Mémorisation de la liste des factures disponibles


      this.factures = factures; // S'il ne reste aucune facture après l'application de tous les filtres

      if (this.factures.length === 0) {
        // On force l'identifiant de la facture à null
        this.idFacture = null;
        this.$emit('update:id-facture-selected', null);
        this.$emit('update:facture', null);
      } else {
        // Vérifions si l'Id de la facture initialement sélectionnée
        // fait toujours parti des factures disponibles
        var index = this.factures.findIndex(function (x) {
          return x.id === _this2.idFacture;
        });

        if (index === -1) {
          // On force l'identifiant de la facture à null
          this.idFacture = null;
          this.$emit('update:id-facture-selected', null);
          this.$emit('update:facture', null);
        }
      }
    },
    onClear: function onClear() {
      this.$emit('update:id-facture-selected', null);
      this.$emit('update:facture', null);
    },
    onChange: function onChange(idSelected) {
      if (idSelected > 0) {
        this.$emit('update:id-facture-selected', idSelected);
        this.$emit('update:facture', this.factures.find(function (x) {
          return x.id === idSelected;
        }));
      }
    }
  } // 11. Rendu
  // --

};
exports.default = _default;