var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "header-affaire" } },
    [
      _c(
        "el-row",
        { staticClass: "btn-back" },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-arrow-left", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v("Retour")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 5, align: "left" } }, [
            _c("h2", { staticClass: "numAffaire" }, [
              _vm._v(_vm._s(_vm.affaire.num_affaire))
            ])
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 14, align: "center" } }, [
            _c("h2", { staticClass: "nomAffaire" }, [
              _vm._v(_vm._s(_vm.affaire.nom))
            ])
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 5, align: "right" } }, [
            _c("h2", { staticClass: "statusAffaire" }, [
              _c(
                "div",
                {
                  staticStyle: { display: "inline-block", "padding-top": "5px" }
                },
                [_vm._v(_vm._s(_vm.status.caption))]
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }