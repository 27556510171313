"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var apiTypeVente = _interopRequireWildcard(require("@/api/type_vente"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'TypeVenteList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      row: [],
      total: 0,
      data: [],
      multipleSelection: [],
      columns: [{
        prop: 'libelle',
        label: 'Libellé',
        width: 150
      }, {
        prop: 'code',
        label: 'code'
      }, {
        prop: 'idc_defaut',
        label: 'Par défaut',
        width: 125
      }],
      filters: [{
        value: '',
        prop: ['libelle', 'code']
      }]
    };
  },
  // 9. Évènements
  // --
  mounted: function mounted() {
    this.loadData();
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    loadData: function loadData() {
      var _this = this;

      this.$loader(true);
      this.data = [];
      apiTypeVente.getAll().then(function (results) {
        results.forEach(function (row) {
          row.idc_defaut = row.idc_defaut === 1 ? '✓' : '';

          _this.data.push(row);
        });
        _this.data.total = results.length;

        _this.$loader(false);
      }).catch(function (error) {
        _this.$message(error);

        _this.$loader(false);
      });
    },
    // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
    colWidth: function colWidth(index) {
      if (this.columns[index] === undefined) {
        return;
      }

      return this.columns[index].width;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;

      this.$confirm("Voulez-vous supprimer le type de vente '".concat(row.libelle, "' ?"), 'Confirmer la suppression ?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this2.$loader(true);

        apiTypeVente.deleteById(row.id).then(function (results) {
          _this2.data.splice(_this2.data.indexOf(row), 1);

          _this2.$message('Suppression effectuée avec succès');

          _this2.$loader(false);
        }).catch(function (err) {
          _this2.$message(err);

          _this2.$loader(false);
        });
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this3 = this;

      var msg = this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée';
      this.$confirm('Voulez-vous supprimer ' + msg + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this3.$loader(true);

        var selection = _this3.multipleSelection.map(function (u) {
          return u.id;
        }).join(',');

        apiTypeVente.bulkDelete(selection).then(function (results) {
          _this3.$message('Suppression effectuée avec succès');

          _this3.loadData();

          _this3.$loader(false);
        }).catch(function (error) {
          _this3.$message(error);

          _this3.$loader(false);
        });
      });
    }
  } // 11. Rendu
  // --

};
exports.default = _default;