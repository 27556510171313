"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _vuex = require("vuex");

var apiPermission = _interopRequireWildcard(require("@/api/permission"));

var _treeAcl = _interopRequireDefault(require("@/components/treeAcl"));

var _listPermissions = _interopRequireDefault(require("@/components/listPermissions"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    formData: Object,
    idSociete: Number,
    title: String,
    action: String
  },
  components: {
    treeAcl: _treeAcl.default,
    listPermissions: _listPermissions.default
  },
  data: function data() {
    var _this = this;

    var validRole = function validRole(rule, value, callback) {
      if (!_this.formData.name || _this.formData.name && _this.formData.name.trim() === '') {
        callback(new Error('Champ obligatoire'));
      }

      callback();
    };

    return {
      rules: {
        key_role: {
          required: true,
          message: 'Champ obligatoire',
          validator: validRole
        }
      },
      societe: {}
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar', 'user', 'permission'])),
  methods: {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this2 = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this2.action) {
            case 'new':
              _this2.actionNew();

              break;

            case 'edit':
              _this2.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this3 = this;

      this.$loader(true);
      var data = this.getData();
      apiPermission.insert(data).then(function (results) {
        _this3.$message('Création effectuée avec succès');

        if (_this3.user.id_societe === _this3.formData.id_societe && _this3.user.key_role === _this3.formData.key_role) {
          _this3.$store.commit('permission/SET_ACL', {
            acl: _this3.formData.acl,
            rootacl: _this3.formData.rootacl
          });

          _this3.$store.commit('permission/SET_RULES', _this3.formData.rules);

          _this3.$store.dispatch('permission/generateRoutes');
        }

        _this3.$loader(false);
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    actionEdit: function actionEdit() {
      var _this4 = this;

      this.$loader(true);
      var data = this.getData();
      apiPermission.update(this.formData.id, data).then(function (results) {
        _this4.$message('Mise à jour effectuée avec succès');

        if (_this4.user.id_societe === _this4.formData.id_societe && _this4.user.key_role === _this4.formData.key_role) {
          _this4.$store.commit('permission/SET_ACL', {
            acl: _this4.formData.acl,
            rootacl: _this4.formData.rootacl
          });

          _this4.$store.commit('permission/SET_RULES', _this4.formData.rules);

          _this4.$store.dispatch('permission/generateRoutes');
        }

        _this4.$loader(false);
      }).catch(function (error) {
        _this4.$message(error);

        _this4.$loader(false);
      });
    },
    makeId: function makeId(length) {
      var result = '';
      var characters = 'abcdefghijklmnopqrstuvwxyz';
      var charactersLength = characters.length;

      for (var i = 0; i < charactersLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result.substring(0, 10);
    },
    getData: function getData() {
      var data = {
        id_societe: this.idSociete,
        key_role: this.action === 'edit' ? this.formData.key_role : this.makeId(),
        name: this.formData.name,
        acl: this.formData.acl,
        rootacl: this.formData.rootacl,
        rules: this.formData.rules
      };
      return data;
    }
  }
};
exports.default = _default;