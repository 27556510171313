var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Notifications")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _vm._v(" \n      "),
              _vm.notifications.unreadCounter > 0
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-check",
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.handleMarkAllAsRead }
                    },
                    [_vm._v("\n        Marquer tout comme lu\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.multipleSelection.length > 0
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-delete",
                        size: "mini",
                        type: "danger"
                      },
                      on: { click: _vm.handleDeleteSelection }
                    },
                    [_vm._v("\n        Supprimer la sélection\n      ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { "white-space": "nowrap" },
              attrs: { span: 12, align: "right" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: {
                  placeholder: "Rechercher",
                  clearable: "",
                  size: "mini"
                },
                model: {
                  value: _vm.filters[0].value,
                  callback: function($$v) {
                    _vm.$set(_vm.filters[0], "value", $$v)
                  },
                  expression: "filters[0].value"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "data-tables",
        {
          ref: "dataTable",
          attrs: {
            layout: "tool, pagination, table, pagination",
            data: _vm.notifications.notifications,
            filters: _vm.filters,
            "pagination-props": { pageSizes: [20, 50, 100] },
            total: _vm.total
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "45" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "column-key": "date_markasread",
              label: "Lu",
              prop: "date_markasread",
              width: "55",
              filters: _vm.filterReadStatus,
              "filter-method": _vm.filterHandler
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row["date_markasread"] !== null
                      ? _c("i", { staticClass: "el-icon-check" })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.columns, function(col, index) {
            return _c("el-table-column", {
              key: col.id,
              attrs: {
                sortable: "custom",
                label: col.label,
                prop: col.prop,
                width: _vm.colWidth(index)
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        col.prop == "num_affaire"
                          ? _c(
                              "div",
                              [
                                scope.row["url_link"] !== null &&
                                scope.row["url_link"] !== ""
                                  ? [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: scope.row["type"] },
                                          on: {
                                            click: function($event) {
                                              _vm.$router.replace(
                                                scope.row["url_link"]
                                                  .toString()
                                                  .replace("/#", "")
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                scope.row["num_affaire"]
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        col.prop == "message"
                          ? _c(
                              "div",
                              [
                                scope.row["url_link"] === null ||
                                scope.row["url_link"] === ""
                                  ? [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            scope.row["message"]
                                          )
                                        }
                                      })
                                    ]
                                  : [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: scope.row["type"] },
                                          on: {
                                            click: function($event) {
                                              _vm.$router.replace(
                                                scope.row["url_link"]
                                                  .toString()
                                                  .replace("/#", "")
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                scope.row["message"]
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ]
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        col.prop == "date_creation"
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("toDateTime")(scope.row[col.prop])
                                  ) +
                                  "\n        "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }