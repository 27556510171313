var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sync-scroll-bars" },
    [
      _c(
        "el-row",
        [_c("el-col", { attrs: { span: 24, align: "right" } }, [_vm._v(" ")])],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: "SyntheseEntetes_" + _vm.refresh,
          staticClass: "zoomer",
          attrs: {
            data: [{}],
            size: "mini",
            "row-class-name": "synthese",
            "cell-class-name": _vm.cellClassName,
            border: "",
            "data-sync-scrollbar": "summaries"
          }
        },
        [
          _c("el-table-column", { attrs: { label: "", width: "2205" } }, [
            _c("table", { staticClass: "synthese_entetes" }, [
              _c("tr", [
                _c("th", { attrs: { width: "470", rowspan: "2" } }, [
                  _vm._v(" ")
                ]),
                _vm._v(" "),
                _c("th", { attrs: { width: "15", rowspan: "2" } }, [
                  _vm._v(" ")
                ]),
                _vm._v(" "),
                _c("th", { attrs: { width: "775", rowspan: "2" } }, [
                  _vm._v(" ")
                ]),
                _vm._v(" "),
                _c("th", { attrs: { width: "15", rowspan: "2" } }, [
                  _vm._v(" ")
                ]),
                _vm._v(" "),
                _c("th", { attrs: { width: "240", colspan: "3" } }, [
                  _vm._v(" ")
                ]),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "tb-desc center",
                    attrs: { width: "130", rowspan: "2" }
                  },
                  [_vm._v("Budget débloqué")]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "tb-desc center",
                    attrs: { width: "130", rowspan: "2" }
                  },
                  [_vm._v("Dépenses"), _c("br"), _vm._v("comptabilisées")]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "tb-desc center",
                    attrs: { width: "130", rowspan: "2" }
                  },
                  [_vm._v("Engagés")]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "tb-desc center",
                    attrs: { width: "130", rowspan: "2" }
                  },
                  [_vm._v("Provisions")]
                )
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: "Synthese_" + _vm.refresh,
          staticClass: "zoomer",
          attrs: {
            data: [{}],
            size: "mini",
            "row-class-name": "synthese",
            "cell-class-name": _vm.cellClassName,
            border: "",
            "data-sync-scrollbar": "footer"
          }
        },
        [
          _c("el-table-column", { attrs: { label: "", width: "1260" } }, [
            _c("table", [
              _c("tbody", [
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "tb-pattern", attrs: { width: "325" } },
                    [_vm._v(" ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "tb-desc center", attrs: { width: "150" } },
                    [_vm._v("Fin de période")]
                  ),
                  _vm._v(" "),
                  _c("td", { attrs: { width: "15" } }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "tb-desc center", attrs: { width: "130" } },
                    [_vm._v("Fin de chantier")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "tb-desc center", attrs: { width: "130" } },
                    [_vm._v("%")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "tb-pattern", attrs: { width: "130" } },
                    [_vm._v(" ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "tb-desc center", attrs: { width: "130" } },
                    [_vm._v("Prévisionnel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "tb-pattern", attrs: { width: "130" } },
                    [_vm._v(" ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "tb-pattern", attrs: { width: "130" } },
                    [_vm._v(" ")]
                  )
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "tb-desc" }, [
                    _vm._v("Produit mérité")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(_vm.data.mtPmPrixDeVente))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(_vm.data.mtFdcPmPrixDeVente))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(_vm._f("toPercentage")(_vm.data.pcPmPrixDeVente))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "9" } }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "tb-desc" }, [
                    _vm._v("Marge brute 1 (Vente - Budget)")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.data.mtMargeBrute1)))
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(_vm.data.mtFdcMargeBrute1))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(_vm._f("toPercentage")(_vm.data.pcMargeBrute1))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "9" } }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "tb-desc" }, [_vm._v("Provisions")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.data.mtTotalGeneralProvisionsRisques
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.data.mtTotalGeneralProvisionsRisques
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "tb-desc" }, [
                    _vm._v("Facture de prorata / Mandat")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm.modeEdit
                      ? _c(
                          "div",
                          [
                            _c("el-input", {
                              directives: [
                                { name: "numberonly", rawName: "v-numberonly" }
                              ],
                              staticClass: "currency",
                              staticStyle: { width: "120px" },
                              attrs: { size: "mini", autocomplete: "no" },
                              on: { input: _vm.handleInput },
                              model: {
                                value: _vm.data.mtFactureProrata,
                                callback: function($$v) {
                                  _vm.$set(_vm.data, "mtFactureProrata", $$v)
                                },
                                expression: "data.mtFactureProrata"
                              }
                            })
                          ],
                          1
                        )
                      : _c("div", [
                          _c("span", { staticClass: "s-input" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(_vm.data.mtFactureProrata)
                              )
                            )
                          ])
                        ])
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(_vm.data.mtFactureProrata))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm.modeEdit
                      ? _c(
                          "div",
                          [
                            _c("el-input", {
                              directives: [
                                { name: "numberonly", rawName: "v-numberonly" }
                              ],
                              staticClass: "currency",
                              staticStyle: { width: "120px" },
                              attrs: { size: "mini", autocomplete: "no" },
                              on: { input: _vm.handleInput },
                              model: {
                                value: _vm.data.mtPrevFactureProrata,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.data,
                                    "mtPrevFactureProrata",
                                    $$v
                                  )
                                },
                                expression: "data.mtPrevFactureProrata"
                              }
                            })
                          ],
                          1
                        )
                      : _c("div", [
                          _c("span", { staticClass: "s-input" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm.data.mtPrevFactureProrata
                                )
                              )
                            )
                          ])
                        ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass: "tb-pattern center",
                      attrs: { colspan: "2" }
                    },
                    [
                      this.$store.getters.affaire.currentGestMensYYYYMM ===
                        this.$store.getters.affaire.YYYYMM &&
                      this.$store.getters.affaire.affaire.etape ===
                        _vm.CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value &&
                      this.$store.getters.affaire.affaire.status !==
                        _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value &&
                      this.$store.getters.affaire.affaire.status !==
                        _vm.CONST.AFFAIRE.STATUS.CLOSED.value &&
                      this.$store.getters.affaire.affaire.etape !==
                        _vm.CONST.AFFAIRE.STATUS.SLEEPING.value
                        ? _c("div", [
                            !_vm.modeEdit
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                          icon: "el-icon-edit"
                                        },
                                        on: { click: _vm.handleEdit }
                                      },
                                      [_vm._v("Modifier")]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "warning",
                                          size: "mini",
                                          icon: "el-icon-close"
                                        },
                                        on: { click: _vm.handleCancel }
                                      },
                                      [_vm._v("Annuler")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "success",
                                          size: "mini",
                                          icon: "el-icon-check"
                                        },
                                        on: { click: _vm.handleSave }
                                      },
                                      [_vm._v("Enregistrer")]
                                    )
                                  ],
                                  1
                                )
                          ])
                        : _vm._e()
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "tb-desc" }, [
                    _vm._v("Factures diverses")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm.modeEdit
                      ? _c(
                          "div",
                          [
                            _c("el-input", {
                              directives: [
                                { name: "numberonly", rawName: "v-numberonly" }
                              ],
                              staticClass: "currency",
                              staticStyle: { width: "120px" },
                              attrs: { size: "mini", autocomplete: "no" },
                              on: { input: _vm.handleInput },
                              model: {
                                value: _vm.data.mtFactureDiverse,
                                callback: function($$v) {
                                  _vm.$set(_vm.data, "mtFactureDiverse", $$v)
                                },
                                expression: "data.mtFactureDiverse"
                              }
                            })
                          ],
                          1
                        )
                      : _c("div", [
                          _c("span", { staticClass: "s-input" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(_vm.data.mtFactureDiverse)
                              )
                            )
                          ])
                        ])
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(_vm.data.mtFactureDiverse))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm.modeEdit
                      ? _c(
                          "div",
                          [
                            _c("el-input", {
                              directives: [
                                { name: "numberonly", rawName: "v-numberonly" }
                              ],
                              staticClass: "currency",
                              staticStyle: { width: "120px" },
                              attrs: { size: "mini", autocomplete: "no" },
                              on: { input: _vm.handleInput },
                              model: {
                                value: _vm.data.mtPrevFactureDiverse,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.data,
                                    "mtPrevFactureDiverse",
                                    $$v
                                  )
                                },
                                expression: "data.mtPrevFactureDiverse"
                              }
                            })
                          ],
                          1
                        )
                      : _c("div", [
                          _c("span", { staticClass: "s-input" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm.data.mtPrevFactureDiverse
                                )
                              )
                            )
                          ])
                        ])
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "tb-desc" }, [
                    _vm._v("Révision / Actualisation")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(_vm.data.mtRevisionActu))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(_vm.data.mtRevisionActu))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm.modeEdit
                      ? _c(
                          "div",
                          [
                            _c("el-input", {
                              directives: [
                                { name: "numberonly", rawName: "v-numberonly" }
                              ],
                              staticClass: "currency",
                              staticStyle: { width: "120px" },
                              attrs: { size: "mini", autocomplete: "no" },
                              on: { input: _vm.handleInput },
                              model: {
                                value: _vm.data.mtPrevRevisionActu,
                                callback: function($$v) {
                                  _vm.$set(_vm.data, "mtPrevRevisionActu", $$v)
                                },
                                expression: "data.mtPrevRevisionActu"
                              }
                            })
                          ],
                          1
                        )
                      : _c("div", [
                          _c("span", { staticClass: "s-input" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm.data.mtPrevRevisionActu
                                )
                              )
                            )
                          ])
                        ])
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "9" } }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "tb-desc" }, [
                    _vm._v("Marge brute 2")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.data.mtMargeBrute2)))
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.data.mtMargeBrute2)))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "9" } }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "tb-desc" }, [
                    _vm._v("Marge brute totale")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(_vm.data.mtMargeBruteTotale))
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(_vm.data.mtFdcMargeBruteTotale)
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toPercentage")(_vm.data.pcMargeBruteTotale)
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "9" } }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "tb-desc" }, [
                    _vm._v("Marge brute totale M-1")
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(_vm.data.mtMargeBruteTotaleMmoins1)
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.data.mtFdcMargeBruteTotaleMmoins1
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "tb-desc" }, [
                    _vm._v("Écarts M / M-1")
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass: "tb-data",
                      style:
                        _vm.data.mtEcartMargeBruteTotaleMmoins1M < 0
                          ? "color:red"
                          : ""
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.data.mtEcartMargeBruteTotaleMmoins1M
                          )
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass: "tb-data",
                      style:
                        _vm.data.mtFdcEcartMargeBruteTotalMmoins1M < 0
                          ? "color:red"
                          : ""
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.data.mtFdcEcartMargeBruteTotalMmoins1M
                          )
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "9" } }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "tb-desc", attrs: { colspan: "2" } },
                    [_vm._v("Rappel MB sur vente initiale")]
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.data.mtRappelMb1)))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(_vm._s(_vm._f("toPercentage")(_vm.data.pcRappelMb1)))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "tb-desc", attrs: { colspan: "2" } },
                    [_vm._v("Rappel MB sur budget objectif")]
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.data.mtRappelMb2)))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(_vm._s(_vm._f("toPercentage")(_vm.data.pcRappelMb2)))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "tb-desc", attrs: { colspan: "2" } },
                    [_vm._v("Rappel MB sur budget d'exécution")]
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.data.mtRappelMb3)))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(_vm._s(_vm._f("toPercentage")(_vm.data.pcRappelMb3)))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { attrs: { colspan: "9" } }, [_vm._v(" ")])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "tb-desc", attrs: { colspan: "2" } },
                    [_vm._v("Écart de MB Budget exécution / MB Fdc")]
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(" ")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass: "tb-data",
                      style:
                        _vm.data.mtEcartMbBudgetExec >= 0 ? "color:red" : ""
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(_vm.data.mtEcartMbBudgetExec)
                          )
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-data" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toPercentage")(_vm.data.pcEcartMbBudgetExec)
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "", width: "930" } }, [
            _c("table", [
              _c(
                "tbody",
                [
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "tb-desc", attrs: { width: "240" } },
                      [_vm._v("Total budget débloqué")]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "tb-data", attrs: { width: "130" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.totaux.mtTotalGeneralBudgetDebloque
                            )
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "tb-pattern", attrs: { width: "130" } },
                      [_vm._v(" ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "tb-pattern", attrs: { width: "130" } },
                      [_vm._v(" ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "tb-pattern", attrs: { width: "130" } },
                      [_vm._v(" ")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("% avancement global")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _c(
                        "span",
                        {
                          attrs: {
                            title:
                              "% avancement global = mtTotalGeneralBudgetDebloque / mtTotalGeneralBudgetGlobalAffaire"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("toPercentage")(
                                _vm.totaux.pcAvancementGlobal
                              )
                            )
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("Dépenses comptabilisées")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(_vm.totaux.mtTotalGeneralFacture)
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("Total engagés")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(_vm.totaux.mtTotalGeneralEngage)
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("Total provisions")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.totaux.mtTotalGeneralProvisionsRisques
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "6" } }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("Marché de base (montant HT)")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.totaux.mtTotalGeneralVenteFinaleHT
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "6" } }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("Avenants client")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-desc center" }, [
                      _vm._v("Acceptés")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-desc center" }, [
                      _vm._v("Déboursés")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-desc center" }, [_vm._v("MB")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-desc center" }, [
                      _vm._v("% MB")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.avenantMarchePrincipal, function(avenant, index) {
                    return _c("tr", { key: index }, [
                      _c("td", { staticClass: "tb-desc" }, [
                        _vm._v("Avenant n° " + _vm._s(index + 1))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "tb-data" }, [
                        _vm._v(_vm._s(_vm._f("toCurrency")(avenant.mt_accepte)))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "tb-data" }, [
                        _vm._v(
                          _vm._s(_vm._f("toCurrency")(avenant.mt_debourse))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "tb-data" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              avenant.mt_accepte - avenant.mt_debourse
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "tb-data" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("toPercentage")(
                              avenant.mt_accepte !== 0
                                ? (avenant.mt_accepte - avenant.mt_debourse) /
                                    avenant.mt_accepte
                                : 0
                            )
                          )
                        )
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc right" }, [
                      _c("strong", [_vm._v("TOTAL")])
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.data.mtgestmensAvtMPrincTotalAcceptes
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.data.mtgestmensAvtMPrincTotalDebourses
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.data.mtgestmensAvtMPrincTotalMB
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toPercentage")(
                              _vm.data.pcgestmensAvtMPrincTotalMB
                            )
                          )
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "5" } }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("Total Marché (montant HT)")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm._f("toCurrency")(_vm.data.mtTotalMarche))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "5" } }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "5" } }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-desc center" }, [
                      _vm._v("Cumul M")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-desc center" }, [
                      _vm._v("Cumul M-1")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-desc center" }, [
                      _vm._v("Mois")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("Facturation client")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.data.mtCumulFacturationClientM
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.dataMmoins1.mtCumulFacturationClientM || 0
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(_vm.mtMoisFacturationClient)
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("Facturé mérité")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _vm._v(
                        _vm._s(_vm._f("toCurrency")(_vm.data.mtPmPrixDeVente))
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.dataMmoins1.mtPmPrixDeVente || 0
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _vm._v(
                        _vm._s(_vm._f("toCurrency")(_vm.mtMoisFactureMerite))
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "5" } }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("Produit constaté d'avance: PCA")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("toCurrency")(
                              // eslint-disable-next-line vue/no-parsing-error
                              _vm.data.mtCumulFacturationClientM >
                                _vm.data.mtPmPrixDeVente &&
                                _vm.data.pcPmPrixDeVente < 1
                                ? _vm.data.mtCumulFacturationClientM -
                                    _vm.data.mtPmPrixDeVente
                                : ""
                            )
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-data" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("toCurrency")(
                              // eslint-disable-next-line vue/no-parsing-error
                              _vm.data.mtCumulFacturationClientMmoins1 >
                                _vm.dataMmoins1.mtPmPrixDeVente &&
                                _vm.data.pcPmPrixDeVente < 1
                                ? _vm.data.mtCumulFacturationClientMmoins1 -
                                    _vm.dataMmoins1.mtPmPrixDeVente
                                : ""
                            )
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("Avoir à établir: AAE")
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "tb-data",
                        style:
                          "color:" +
                          (_vm.data.mtCumulFacturationClientM >=
                          _vm.data.mtPmPrixDeVente
                            ? "black"
                            : "red")
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("toCurrency")(
                                // eslint-disable-next-line vue/no-parsing-error
                                _vm.data.mtCumulFacturationClientM >=
                                  _vm.data.mtPmPrixDeVente &&
                                  _vm.data.pcPmPrixDeVente >= 1
                                  ? _vm.data.mtCumulFacturationClientM -
                                      _vm.data.mtPmPrixDeVente
                                  : ""
                              )
                            ) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "tb-data",
                        style:
                          "color:" +
                          (_vm.data.mtCumulFacturationClientMmoins1 >=
                          _vm.dataMmoins1.mtPmPrixDeVente
                            ? "black"
                            : "red")
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("toCurrency")(
                                _vm.data.mtCumulFacturationClientMmoins1 >=
                                  _vm.dataMmoins1.mtPmPrixDeVente &&
                                  _vm.dataMmoins1.pcPmPrixDeVente >= 1
                                  ? _vm.data.mtCumulFacturationClientMmoins1 -
                                      _vm.dataMmoins1.mtPmPrixDeVente
                                  : ""
                              )
                            ) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "tb-desc" }, [
                      _vm._v("Facture à établir: FAE")
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "tb-data",
                        style:
                          "color:" +
                          (_vm.data.mtCumulFacturationClientM >=
                          _vm.data.mtPmPrixDeVente
                            ? "black"
                            : "red")
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("toCurrency")(
                                // eslint-disable-next-line vue/no-parsing-error
                                _vm.data.mtCumulFacturationClientM <
                                  _vm.data.mtPmPrixDeVente
                                  ? Math.abs(
                                      _vm.data.mtCumulFacturationClientM -
                                        _vm.data.mtPmPrixDeVente
                                    )
                                  : ""
                              )
                            ) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "tb-data",
                        style:
                          "color:" +
                          (_vm.data.mtCumulFacturationClientMmoins1 >=
                          _vm.dataMmoins1.mtPmPrixDeVente
                            ? "black"
                            : "red")
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("toCurrency")(
                                // eslint-disable-next-line vue/no-parsing-error
                                _vm.data.mtCumulFacturationClientMmoins1 <
                                  _vm.dataMmoins1.mtPmPrixDeVente
                                  ? Math.abs(
                                      _vm.data.mtCumulFacturationClientMmoins1 -
                                        _vm.dataMmoins1.mtPmPrixDeVente
                                    )
                                  : ""
                              )
                            ) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "tb-pattern" }, [_vm._v(" ")])
                  ])
                ],
                2
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }