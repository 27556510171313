"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

var _eventBus = require("@/helpers/event-bus.js");

var _CommandeInfos = _interopRequireDefault(require("@/components/CommandeInfos"));

var _FactureList = _interopRequireDefault(require("./FactureList"));

var _FactureNew = _interopRequireDefault(require("./FactureNew"));

var _FactureEdit = _interopRequireDefault(require("./FactureEdit"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'FactureModule',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    FactureList: _FactureList.default,
    FactureNew: _FactureNew.default,
    FactureEdit: _FactureEdit.default,
    CommandeInfos: _CommandeInfos.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    // Filtre global pour ne voir que les factures d'une commande (cf. Module des commandes)
    idCommande: Number
  },

  /* ---------------------------------------
   *  Données / Méthodes disponibles pour les composants enfants
   * ---------------------------------------
   *
   *   - getCommande
   *     Retrouver la commande parente d'une facture (depuis le formulaire de création / édition).
   *
   */
  provide: function provide() {
    return {
      getCommande: this.getCommande
    };
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      // Mode d'affichage : list, new, edit
      modeView: 'list',
      // Identifiant de la facture à éditer
      id_facture: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('facturation', ['getCommandeById']), {
    /* ---------------------------------------
     *  Mode saisie des factures
     * ---------------------------------------
     *
     *   - true si modeView = (new | edit)
     *   - false si modeView = 'list'
     *
     */
    modeEdit: function modeEdit() {
      return this.modeView !== 'list';
    },

    /* ---------------------------------------
     *  Afficher le bouton de retour
     * ---------------------------------------
     *
     *   - si on est sur un formulaire (création, édition)
     *     OU
     *   - si on consulte les factures d'une commande
     *
     */
    showBtnReturn: function showBtnReturn() {
      return this.modeEdit === true || this.commande !== null;
    },
    // Commande parente
    commande: function commande() {
      return this.getCommandeById(this.idCommande) || null;
    }
  }),
  // 9. Évènements
  // --
  watch: {
    idCommande: function idCommande(newVal, oldVal) {
      if (newVal === undefined) {
        this.id_facture = 0;
        this.modeView = 'list';

        _eventBus.EventBus.$emit('FACTURE_RESET_LIST');
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('FACTURE_NEW', function () {
      _this.modeView = 'new';
    });

    _eventBus.EventBus.$on('FACTURE_EDIT', function (row) {
      _this.id_facture = row.id;
      _this.modeView = 'edit';
    });

    _eventBus.EventBus.$on('FACTURE_SAVE', function () {
      _this.id_facture = 0;
      _this.modeView = 'list';

      _eventBus.EventBus.$emit('REFRESH_CALCULS');
    });

    _eventBus.EventBus.$on('FACTURE_COMMENTAIRE_SAVE', function () {// this.id_facture = 0
      // this.modeView = 'list'
    });

    _eventBus.EventBus.$on('FACTURE_RETURN', function () {
      _this.id_facture = 0;
      _this.modeView = 'list';
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('FACTURE_NEW');

    _eventBus.EventBus.$off('FACTURE_EDIT');

    _eventBus.EventBus.$off('FACTURE_SAVE');

    _eventBus.EventBus.$off('FACTURE_COMMENTAIRE_SAVE');

    _eventBus.EventBus.$off('FACTURE_RETURN');
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    handleReturn: function handleReturn() {
      if (this.modeEdit === true) {
        _eventBus.EventBus.$emit('FACTURE_RETURN');
      } else if (this.commande !== null) {
        _eventBus.EventBus.$emit('TAB_MENU', 'commandes');
      }
    },

    /* ---------------------------------------
     *  Récupération de la commande en cours
     * ---------------------------------------
     *
     *  Méthode permettant à n'importe quel composant enfant de récupérer
     *  la commande actuellement sélectionnée (passée en prop depuis le parent).
     *  Utilisé pour gérer les factures liées à une commande notamment,
     *  via l'option `provide`
     *
     */
    getCommande: function getCommande(callback) {
      callback(this.commande);
    }
  } // 11. Rendu
  // --

};
exports.default = _default;