"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin-user',
  name: 'AdminUser',
  order: 5,
  meta: {
    title: 'Gestion des utilisateurs',
    icon: 'users'
  },
  redirect: '/admin/user',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '/admin/user',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/users'));
      });
    },
    name: 'ListUser',
    meta: {
      title: 'Utilisateurs',
      activeMenu: '/admin/user'
    }
  }, {
    path: '/admin/user/new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/users/new'));
      });
    },
    name: 'NewUser',
    meta: {
      title: 'Ajouter un utilisateur',
      activeMenu: '/admin/user'
    }
  }, {
    path: '/admin/user/edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/users/edit'));
      });
    },
    name: 'EditUser',
    meta: {
      title: 'Modifier un utilisateur',
      activeMenu: '/admin/user'
    }
  }]
}];
var _default = routes;
exports.default = _default;