"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.repeat");

require("core-js/modules/es7.string.pad-start");

var defaultValues = function defaultValues() {
  return {
    client: {
      visible: false,
      required: false
    },
    enseigne: {
      visible: false,
      required: false
    },
    ingenieurEtude: {
      visible: false,
      required: false
    },
    chefProjet: {
      visible: false,
      required: false,
      label: 'Chef de projet'
    },
    conducteurTravaux: {
      visible: false,
      required: false
    },
    codeIdentVille: {
      length: 0,
      visible: false,
      required: false
    },
    responsableSecteur: {
      visible: false,
      required: false
    },
    secteurGeo: {
      modifiable: true
    },
    // ==================
    numChrono: '',
    numAffaire: '',
    codeAnalytique: '',
    compteAuxilaire: ''
  };
};

var state = defaultValues();
var mutations = {
  SET_DEFAULT_VALUES: function SET_DEFAULT_VALUES(state) {
    Object.assign(state, defaultValues());
  },
  SET_PARAMS_PHMIMM: function SET_PARAMS_PHMIMM(state) {
    state.client = {
      visible: true,
      required: true
    };
    state.enseigne = {
      visible: true,
      required: true
    };
    state.chefProjet = {
      visible: true,
      required: true,
      label: 'Chef de projet'
    };
    state.conducteurTravaux = {
      visible: true,
      required: false
    };
    state.codeIdentVille = {
      length: 11,
      visible: true,
      required: true
    };
    state.responsableSecteur = {
      visible: true,
      required: true
    };
  },
  SET_PARAMS_PHMINV: function SET_PARAMS_PHMINV(state) {
    state.client = {
      visible: true,
      required: false
    };
    state.enseigne = {
      visible: true,
      required: false
    };
    state.chefProjet = {
      visible: true,
      required: true,
      label: 'Chargé de montage d\'opération'
    };
    state.codeIdentVille = {
      length: 16,
      visible: true,
      required: true
    };
  },
  SET_PARAMS_CLUB: function SET_PARAMS_CLUB(state) {
    state.client = {
      visible: true,
      required: true
    };
    state.enseigne = {
      visible: true,
      required: true
    };
    state.ingenieurEtude = {
      visible: true,
      required: false
    };
    state.conducteurTravaux = {
      visible: true,
      required: false
    };
    state.codeIdentVille = {
      length: 11,
      visible: true,
      required: true
    };
    state.responsableSecteur = {
      visible: true,
      required: true
    };
  },
  SET_PARAMS_VHAB: function SET_PARAMS_VHAB(state) {
    state.client = {
      visible: true,
      required: true
    };
    state.conducteurTravaux = {
      visible: true,
      required: false
    };
  },
  // ===========================
  SET_COMPTES_PHMIMM: function SET_COMPTES_PHMIMM(state, param) {
    var numChrono = param.formData.num_chrono ? param.formData.num_chrono : '';
    var secteurGeoCode = param.selection.secteurGeo ? String(param.selection.secteurGeo.code).padStart(2, '0') : ' '.repeat(2);
    var secteurActCode = param.selection.secteurAct ? String(param.selection.secteurAct.code).substring(0, 1) : ' '.repeat(1);
    var enseigneCode = param.selection.enseigne ? param.selection.enseigne.code : ' '.repeat(3);
    var codeIdentVille = param.formData.code_analytique_ident_ville ? param.formData.code_analytique_ident_ville : ' '.repeat(state.codeIdentVille.length);
    var indice = param.formData.indice || '';
    state.codeAnalytique = (secteurActCode + '-' + secteurGeoCode + '-' + enseigneCode + codeIdentVille + indice).toUpperCase();
    state.compteAuxilaire = 'C' + (enseigneCode + codeIdentVille + indice).toUpperCase();
    state.numAffaire = (numChrono + state.codeAnalytique).toUpperCase();
    state.numChrono = numChrono;
  },
  SET_COMPTES_PHMINV: function SET_COMPTES_PHMINV(state, param) {
    var secteurActCode = param.selection.secteurAct ? String(param.selection.secteurAct.code).substring(0, 2) : ' '.repeat(2);
    var codeIdentVille = param.formData.code_analytique_ident_ville ? param.formData.code_analytique_ident_ville : ' '.repeat(state.codeIdentVille.length);
    var indice = param.formData.indice || '';
    state.codeAnalytique = 'C' + (secteurActCode + '-' + codeIdentVille + indice).toUpperCase();
    state.compteAuxilaire = '';
    state.numAffaire = state.codeAnalytique.toUpperCase();
  },
  SET_COMPTES_CLUB: function SET_COMPTES_CLUB(state, param) {
    var secteurGeoCode = param.selection.secteurGeo ? String(param.selection.secteurGeo.code).padStart(2, '0') : ' '.repeat(2);
    var secteurActCode = param.selection.secteurAct ? String(param.selection.secteurAct.code).substring(0, 1) : ' '.repeat(1);
    var enseigneCode = param.selection.enseigne ? param.selection.enseigne.code : ' '.repeat(3);
    var codeIdentVille = param.formData.code_analytique_ident_ville ? param.formData.code_analytique_ident_ville : ' '.repeat(state.codeIdentVille.length);
    var indice = param.formData.indice || '';
    state.codeAnalytique = (secteurGeoCode + '-' + secteurActCode + '-' + enseigneCode + codeIdentVille + indice).toUpperCase();
    state.compteAuxilaire = 'C' + (enseigneCode + codeIdentVille + indice).toUpperCase();
    state.numAffaire = state.codeAnalytique.toUpperCase();
  },
  SET_COMPTES_VHAB: function SET_COMPTES_VHAB(state, param) {
    var numChrono = param.formData.num_chrono ? String(param.formData.num_chrono).padStart(3, '0') : '___';
    var secteurGeoCode = param.selection.secteurGeo ? String(param.selection.secteurGeo.code).padStart(2, '0') : ' '.repeat(2);
    var secteurActCode = param.selection.secteurAct ? String(param.selection.secteurAct.code).substring(0, 1) : ' '.repeat(1);
    var nomClient = param.selection.client && param.selection.client.libelle ? param.selection.client.libelle.substring(0, 11).trim() : '';
    var codeClient = param.selection.client && param.selection.client.code ? param.selection.client.code.substring(0, 11).trim() : '';
    var indice = param.formData.indice || '';
    state.codeAnalytique = (secteurActCode + '-' + secteurGeoCode + '-' + nomClient + numChrono + indice).toUpperCase();
    state.compteAuxilaire = 'C' + nomClient; // state.numAffaire = state.codeAnalytique.toUpperCase()

    state.numAffaire = (secteurActCode + secteurGeoCode + ' ' + codeClient).toUpperCase();
    state.numChrono = numChrono;
  }
};
var actions = {
  initSocieteParams: function initSocieteParams(_ref, user) {
    var commit = _ref.commit;
    commit('SET_DEFAULT_VALUES');

    switch (user.societe_shortname) {
      case 'PHMIMM':
        commit('SET_PARAMS_PHMIMM');
        break;

      case 'PHMINV':
        commit('SET_PARAMS_PHMINV');
        break;

      case 'CLUB':
        commit('SET_PARAMS_CLUB');
        break;

      case 'VHAB':
        commit('SET_PARAMS_VHAB');
        break;
    }
  },
  updComptes: function updComptes(_ref2, param) {
    var commit = _ref2.commit;

    switch (param.societeShortname) {
      case 'PHMIMM':
        commit('SET_COMPTES_PHMIMM', param);
        break;

      case 'PHMINV':
        commit('SET_COMPTES_PHMINV', param);
        break;

      case 'CLUB':
        commit('SET_COMPTES_CLUB', param);
        break;

      case 'VHAB':
        commit('SET_COMPTES_VHAB', param);
        break;
    }
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;