var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sync-scroll-bars" },
    [
      _c(
        "el-table",
        {
          key: _vm.refresh,
          ref: "dataTable",
          staticClass: "avenants",
          attrs: {
            size: "mini",
            data: _vm.lotsDetail,
            "data-sync-scrollbar": "main"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "ordre",
              label: "#",
              fixed: "left",
              width: "40",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { attrs: { title: "id lot: " + scope.row.id } },
                      [_vm._v(_vm._s(scope.$index + 1))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "libelle",
              label: "Lots",
              fixed: "left",
              width: "250"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.libelle))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "prestataire",
              label: "Prestataire",
              fixed: "left",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.prestataire))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Montants des marchés initiaux\\n(1)",
              width: "130",
              align: "right",
              "render-header": _vm.renderHeader
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.calculs[scope.$index]
                              .mtDernierePropositionPresta
                          )
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "Travaux supplémentaires",
                align: "center",
                width: "120"
              }
            },
            [
              _vm._l(_vm.affaire.nbColAvenant, function(col) {
                return _c(
                  "el-table-column",
                  {
                    key: "Avenant_" + col,
                    attrs: { width: "120", align: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      _vm.calculs[scope.$index].mtAvenant[col]
                                        ? _vm.calculs[scope.$index].mtAvenant[
                                            col
                                          ].mt_avenant
                                        : ""
                                    )
                                  ) +
                                  "\n        "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _vm._v(
                        "\n          Avenant n°" +
                          _vm._s(col) +
                          " " +
                          _vm._s(_vm._f("toDate")(_vm.dateColAvenant(col))) +
                          "\n        "
                      )
                    ])
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Total", width: "120", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("toCurrency")(
                                _vm.calculs[scope.$index]
                                  .mtTotalLotDetailLigneAvenant
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { label: "Avenants au marché principal", align: "center" }
            },
            [
              _vm._l(_vm.affaire.nbColAvenantMarchePrincipal, function(col) {
                return _c(
                  "el-table-column",
                  {
                    key: "AvenantMarchePrincipal_" + col,
                    attrs: { width: "120", align: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      _vm.calculs[scope.$index]
                                        .mtAvenantMarchePrincipal[col]
                                        ? _vm.calculs[scope.$index]
                                            .mtAvenantMarchePrincipal[col]
                                            .mt_avenant
                                        : ""
                                    )
                                  ) +
                                  "\n        "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _vm._v(
                        "\n          Avenant n°" +
                          _vm._s(col) +
                          " " +
                          _vm._s(
                            _vm._f("toDate")(
                              _vm.dateColAvenantMarchePrincipal(col)
                            )
                          ) +
                          "\n        "
                      )
                    ])
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Total", width: "120", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("toCurrency")(
                                _vm.calculs[scope.$index]
                                  .mtTotalLotDetailLigneAvenantMarchePrincipal
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Total Avenants\\n(2)",
              width: "120",
              align: "right",
              "render-header": _vm.renderHeader
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.calculs[scope.$index]
                              .mtTotalLotDetailLigneAvenant +
                              _vm.calculs[scope.$index]
                                .mtTotalLotDetailLigneAvenantMarchePrincipal
                          )
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "TOTAL\\n(1) + (2)",
              width: "130",
              align: "right",
              "render-header": _vm.renderHeader
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return (
                      _vm.calculs[scope.$index].mtTotalLotDetailLigneAvenant +
                      _vm.calculs[scope.$index]
                        .mtTotalLotDetailLigneAvenantMarchePrincipal +
                      _vm.calculs[scope.$index].mtDernierePropositionPresta
                    ).toString() !== "null"
                      ? [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("toCurrency")(
                                  Number(
                                    _vm.calculs[scope.$index]
                                      .mtTotalLotDetailLigneAvenant
                                  ) +
                                    Number(
                                      _vm.calculs[scope.$index]
                                        .mtTotalLotDetailLigneAvenantMarchePrincipal
                                    ) +
                                    Number(
                                      _vm.calculs[scope.$index]
                                        .mtDernierePropositionPresta
                                    )
                                )
                              ) +
                              "\n      "
                          )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: "TotalLot_" + _vm.refresh,
          staticClass: "avenants",
          attrs: {
            data: _vm.totaux,
            size: "mini",
            "row-class-name": "total",
            border: "",
            "data-sync-scrollbar": "footer"
          }
        },
        [
          _c("el-table-column", { attrs: { width: "40", fixed: "left" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { width: "250", fixed: "left" } }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { width: "150", fixed: "left", align: "right" } },
            [_vm._v("S/TOTAL")]
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Montants des marchés initiaux",
              width: "130",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.totaux[scope.$index]
                            .mtTotalLotDernierePropositionPresta
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _vm._l(_vm.affaire.nbColAvenant, function(col) {
            return _c("el-table-column", {
              key: "TotalAvenant_" + col,
              attrs: { width: "120", align: "right" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.mtTotalLotColonneAvenant(scope.$index, col)
                            )
                          )
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Total", width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.mtTotalLotLigneAvenant(scope.$index)
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _vm._l(_vm.affaire.nbColAvenantMarchePrincipal, function(col) {
            return _c("el-table-column", {
              key: "TotalAvenantMarchePrincipal_" + col,
              attrs: { width: "120", align: "right" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.mtTotalLotColonneAvenantMarchePrincipal(
                                scope.$index,
                                col
                              )
                            )
                          )
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Total", width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.mtTotalLotLigneAvenantMarchePrincipal(
                            scope.$index
                          )
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Total Avenants", width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.mtTotalLotLigneAvenant(scope.$index) +
                              _vm.mtTotalLotLigneAvenantMarchePrincipal(
                                scope.$index
                              )
                          )
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Total", width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.mtTotalLotLigneAvenant(scope.$index) +
                              _vm.mtTotalLotLigneAvenantMarchePrincipal(
                                scope.$index
                              ) +
                              _vm.totaux[scope.$index]
                                .mtTotalLotDernierePropositionPresta
                          )
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }