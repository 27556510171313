var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { "margin-bottom": "20px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _vm._v("N° de la facture :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.facture.numero))])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [_vm._v("Libellé :")]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 10 } }, [
                    _vm._v(_vm._s(_vm.facture.libelle))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _vm._v("Date de la facture :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 10 } }, [
                    _vm._v(_vm._s(_vm._f("toDate")(_vm.facture.date_facture)))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _vm._v("Date d'échéance :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 10 } }, [
                    _vm._v(_vm._s(_vm._f("toDate")(_vm.facture.date_echeance)))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("strong", [_vm._v("Facture")])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _vm._v("Montant à percevoir TTC :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7, align: "right" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(_vm.facture.mt_a_percevoir_ttc)
                      )
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _vm._v("Cumulé payé TTC :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7, align: "right" } }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.montantCumuleRegle)))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _vm._v("Cumulé imputé TTC :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 7, align: "right" } }, [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(-_vm.facture.montant_impute))
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _vm._v("Reste à recevoir TTC :")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      class: { "trop-percu": _vm.montantRestantDu < 0 },
                      attrs: { span: 7, align: "right" }
                    },
                    [_vm._v(_vm._s(_vm._f("toCurrency")(_vm.montantRestantDu)))]
                  ),
                  _vm._v(" "),
                  _vm.montantRestantDu < 0
                    ? _c("span", [_vm._v(" (trop-perçu)")])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.montantReglement
                ? [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 10 } }, [
                          _vm._v("Règlement en cours TTC :")
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 7, align: "right" } }, [
                          _vm._v(
                            _vm._s(_vm._f("toCurrency")(_vm.montantReglement))
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 10 } }, [
                          _vm._v("Solde en cours TTC :")
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 7, align: "right" } }, [
                          _vm._v(_vm._s(_vm._f("toCurrency")(_vm.solde)))
                        ])
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.montantTropPercu
                ? [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 10 } }, [
                          _vm._v("trop-perçu TTC :")
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 7, align: "right" } }, [
                          _vm._v(
                            _vm._s(_vm._f("toCurrency")(_vm.montantTropPercu))
                          )
                        ])
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }