"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var apiSociete = _interopRequireWildcard(require("@/api/societe"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user', 'envNode'])),
  mounted: function mounted() {
    var _this = this;

    apiSociete.getById(this.user.id_societe).then(function (result) {
      _this.$store.state.settings.sidebarLogo = result[0].logo;
      _this.$store.state.settings.siteTitle = result[0].name;
    });
  }
};
exports.default = _default;