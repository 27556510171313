"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.number.constructor");

var apiEnseigne = _interopRequireWildcard(require("@/api/enseigne"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Enseigne',
  // props: ['idEnseigne', 'idClient', 'enseigne'],
  props: {
    idEnseigne: {
      type: Number,
      required: false,
      default: null
    },
    idClient: {
      type: Number,
      required: false,
      default: null
    },
    enseigne: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      enseignes: [],
      id_enseigne: this.idEnseigne
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    if (this.idClient === null) {
      this.enseignes = [];
      return;
    }

    apiEnseigne.getAllActifByIdClient(this.idClient).then(function (results) {
      _this.enseignes = results;

      var enseigne = _this.enseignes.find(function (x) {
        return x.id === _this.idEnseigne;
      });

      if (enseigne) {
        _this.$emit('update:enseigne', enseigne);
      }
    }).catch(function (error) {
      _this.$message(error);
    });
  },
  computed: {
    listEnseignes: function listEnseignes() {
      var _this2 = this;

      if (this.idClient > 0) {
        return this.enseignes.filter(function (x) {
          return x.id_client === _this2.idClient;
        });
      } else {
        return this.enseignes;
      }
    }
  },
  methods: {
    onClear: function onClear() {
      this.$emit('update:idEnseigne', null);
      this.$emit('update:enseigne', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:idEnseigne', index);
        this.$emit('update:enseigne', this.enseignes.find(function (x) {
          return x.id === index;
        }));
      }
    }
  },
  watch: {
    idClient: function idClient() {
      this.$emit('update:idEnseigne', null);
      this.$emit('update:enseigne', null);
    }
  }
};
exports.default = _default;