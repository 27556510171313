"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'CommandeSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    idCommandeSelected: {
      type: Number
    },
    commande: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      commandes: [],
      idCommande: this.idCommandeSelected
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('facturation', ['getCommandeListForCurrentAffaire'])),
  // 9. Évènements
  // --
  beforeMount: function beforeMount() {
    var _this = this;

    this.commandes = this.getCommandeListForCurrentAffaire;
    var commande = this.commandes.find(function (x) {
      return x.id === _this.idCommandeSelected;
    });

    if (commande) {
      this.$emit('update:commande', commande);
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    onClear: function onClear() {
      this.$emit('update:id-commande-selected', null);
      this.$emit('update:commande', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:id-commande-selected', index);
        this.$emit('update:commande', this.commandes.find(function (x) {
          return x.id === index;
        }));
      }
    }
  } // 11. Rendu
  // --

};
exports.default = _default;