"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _eventBus = require("@/helpers/event-bus.js");

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'LotDetailObjDeb',
  props: ['idLot'],
  data: function data() {
    return {
      lotsDetail: [],
      calculs: [],
      totaux: [],
      modeEdit: false,
      rowsForCanceling: [],
      rowsForSaving: [],
      // pour mémoriser uniquement les lignes modifiées et éviter de tout sauvegarder
      refresh: 0
    };
  },
  created: function created() {
    var _this = this;

    this.$watch('$root.modeEdit', function (newValue) {
      if (newValue === false) {
        _this.modeEdit = false;
      }
    });
  },
  mounted: function mounted() {
    var _this2 = this;

    _eventBus.EventBus.$on('EDIT_LOT', function (idLot) {
      if (_this2.idLot === idLot) {
        _this2.modeEdit = true;
        _this2.rowsForCanceling = JSON.parse(JSON.stringify(_this2.lotsDetail));
        _this2.rowsForSaving = [];

        _eventBus.EventBus.$emit('MODE_EDIT', true);
      }
    });

    _eventBus.EventBus.$on('REFRESH', function () {
      var typeLigne = [CONST.LOT_DETAIL.TYPE_LIGNE.INITIALE];
      _this2.lotsDetail = _this2.$store.getters.affaire.lotsDetail.filter(function (x) {
        return x.id_lot === _this2.idLot && typeLigne.includes(x.type_lot_detail);
      });
      _this2.calculs = _this2.$store.getters.affaire.calculs.lotsDetail.filter(function (x) {
        return x.id_lot === _this2.idLot && typeLigne.includes(x.type_lot_detail);
      });
      _this2.totaux = _this2.$store.getters.affaire.calculs.lots.filter(function (x) {
        return x.id === _this2.idLot;
      });
    });

    this.refresh++;
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('EDIT_LOT');

    _eventBus.EventBus.$off('REFRESH');
  },
  methods: {
    handleInput: function handleInput(scope, action) {
      // ne pas faire planter la requete en base si on ne saisi rien car coef_objectif_debourse est un numerique et n'accepte pas un string vide
      scope.row.coef_objectif_debourse = scope.row.coef_objectif_debourse === '' ? null : scope.row.coef_objectif_debourse; // mémorisation de la ligne modifiée pour ne sauvegarder + tard que celles concernées
      // et pas toutes les lignes de lotsDetail (on optimise le nombre de requete sql à faire)

      if (this.rowsForSaving.indexOf(scope.$index) === -1) {
        this.rowsForSaving.push(scope.$index);
      }

      this.$store.dispatch('affaire/setLotDetail', scope.row).then(function () {
        if (action === 'refresh_calcul') {
          _eventBus.EventBus.$emit('REFRESH_CALCULS');
        }
      });
    },
    handleCancel: function handleCancel() {
      var _this3 = this;

      this.modeEdit = false;
      var rows = this.rowsForCanceling;
      rows.forEach(function (row, index) {
        _this3.$store.dispatch('affaire/setLotDetail', row);
      });
      this.rowsForCanceling = [];
      this.rowsForSaving = [];

      _eventBus.EventBus.$emit('REFRESH_CALCULS');

      _eventBus.EventBus.$emit('MODE_EDIT', false);
    },
    handleSave: function handleSave() {
      var _this4 = this;

      this.modeEdit = false;
      var rows = this.rowsForSaving;
      rows.forEach(function (index) {
        _this4.action('updateLotDetail', _this4.lotsDetail[index]);
      });

      _eventBus.EventBus.$emit('MODE_EDIT', false);
    },
    // -----------------------------------------------
    action: function action(_action, lotdetail) {
      var _this5 = this;

      // console.log(action, lotdetail)
      this.$store.dispatch('affaire/' + _action, lotdetail).then(function () {
        _this5.$store.dispatch('affaire/setLotDetail', lotdetail).then(function () {
          _this5.rowsForSaving = [];
          _this5.rowsForCanceling = [];

          _eventBus.EventBus.$emit('REFRESH_CALCULS');
        });
      }).catch(function (error) {
        _this5.$message(error);
      });
    }
  }
};
exports.default = _default;