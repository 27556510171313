"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'AffaireBlocInfos',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('affaire', ['getAffaire']), {
    affaire: function affaire() {
      return this.getAffaire;
    },
    status: function status() {
      var caption = '';
      var extra = '';

      switch (this.affaire.status) {
        case CONST.AFFAIRE.STATUS.DRAFT.value:
          caption = CONST.AFFAIRE.STATUS.DRAFT.title;
          break;

        case CONST.AFFAIRE.STATUS.TOVALIDATE.value:
        case CONST.AFFAIRE.STATUS.TOVALIDATE1.value:
        case CONST.AFFAIRE.STATUS.TOVALIDATE2.value:
          caption = 'En attente de validation';
          break;

        case CONST.AFFAIRE.STATUS.DENIED.value:
        case CONST.AFFAIRE.STATUS.DENIED1.value:
        case CONST.AFFAIRE.STATUS.DENIED2.value:
          caption = 'Refusé';
          break;

        case CONST.AFFAIRE.STATUS.SLEEPING.value:
          caption = CONST.AFFAIRE.STATUS.SLEEPING.title;
          break;

        case CONST.AFFAIRE.STATUS.ARCHIVED.value:
          caption = CONST.AFFAIRE.STATUS.ARCHIVED.title;
          break;

        case CONST.AFFAIRE.STATUS.CLOSED.value:
          caption = CONST.AFFAIRE.STATUS.CLOSED.title;
          break;

        default:
          caption = 'En cours';
      }

      switch (this.affaire.etape) {
        case CONST.AFFAIRE.ETAPE.VENTE_INITIALE.value:
          caption += ' | Vente initiale';
          break;

        case CONST.AFFAIRE.ETAPE.OBJECTIF_DEBOURSE.value:
          caption += ' | Objectifs déboursés';
          break;

        case CONST.AFFAIRE.ETAPE.BUDGET_AFFAIRE.value:
          caption += ' | Budget affaire';
          break;

        case CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value:
          caption += ' | En gestion ';
          extra = this.allPeriodeGestMens;
          break;
      }

      return {
        caption: caption,
        extra: extra
      };
    }
  }) // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  // 11. Rendu
  // --

};
exports.default = _default;