"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.passwordReminder = passwordReminder;
exports.passwordReset = passwordReset;
exports.logout = logout;
exports.getUserInfo = getUserInfo;
exports.getByEmail = getByEmail;
exports.getBySocieteEmail = getBySocieteEmail;
exports.getById = getById;
exports.getAllBySocieteId = getAllBySocieteId;
exports.update = update;
exports.insert = insert;
exports.deleteById = deleteById;
exports.getAll = getAll;
exports.getAllActif = getAllActif;
exports.getAllActifBySocieteId = getAllActifBySocieteId;
exports.bulkDelete = bulkDelete;

var _request = _interopRequireDefault(require("@/helpers/request"));

function login(data) {
  return (0, _request.default)({
    url: '/auth',
    method: 'post',
    data: data
  });
}

function passwordReminder(data) {
  return (0, _request.default)({
    url: "/user/send-password-reminder-mail",
    method: 'post',
    data: data
  });
}

function passwordReset(data) {
  return (0, _request.default)({
    url: '/user/reset-password',
    method: 'put',
    data: data
  });
}

function logout() {
  return (0, _request.default)({
    url: '/user/logout',
    method: 'post'
  });
}

function getUserInfo(token) {
  return (0, _request.default)({
    url: '/user/me',
    method: 'get',
    params: {
      token: token
    }
  });
}

function getByEmail(email) {
  return (0, _request.default)({
    url: '/user/email/' + email,
    method: 'get'
  });
}

function getBySocieteEmail(data) {
  return (0, _request.default)({
    url: '/user/societe/email/',
    method: 'post',
    data: data
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/user/' + id,
    method: 'get'
  });
}

function getAllBySocieteId(id) {
  return (0, _request.default)({
    url: '/user/societe/' + id,
    method: 'get'
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/user/' + id,
    method: 'put',
    data: data
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/user',
    method: 'post',
    data: data
  });
}

function deleteById(id) {
  return (0, _request.default)({
    url: '/user/' + id,
    method: 'delete'
  });
}

function getAll() {
  return (0, _request.default)({
    url: '/user',
    method: 'get'
  });
}

function getAllActif() {
  return (0, _request.default)({
    url: '/user/actif',
    method: 'get'
  });
}

function getAllActifBySocieteId(idSociete) {
  return (0, _request.default)({
    url: '/user/actif/societe/' + idSociete,
    method: 'get'
  });
}

function bulkDelete(ids) {
  return (0, _request.default)({
    url: '/user/' + ids,
    method: 'delete'
  });
}