var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { slot: "tool" }, slot: "tool" },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _vm._v("    \n      "),
            _vm.canEdit() &&
            _vm.userCanDoAction &&
            _vm.affaire.affaire.etape ===
              _vm.CONST.AFFAIRE.ETAPE.GESTION_MENSUELLE.value &&
            _vm.affaire.affaire.status !==
              _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
              ? _c(
                  "div",
                  [
                    !_vm.modeEdit
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:avenant",
                                value: "creer",
                                expression: "'creer'",
                                arg: "avenant"
                              }
                            ],
                            attrs: {
                              type: "success",
                              size: "mini",
                              icon: "el-icon-plus"
                            },
                            on: { click: _vm.handleNewAvenant }
                          },
                          [_vm._v("Nouvel avenant")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.modeEdit && _vm.entetesLength > 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:avenant",
                                value: "editer",
                                expression: "'editer'",
                                arg: "avenant"
                              }
                            ],
                            attrs: {
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-edit"
                            },
                            on: { click: _vm.handleEditAvenant }
                          },
                          [_vm._v("Modifier avenant")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.modeEdit
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "warning",
                              size: "mini",
                              icon: "el-icon-close"
                            },
                            on: { click: _vm.handleCancelAvenant }
                          },
                          [_vm._v("Annuler")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.modeEdit
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-check"
                            },
                            on: { click: _vm.handleSaveAvenant }
                          },
                          [_vm._v("Enregistrer")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.modeEdit
        ? _c(
            "el-row",
            { attrs: { id: "EnteteAvenantMarchePrincipal" } },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "background-color": "#f2f2f2",
                    padding: "10px",
                    "border-bottom": "1px solid #ddd"
                  },
                  attrs: { span: 24, align: "center" }
                },
                [
                  _vm._v(
                    "Avenant n° " +
                      _vm._s(_vm.formData.numEntete) +
                      " au marché principal"
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "background-color": "#efefef",
                    "padding-top": "10px"
                  },
                  attrs: { span: 24 }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ElForm",
                      staticClass: "ElForm",
                      attrs: {
                        model: _vm.formData,
                        rules: _vm.rules,
                        "label-width": "300px",
                        size: "mini"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Désignation des travaux",
                            prop: "libelle"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              type: "string",
                              autocomplete: "no",
                              maxlength: "100"
                            },
                            model: {
                              value: _vm.formData.libelle,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "libelle", $$v)
                              },
                              expression: "formData.libelle"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Montant présenté HT",
                            prop: "mt_presente"
                          }
                        },
                        [
                          _c("el-input", {
                            directives: [
                              { name: "numberonly", rawName: "v-numberonly" }
                            ],
                            staticClass: "currency",
                            staticStyle: { width: "100px" },
                            attrs: { size: "mini", autocomplete: "no" },
                            model: {
                              value: _vm.formData.mt_presente,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "mt_presente", $$v)
                              },
                              expression: "formData.mt_presente"
                            }
                          }),
                          _vm._v(" €\n        ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Montant accepté HT",
                            prop: "mt_accepte"
                          }
                        },
                        [
                          _c("el-input", {
                            directives: [
                              { name: "numberonly", rawName: "v-numberonly" }
                            ],
                            staticClass: "currency",
                            staticStyle: { width: "100px" },
                            attrs: { size: "mini", autocomplete: "no" },
                            model: {
                              value: _vm.formData.mt_accepte,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "mt_accepte", $$v)
                              },
                              expression: "formData.mt_accepte"
                            }
                          }),
                          _vm._v(" €\n        ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Montant déboursé S/T Fournisseurs",
                            prop: "mt_debourse"
                          }
                        },
                        [
                          _c("el-input", {
                            directives: [
                              { name: "numberonly", rawName: "v-numberonly" }
                            ],
                            staticClass: "currency",
                            staticStyle: { width: "100px" },
                            attrs: { size: "mini", autocomplete: "no" },
                            model: {
                              value: _vm.formData.mt_debourse,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "mt_debourse", $$v)
                              },
                              expression: "formData.mt_debourse"
                            }
                          }),
                          _vm._v(" €\n        ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { label: "Marge brute" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("toCurrency")(_vm.mb)) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "% Marge brute" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("toPercentage")(
                                  _vm.formData.mt_accepte !== 0
                                    ? _vm.mb / _vm.formData.mt_accepte
                                    : ""
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.modeEdit &&
      _vm.entetesLength > 0 &&
      _vm.affaire.nbColAvenantMarchePrincipal > 0
        ? _c(
            "el-row",
            {
              key: "Entete_" + _vm.refresh,
              staticStyle: { padding: "0", border: "1px solid #ddd" }
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "background-color": "#f2f2f2",
                    padding: "10px",
                    "border-bottom": "1px solid #ddd"
                  },
                  attrs: { span: 24, align: "center" }
                },
                [
                  _vm._v(
                    "Avenant n° " +
                      _vm._s(_vm.numEntete) +
                      " au marché principal"
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticStyle: { "background-color": "#f2f2f2" },
                  attrs: { span: 24 }
                },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "tbForm",
                      attrs: { model: {}, "label-width": "300px", size: "mini" }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Désignation des travaux :" } },
                        [_vm._v(_vm._s(_vm.entete.libelle))]
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { label: "Créé le :" } }, [
                        _vm._v(
                          _vm._s(_vm._f("toDate")(_vm.entete.date_creation))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Montant présenté HT :" } },
                        [
                          _vm._v(
                            _vm._s(_vm._f("toCurrency")(_vm.entete.mt_presente))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Montant accepté HT :" } },
                        [
                          _vm._v(
                            _vm._s(_vm._f("toCurrency")(_vm.entete.mt_accepte))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Montant déboursé S/T Fournisseurs :"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("toCurrency")(_vm.entete.mt_debourse))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Marge brute :" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("toCurrency")(
                                _vm.entete.mt_accepte - _vm.entete.mt_debourse
                              )
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "% Marge brute :" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("toPercentage")(
                                _vm.entete.mt_accepte !== 0
                                  ? (_vm.entete.mt_accepte -
                                      _vm.entete.mt_debourse) /
                                      _vm.entete.mt_accepte
                                  : ""
                              )
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24, align: "center" } },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.numEntete,
                      "page-size": 1,
                      layout: "prev, pager, next, jumper",
                      total: _vm.entetesLength
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                      "update:currentPage": function($event) {
                        _vm.numEntete = $event
                      },
                      "update:current-page": function($event) {
                        _vm.numEntete = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "SyncScrollBars",
        [
          _vm._l(_vm.affaire.lots, function(lot, index) {
            return _c(
              "el-row",
              { key: lot.id + "_" + _vm.refresh },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("h1", { staticStyle: { "margin-top": "10px" } }, [
                          _vm._v(
                            "Lot " +
                              _vm._s(index + 1) +
                              " " +
                              _vm._s(lot.libelle)
                          )
                        ]),
                        _vm._v(" "),
                        _c("LotDetail", {
                          key: "LotDetail_" + _vm.refresh,
                          attrs: { idLot: lot.id }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-row",
            { key: "e2_" + _vm.refresh, staticStyle: { margin: "20px 0" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [_c("AffaireTotaux", { key: "Totaux_" + _vm.refresh })],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }