"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin-permission',
  name: 'AdminPermission',
  order: 7,
  meta: {
    title: 'Gestion des droits d\'accès',
    icon: 'permission'
  },
  redirect: '/admin/permission',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '/admin/permission',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/permission'));
      });
    },
    name: 'ListPermission',
    meta: {
      title: 'Droits d\'accès',
      activeMenu: '/admin/permission'
    }
  }, {
    path: '/admin/permission/new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/permission/new'));
      });
    },
    name: 'NewPermission',
    meta: {
      title: 'Ajouter un droit d\'accès',
      activeMenu: '/admin/permission'
    }
  }, {
    path: '/admin/permission/edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/permission/edit'));
      });
    },
    name: 'EditPermission',
    meta: {
      title: 'Modifier un droit d\'accès',
      activeMenu: '/admin/permission'
    }
  }]
}];
var _default = routes;
exports.default = _default;