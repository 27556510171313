"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.newGestMens = newGestMens;
exports.getLastGestMens = getLastGestMens;

var _request = _interopRequireDefault(require("@/helpers/request"));

function newGestMens(idSociete, periodeRef) {
  return (0, _request.default)({
    url: '/gestmens/new/' + idSociete + '/' + periodeRef,
    method: 'get'
  });
}

function getLastGestMens(idSociete) {
  return (0, _request.default)({
    url: '/gestmens/last/' + idSociete,
    method: 'get'
  });
}