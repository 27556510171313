"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSyntheseList = getSyntheseList;
exports.getCreances = getCreances;
exports.getAll = getAll;
exports.checkUnicity = checkUnicity;
exports.getAllByAffaireId = getAllByAffaireId;
exports.getFacturationSoldeeStatusByAffaireId = getFacturationSoldeeStatusByAffaireId;
exports.getAllByCommandeId = getAllByCommandeId;
exports.getById = getById;
exports.update = update;
exports.insert = insert;
exports.deleteById = deleteById;
exports.bulkDelete = bulkDelete;
exports.upload = upload;
exports.deleteFactureFichier = deleteFactureFichier;
exports.getFactureFichier = getFactureFichier;

var _request = _interopRequireDefault(require("@/helpers/request"));

var _factures = require("@/helpers/factures");

function getSyntheseList() {
  return (0, _request.default)({
    url: '/creance-vente-synthese',
    method: 'get'
  });
}

function getCreances() {
  return (0, _request.default)({
    url: '/creance',
    method: 'get'
  });
}

function getAll() {
  return (0, _request.default)({
    url: '/facture',
    method: 'get'
  });
}

function checkUnicity(data) {
  return (0, _request.default)({
    url: '/facture/verif-unicite',
    method: 'post',
    data: data
  });
}

function getAllByAffaireId(id) {
  return (0, _request.default)({
    url: '/facture/affaire/' + id,
    method: 'get'
  });
}

function getFacturationSoldeeStatusByAffaireId(id) {
  return (0, _request.default)({
    url: '/facture/affaire/statut-solde/' + id,
    method: 'get'
  });
}

function getAllByCommandeId(id) {
  return (0, _request.default)({
    url: '/facture/commande/' + id,
    method: 'get'
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/facture/' + id,
    method: 'get'
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/facture/' + id,
    method: 'put',
    data: data
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/facture',
    method: 'post',
    data: data
  });
}

function deleteById(id) {
  return (0, _request.default)({
    url: '/facture/' + id,
    method: 'delete'
  });
}

function bulkDelete(ids) {
  return (0, _request.default)({
    url: '/facture/' + ids,
    method: 'delete'
  });
}

function upload(id, data) {
  return (0, _request.default)({
    url: '/facture/' + id + '/upload',
    method: 'patch',
    data: data,
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
    maxBodyLength: 5 * 1000 * 1000,
    // 5Mb
    // `onUploadProgress` allows handling of progress events for uploads
    // browser only
    onUploadProgress: function onUploadProgress(progressEvent) {// Do whatever you want with the native progress event
      // console.log('onUploadProgress: progressEvent', progressEvent)
      // console.log('getFactureFichier --> onUploadProgress: progressEvent', `${progressEvent.loaded} / ${progressEvent.total}`)
    }
  });
}

function deleteFactureFichier(id) {
  return (0, _request.default)({
    url: '/facture/' + id + '/deleteFichier',
    method: 'patch'
  });
}

function getFactureFichier(facture) {
  return (0, _request.default)({
    url: (0, _factures.getFactureFichierUri)(facture, false),
    method: 'get',
    responseType: 'blob',
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30 * 1000,
    // 30s
    // `maxContentLength` defines the max size of the http response content in bytes allowed
    maxContentLength: 5 * 1000 * 1000,
    // 5Mb
    // `onDownloadProgress` allows handling of progress events for downloads
    // browser only
    onDownloadProgress: function onDownloadProgress(progressEvent) {// Do whatever you want with the native progress event
      // console.log('onDownloadProgress: progressEvent', progressEvent)
      // console.log('getFactureFichier --> onDownloadProgress: progressEvent', `${progressEvent.loaded} / ${progressEvent.total}`)
    }
  });
}