"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin-client',
  name: 'AdminClient',
  order: 2,
  meta: {
    title: 'Gestion des clients',
    icon: 'client'
  },
  redirect: '/admin/client',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '/admin/client',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/client'));
      });
    },
    name: 'ListClient',
    meta: {
      title: 'Clients',
      activeMenu: '/admin/client'
    }
  }, {
    path: '/admin/client/new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/client/new'));
      });
    },
    name: 'NewClient',
    meta: {
      title: 'Ajouter un client',
      activeMenu: '/admin/client'
    }
  }, {
    path: '/admin/client/edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/client/edit'));
      });
    },
    name: 'EditClient',
    meta: {
      title: 'Modifier un client',
      activeMenu: '/admin/client'
    }
  }]
}];
var _default = routes;
exports.default = _default;