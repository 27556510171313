"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AffaireTotaux',
  data: function data() {
    return {
      refresh: 0,
      calculs: [],
      lots: [],
      modeEdit: false
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.calculs = _this.$store.getters.affaire.calculs;
      _this.lots = _this.$store.getters.affaire.lots;
      _this.refresh++;
    });

    _eventBus.EventBus.$on('MODE_EDIT', function (onoff) {
      _this.modeEdit = onoff; // bascule le mode d'edition
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  },
  computed: {
    affaire: function affaire() {
      return this.$store.getters.affaire;
    },
    nbCol: function nbCol() {
      return this.affaire.nbColBudgetComp;
    }
  },
  methods: {
    dateCol: function dateCol(numCol) {
      if (this.affaire.calculs.affaire) {
        return this.affaire.calculs.affaire.dateColonneBudgetComp[numCol];
      }
    }
  }
};
exports.default = _default;