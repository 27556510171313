"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.array.sort");

var _slicedToArray2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.object.entries");

require("core-js/modules/es6.number.constructor");

var apiUser = _interopRequireWildcard(require("@/api/user"));

var _utils = require("@/helpers/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CascaderUtilisateur',
  props: ['idUserSelected', 'idSociete', 'user', 'clearable'],
  data: function data() {
    return {
      users: [],
      listUsers: [],
      idUser: this.idUserSelected,
      show: false
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    apiUser.getAllActifBySocieteId(Number(this.idSociete)).then(function (results) {
      if (results) {
        _this.users = results;
        var list = (0, _utils.groupByKey)(results, 'role_name');

        for (var _i = 0, _Object$entries = Object.entries(list); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = (0, _slicedToArray2.default)(_Object$entries[_i], 2),
              key = _Object$entries$_i[0],
              val = _Object$entries$_i[1];

          var children = [];

          for (var idx in val) {
            var c = {
              value: val[idx].id,
              label: val[idx].lastname + ' ' + val[idx].firstname
            };
            children.push(c);
          }

          var option = {
            value: key,
            label: key,
            children: children
          };

          _this.listUsers.push(option);
        }

        _this.listUsers = _this.listUsers.sort((0, _utils.dynamicSort)('label'));
      }
    }).then(function () {
      _this.show = true;
    }).catch(function (error) {
      _this.$message(error);
    });
  },
  methods: {
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:idUserSelected', index);
        this.$emit('update:user', this.users.find(function (x) {
          return x.id === index;
        }));
      } else {
        this.$emit('update:idUserSelected', null);
        this.$emit('update:user', null);
      }
    },
    handleFilter: function handleFilter(node, keyword) {
      return node.text.toLowerCase().includes(keyword.toLowerCase());
    }
  }
};
exports.default = _default;