"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var apiEnseigne = _interopRequireWildcard(require("@/api/enseigne"));

//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ListEnseigneMultiple',
  props: ['data', 'idsClients', 'idSociete'],
  data: function data() {
    return {
      enseignes: [],
      values: this.data
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    this.enseignes = [];
    var index = this.idsClients.indexOf(0);
    if (index !== -1) this.idsClients.splice(index, 1);

    if ((!this.idsClients || this.idsClients.length === 0) && this.idSociete > 0) {
      apiEnseigne.getAllBySocieteId(this.idSociete).then(function (results) {
        _this.enseignes = results;
      }).catch(function (error) {
        _this.$message(error);
      });
    } else {
      apiEnseigne.getAll().then(function (results) {
        _this.enseignes = results.filter(function (e) {
          return _this.idsClients.includes(e.id_client);
        });
      }).catch(function (error) {
        _this.$message(error);
      });
    }
  },
  methods: {
    onClear: function onClear() {
      this.values = []; // nb: onChange est appellé quand @clear est déclenché
    },
    onChange: function onChange(selection) {
      if (selection.includes(0)) {
        this.values = this.enseignes.map(function (x) {
          return x.id;
        });
      } else if (this.selection !== undefined) {
        this.values.push(this.selection);
      }

      this.$emit('update:data', this.values);
    }
  }
};
exports.default = _default;