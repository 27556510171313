"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin-type-vente',
  name: 'AdminTypeVente',
  order: 6,
  meta: {
    title: 'Gestion des types de vente',
    icon: 'type-vente'
  },
  redirect: '/admin/type-vente',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '/admin/type-vente',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/type_vente/TypeVenteList'));
      });
    },
    name: 'ListTypeVente',
    meta: {
      title: 'Type de vente',
      activeMenu: '/admin/type-vente'
    }
  }, {
    path: '/admin/type-vente/new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/type_vente/TypeVenteNew'));
      });
    },
    name: 'NewTypeVente',
    meta: {
      title: 'Ajouter un type de vente',
      activeMenu: '/admin/type-vente'
    }
  }, {
    path: '/admin/type-vente/edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/type_vente/TypeVenteEdit'));
      });
    },
    name: 'EditTypeVente',
    meta: {
      title: 'Modifier un type de vente',
      activeMenu: '/admin/type-vente'
    }
  }]
}];
var _default = routes;
exports.default = _default;