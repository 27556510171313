"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var apiEnseigne = _interopRequireWildcard(require("@/api/enseigne"));

var _form = _interopRequireDefault(require("./components/form"));

//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      data: {},
      title: 'Modifier une enseigne',
      action: 'edit'
    };
  },
  components: {
    Form: _form.default
  },
  created: function created() {
    var _this = this;

    this.$loader(true);
    apiEnseigne.getById(this.$route.params.id).then(function (results) {
      _this.data = results[0];

      _this.$loader(false);
    }).catch(function (err) {
      _this.$message(err);

      _this.$loader(false);
    });
  }
};
exports.default = _default;