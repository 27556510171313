"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.array.find");

var apiSociete = _interopRequireWildcard(require("@/api/societe"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Societe',
  props: ['idSocieteSelected', 'societe', 'placeholder'],
  data: function data() {
    return {
      societes: [],
      idSociete: this.idSocieteSelected,
      show: false
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;

    apiSociete.getAllActif().then(function (results) {
      _this.societes = results;

      var societe = _this.societes.find(function (x) {
        return Number(x.id) === Number(_this.idSocieteSelected);
      });

      if (societe) {
        _this.$emit('update:societe', societe);
      }
    }).then(function () {
      _this.show = true;
    }).catch(function (error) {
      _this.$message(error);
    });
  },
  methods: {
    onClear: function onClear() {
      this.$emit('update:id-societe-selected', null);
      this.$emit('update:societe', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:id-societe-selected', index);
        this.$emit('update:societe', this.societes.find(function (x) {
          return x.id === index;
        }));
      }
    }
  }
};
exports.default = _default;