exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"LogoBg": "#2b2f3a",
	"LogoText": "#fff",
	"NavbarBg": "#545c64",
	"NavbarMenuText": "#fff",
	"SidebarWidth": "210px",
	"SidebarMenuBg": "#1a1a1a",
	"SidebarMenuHover": "#dcfa00",
	"SidebarMenuText": "#fff",
	"SidebarMenuTextHover": "#1a1a1a",
	"SidebarMenuActiveText": "#dcfa00",
	"SidebarSubMenuBg": "#3a3a3a",
	"SidebarSubMenuHover": "#dcfa00",
	"SidebarSubMenuText": "#3a3a3a",
	"SidebarSubMenuTextHover": "#3a3a3a",
	"SidebarSubMenuActiveText": "#dcfa00"
};