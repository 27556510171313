"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var apiAffaireCalculs = _interopRequireWildcard(require("@/api/affairecalculs"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      data: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      var idAffaire = _this.$store.getters.affaire.affaire.id;
      var anneemois = _this.$store.getters.affaire.YYYYMM;
      apiAffaireCalculs.getByAffaireIdAnneeMois(idAffaire, anneemois).then(function (results) {
        _this.data = results[0];
      });
    });
  }
};
exports.default = _default;