var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "60%",
        height: "60%",
        "lock-scroll": false,
        "close-on-click-modal": false,
        title:
          _vm.action === "new" ? "Nouveau commentaire" : "Modifier commentaire",
        visible: _vm.visible
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        opened: _vm.handleOpened
      }
    },
    [
      _c(
        "div",
        { staticClass: "el-tiptap-editor__wrapper" },
        [
          _c("el-tiptap", {
            attrs: {
              height: "300px",
              extensions: _vm.extensions,
              lang: "fr",
              spellcheck: true,
              placeholder: "Tapez votre commentaire ici"
            },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = $$v
              },
              expression: "content"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleClose } },
            [_vm._v("Annuler")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function($event) {
                  _vm.action === "new" ? _vm.handleSave() : _vm.handleUpdate()
                }
              }
            },
            [_vm._v("Enregistrer")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }