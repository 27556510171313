"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DELAI_PAIEMENT = exports.TYPES_MONTANT = exports.FACTURES = exports.REGLEMENTS = exports.LOT_DETAIL = exports.AFFAIRE = void 0;

require("core-js/modules/es6.object.freeze");

var AFFAIRE = Object.freeze({
  STATUS: {
    DRAFT: {
      value: 0,
      title: 'Brouillon'
    },
    CREATED: {
      value: 1,
      title: 'En cours'
    },
    INPROGRESS: {
      // plus utilisé
      value: 2,
      title: 'En cours de traitement'
    },
    TOVALIDATE: {
      // plus utilisé
      value: 3,
      title: 'Demande de validation'
    },
    VALIDATED: {
      // plus utilisé
      value: 4,
      title: 'Validation'
    },
    DENIED: {
      // plus utilisé
      value: 5,
      title: 'Refus'
    },
    PENDING: {
      // pas utilisé
      value: 6,
      title: 'En attente'
    },
    SLEEPING: {
      value: 7,
      title: 'En sommeil'
    },
    ARCHIVED: {
      value: 8,
      title: 'Archive'
    },
    TOVALIDATE1: {
      // pour wokrflow niveau 2 (budget affaire)
      value: 9,
      title: 'Demande de validation'
    },
    VALIDATED1: {
      // pour wokrflow niveau 2 (budget affaire)
      value: 10,
      title: 'Validation'
    },
    DENIED1: {
      // pour wokrflow niveau 2 (budget affaire)
      value: 11,
      title: 'Refus'
    },
    TOVALIDATE2: {
      // pour wokrflow niveau 2 (budget affaire)
      value: 13,
      title: 'Demande de validation'
    },
    VALIDATED2: {
      // pour wokrflow niveau 2 (budget affaire)
      value: 14,
      title: 'Validation'
    },
    DENIED2: {
      // pour wokrflow niveau 2 (budget affaire)
      value: 15,
      title: 'Refus'
    },
    VALIDSYNTHESE: {
      value: 20,
      title: 'Synthèse gestion mensuelle'
    },
    ASKSLEEPING: {
      value: 30,
      title: 'Demande de mise en sommeil'
    },
    ASKARCHIVING: {
      value: 31,
      title: 'Demande d\'archivage'
    },
    ASKWAKEUP: {
      value: 32,
      title: 'Demande de réveil'
    },
    CLOSED: {
      value: 40,
      title: 'Clôturé'
    }
  },
  ETAPE: {
    AFFAIRE: {
      value: 0,
      title: 'Affaire création'
    },
    VENTE_INITIALE: {
      value: 1,
      title: 'Vente initiale'
    },
    OBJECTIF_DEBOURSE: {
      value: 2,
      title: 'Objectifs déboursés'
    },
    BUDGET_AFFAIRE: {
      value: 3,
      title: 'Budget d\'affaire'
    },
    GESTION_MENSUELLE: {
      value: 4,
      title: 'Gestion mensuelle'
    }
  }
});
exports.AFFAIRE = AFFAIRE;
var LOT_DETAIL = Object.freeze({
  TYPE_LIGNE: {
    INITIALE: 0,
    BUDGET_COMP: 1,
    TRANSFERT: 2,
    PROVISION: 3,
    AVENANT: 4,
    AVENANT_MARCHE_PRINCIPAL: 5,
    SUPPLEMENTAIRE: 6,
    PROVISIONRISQUE: 7
  }
});
exports.LOT_DETAIL = LOT_DETAIL;
var REGLEMENTS = Object.freeze({
  MODE: {
    VIREMENT: {
      value: 1,
      title: 'Virement',
      default: true
    },
    CHEQUE: {
      value: 2,
      title: 'Chèque'
    },
    TRAITE: {
      value: 3,
      title: 'Traite'
    },
    IMPUTATION: {
      value: 4,
      title: 'Imputation'
    }
  },
  TYPE: {
    PAIEMENT: {
      value: 1,
      title: 'Paiement'
    },
    TROP_PERCU: {
      value: 3,
      title: 'Remboursement de trop-perçu'
    }
  }
});
exports.REGLEMENTS = REGLEMENTS;
var FACTURES = Object.freeze({
  /* ---------------------------------------
   *  Taille limite d'upload en Mo
   * ---------------------------------------
   *  A faire correspondre avec la propriété `MAX_UPLOAD_SIZE`
   *  de la constantes `FACTURES` côté API
   *  (cf. [API]/src/helpers/constantes.js
   *
   */
  // TODO : Prévoir un appel API au démarrage de l'extranet pour récupérer ce paramètre
  MAX_UPLOAD_SIZE: 1024 * 1000 * 3 // 3Mo

});
exports.FACTURES = FACTURES;
var TYPES_MONTANT = Object.freeze({
  HT: {
    value: 1,
    title: 'HT'
  },
  TTC: {
    value: 2,
    title: 'TTC'
  }
});
exports.TYPES_MONTANT = TYPES_MONTANT;
var DELAI_PAIEMENT = Object.freeze({
  D_COMPTANT: {
    value: 'COMPTANT',
    title: 'comptant'
  },
  D_30J_FIN_MOIS: {
    value: '30J_FIN_MOIS',
    title: '30 jours fin de mois'
  },
  D_15J_DATE_FACTURE: {
    value: '15J_DATE_FACTURE',
    title: '15 jours date de facture'
  },
  D_30J_DATE_FACTURE: {
    value: '30J_DATE_FACTURE',
    title: '30 jours date de facture'
  },
  D_45J_FIN_MOIS: {
    value: '45J_FIN_MOIS',
    title: '45 jours fin de mois'
  },
  D_45J_DATE_FACTURE: {
    value: '45J_DATE_FACTURE',
    title: '45 jours date de facture'
  },
  D_60J_DATE_FACTURE: {
    value: '60J_DATE_FACTURE',
    title: '60 jours date de facture'
  }
});
exports.DELAI_PAIEMENT = DELAI_PAIEMENT;