"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("/var/www/easy_dev/extranet/node_modules/core-js/modules/es6.array.iterator.js");

require("/var/www/easy_dev/extranet/node_modules/core-js/modules/es6.promise.js");

require("/var/www/easy_dev/extranet/node_modules/core-js/modules/es6.object.assign.js");

require("/var/www/easy_dev/extranet/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/reset.css");

require("element-ui/lib/theme-chalk/index.css");

var _fr = _interopRequireDefault(require("element-ui/lib/locale/lang/fr"));

var _vueDataTables = _interopRequireDefault(require("vue-data-tables"));

var _vueDynamicFormComponent = _interopRequireDefault(require("vue-dynamic-form-component"));

var _vuePrintNb = _interopRequireDefault(require("vue-print-nb"));

var _vueImageUploadResize = _interopRequireDefault(require("vue-image-upload-resize"));

var _elementUiElTableDraggable = _interopRequireDefault(require("element-ui-el-table-draggable"));

var _elTableFixedHeader = _interopRequireDefault(require("el-table-fixed-header"));

var _syncScrollBars = _interopRequireDefault(require("@/layout/components/SyncScrollBars/sync-scroll-bars"));

var _Zoomer = _interopRequireDefault(require("@/layout/components/Zoomer/Zoomer.vue"));

var _Printer = _interopRequireDefault(require("@/layout/components/Printer/Printer.vue"));

var _vueJsonCsv = _interopRequireDefault(require("vue-json-csv"));

require("@/styles/index.scss");

require("@/styles/marraud.scss");

var _i18n = _interopRequireDefault(require("./i18n"));

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("@/icons");

require("@/permission");

require("@/helpers/directives");

require("@/helpers/filters");

// A modern alternative to CSS resets
// lang i18n
// import DataTables and DataTablesServer together
// global css
// global css
// icon
// permission control
// all directives
// all filters
// set ElementUI lang to FR
_vue.default.use(_elementUi.default, {
  locale: _fr.default
});

_vue.default.use(_vueDataTables.default);

_vue.default.use(_elTableFixedHeader.default);

_vue.default.use(_vueDynamicFormComponent.default);

_vue.default.use(_vueImageUploadResize.default);

_vue.default.use(_vuePrintNb.default);

_vue.default.component('ElTableDraggable', _elementUiElTableDraggable.default);

_vue.default.component('SyncScrollBars', _syncScrollBars.default);

_vue.default.component('Zoomer', _Zoomer.default);

_vue.default.component('Printer', _Printer.default);

_vue.default.component('DownloadCsv', _vueJsonCsv.default);

_vue.default.config.disableNoTranslationWarning = true;
_vue.default.config.productionTip = false;
_vue.default.prototype.$apiEndpoint = process.env.VUE_APP_BASE_API;
_vue.default.prototype.$remotePublicUrl = process.env.VUE_APP_REMOTE_PUBLIC;
_vue.default.prototype.$remoteFacturePublicUrl = process.env.VUE_APP_REMOTE_PUBLIC + '/uploads/factures/';

_vue.default.prototype.$loader = function (value) {
  var _this = this;

  return new Promise(function (resolve) {
    var options = {
      fullscreen: true,
      lock: true,
      background: 'transparent',
      // 'rgba(0, 0, 0, 0.8)',
      text: 'Chargement en cours...',
      target: 'section#appMain'
    };

    if (value) {
      _elementUi.default.Loading.service(options);
    } else {
      _this.$loading().close();
    }

    resolve();
  });
}; // eslint-disable-next-line


new _vue.default({
  data: function data() {
    return {
      modeEdit: false // global reactive var

    };
  },
  el: '#app',
  i18n: _i18n.default,
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});