"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _eventBus = require("@/helpers/event-bus.js");

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'LotDetailAvenants',
  props: ['idLot'],
  data: function data() {
    return {
      refresh: 0,
      lotsDetail: [],
      calculs: [],
      totaux: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['affaire'])),
  created: function created() {
    var _this = this;

    this.$watch('$root.modeEdit', function (newValue) {
      if (newValue === false) {
        _this.modeEdit = false;
      }
    });
  },
  mounted: function mounted() {
    var _this2 = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      var typeLigne = [CONST.LOT_DETAIL.TYPE_LIGNE.INITIALE, CONST.LOT_DETAIL.TYPE_LIGNE.AVENANT, CONST.LOT_DETAIL.TYPE_LIGNE.AVENANT_MARCHE_PRINCIPAL, CONST.LOT_DETAIL.TYPE_LIGNE.SUPPLEMENTAIRE];
      _this2.lotsDetail = _this2.$store.getters.affaire.lotsDetail.filter(function (x) {
        return x.id_lot === _this2.idLot && typeLigne.includes(x.type_lot_detail);
      });
      _this2.calculs = _this2.$store.getters.affaire.calculs.lotsDetail.filter(function (x) {
        return x.id_lot === _this2.idLot && typeLigne.includes(x.type_lot_detail);
      });
      _this2.totaux = _this2.$store.getters.affaire.calculs.lots.filter(function (x) {
        return x.id === _this2.idLot;
      });
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  },
  methods: {
    dateColAvenant: function dateColAvenant(numCol) {
      if (this.affaire.calculs.affaire) {
        return this.affaire.calculs.affaire.dateColonneAvenant[numCol];
      }
    },
    mtTotalLotColonneAvenant: function mtTotalLotColonneAvenant(index, column) {
      return this.totaux[index] && this.totaux[index].mtTotalLotColonneAvenant[column] ? this.totaux[index].mtTotalLotColonneAvenant[column] : '';
    },
    mtTotalLotLigneAvenant: function mtTotalLotLigneAvenant(index) {
      return this.totaux[index] ? this.totaux[index].mtTotalLotLigneAvenant : '';
    },
    // -- Marche principal --
    dateColAvenantMarchePrincipal: function dateColAvenantMarchePrincipal(numCol) {
      if (this.affaire.calculs.affaire) {
        return this.affaire.calculs.affaire.dateColonneAvenantMarchePrincipal[numCol];
      }
    },
    mtTotalLotColonneAvenantMarchePrincipal: function mtTotalLotColonneAvenantMarchePrincipal(index, column) {
      return this.totaux[index] && this.totaux[index].mtTotalLotColonneAvenantMarchePrincipal[column] ? this.totaux[index].mtTotalLotColonneAvenantMarchePrincipal[column] : '';
    },
    mtTotalLotLigneAvenantMarchePrincipal: function mtTotalLotLigneAvenantMarchePrincipal(index) {
      return this.totaux[index] ? this.totaux[index].mtTotalLotLigneAvenantMarchePrincipal : '';
    },
    renderHeader: function renderHeader(h, _ref) {
      var column = _ref.column,
          $index = _ref.$index;
      var label = column.label.split('\\n');
      return h('div', {}, [h('span', label[0]), h('br'), h('span', label[1])]);
    }
  }
};
exports.default = _default;