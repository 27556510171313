"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.insert = insert;
exports.getAllByAffaireId = getAllByAffaireId;

var _request = _interopRequireDefault(require("@/helpers/request"));

function insert(data) {
  return (0, _request.default)({
    url: '/transfert',
    method: 'post',
    data: data
  });
}

function getAllByAffaireId(id) {
  return (0, _request.default)({
    url: '/transfert/affaire/' + id,
    method: 'get'
  });
}