var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "css-zoomer" },
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: "Zoom -", placement: "bottom" }
        },
        [
          _c("el-button", {
            attrs: { icon: "el-icon-zoom-out", circle: "", mini: "" },
            on: { click: _vm.zoomout }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: "Zoom +", placement: "bottom" }
        },
        [
          _c("el-button", {
            attrs: { icon: "el-icon-zoom-in", circle: "", mini: "" },
            on: { click: _vm.zoomin }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: "Zoom 1:1", placement: "bottom" }
        },
        [
          _c(
            "el-button",
            { attrs: { circle: "", mini: "" }, on: { click: _vm.zoom1 } },
            [_c("span", { staticStyle: { color: "#888" } }, [_vm._v("1:1")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }