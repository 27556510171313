"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllByAffaireId = getAllByAffaireId;
exports.update = update;
exports.insert = insert;
exports.deleteById = deleteById;
exports.deleteAllByAffaireId = deleteAllByAffaireId;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAllByAffaireId(id) {
  return (0, _request.default)({
    url: '/lot/affaire/' + id,
    method: 'get'
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/lot/' + id,
    method: 'put',
    data: data
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/lot',
    method: 'post',
    data: data
  });
}

function deleteById(id) {
  return (0, _request.default)({
    url: '/lot/' + id,
    method: 'delete'
  });
}

function deleteAllByAffaireId(ids) {
  return (0, _request.default)({
    url: '/lot/affaire/' + ids,
    method: 'delete'
  });
}