"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var validate = _interopRequireWildcard(require("@/helpers/validate"));

var _listSociete = _interopRequireDefault(require("@/components/listSociete"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PasswordReminder',
  components: {
    listSociete: _listSociete.default
  },
  data: function data() {
    var validateSociete = function validateSociete(rule, value, callback) {
      if (value > 0) {
        callback();
      } else {
        callback(new Error('Société obligatoire'));
      }
    };

    var validateEmail = function validateEmail(rule, value, callback) {
      if (value === '') {
        callback(new Error('Adresse email obligatoire'));
      } else if (!validate.validEmail(value)) {
        callback(new Error('Adresse email non valide'));
      } else {
        callback();
      }
    };

    return {
      selection: {},
      reminderForm: {
        id_societe: '',
        email: ''
      },
      reminderRules: {
        id_societe: [{
          required: true,
          validator: validateSociete
        }],
        email: [{
          required: true,
          validator: validateEmail
        }]
      },
      loading: false
    };
  },
  beforeMount: function beforeMount() {
    this.reminderForm.id_societe = Number(_jsCookie.default.get('loginSociete')) || '';
  },
  methods: {
    resetForm: function resetForm() {
      this.$refs.reminderForm.resetFields();
    },
    handlePasswordReminder: function handlePasswordReminder() {
      var _this = this;

      this.$refs.reminderForm.validate(function (valid) {
        if (valid) {
          _this.$loader(true);

          _this.loading = true;

          _this.$store.dispatch('user/passwordReminder', _this.reminderForm).then(function (results) {
            _this.resetForm();

            _this.$message(_this.$i18n.t(results.message));

            _this.loading = false;

            _this.$loader(false);
          }).catch(function () {
            _this.loading = false;

            _this.$loader(false);
          });
        } else {
          console.log('error submit!!');
        }

        return false;
      });
    }
  }
};
exports.default = _default;