var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        { on: { command: _vm.onMenuCommand } },
        [
          _c(
            "el-badge",
            {
              class: _vm.notifications.unreadCounter > 0 ? "item" : "item hide",
              attrs: { value: _vm.notifications.unreadCounter }
            },
            [
              _c("el-button", {
                attrs: { type: "text", size: "medium", icon: "el-icon-bell" },
                on: {
                  click: function($event) {
                    _vm.showPanel = true
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", [
                _c("span", { staticClass: "itemleft" }, [_vm._v("Non lu")]),
                _vm._v(" "),
                _c("span", { staticClass: "itemright" }, [
                  _vm._v(_vm._s(_vm.notifications.unreadCounter))
                ])
              ]),
              _vm._v(" "),
              _c("el-dropdown-item", [
                _c("span", { staticClass: "itemleft" }, [_vm._v("Lu")]),
                _vm._v(" "),
                _c("span", { staticClass: "itemright" }, [
                  _vm._v(_vm._s(_vm.notifications.readCounter))
                ])
              ]),
              _vm._v(" "),
              _c("el-dropdown-item", { attrs: { command: "markAllAsRead" } }, [
                _c("span", { staticClass: "itemleft" }, [
                  _vm._v("Marquer tout comme lu")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "itemright" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-item",
                { attrs: { command: "seeAllNotifications" } },
                [
                  _c("span", { staticClass: "itemleft" }, [
                    _vm._v("Voir toutes les notifications")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "itemright" })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            title: "Notifications",
            visible: _vm.showPanel,
            direction: "rtl",
            "before-close": _vm.onClosePanel,
            modal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.showPanel = $event
            }
          }
        },
        _vm._l(_vm.unreadNotification(), function(row) {
          return _c(
            "div",
            { key: row.id },
            [
              row.url_link === null || row.url_link === ""
                ? [
                    _c(
                      "el-alert",
                      {
                        attrs: {
                          title: _vm._f("toDateTime")(row.date_creation),
                          type: row.type,
                          "show-icon": ""
                        },
                        on: {
                          close: function($event) {
                            return _vm.onCloseAlert(row)
                          }
                        }
                      },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(row.message) }
                        })
                      ]
                    )
                  ]
                : [
                    _c(
                      "el-alert",
                      {
                        attrs: {
                          title: _vm._f("toDateTime")(row.date_creation),
                          type: row.type,
                          "show-icon": ""
                        },
                        on: {
                          close: function($event) {
                            return _vm.onCloseAlert(row)
                          }
                        }
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: row.type },
                            on: {
                              click: function($event) {
                                _vm.$refs["drawer"].closeDrawer()
                                _vm.$router.replace(
                                  row.url_link.toString().replace("/#", "")
                                )
                              }
                            }
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  "Affaire n° : " +
                                    row.num_affaire +
                                    "<br>" +
                                    row.message
                                )
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }