"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var _createForOfIteratorHelper2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper"));

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var apiSecteurGeo = _interopRequireWildcard(require("@/api/secteurgeo"));

var apiUser = _interopRequireWildcard(require("@/api/user"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      total: 0,
      secteurs: [],
      users: [],
      multipleSelection: [],
      refresh: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user', 'workflow']), {
    idSociete: function idSociete() {
      return this.user.id_societe;
    }
  }),
  beforeMount: function beforeMount() {
    var _this = this;

    this.users = [];
    apiUser.getAllBySocieteId(this.idSociete).then(function (results) {
      _this.users = results;
    });
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this2 = this;

      this.$loader(true);
      this.secteurs = [];
      apiSecteurGeo.getAllBySocieteId(this.idSociete).then(function (results) {
        var _iterator = (0, _createForOfIteratorHelper2.default)(results),
            _step;

        try {
          var _loop = function _loop() {
            var row = _step.value;
            row.actif = row.actif === 1 ? '✓' : '✗';

            var resp = _this2.users.find(function (x) {
              return x.id === row.id_responsable_secteur;
            });

            row.responsable = resp ? resp.firstname + ' ' + resp.lastname : '';

            _this2.secteurs.push(row);
          };

          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            _loop();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        _this2.secteurs.total = results.length;

        _this2.$loader(false);

        _this2.refresh++;
      }).catch(function (error) {
        _this2.$message(error);

        _this2.$loader(false);
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelete: function handleDelete(index, row) {
      var _this3 = this;

      this.$confirm('Voulez-vous supprimer le secteur ' + row.libelle + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this3.$loader(true);

        apiSecteurGeo.deleteById(row.id).then(function (results) {
          _this3.secteurs.splice(_this3.secteurs.indexOf(row), 1);

          _this3.$message('Suppression effectuée avec succès');

          _this3.$loader(false);
        }).catch(function (err) {
          _this3.$message(err);

          _this3.$loader(false);
        });
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this4 = this;

      var msg = this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée';
      this.$confirm('Voulez-vous supprimer ' + msg + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this4.$loader(true);

        var selection = _this4.multipleSelection.map(function (u) {
          return u.id;
        }).join(',');

        apiSecteurGeo.bulkDelete(selection).then(function (results) {
          _this4.$message('Suppression effectuée avec succès');

          _this4.loadData();

          _this4.$loader(false);
        }).catch(function (error) {
          _this4.$message(error);

          _this4.$loader(false);
        });
      });
    }
  }
};
exports.default = _default;