"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus");

var _FactureModule = _interopRequireDefault(require("@/views/app/factures/FactureModule"));

var _AffaireBlocInfo = _interopRequireDefault(require("@/components/AffaireBlocInfo"));

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'FactureByAffaire',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    AffaireBlocInfo: _AffaireBlocInfo.default,
    FactureModule: _FactureModule.default
  },
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    // propriété envoyé depuis le Router Guard (cf. src/router/routes/vente-creance/index.js)
    affaire: {
      type: Object,
      required: true
    },
    nextTickEventName: {
      type: String,
      required: false,
      default: ''
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  // 9. Évènements
  // --
  mounted: function mounted() {
    this.$loader(false); // Attente du prochain rendu du DOM pour déclencher un nouvel événement

    if (this.nextTickEventName !== '') {
      this.$nextTick(function () {
        // console.log('emit ', this.nextTickEventName)
        _eventBus.EventBus.$emit(this.nextTickEventName);
      });
    }
  } // 10. Propriétés non réactives
  // --
  // 11. Rendu
  // --

};
exports.default = _default;