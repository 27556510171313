"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

var _LotDetail = _interopRequireDefault(require("./components/LotDetail"));

var _LotDetailTotaux = _interopRequireDefault(require("./components/LotDetailTotaux"));

var _AffaireTotaux = _interopRequireDefault(require("./components/AffaireTotaux"));

var _Synthese = _interopRequireDefault(require("./components/Synthese"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SyntheseMensuelle',
  components: {
    LotDetail: _LotDetail.default,
    LotDetailTotaux: _LotDetailTotaux.default,
    AffaireTotaux: _AffaireTotaux.default,
    Synthese: _Synthese.default
  },
  data: function data() {
    return {
      refresh: 0,
      CONST: CONST
    };
  },
  mounted: function mounted() {
    _eventBus.EventBus.$emit('REFRESH_CALCULS');
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user']), {
    affaire: function affaire() {
      return this.$store.getters.affaire;
    }
  }),
  methods: {
    periodeGestion: function periodeGestion() {
      _moment.default.locale('fr');

      var periode = (0, _moment.default)(this.$store.getters.affaire.YYYYMMmoins1, 'YYYYMM').format('MMMM YYYY');
      var isValidDate = (0, _moment.default)(this.$store.getters.affaire.YYYYMMmoins1, 'YYYYMM', true).isValid();
      return this.$store.getters.affaire.currentGestMensYYYYMM && isValidDate ? 'Gestion de ' + periode : '';
    }
  }
};
exports.default = _default;