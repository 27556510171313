"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus.js");

var _moment = _interopRequireDefault(require("moment"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Synthse',
  data: function data() {
    return {
      CONST: CONST,
      refresh: 0,
      totaux: [],
      data: [],
      dataMmoins1: [],
      avenantMarchePrincipal: [],
      dataSave: [],
      modeEdit: false
    };
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.data = _this.$store.getters.affaire.calculs.affaire;
      _this.dataMmoins1 = _this.$store.getters.affaire.calculsMmoins1;
      _this.totaux = _this.$store.getters.affaire.calculs.affaire;
      _this.avenantMarchePrincipal = _this.$store.getters.affaire.calculs.avenantsMarchePrincipalEntetes;
      _this.gestmensAffaire = _this.$store.getters.affaire.gestmensAffaire.filter(function (x) {
        return x.anneemois === _this.data.YYYYMM;
      })[0] || [];
    });

    this.refresh++;
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  },
  computed: {
    currentYYYYMM: function currentYYYYMM() {
      return (0, _moment.default)().format('YYYYMM');
    },
    mtMoisFacturationClient: function mtMoisFacturationClient() {
      return this.data.mtFacturationClient || 0;
    },
    mtMoisFactureMerite: function mtMoisFactureMerite() {
      var pv = this.dataMmoins1.mtPmPrixDeVente || 0;
      return this.data.mtPmPrixDeVente - pv;
    }
  },
  methods: {
    handleEdit: function handleEdit() {
      this.modeEdit = true;
      this.$root.modeEdit = true; // on sauvegarde le contneu des champs avant modification pour les rappeler en cas de cancel

      this.dataSave = {
        mtFactureProrata: this.data.mtFactureProrata,
        mtFactureDiverse: this.data.mtFactureDiverse,
        mtPrevFactureProrata: this.data.mtPrevFactureProrata,
        mtPrevFactureDiverse: this.data.mtPrevFactureDiverse,
        mtPrevRevisionActu: this.data.mtPrevRevisionActu
      };
    },
    handleInput: function handleInput(action) {
      var data = {
        id: this.gestmensAffaire.id || null,
        id_affaire: this.$store.getters.affaire.affaire.id,
        anneemois: this.data.YYYYMM,
        mt_facture_prorata: this.data.mtFactureProrata,
        mt_facture_diverse: this.data.mtFactureDiverse,
        mt_prev_facture_prorata: this.data.mtPrevFactureProrata,
        mt_prev_facture_diverse: this.data.mtPrevFactureDiverse,
        mt_prev_revision_actu: this.data.mtPrevRevisionActu
      };
      this.$store.dispatch('affaire/setGestMensAffaire', data).then(function () {
        _eventBus.EventBus.$emit('REFRESH_CALCULS');
      });
    },
    handleCancel: function handleCancel() {
      this.modeEdit = false;
      this.$root.modeEdit = false;
      var data = {
        id: this.gestmensAffaire.id || null,
        id_affaire: this.$store.getters.affaire.affaire.id,
        anneemois: this.data.YYYYMM,
        mt_facture_prorata: this.dataSave.mtFactureProrata,
        mt_facture_diverse: this.dataSave.mtFactureDiverse,
        mt_prev_facture_prorata: this.dataSave.mtPrevFactureProrata,
        mt_prev_facture_diverse: this.dataSave.mtPrevFactureDiverse,
        mt_prev_revision_actu: this.dataSave.mtPrevRevisionActu
      };
      this.$store.dispatch('affaire/setGestMensAffaire', data).then(function () {
        _eventBus.EventBus.$emit('REFRESH_CALCULS');
      });
    },
    handleSave: function handleSave() {
      this.modeEdit = false;
      this.$root.modeEdit = false;
      var data = {
        id: this.gestmensAffaire.id || null,
        id_affaire: this.$store.getters.affaire.affaire.id,
        anneemois: this.data.YYYYMM,
        mt_facture_prorata: this.data.mtFactureProrata,
        mt_facture_diverse: this.data.mtFactureDiverse,
        mt_prev_facture_prorata: this.data.mtPrevFactureProrata,
        mt_prev_facture_diverse: this.data.mtPrevFactureDiverse,
        mt_prev_revision_actu: this.data.mtPrevRevisionActu
      };

      if (data && data.id) {
        this.$store.dispatch('affaire/updateGestMensAffaire', data).then(function () {
          _eventBus.EventBus.$emit('REFRESH_CALCULS');
        });
      } else {
        this.$store.dispatch('affaire/insertGestMensAffaire', data).then(function () {
          _eventBus.EventBus.$emit('REFRESH_CALCULS');
        });
      }
    },
    cellClassName: function cellClassName() {
      return '';
    }
  }
};
exports.default = _default;