"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAffaireHistoByIdAnneeMois = getAffaireHistoByIdAnneeMois;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAffaireHistoByIdAnneeMois(idAffaire, anneemois) {
  // console.log('getAffaireHistoByIdAnneeMois', idAffaire, anneemois)
  return (0, _request.default)({
    url: "/affaire_histo/".concat(idAffaire, "/").concat(anneemois),
    method: 'get'
  });
}