"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var validate = _interopRequireWildcard(require("@/helpers/validate"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PasswordReset',
  data: function data() {
    var _this = this;

    var validatePassword = function validatePassword(rule, value, callback) {
      _this.$refs.passwordResetForm.clearValidate('passwordRepeat');

      if (value.length === 0) {
        callback(new Error('Mot de passe obligatoire'));
      } else if (value !== '' && !validate.isStrongPassword(value)) {
        callback(new Error('Le mot de passe ne respecte pas les règles de sécurité'));
      } else if (value !== _this.passwordResetForm.passwordRepeat) {
        callback(new Error('Les 2 mots de passe ne correspondent pas'));
      } else {
        callback();
      }
    };

    var validatePasswordRepeat = function validatePasswordRepeat(rule, value, callback) {
      _this.$refs.passwordResetForm.clearValidate('password');

      if (value.length === 0) {
        callback(new Error('Mot de passe répété obligatoire'));
      } else if (value !== _this.passwordResetForm.password) {
        callback(new Error('Les 2 mots de passe ne correspondent pas'));
      } else {
        callback();
      }
    };

    return {
      passwordResetForm: {
        password: '',
        passwordRepeat: ''
      },
      passwordResetRules: {
        password: [{
          required: true,
          validator: validatePassword
        }],
        passwordRepeat: [{
          required: true,
          validator: validatePasswordRepeat
        }]
      },
      loading: false,
      passwordType: 'password'
    };
  },
  methods: {
    showPwd: function showPwd() {
      var _this2 = this;

      this.passwordType = this.passwordType === 'password' ? '' : 'password';
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    handlePasswordReset: function handlePasswordReset() {
      var _this3 = this;

      this.$refs.passwordResetForm.validate(function (valid) {
        if (valid) {
          _this3.$loader(true);

          _this3.loading = true;
          var data = {
            password: _this3.passwordResetForm.password,
            token: _this3.$route.params.pwdResetToken
          };

          _this3.$store.dispatch('user/passwordReset', data).then(function (results) {
            _this3.$refs.passwordResetForm.resetFields();

            _this3.$message(_this3.$i18n.t(results.message));

            _this3.loading = false;

            _this3.$loader(false);

            _this3.$router.push({
              name: '/'
            });
          }).catch(function () {
            _this3.loading = false;

            _this3.$loader(false);
          });
        } else {
          console.log('error submit!!');
        }

        return false;
      });
    }
  }
};
exports.default = _default;