"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.object.entries");

var _constantes = require("@/helpers/constantes");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'DelaiPaiementSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    delaiPaiementSelected: {
      type: String,
      validator: function validator(value) {
        for (var _i = 0, _Object$entries = Object.entries(_constantes.DELAI_PAIEMENT); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = (0, _slicedToArray2.default)(_Object$entries[_i], 2),
              row = _Object$entries$_i[1];

          if (value === row.value) {
            return true;
          }
        }

        return false;
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      delaiPaiementList: _constantes.DELAI_PAIEMENT,
      delaiPaiement: this.delaiPaiementSelected
    };
  },
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: {
    onClear: function onClear() {
      this.$emit('update:delai-paiement-selected', null);
    },
    onChange: function onChange(index) {
      if (index !== '') {
        this.$emit('update:delai-paiement-selected', index);
      }
    }
  }
};
exports.default = _default;