"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin-secteuract',
  name: 'AdminSecteurAct',
  order: 1,
  meta: {
    title: 'Gestion des secteurs d\'activité',
    icon: 'location'
  },
  redirect: '/admin/secteuract',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '/admin/secteuract',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/secteuract'));
      });
    },
    name: 'ListSecteurAct',
    meta: {
      title: 'Secteurs Activités',
      activeMenu: '/admin/secteuract'
    }
  }, {
    path: '/admin/secteuract/new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/secteuract/new'));
      });
    },
    name: 'NewSecteurAct',
    meta: {
      title: 'Ajouter un secteur d\'activité',
      activeMenu: '/admin/secteuract'
    }
  }, {
    path: '/admin/secteuract/edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/secteuract/edit'));
      });
    },
    name: 'EditSecteurAct',
    meta: {
      title: 'Modifier un secteur d\'activité',
      activeMenu: '/admin/secteuract'
    }
  }]
}];
var _default = routes;
exports.default = _default;