var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { "margin-bottom": "20px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("N° de la commande :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _c("strong", [_vm._v(_vm._s(_vm.commande.numero))])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("Emetteur :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm._v(_vm._s(_vm.commande.emetteur))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("Date de la commande :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm._v(_vm._s(_vm._f("toDate")(_vm.commande.date_commande)))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("Libellé de la commande :")
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                    _vm._v(_vm._s(_vm.commande.libelle))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("Montant HT :")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: Number(_vm.spanCol3), align: "right" } },
                    [_vm._v(_vm._s(_vm._f("toCurrency")(_vm.commande.montant)))]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("Cumulé facturé HT :")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: Number(_vm.spanCol3), align: "right" } },
                    [
                      _vm._v(
                        _vm._s(_vm._f("toCurrency")(_vm.montantCumuleFacture))
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("Reste à facturer HT :")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: Number(_vm.spanCol3), align: "right" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(_vm.montantRestantAFacturer)
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                    _vm._v("%age avancement :")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: Number(_vm.spanCol3), align: "right" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("toPercentage")(_vm.pourcentageAvancement)
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.montantFacture
                ? [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: Number(_vm.spanCol1) } },
                          [_vm._v("Facturation en cours HT :")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            attrs: {
                              span: Number(_vm.spanCol3),
                              align: "right"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("toCurrency")(_vm.montantFacture))
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: Number(_vm.spanCol1) } },
                          [_vm._v("Solde à facturer en cours HT :")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            attrs: {
                              span: Number(_vm.spanCol3),
                              align: "right"
                            }
                          },
                          [_vm._v(_vm._s(_vm._f("toCurrency")(_vm.solde)))]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }