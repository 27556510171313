"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _CommandeModule = _interopRequireDefault(require("@/views/app/commandes/CommandeModule"));

var _AffaireBlocInfo = _interopRequireDefault(require("@/components/AffaireBlocInfo"));

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'CommandeByAffaire',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    AffaireBlocInfo: _AffaireBlocInfo.default,
    CommandeModule: _CommandeModule.default
  },
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    // propriété envoyé depuis le Router Guard (cf. src/router/routes/vente-creance/index.js)
    affaire: {
      type: Object,
      required: true
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  // 9. Évènements
  // --
  mounted: function mounted() {
    this.$loader(false);
  } // 10. Propriétés non réactives
  // --
  // 11. Rendu
  // --

};
exports.default = _default;