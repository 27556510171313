var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { slot: "tool" }, slot: "tool" },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _vm._v("    \n      "),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.EventBus.$emit(
                          "TAB_MENU",
                          "avenants_travaux_suppl"
                        )
                      }
                    }
                  },
                  [_vm._v("Avenants travaux supplémentaires")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.EventBus.$emit(
                          "TAB_MENU",
                          "avenants_marche_princ"
                        )
                      }
                    }
                  },
                  [_vm._v("Avenants au marché principal")]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "SyncScrollBars",
        [
          _vm._l(_vm.affaire.lots, function(lot, index) {
            return _c(
              "el-row",
              { key: lot.id },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("h1", { staticStyle: { "margin-top": "10px" } }, [
                          _vm._v(
                            "Lot " +
                              _vm._s(index + 1) +
                              " " +
                              _vm._s(lot.libelle)
                          )
                        ]),
                        _vm._v(" "),
                        _c("LotDetail", {
                          key: "LotDetail_" + _vm.refresh,
                          attrs: { idLot: lot.id }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-row",
            { key: "e2_" + _vm.refresh },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [_c("AffaireTotaux", { key: "Totaux_" + _vm.refresh })],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }