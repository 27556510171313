var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Total ∑ affaires " + _vm._s(_vm.year))]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-bottom": "15px" },
          attrs: { data: _vm.dspData, size: "mini" }
        },
        [
          _c("el-table-column", { attrs: { width: "300" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.designationDI))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtBudgetInitial",
              label: "Budget initial",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(scope.row.mtBudgetInitial))
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtProvisions",
              label: "Aléas",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("toCurrency")(scope.row.mtProvisions)))
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtBudgetMoisM",
              label: "Budget au mois M",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm._f("toCurrency")(scope.row.mtBudgetMoisM))
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "pcAvancement",
              label: "% Avancement",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("toPercentage")(
                            scope.row.mtBudgetMoisM === 0
                              ? 0
                              : scope.row.mtBudgetDebloqueMoisM /
                                  scope.row.mtBudgetMoisM
                          )
                        ) +
                        "\n        "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtBudgetDebloqueMoisM",
              label: "Budget débloqué à M",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(scope.row.mtBudgetDebloqueMoisM)
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtDepensesComptabilisees",
              label: "Dépenses comptabilisées",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(scope.row.mtDepensesComptabilisees)
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtEngages",
              label: "Engagés",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("toCurrency")(scope.row.mtEngages)))
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtSoldeBudget",
              label: "Solde budget",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm._f("toCurrency")(scope.row.mtSoldeBudget))
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }