"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var apiSecteurGeo = _interopRequireWildcard(require("@/api/secteurgeo"));

var _listCascaderUtilisateur = _interopRequireDefault(require("@/components/listCascaderUtilisateur"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    formData: Object,
    title: String,
    action: String
  },
  components: {
    listCascaderUtilisateur: _listCascaderUtilisateur.default
  },
  data: function data() {
    var _this = this;

    var validRequired = function validRequired(rule, value, callback) {
      value = value || '';
      var required = rule.key && _this.workflow[rule.key] && _this.workflow[rule.key].required ? _this.workflow[rule.key].required : false;

      if (value === '' && required) {
        callback(new Error('Champ obligatoire'));
      }

      callback();
    };

    return {
      selection: [],
      rules: {
        libelle: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        code: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        id_responsable_secteur: [{
          key: 'responsableSecteur',
          validator: validRequired
        }]
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['workflow'])),
  methods: {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this2 = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this2.action) {
            case 'new':
              _this2.actionNew();

              break;

            case 'edit':
              _this2.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this3 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiSecteurGeo.insert(data).then(function (results) {
        _this3.$message('Création effectuée avec succès');

        _this3.resetForm();

        _this3.$loader(false);
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    actionEdit: function actionEdit() {
      var _this4 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiSecteurGeo.update(this.formData.id, data).then(function (results) {
        _this4.$message('Mise à jour effectuée avec succès');

        _this4.$loader(false);
      }).catch(function (error) {
        _this4.$message(error);

        _this4.$loader(false);
      });
    },
    getFormData: function getFormData() {
      var data = {
        id_societe: this.$store.getters.user.id_societe,
        libelle: this.formData.libelle,
        code: this.formData.code,
        id_responsable_secteur: this.formData.id_responsable_secteur,
        actif: this.formData.actif
      };
      return data;
    }
  }
};
exports.default = _default;