"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterProtectedRoutes = filterProtectedRoutes;
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.function.name");

var _router = require("@/router");

var apiPermission = _interopRequireWildcard(require("@/api/permission"));

function hasPermission(route) {
  // console.log(route.name, state.acl)
  if (route.name) {
    var acl = state.acl ? state.acl.includes(route.name) : false;
    var rootacl = state.rootacl ? state.rootacl.includes(route.name) : false;
    return acl || rootacl;
  } else {
    return false;
  }
}

function filterProtectedRoutes(routes) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);

    if (hasPermission(tmp)) {
      if (tmp.children) {
        tmp.children = filterProtectedRoutes(tmp.children);
      }

      res.push(tmp);
    }
  });
  return res;
}

var state = {
  routes: [],
  // addRoutes: [],
  permission: [],
  rules: [],
  acl: [],
  rootacl: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    // state.addRoutes = routes
    state.routes = routes;
  },
  SET_PERMISSION: function SET_PERMISSION(state, permission) {
    state.permission = permission;
    state.acl = state.permission.acl;
    state.rootacl = state.permission.rootacl;
    state.rules = state.permission.rules;
  },
  SET_ACL: function SET_ACL(state, _ref) {
    var acl = _ref.acl,
        rootacl = _ref.rootacl;
    state.acl = acl;
    state.rootacl = rootacl;
    state.permission.acl = acl;
    state.permission.rootacl = rootacl;
  },
  SET_RULES: function SET_RULES(state, rules) {
    state.rules = rules;
    state.permission.rules = rules;
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref2, user) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      var accessedRoutes = filterProtectedRoutes(_router.Routes);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  getPermission: function getPermission(_ref3, user) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      apiPermission.getBySocieteRole(user.id_societe, user.key_role).then(function (response) {
        var permissions = response[0];
        commit('SET_PERMISSION', permissions);
        commit('SET_ACL', {
          acl: permissions.acl,
          rootacl: permissions.rootacl
        });
        commit('SET_RULES', permissions.rules); // console.log(permissions)

        resolve(permissions);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;