"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var validate = _interopRequireWildcard(require("@/helpers/validate"));

var _vuex = require("vuex");

var _listSociete = _interopRequireDefault(require("@/components/listSociete"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  components: {
    listSociete: _listSociete.default
  },
  data: function data() {
    var validateSociete = function validateSociete(rule, value, callback) {
      if (value > 0) {
        callback();
      } else {
        callback(new Error('Société obligatoire'));
      }
    };

    var validateEmail = function validateEmail(rule, value, callback) {
      if (value === '') {
        callback(new Error('Adresse email obligatoire'));
      } else if (!validate.validEmail(value)) {
        callback(new Error('Adresse email non valide'));
      } else {
        callback();
      }
    };

    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length === 0) {
        callback(new Error('Mot de passe obligatoire'));
      } else {
        callback();
      }
    };

    return {
      selection: {},
      loginForm: {
        email: '',
        password: '',
        id_societe: ''
      },
      loginRules: {
        id_societe: [{
          required: true,
          validator: validateSociete
        }],
        email: [{
          required: true,
          validator: validateEmail
        }],
        password: [{
          required: true,
          validator: validatePassword
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['gitInfo'])),
  beforeMount: function beforeMount() {
    this.loginForm.id_societe = Number(_jsCookie.default.get('loginSociete')) || '';
    this.$store.dispatch('app/getGitInfo');
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    showPwd: function showPwd() {
      var _this = this;

      this.passwordType = this.passwordType === 'password' ? '' : 'password';
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.$loader(true);

          _this2.loading = true;

          _this2.$store.dispatch('user/login', _this2.loginForm).then(function () {
            _jsCookie.default.set('loginSociete', _this2.loginForm.id_societe);

            _this2.loading = false;

            _this2.$router.push({
              path: _this2.redirect || '/'
            });

            _this2.$loader(false);
          }).catch(function () {
            _this2.loading = false;

            _this2.$loader(false);
          });
        } else {
          console.log('error submit!!');
        }

        return false;
      });
    }
  }
};
exports.default = _default;