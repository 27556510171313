"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("element-ui/lib/theme-chalk/index.css");

require("element-tiptap/lib/index.css");

var _elementTiptap = require("element-tiptap");

var _vuex = require("vuex");

var apiCommentaire = _interopRequireWildcard(require("@/api/commentaire"));

var _moment = _interopRequireDefault(require("moment"));

var apiHistorique = _interopRequireWildcard(require("@/api/historique"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import ElementUI's styles
// import this package's styles
var _default = {
  name: 'dlgEditor',
  props: ['idAffaire', 'commentaire', 'action', 'visible'],
  components: {
    'el-tiptap': _elementTiptap.ElementTiptap
  },
  data: function data() {
    return {
      extensions: [new _elementTiptap.Doc(), new _elementTiptap.Text(), new _elementTiptap.Paragraph(), // ---
      new _elementTiptap.Bold(), new _elementTiptap.Underline(), new _elementTiptap.Italic(), new _elementTiptap.Strike(), new _elementTiptap.ListItem(), new _elementTiptap.BulletList(), new _elementTiptap.OrderedList(), new _elementTiptap.TextColor(), new _elementTiptap.TextHighlight(), new _elementTiptap.TodoItem(), new _elementTiptap.TodoList(), new _elementTiptap.FormatClear(), new _elementTiptap.Preview(), new _elementTiptap.Fullscreen()],
      // editor's content
      content: ''
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user'])),
  methods: {
    handleOpened: function handleOpened() {
      if (this.action === 'edit') {
        this.content = this.commentaire.message;
      } else {
        this.content = '';
      }
    },
    handleClose: function handleClose() {
      this.$emit('update:visible', false);
    },
    handleUpdate: function handleUpdate() {
      var _this = this;

      this.$loader(true);
      var data = {
        message: this.content.trim(),
        date_modification: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss')
      };
      apiCommentaire.update(this.commentaire.id, data).then(function () {
        apiHistorique.insert({
          id_affaire: _this.idAffaire,
          id_user: _this.user.id,
          message: 'Modification du commentaire id#' + _this.commentaire.id
        }).then(function () {
          _this.$loader(false);

          _this.handleClose();
        });
      }).catch(function (error) {
        _this.$message(error);

        _this.$loader(false);
      });
    },
    handleSave: function handleSave() {
      var _this2 = this;

      this.$loader(true);
      var data = {
        id_affaire: this.idAffaire,
        id_user: this.user.id,
        utilisateur: this.user.firstname + ' ' + this.user.lastname,
        email: this.user.email,
        message: this.content.trim()
      };
      apiCommentaire.insert(data).then(function (results) {
        apiHistorique.insert({
          id_affaire: _this2.idAffaire,
          id_user: _this2.user.id,
          message: 'Ajout du commentaire id#' + results.insertId
        }).then(function () {
          _this2.$loader(false);

          _this2.handleClose();
        });
      }).catch(function (error) {
        _this2.$message(error);

        _this2.$loader(false);
      });
    }
  }
};
exports.default = _default;