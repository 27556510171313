"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _setInterval = _interopRequireDefault(require("set-interval"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      showPanel: false
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['notifications', 'user'])),
  created: function created() {
    if (this.user && this.user.id > 0) {
      this.$store.dispatch('notifications/read', this.user.id);
    }
  },
  beforeMount: function beforeMount() {
    var _this = this;

    this.$store.dispatch('notifications/read', this.user.id).then(function (notifications) {
      _this.noticeNewNotifs(notifications);
    });
  },
  mounted: function mounted() {
    var _this2 = this;

    if (this.user && this.user.id > 0) {
      _setInterval.default.start(function () {
        _this2.$store.dispatch('notifications/read', _this2.user.id).then(function (notifications) {
          _this2.noticeNewNotifs(notifications);
        });
      }, 60000, 'NOTIFIER'); // 60000 = 60s * 1000ms = every minutes

    }
  },
  beforeDestroy: function beforeDestroy() {
    _setInterval.default.clear('NOTIFIER');
  },
  methods: {
    unreadNotification: function unreadNotification() {
      return this.notifications.notifications.filter(function (x) {
        return x.date_markasread === null;
      });
    },
    onMenuCommand: function onMenuCommand(command) {
      switch (command) {
        case 'markAllAsRead':
          this.$store.dispatch('notifications/markallasread', this.user.id);
          break;

        case 'seeAllNotifications':
          this.$router.push('/notification');
          break;
      }
    },
    onClosePanel: function onClosePanel(done) {
      done();
    },
    onCloseAlert: function onCloseAlert(notification) {
      this.$store.dispatch('notifications/markasread', notification);
    },
    noticeNewNotifs: function noticeNewNotifs(notifications) {
      var _this3 = this;

      var newNotifs = notifications.filter(function (x) {
        return x.date_notification === null && x.date_markasread === null;
      });

      if (newNotifs.length > 0) {
        newNotifs.forEach(function (x) {
          _this3.$store.dispatch('notifications/markasnotified', x);
        });
      }
    }
  }
};
exports.default = _default;