"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'CommandeForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new', 'edit'].includes(value);
      }
    },
    formData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      validator: function validator(value) {
        return value.length > 0;
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      rules: {
        date_commande: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        montant_initial: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        numero: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['affaire'])),
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)({}, (0, _vuex.mapActions)('facturation', ['insertCommande', 'updateCommande']), {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this.action) {
            case 'new':
              _this.actionNew();

              break;

            case 'edit':
              _this.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this2 = this;

      var data = this.getFormData();
      this.$loader(true);
      var commande = data;
      console.log('[Commande/actionNew] 1.1. Création de la commande en BDD');
      this.insertCommande(commande).then(function () {
        console.log('[Commande/actionNew] 1.2. commande créée en BDD');

        _this2.$message('Création effectuée avec succès');

        _this2.resetForm();

        _this2.$loader(false);

        _eventBus.EventBus.$emit('COMMANDE_SAVE');
      }).catch(function (error) {
        _this2.$loader(false);

        _this2.$message(error);
      });
    },
    actionEdit: function actionEdit() {
      var _this3 = this;

      var data = this.getFormData();
      this.$loader(true);
      var commande = (0, _objectSpread2.default)({}, data, {
        id: this.formData.id
      });
      console.log('[Commande/actionEdit] 1.1. Modification de la commande ' + commande.id + ' en BDD');
      this.updateCommande(commande).then(function () {
        console.log('[Commande/actionEdit] 1.2. Commande ' + commande.id + ' modifiée en BDD');

        _this3.$message('Mise à jour effectuée avec succès');

        _this3.resetForm();

        _this3.$loader(false);

        console.log('[Commande/actionEdit] 2.3 emit event COMMANDE_SAVE');

        _eventBus.EventBus.$emit('COMMANDE_SAVE');
      }).catch(function (error) {
        _this3.$loader(false);

        _this3.$message(error);
      });
    },
    getFormData: function getFormData() {
      return {
        id_affaire: this.affaire.affaire.id,
        numero: this.formData.numero,
        date_commande: this.formData.date_commande,
        montant_initial: this.formData.montant_initial,
        avenant_1: this.formData.avenant_1,
        avenant_2: this.formData.avenant_2,
        avenant_3: this.formData.avenant_3,
        montant: this.formData.montant,
        emetteur: this.formData.emetteur,
        libelle: this.formData.libelle,
        commentaire: this.formData.commentaire
      };
    },
    handleMontantFinal: function handleMontantFinal() {
      var mtInitial = isNaN(this.formData.montant_initial) ? 0 : Number(this.formData.montant_initial);
      var mtAvenant1 = isNaN(this.formData.avenant_1) ? 0 : Number(this.formData.avenant_1);
      var mtAvenant2 = isNaN(this.formData.avenant_2) ? 0 : Number(this.formData.avenant_2);
      var mtAvenant3 = isNaN(this.formData.avenant_3) ? 0 : Number(this.formData.avenant_3);
      var mtFinal = mtInitial + mtAvenant1 + mtAvenant2 + mtAvenant3; // console.log('mtFinal', mtInitial, mtAvenant1, mtAvenant2, mtAvenant3, mtFinal)

      this.formData.montant = mtFinal;
    }
  }) // 11. Rendu
  // --

};
exports.default = _default;