"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var apiSociete = _interopRequireWildcard(require("@/api/societe"));

var apiUser = _interopRequireWildcard(require("@/api/user"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      total: 0,
      data: [],
      users: [],
      multipleSelection: [],
      columns: [// { prop: 'logo', label: 'Logo' },
      {
        prop: 'name',
        label: 'Nom société'
      }, {
        prop: 'actif',
        label: 'Actif'
      }, {
        prop: 'nbusers',
        label: 'Nb Utilisateurs'
      }]
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;

      this.$loader(true);
      this.data = [];
      this.users = [];
      apiUser.getAll().then(function (results) {
        _this.users = results;
        apiSociete.getAll().then(function (results) {
          results.forEach(function (row) {
            row.nbusers = _this.nbUsers(row.id);
            row.actif = row.actif === 1 ? '✓' : '✗';

            _this.data.push(row);
          });
          _this.data.total = results.length;

          _this.$loader(false);
        }).catch(function (error) {
          _this.$message(error);

          _this.$loader(false);
        });
      });
    },
    nbUsers: function nbUsers(idSociete) {
      return this.users.filter(function (x) {
        return x.id_societe === idSociete;
      }).length;
    },
    ColWidth: function ColWidth(index) {
      // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index

      /*
      if (index === 0) {
        return 80
      } else if (index === 1) {
        return 300
      } else if (index === 2) {
        return 80
      } else if (index === 3) {
        return 150
      }
      */
      if (index === 0) {
        return 300;
      } else if (index === 1) {
        return 80;
      } else if (index === 2) {
        return 150;
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;

      this.$confirm('Voulez-vous supprimer la société ' + row.name + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this2.$loader(true);

        apiSociete.deleteById(row.id).then(function (results) {
          _this2.data.splice(_this2.data.indexOf(row), 1);

          _this2.$message('Suppression effectuée avec succès');

          _this2.$loader(false);
        }).catch(function (err) {
          _this2.$message(err);

          _this2.$loader(false);
        });
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this3 = this;

      var msg = this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée';
      this.$confirm('Voulez-vous supprimer ' + msg + ' ?', 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this3.$loader(true);

        var selection = _this3.multipleSelection.map(function (u) {
          return u.id;
        }).join(',');

        apiSociete.bulkDelete(selection).then(function (results) {
          _this3.$message('Suppression effectuée avec succès');

          _this3.loadData();

          _this3.$loader(false);
        }).catch(function (error) {
          _this3.$message(error);

          _this3.$loader(false);
        });
      });
    }
  }
};
exports.default = _default;