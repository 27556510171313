"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _validate = require("@/helpers/validate");

var _utils = require("@/helpers/utils");

var apiUser = _interopRequireWildcard(require("@/api/user"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'UserProfil',
  data: function data() {
    var _this = this;

    var validatePwd1 = function validatePwd1(rule, value, callback) {
      if (value === '') {
        callback(new Error('Veuillez entrer le mot de passe'));
      } else {
        if (!(0, _validate.isStrongPassword)(value)) {
          callback(new Error('Le mot de passe ne respecte pas les règles de sécurité'));
        }
      }

      callback();
    };

    var validatePwd2 = function validatePwd2(rule, value, callback) {
      if (value === '') {
        callback(new Error('Veuillez entrer le mot de passe de confirmation'));
      } else {
        if (value !== _this.form2Data.pwd1) {
          callback(new Error('Les mots de passe ne correspondent pas'));
        }
      }

      callback();
    };

    return {
      form2Data: {
        pwd1: '',
        pwd2: ''
      },
      descForm1: {
        firstname: {
          label: 'Prénom',
          type: 'string',
          required: true,
          min: 3,
          max: 50,
          message: 'Longueur entre 3 et 50 caractères'
        },
        lastname: {
          label: 'Nom',
          type: 'string',
          required: true,
          min: 3,
          max: 50,
          message: 'Longueur entre 3 et 50 caractères'
        }
      },
      dialogForm1Visible: false,
      dialogForm2Visible: false,
      rules: {
        pwd1: [{
          required: true,
          validator: validatePwd1,
          trigger: 'change'
        }],
        pwd2: [{
          required: true,
          validator: validatePwd2,
          trigger: 'change'
        }]
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user']), {
    notification: {
      get: function get() {
        return Number(this.user.notification_mail) || 0;
      },
      set: function set(value) {
        this.user.notification_mail = value;
      }
    }
  }),
  methods: {
    resetFieldsForm1: function resetFieldsForm1() {
      this.$refs['dialogForm1'].resetFields();
      this.dialogForm1Visible = false;
    },
    resetFieldsForm2: function resetFieldsForm2() {
      this.$refs['dialogForm2'].resetFields();
      this.dialogForm2Visible = false;
    },
    validateForm1: function validateForm1() {
      var _this2 = this;

      this.$refs['dialogForm1'].validate(function (valid) {
        if (valid) {
          var data = {
            firstname: _this2.user.firstname,
            lastname: _this2.user.lastname
          };
          apiUser.update(_this2.user.id, data).then(function (results) {
            _this2.$message('Mise à jour effectuée avec succès');
          }).catch(function (error) {
            _this2.$refs['dialogForm1'].resetFields();

            _this2.$message(error);
          });
          _this2.dialogForm1Visible = false;
        }
      });
    },
    validateForm2: function validateForm2() {
      var _this3 = this;

      this.$refs['dialogForm2'].validate(function (valid) {
        if (valid) {
          var data = {
            password: (0, _utils.encodePassword)(_this3.form2Data.pwd1)
          };
          apiUser.update(_this3.user.id, data).then(function (results) {
            _this3.$message('Mise à jour du mot de passe effectuée avec succès');
          }).catch(function (error) {
            _this3.$refs['dialogForm1'].resetFields();

            _this3.$message(error);
          });
          _this3.dialogForm2Visible = false;
        }
      });
    },
    handleChange: function handleChange() {
      var _this4 = this;

      var data = {
        notification_mail: this.notification
      };
      apiUser.update(this.user.id, data).then(function (results) {
        _this4.$message('Mise à jour effectuée avec succès');
      }).catch(function (error) {
        _this4.$message(error);
      });
    }
  }
};
exports.default = _default;