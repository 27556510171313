"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.number.constructor");

var apiTypeVente = _interopRequireWildcard(require("@/api/type_vente"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'TypeVenteSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    idTypeVenteSelected: {
      type: Number
    },
    tva: {
      type: Object
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      typeVenteList: [],
      idTypeVente: this.idTypeVenteSelected
    };
  },
  // 9. Évènements
  // --
  beforeMount: function beforeMount() {
    this.getAll();
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    getAll: function getAll() {
      var _this = this;

      apiTypeVente.getAll().then(function (results) {
        _this.typeVenteList = results;

        var typeVente = _this.typeVenteList.find(function (x) {
          return x.id === _this.idTypeVenteSelected;
        });

        if (typeVente) {
          _this.$emit('update:type-vente', typeVente);
        }
      }).catch(function (error) {
        _this.$message(error);
      });
    },
    onClear: function onClear() {
      this.$emit('update:id-type-vente-selected', null);
      this.$emit('update:type-vente', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:id-type-vente-selected', index);
        this.$emit('update:type-vente', this.typeVenteList.find(function (x) {
          return x.id === index;
        }));
      }
    }
  } // 11. Rendu
  // --

};
exports.default = _default;