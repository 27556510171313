var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("Liste des données calculées")]),
    _vm._v(" "),
    _c("p", [
      _vm._v("\n    PK id : " + _vm._s(_vm.data.id)),
      _c("br"),
      _vm._v("\n    Id Affaire : " + _vm._s(_vm.data.id_affaire)),
      _c("br"),
      _vm._v("\n    Année/Mois : " + _vm._s(_vm.$store.getters.affaire.YYYYMM)),
      _c("br"),
      _vm._v(
        "\n    Date dernière mise à jour : " +
          _vm._s(_vm.data.date_update) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c("pre", [
      _vm._v(_vm._s(_vm.data && _vm.data.data ? JSON.parse(_vm.data.data) : ""))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }