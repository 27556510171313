"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
var _default = {
  name: 'Printer',
  data: function data() {
    return {
      formatPage: ''
    };
  },
  computed: {
    printObj: function printObj() {
      var formatPage = '';

      switch (this.$store.getters.breadcrumb.key) {
        case 'venteInitiale':
        case 'budgetAffaire':
          formatPage = 'portrait';
          break;

        case 'gestionMensuelle':
        case 'syntheseMensuelle':
          formatPage = 'landscape';
          break;

        default:
          formatPage = 'portrait';
      } // console.log(' impression formatPage', this.$store.getters.breadcrumb.key, formatPage)


      return {
        id: 'printer',
        popTitle: '',
        extraCss: './print.css',
        extraHead: "<style>@page { size: ".concat(formatPage, "; }</style>")
      };
    }
  }
};
exports.default = _default;