"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVersion = getVersion;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getVersion() {
  return (0, _request.default)({
    url: '/version',
    method: 'get'
  });
}