"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.is-nan");

require("core-js/modules/es6.number.parse-int");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.number.parse-float");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _eventBus = require("@/helpers/event-bus.js");

var _factures = require("@/helpers/factures");

var _utils = require("@/helpers/utils");

var _vuex = require("vuex");

var _CommandeSelectList = _interopRequireDefault(require("@/components/CommandeSelectList"));

var _CommandeInfos = _interopRequireDefault(require("@/components/CommandeInfos"));

var _TvaSelectList = _interopRequireDefault(require("@/components/TvaSelectList"));

var _TypeVenteSelectList = _interopRequireDefault(require("@/components/TypeVenteSelectList"));

var _MontantTypeSelectList = _interopRequireDefault(require("@/components/MontantTypeSelectList"));

var _constantes = require("@/helpers/constantes");

var apiFacture = _interopRequireWildcard(require("@/api/facture"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'FactureForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    CommandeSelectList: _CommandeSelectList.default,
    CommandeInfos: _CommandeInfos.default,
    TvaSelectList: _TvaSelectList.default,
    TypeVenteSelectList: _TypeVenteSelectList.default,
    MontantTypeSelectList: _MontantTypeSelectList.default
  },
  inject: ['getCommande'],
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new', 'edit'].includes(value);
      }
    },
    formData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      validator: function validator(value) {
        return value.length > 0;
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    var _this = this;

    var validDateFacturation = function validDateFacturation(rule, value, callback) {
      var date = value;

      if (!(0, _moment.default)(date, 'YYYY/MM/DD').isValid()) {
        callback(new Error('Date de facturation invalide'));
      }

      callback();
    };

    var validDateEcheance = function validDateEcheance(rule, value, callback, source, options) {
      var dateEcheance = value;
      var dateFacture = _this.formData.date_facture || '';

      if (dateFacture && (0, _moment.default)(dateFacture, 'YYYY/MM/DD').isValid()) {
        if (!(0, _moment.default)(dateEcheance, 'YYYY/MM/DD').isValid()) {
          callback(new Error('Date d\'échéance invalide'));
        } else if (!(0, _moment.default)(dateEcheance, 'YYYY/MM/DD').isSameOrAfter(dateFacture)) {
          callback(new Error('La date d\'échéance ne peut être antérieure à la date de facturation'));
        }
      }

      callback();
    };
    /*
    const validRetenueGarantie = (rule, value, callback) => {
      const retenurGarantie = value
      if (retenurGarantie < 0) {
        callback(new Error('Le montant de la retenue de garantie doit être positif'))
      }
      callback()
    }
    */


    var validFichier = function validFichier(rule, value, callback, source, options) {
      if (_this.selectedFile) {
        var maxUploadSize = _constantes.FACTURES.MAX_UPLOAD_SIZE / 1024 / 1000; // converti en octets

        var fileSize = Number.parseFloat(_this.selectedFile.size / 1024 / 1000).toFixed(2); // converti en octets

        var allowedType = 'application/pdf';

        if (_this.selectedFile.type !== allowedType) {
          callback(new Error(_this.$i18n.t('facturation.facture.restricted_allowed_filetypes', {
            fileTypes: '.pdf'
          })));
        } else if (fileSize > maxUploadSize) {
          callback(new Error(_this.$i18n.t('facturation.facture.file_too_big', {
            fileSize: "".concat(fileSize, " Mo"),
            maxFileSize: "".concat(maxUploadSize, " Mo")
          })));
        }
      }

      callback();
    };

    var validNumero = function validNumero(rule, value, callback) {
      if (value !== '') {
        var payload = {
          id: _this.formData.id,
          numero: value
        };
        apiFacture.checkUnicity(payload).then(function (results) {
          if (results.length > 0) {
            callback(new Error('Numéro de facture déjà utilisé'));
          }

          callback();
        });
      }
    };

    return {
      // Constantes
      CONST: {
        FACTURES: _constantes.FACTURES,
        TYPES_MONTANT: _constantes.TYPES_MONTANT,
        COEF_CALCUL_AUTO_RG: 0.05
      },
      autoCalculRG: false,
      selection: {},
      helper: {
        rev_actu: this.formData.rev_actu,
        rev_actu_type_montant: _constantes.TYPES_MONTANT.HT.value,
        retenue_garantie: this.formData.retenue_garantie,
        retenue_garantie_type_montant: _constantes.TYPES_MONTANT.TTC.value
      },
      // L'idCommande est-il forcé par la commande parente de la facture à créer / modifier ?
      idCommandeForced: false,
      // L'idCommande initialement lié à la facture actuelle
      idCommandeInitial: null,
      // Montant de la facture initial (0 en création mais égal au montantAPercevoirTtc en édition)
      montantFactureInitial: 0,
      // Doit-on supprimer le fichier précédemment uploadé ?
      deleteFichier: false,
      // Fichier à uploader
      selectedFile: null,
      // Règles de validation du formulaire
      rules: {
        date_facture: [{
          required: true,
          message: 'Champ obligatoire'
        }, {
          validator: validDateFacturation
        }],
        date_echeance: [{
          required: true,
          message: 'Champ obligatoire'
        }, {
          validator: validDateEcheance
        }],
        id_tva: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        id_type_vente: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        libelle: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        montant: [{
          required: true,
          message: 'Champ obligatoire'
        }],

        /*
        retenue_garantie: [
          { validator: validRetenueGarantie }
        ],
        */
        numero: [{
          required: true,
          message: 'Champ obligatoire'
        }, {
          validator: validNumero
        }],
        fichier: [{
          validator: validFichier
        }]
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['affaire']), {}, (0, _vuex.mapGetters)('facturation', ['getTauxTvaById', 'getCommandeById']), {
    factureFichierUri: function factureFichierUri() {
      return (0, _factures.getFactureFichierUri)(this.formData);
    },
    // Une commande parente est-elle sélectionnée
    commandeIsSelected: function commandeIsSelected() {
      var idCommande = Number.parseInt(this.formData.id_commande);
      return !Number.isNaN(idCommande);
    },
    showMontantRevActuHt: function showMontantRevActuHt() {
      return (this.helper.rev_actu > 0 || this.helper.rev_actu < 0) && this.helper.rev_actu_type_montant === this.CONST.TYPES_MONTANT.TTC.value;
    },
    showMontantRetenueGarantieTtc: function showMontantRetenueGarantieTtc() {
      return this.helper.retenue_garantie > 0 && this.helper.retenue_garantie_type_montant === this.CONST.TYPES_MONTANT.HT.value;
    },
    // Définition: montant calculé en fonction du tarif HT ou TTC indiqué
    montantRevisionActualisation: function montantRevisionActualisation() {
      var montant = 0;

      if (this.helper.rev_actu > 0 || this.helper.rev_actu < 0) {
        switch (this.helper.rev_actu_type_montant) {
          // Saisie en HT
          case this.CONST.TYPES_MONTANT.HT.value:
            montant = this.helper.rev_actu;
            break;
          // Saisie en TTC

          case this.CONST.TYPES_MONTANT.TTC.value:
            montant = this.helper.rev_actu / (1 + this.tauxTva);
            break;
        }
      }

      return Number.parseFloat(montant);
    },
    // Définition: {Montant HT} + {Révision / Actualisation}
    // Calcul: `montant_ht` + `rev_actu`
    montantTotalHt: function montantTotalHt() {
      var montant = Number.isNaN(Number.parseFloat(this.formData.montant)) ? 0 : Number.parseFloat(this.formData.montant);
      var revisionActualisation = this.montantRevisionActualisation; // const revisionActualisation = Number.isNaN(Number.parseFloat(this.formData.rev_actu)) ? 0 : Number.parseFloat(this.formData.rev_actu)

      var montantTotalHT = montant + revisionActualisation;
      return montantTotalHT;
    },
    // Taux de TVA en pourcentage
    tauxTva: function tauxTva() {
      var idTVA = Number.parseInt(this.formData.id_tva);

      if (Number.isNaN(idTVA)) {
        return 0;
      } // On utilise le store pour trouver le taux de TVA correspondant à la TVA sélectionnée


      var tva = this.getTauxTvaById(idTVA);
      return tva.taux / 100;
    },
    // Définition: {Montant Total HT} * {Taux TVA}%
    // Calcul: `montantTotalHt` + `tva.taux` / 100
    montantTotalTva: function montantTotalTva() {
      return this.montantTotalHt * this.tauxTva;
    },
    // Définition: {Montant Total HT} * {Montant total TVA}
    // Calcul: `montantTotalHt` + `montantTotalTva`
    montantAvantRetenueGarantieTtc: function montantAvantRetenueGarantieTtc() {
      return this.montantTotalHt + this.montantTotalTva;
    },
    // Définition: montant calculé en fonction du tarif HT ou TTC indiqué
    montantRetenueGarantie: function montantRetenueGarantie() {
      var montant = 0;
      var tarif = Number.isNaN(Number.parseFloat(this.helper.retenue_garantie)) ? 0 : Number.parseFloat(this.helper.retenue_garantie);
      var tarifType = this.helper.retenue_garantie_type_montant;

      if (tarif !== 0) {
        switch (tarifType) {
          // Saisie en HT
          case this.CONST.TYPES_MONTANT.HT.value:
            montant = tarif * (1 + this.tauxTva);
            break;
          // Saisie en TTC

          case this.CONST.TYPES_MONTANT.TTC.value:
            montant = tarif;
            break;
        }
      }

      return Number.parseFloat(montant);
    },
    // Définition: {Montant Total TTC avant R.G.} - {Retenue de Garantie TTC} + {Divers TTC}
    // Calcul: `montantAvantRetenueGarantieTtc` - `retenue_garantie` + `divers_ttc`
    montantApresRetenueGarantieTtc: function montantApresRetenueGarantieTtc() {
      var retenueGarantie = this.montantRetenueGarantie;
      var diversTTC = Number.isNaN(Number.parseFloat(this.formData.divers_ttc)) ? 0 : Number.parseFloat(this.formData.divers_ttc);
      var montantApresRetenueGarantieTtc = this.montantAvantRetenueGarantieTtc - retenueGarantie + diversTTC;
      return montantApresRetenueGarantieTtc;
    },
    // Définition: {Montant Total TTC après R.G.} - {Montant Paiement Direct Sous-Traitant}
    // Calcul: `montantApresRetenueGarantieTtc` - `mt_paiement_direct_st`
    montantAPercevoirTtc: function montantAPercevoirTtc() {
      var montantPaiementDirectSousTraitant = Number.isNaN(Number.parseFloat(this.formData.mt_paiement_direct_st)) ? 0 : Number.parseFloat(this.formData.mt_paiement_direct_st);
      var montantAPercevoirTtc = this.montantApresRetenueGarantieTtc - montantPaiementDirectSousTraitant;
      return montantAPercevoirTtc;
    },
    montantFacture: function montantFacture() {
      var montant = Number(this.montantTotalHt);
      return isNaN(montant) ? 0 : montant;
    },
    echeanceByPaymentDelay: function echeanceByPaymentDelay() {
      return this.affaire.affaire.delai_paiement && this.formData.date_echeance === this.getDateEcheance();
    }
  }),
  // 9. Évènements
  // --
  watch: {
    /* ---------------------------------------
     *  Montant de la facture initial
     * ---------------------------------------
     *
     *   - si on est en édition
     *     ET que la facture était initialement liée à la commande sélectionnée
     *     le montant initial est égal au montantAPercevoirTtc
     *   - par défaut: 0.00
     *
     */
    'formData.mt_a_percevoir_ttc': function formDataMt_a_percevoir_ttc(newVal) {
      this.setMontantFactureInitial();
    },
    'formData.id_commande': function formDataId_commande(newVal) {
      this.setEmetteurCommande();
    },
    'formData.date_facture': function formDataDate_facture(newVal) {
      this.setDateEcheance();
    },
    'selection.commande': function selectionCommande(newVal) {
      this.setMontantFactureInitial();
    },
    'helper.rev_actu': function helperRev_actu(newVal) {
      this.setMontantRevActuHt();
    },
    'helper.rev_actu_type_montant': function helperRev_actu_type_montant(newVal) {
      this.setMontantRevActuHt();
    },
    'helper.retenue_garantie': function helperRetenue_garantie(newVal) {
      this.setMontantRetenueGarantieTtc();

      if (Number.isNaN(Number.parseFloat(newVal))) {
        this.autoCalculRG = false;
      }
    },
    'helper.retenue_garantie_type_montant': function helperRetenue_garantie_type_montant(newVal) {
      this.setMontantRetenueGarantieTtc();
    },
    'formData.id_tva': function formDataId_tva(newVal) {
      this.setMontantRevActuHt();
      this.setMontantRetenueGarantieTtc();
    },
    'autoCalculRG': function autoCalculRG(newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.calculateRG();
      } else if (newVal === false && oldVal === true) {
        this.helper.retenue_garantie = '';
      }
    },
    'montantAvantRetenueGarantieTtc': function montantAvantRetenueGarantieTtc(newVal) {
      if (this.autoCalculRG) {
        this.calculateRG();
      }
    }
  },
  created: function created() {
    var vm = this; // Récupération de l'ID de la commande parente sélectionnée
    // pour lier automatiquement la facture à cette commande

    vm.getCommande(function (commande) {
      if (commande) {
        vm.idCommandeForced = true;
        vm.formData.id_commande = commande.id;
        vm.formData.emetteur = commande.emetteur;
        vm.formData.libelle = commande.libelle;
      }
    });
  },
  mounted: function mounted() {
    this.idCommandeInitial = this.formData.id_commande;
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)({}, (0, _vuex.mapActions)('facturation', ['insertFacture', 'updateFacture']), {
    getCalculatedRGAmount: function getCalculatedRGAmount() {
      var coef = this.CONST.COEF_CALCUL_AUTO_RG; // Récupérer le montant TTC avant RG

      var montantAvantRetenueGarantieTtc = Number.parseFloat(this.montantAvantRetenueGarantieTtc);

      if (!Number.isNaN(montantAvantRetenueGarantieTtc)) {
        return Math.round(coef * montantAvantRetenueGarantieTtc * 100) / 100;
      }

      return false;
    },
    calculateRG: function calculateRG() {
      /*
      const coef = this.CONST.COEF_CALCUL_AUTO_RG
      // Récupérer le montant TTC avant RG
      const montantAvantRetenueGarantieTtc = Number.parseFloat(this.montantAvantRetenueGarantieTtc)
      if (!Number.isNaN(montantAvantRetenueGarantieTtc)) {
        this.helper.retenue_garantie = Math.round(coef * montantAvantRetenueGarantieTtc * 100) / 100
      }
      */
      var montant = this.getCalculatedRGAmount();

      if (montant !== false) {
        this.helper.retenue_garantie = montant;
      }
    },
    // Si une commande est sélectionnée : remplir le champ `emetteur` avec l'émetteur de la commande parente
    // sinon vider le champ pour que l'utilisateur puisse le remplir
    setEmetteurCommande: function setEmetteurCommande() {
      // Récupérer l'émetteur de la commande parente (si sélectionnée)
      var idCommande = Number.parseInt(this.formData.id_commande);

      if (Number.isNaN(idCommande)) {
        this.formData.emetteur = '';
        return;
      } // On utilise le store pour trouver l'émetteur de la commande correspondant à la commande sélectionnée


      var commande = this.getCommandeById(idCommande);
      this.formData.emetteur = commande.emetteur;
    },
    // Calculer la date d'échéance d'après la date de facture et le délai de paiement de l'affaire
    getDateEcheance: function getDateEcheance() {
      var dateFacture = this.formData.date_facture || '';
      var delaiPaiement = this.affaire.affaire.delai_paiement || '';
      var dateEcheance = (0, _utils.getDateEcheanceByDelaiPaiement)({
        dateFacture: dateFacture,
        delaiPaiement: delaiPaiement
      });

      if (!(0, _moment.default)(dateEcheance, 'YYYY/MM/DD').isValid()) {
        dateEcheance = null;
      }

      return dateEcheance;
    },
    setDateEcheance: function setDateEcheance() {
      this.formData.date_echeance = this.getDateEcheance();
    },
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this2 = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this2.action) {
            case 'new':
              _this2.actionNew();

              break;

            case 'edit':
              _this2.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this3 = this;

      this.$loader(true);
      var payload = {
        facture: this.getFormData(),
        fichier: {
          newFichier: this.selectedFile,
          deleteFichier: this.deleteFichier
        }
      }; // 1. Création de la facture en BDD

      this.insertFacture(payload).then(function (_) {
        // 2 Facture créée en BDD et fichier uploadé si nécessaire
        _this3.$loader(false);

        _this3.$message('Création effectuée avec succès');

        _this3.resetForm(); // console.log('[Facture/actionNew] 3 emit event FACTURE_SAVE')


        _eventBus.EventBus.$emit('FACTURE_SAVE');
      }).catch(function (error) {
        _this3.$loader(false);

        _this3.$message(error);
      });
    },
    actionEdit: function actionEdit() {
      var _this4 = this;

      this.$loader(true);
      var payload = {
        facture: (0, _objectSpread2.default)({}, this.getFormData(), {
          id: this.formData.id
        }),
        fichier: {
          newFichier: this.selectedFile,
          deleteFichier: this.deleteFichier
        }
      }; // 1. Modification de la facture en BDD

      this.updateFacture(payload).then(function (_) {
        // 2 Facture créée en BDD et fichier uploadé si nécessaire
        _this4.$loader(false);

        _this4.$message('Mise à jour effectuée avec succès');

        _this4.resetForm();

        _eventBus.EventBus.$emit('FACTURE_SAVE');
      }).catch(function (error) {
        _this4.$loader(false);

        _this4.$message(error);
      });
    },
    getFormData: function getFormData() {
      return {
        id_commande: this.formData.id_commande,
        id_tva: this.formData.id_tva,
        id_type_vente: this.formData.id_type_vente,
        id_affaire: this.affaire.affaire.id,
        numero: this.formData.numero,
        date_facture: this.formData.date_facture,
        date_echeance: this.formData.date_echeance,
        montant: Number.isNaN(Number.parseFloat(this.formData.montant)) ? 0 : Number.parseFloat(this.formData.montant),
        // Revision / Actualisation HT (calculée en fonction de la saisie HT ou TTC de l'utilisateur)
        rev_actu: Number.isNaN(Number.parseFloat(this.formData.rev_actu)) ? 0 : Number.parseFloat(this.formData.rev_actu),
        // Retenue de Garantie TTC (calculée en fonction de la saisie HT ou TTC de l'utilisateur)
        retenue_garantie: Number.isNaN(Number.parseFloat(this.formData.retenue_garantie)) ? 0 : Number.parseFloat(this.formData.retenue_garantie),
        divers_ttc: Number.isNaN(Number.parseFloat(this.formData.divers_ttc)) ? 0 : Number.parseFloat(this.formData.divers_ttc),
        libelle: this.formData.libelle,
        emetteur: this.formData.emetteur,
        mt_paiement_direct_st: Number.isNaN(Number.parseFloat(this.formData.mt_paiement_direct_st)) ? 0 : Number.parseFloat(this.formData.mt_paiement_direct_st),
        // Champ montantAPercevoirTtc calculé
        mt_a_percevoir_ttc: Number.isNaN(Number.parseFloat(this.montantAPercevoirTtc)) ? 0 : Number.parseFloat(this.montantAPercevoirTtc),
        // le fichier sera ensuite mis à jour via la fonctionnalité d'upload
        fichier: this.formData.fichier
      };
    },
    onFileChange: function onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    onFileRemove: function onFileRemove() {
      this.selectedFile = null;
      this.$refs.fichierUploader.value = '';
    },
    setMontantFactureInitial: function setMontantFactureInitial() {
      if (this.formData.id_commande !== null && this.selection.commande && this.idCommandeInitial === this.selection.commande.id) {
        this.montantFactureInitial = this.montantTotalHt;
      } else {
        this.montantFactureInitial = 0;
      }
    },
    setMontantRevActuHt: function setMontantRevActuHt() {
      this.formData.rev_actu = this.montantRevisionActualisation;
    },
    setMontantRetenueGarantieTtc: function setMontantRetenueGarantieTtc() {
      this.formData.retenue_garantie = this.montantRetenueGarantie;
    }
  }) // 11. Rendu
  // --

};
exports.default = _default;