"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _eventBus = require("@/helpers/event-bus.js");

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'LotDetailGestMensuelleBudgetComp',
  props: ['idLot'],
  data: function data() {
    return {
      refresh: 0,
      show: true,
      lotsDetail: [],
      calculs: [],
      totaux: [],
      modeEdit: false,
      CONST: CONST,
      YYYYMM: '',
      YYYYMMmoins1: ''
    };
  },
  computed: {
    affaire: function affaire() {
      return this.$store.getters.affaire;
    }
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REFRESH', function () {
      _this.lotsDetail = _this.$store.getters.affaire.lotsDetail.filter(function (x) {
        return x.id_lot === _this.idLot && [CONST.LOT_DETAIL.TYPE_LIGNE.BUDGET_COMP].includes(x.type_lot_detail);
      });
      _this.calculs = _this.$store.getters.affaire.calculs.lotsDetail.filter(function (x) {
        return x.id_lot === _this.idLot && [CONST.LOT_DETAIL.TYPE_LIGNE.BUDGET_COMP].includes(x.type_lot_detail);
      });
      _this.totaux = _this.$store.getters.affaire.calculs.lots.filter(function (x) {
        return x.id === _this.idLot;
      });
      _this.show = _this.lotsDetail.length > 0; // période de gestion mensuelle

      _this.YYYYMM = _this.$store.getters.affaire.YYYYMM ? (0, _moment.default)(_this.$store.getters.affaire.YYYYMM, 'YYYYMM').format('MM/YYYY') : '';
      _this.YYYYMMmoins1 = _this.$store.getters.affaire.YYYYMMmoins1 ? (0, _moment.default)(_this.$store.getters.affaire.YYYYMMmoins1, 'YYYYMM').format('MM/YYYY') : '';
    });

    this.refresh++;
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REFRESH');
  },
  methods: {
    cellClassName: function cellClassName(_ref) {
      var columnIndex = _ref.columnIndex;

      if (columnIndex >= 11 && columnIndex <= 13) {
        return 'tb-pattern';
      }
    }
  }
};
exports.default = _default;