"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var routes = [{
  path: '/admin-workflow',
  name: 'AdminWorflow',
  order: 5,
  meta: {
    title: 'Gestion du circuit des affaires',
    icon: 'workflow'
  },
  redirect: '/admin/workflow',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/layout/empty'));
    });
  },
  children: [{
    path: '/admin/workflow',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/workflow'));
      });
    },
    name: 'ListWorkflow',
    meta: {
      title: 'Circuit des affaires',
      activeMenu: '/admin/workflow'
    }
  }, {
    path: '/admin/workflow/new',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/workflow/new'));
      });
    },
    name: 'NewWorkflow',
    meta: {
      title: 'Ajouter une ligne',
      activeMenu: '/admin/workflow'
    }
  }, {
    path: '/admin/workflow/edit/:id(\\d+)',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/workflow/edit'));
      });
    },
    name: 'EditWorkflow',
    meta: {
      title: 'Modifier une ligne',
      activeMenu: '/admin/workflow'
    }
  }]
}];
var _default = routes;
exports.default = _default;