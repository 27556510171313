"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.object.values");

require("core-js/modules/es6.array.find");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

var _eventBus = require("@/helpers/event-bus.js");

var _FactureInfos = _interopRequireDefault(require("@/components/FactureInfos"));

var _ReglementList = _interopRequireDefault(require("./ReglementList"));

var _ReglementNew = _interopRequireDefault(require("./ReglementNew"));

var _ReglementEdit = _interopRequireDefault(require("./ReglementEdit"));

var _constantes = require("@/helpers/constantes");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'ReglementModule',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  components: {
    FactureInfos: _FactureInfos.default,
    ReglementList: _ReglementList.default,
    ReglementNew: _ReglementNew.default,
    ReglementEdit: _ReglementEdit.default
  },
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    // Filtre global pour ne voir que les reglements d'une facture (cf. Module des factures)
    idFacture: Number
  },

  /* ---------------------------------------
   *  Données / Méthodes disponibles pour les composants enfants
   * ---------------------------------------
   *
   *   - getFacture
   *     Retrouver la facture parente d'un règlement (depuis le formulaire de création / édition).
   *
   */
  provide: function provide() {
    return {
      getFacture: this.getFacture
    };
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      // Mode d'affichage : list, new, edit
      modeView: 'list',
      // Identifiant du règlement à éditer
      id_reglement: 0,
      // Forcer le type de règlement
      force_type_reglement: _constantes.REGLEMENTS.TYPE.PAIEMENT.value,
      // Variable utilisée pour forcer le rafraîchissement de certains composants
      refresh: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('facturation', ['getFactureById', // 'getFactureList',
  'getOrigineReglement']), {
    origine: function origine() {
      return this.getOrigineReglement;
    },

    /* ---------------------------------------
     *  Mode saisie des règlements
     * ---------------------------------------
     *
     *   - true si modeView = (new | modification)
     *   - false si modeView = 'liste'
     *
     */
    modeEdit: function modeEdit() {
      return this.modeView !== 'list';
    },

    /* ---------------------------------------
     *  Afficher le bouton de retour
     * ---------------------------------------
     *
     *   - si on est sur un formulaire (création, édition)
     *     OU
     *   - si on consulte les règlements d'une facture
     *
     */
    showBtnReturn: function showBtnReturn() {
      return this.modeEdit === true || this.facture !== null;
    },
    // Facture parente
    facture: function facture() {
      // const data = this.getFactureList()
      // return data.find(x => x.id === this.idFacture) || null
      return this.getFactureById(this.idFacture) || null;
    }
  }),
  // 9. Évènements
  // --
  watch: {
    /* ---------------------------------------
     *  Réinitialiser la liste des règlements
     * ---------------------------------------
     *
     *   Quand on passe de la vue filtrée des règlements d'une facture
     *   à la vue de tous les règlements de l'affaire.
     *
     */
    idFacture: function idFacture(newVal, oldVal) {
      if (newVal === undefined) {
        this.id_reglement = 0;
        this.modeView = 'list';

        _eventBus.EventBus.$emit('REGLEMENT_RESET_LIST');
      }
    }
  },

  /* ---------------------------------------
   *  Quand le composant est montée au niveau du DOM
   * ---------------------------------------
   *
   *   Ajout de listeners :
   *
   *   - REGLEMENT_NEW
   *     Affichage du formulaire vierge pour créer un nouveau règlement.
   *
   *   - REGLEMENT_EDIT
   *     Affichage du formulaire pré-rempli pour éditer un règlement.
   *
   *   - REGLEMENT_SAVE :
   *     Un règlement est enregistré (création / modification) en base de données.
   *
   *   - REGLEMENT_RETURN :
   *     Affichage de la liste des règlement.
   *
   */
  mounted: function mounted() {
    var _this = this;

    /* ---------------------------------------
     *  Formulaire de création d'un nouveau règlement
     * ---------------------------------------
     *
     * Il est possible de forcer le type de règlement souhaité :
     * (voir les constantes REGLEMENTS.TYPE)
     *  - 1 : paiement normal
     *
     */
    _eventBus.EventBus.$on('REGLEMENT_NEW', function (forceTypeReglement) {
      // Forçage du type de règlement (si indiqué)
      if (forceTypeReglement && Object.values(_constantes.REGLEMENTS.TYPE).find(function (x) {
        return x.value === forceTypeReglement;
      }) !== undefined) {
        _this.force_type_reglement = forceTypeReglement;
      } else {
        _this.force_type_reglement = _constantes.REGLEMENTS.TYPE.PAIEMENT.value;
      }

      _this.modeView = 'new';
    });

    _eventBus.EventBus.$on('REGLEMENT_EDIT', function (row) {
      _this.id_reglement = row.id;
      _this.modeView = 'edit';
    });

    _eventBus.EventBus.$on('REGLEMENT_SAVE', function () {
      _this.id_reglement = 0;
      _this.modeView = 'list';
    });

    _eventBus.EventBus.$on('REGLEMENT_RETURN', function () {
      _this.id_reglement = 0;
      _this.modeView = 'list';
    });

    _eventBus.EventBus.$on('REGLEMENT_REFRESH', function () {
      _this.refresh++;
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REGLEMENT_NEW');

    _eventBus.EventBus.$off('REGLEMENT_EDIT');

    _eventBus.EventBus.$off('REGLEMENT_SAVE');

    _eventBus.EventBus.$off('REGLEMENT_RETURN');

    _eventBus.EventBus.$off('REGLEMENT_REFRESH');
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    /* ---------------------------------------
     *  Clic sur le bouton "Retour"
     * ---------------------------------------
     *
     *  - si on est en mode édition (formulaire de création / modification),
     *    on émet l'événement 'REGLEMENT_RETURN' pour afficher revenir à la liste.
     *
     *  - si une facture parente est indiquée,
     *    on émet l'événement 'TAB_MENU' pour retourner à la liste des factures de la commande liée.
     *
     */
    handleReturn: function handleReturn() {
      if (this.modeEdit === true) {
        _eventBus.EventBus.$emit('REGLEMENT_RETURN');
      } else if (this.facture !== null) {
        if (this.origine === 'creance') {
          _eventBus.EventBus.$emit('CREANCES_REGLEMENTS_RETURN');
        } else {
          _eventBus.EventBus.$emit('TAB_MENU', 'factures_by_commande', this.facture.commande);
        }
      }
    },

    /* ---------------------------------------
     *  Récupération de la facture en cours
     * ---------------------------------------
     *
     *  Méthode permettant à n'importe quel composant enfant de récupérer
     *  la facture actuellement sélectionnée (passée en prop depuis le parent).
     *  Utilisé pour gérer les règlements liés à une facture notamment,
     *  via l'option `provide`
     *
     */
    getFacture: function getFacture(callback) {
      callback(this.facture);
    }
  } // 11. Rendu
  // --

};
exports.default = _default;