var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("affaire-bloc-info"),
      _vm._v(" "),
      _c("div", { attrs: { id: "content-affaire" } }, [
        _c(
          "div",
          { staticStyle: { "margin-top": "10px" } },
          [_c("commande-module")],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }