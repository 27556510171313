"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getByAffaireIdAnneeMois = getByAffaireIdAnneeMois;
exports.getPreviousPeriodeByAffaireIdAnneeMois = getPreviousPeriodeByAffaireIdAnneeMois;
exports.getLastGestMens = getLastGestMens;
exports.save = save;
exports.read = read;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getByAffaireIdAnneeMois(id, anneemois) {
  return (0, _request.default)({
    url: '/affaire_calculs/affaire/' + id + '/anneemois/' + anneemois,
    method: 'get'
  });
}

function getPreviousPeriodeByAffaireIdAnneeMois(id, anneemois) {
  return (0, _request.default)({
    url: '/affaire_calculs/prevperiode/affaire/' + id + '/anneemois/' + anneemois,
    method: 'get'
  });
}

function getLastGestMens(idSociete) {
  return (0, _request.default)({
    url: '/affaire_calculs/lastgestmens/societe/' + idSociete,
    method: 'get'
  });
}

function save(data) {
  return (0, _request.default)({
    url: '/affaire_calculs/save',
    method: 'post',
    data: data
  });
}

function read(data) {
  return (0, _request.default)({
    url: '/affaire_calculs/read',
    method: 'post',
    data: data
  });
}