var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "synthese" } }, [
    _c(
      "div",
      { staticClass: "synthese", attrs: { id: "printer" } },
      [
        _c(
          "div",
          { staticStyle: { display: "none" }, attrs: { id: "print-header" } },
          [
            _c(
              "el-row",
              { staticClass: "btn-back" },
              [
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm._v("Gestion mensuellle")
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 14, align: "center" } }, [
                  _c("span", { staticStyle: { "font-size": "12px" } }, [
                    _vm._v(" " + _vm._s(_vm.periodeGestion()) + " ")
                  ])
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 5, align: "right" } }, [
                  _vm._v(" ")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 5, align: "left" } }, [
                  _c("h2", { staticClass: "numAffaire" }, [
                    _vm._v(_vm._s(_vm.affaire.affaire.num_affaire))
                  ])
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 14, align: "center" } }, [
                  _c("h2", { staticClass: "nomAffaire" }, [
                    _vm._v(_vm._s(_vm.affaire.affaire.nom))
                  ])
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 5, align: "right" } }, [
                  _vm._v(_vm._s(this.$store.getters.affaire.societe.name))
                ])
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("LotDetail", { key: "LotEntete_" + _vm.refresh }),
        _vm._v(" "),
        _vm._l(_vm.affaire.lots, function(lot) {
          return _c(
            "div",
            { key: lot.id + "_" + _vm.refresh },
            [
              _c("LotDetailTotaux", {
                key: "LotDetailTotaux_" + _vm.refresh,
                attrs: { idLot: lot.id }
              })
            ],
            1
          )
        }),
        _vm._v(" "),
        _c(
          "el-row",
          { key: "row1_" + _vm.refresh },
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [_c("AffaireTotaux", { key: "AffaireTotaux_" + _vm.refresh })],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          { key: "row2_" + _vm.refresh },
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [_c("Synthese", { key: "Synthese_" + _vm.refresh })],
              1
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }