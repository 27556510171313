"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAll = getAll;
exports.getAllBySocieteId = getAllBySocieteId;
exports.getAllBySocieteIdAndUserRole = getAllBySocieteIdAndUserRole;
exports.getById = getById;
exports.insert = insert;
exports.update = update;
exports.duplicate = duplicate;
exports.clean = clean;
exports.rptSynthese = rptSynthese;
exports.rptDeboursesIndirects = rptDeboursesIndirects;
exports.rptFaePca = rptFaePca;
exports.rptRecapAnnuel = rptRecapAnnuel;
exports.rptProvisionsEtEngages = rptProvisionsEtEngages;
exports.rptProvisionsRisques = rptProvisionsRisques;
exports.getContacts = getContacts;
exports.remove = remove;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAll() {
  return (0, _request.default)({
    url: '/affaire',
    method: 'get'
  });
}

function getAllBySocieteId(id) {
  return (0, _request.default)({
    url: '/affaire/societe/' + id,
    method: 'get'
  });
}

function getAllBySocieteIdAndUserRole(user) {
  return (0, _request.default)({
    url: '/affaire/societe/' + user.id_societe + '/role/' + user.key_role,
    method: 'get'
  });
}

function getById(id) {
  return (0, _request.default)({
    url: '/affaire/' + id,
    method: 'get'
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/affaire',
    method: 'post',
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/affaire/' + id,
    method: 'put',
    data: data
  });
}

function duplicate(id) {
  return (0, _request.default)({
    url: '/affaire/duplicate/' + id,
    method: 'get'
  });
} // supprime tous les lots et les lots détail d'une affaire
// utilisé lors de l'import d'un modele


function clean(id) {
  return (0, _request.default)({
    url: '/affaire/clean/' + id,
    method: 'get'
  });
} // ========================================


function rptSynthese(data) {
  return (0, _request.default)({
    url: '/affaire/rpt/synthese',
    method: 'post',
    data: data
  });
}

function rptDeboursesIndirects(data) {
  return (0, _request.default)({
    url: '/affaire/rpt/deboursesindirects',
    method: 'post',
    data: data
  });
}

function rptFaePca(data) {
  return (0, _request.default)({
    url: '/affaire/rpt/faepca',
    method: 'post',
    data: data
  });
}

function rptRecapAnnuel(data) {
  return (0, _request.default)({
    url: '/affaire/rpt/recapannuel',
    method: 'post',
    data: data
  });
}

function rptProvisionsEtEngages(data) {
  return (0, _request.default)({
    url: '/affaire/rpt/provisionsetengages',
    method: 'post',
    data: data
  });
}

function rptProvisionsRisques(data) {
  return (0, _request.default)({
    url: '/affaire/rpt/provisionsrisques',
    method: 'post',
    data: data
  });
}

function getContacts(data) {
  return (0, _request.default)({
    url: '/affaire/contacts',
    method: 'post',
    data: data
  });
}

function remove(id) {
  return (0, _request.default)({
    url: '/affaire/remove/' + id,
    method: 'get'
  });
}