"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllByLotId = getAllByLotId;
exports.getAllByAffaireId = getAllByAffaireId;
exports.update = update;
exports.insert = insert;
exports.deleteById = deleteById;
exports.deleteAllByLotId = deleteAllByLotId;
exports.updateAllByLotId = updateAllByLotId;
exports.updateAllKobjdebByLotId = updateAllKobjdebByLotId;
exports.validateByAffaireId = validateByAffaireId;
exports.resetAwaitingValidation = resetAwaitingValidation;

var _request = _interopRequireDefault(require("@/helpers/request"));

function getAllByLotId(id) {
  return (0, _request.default)({
    url: '/lot_detail/lot/' + id,
    method: 'get'
  });
}

function getAllByAffaireId(id) {
  return (0, _request.default)({
    url: '/lot_detail/affaire/' + id,
    method: 'get'
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: '/lot_detail/' + id,
    method: 'put',
    data: data
  });
}

function insert(data) {
  return (0, _request.default)({
    url: '/lot_detail',
    method: 'post',
    data: data
  });
}

function deleteById(id) {
  return (0, _request.default)({
    url: '/lot_detail/' + id,
    method: 'delete'
  });
}

function deleteAllByLotId(ids) {
  return (0, _request.default)({
    url: '/lot_detail/lot/' + ids,
    method: 'delete'
  });
}

function updateAllByLotId(ids, data) {
  return (0, _request.default)({
    url: '/lot_detail/lot/' + ids,
    method: 'put',
    data: data
  });
}

function updateAllKobjdebByLotId(ids, data) {
  return (0, _request.default)({
    url: '/lot_detail/kobjdeb/lot/' + ids,
    method: 'put',
    data: data
  });
}

function validateByAffaireId(id, data) {
  return (0, _request.default)({
    url: '/lot_detail/validate/affaire/' + id,
    method: 'put',
    data: data
  });
}

function resetAwaitingValidation(id) {
  return (0, _request.default)({
    url: '/lot_detail/reset-awaiting-validation/affaire/' + id,
    method: 'put',
    data: null
  });
}