"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.encodePassword = encodePassword;
exports.ExportCsv = ExportCsv;
exports.toCurrency = toCurrency;
exports.toCurrencyFilter = toCurrencyFilter;
exports.toPercentage = toPercentage;
exports.dateToSQL = dateToSQL;
exports.getTotal = getTotal;
exports.getLabelByConstant = getLabelByConstant;
exports.getListModeReglement = getListModeReglement;
exports.getLabelModeReglement = getLabelModeReglement;
exports.getLisTypeReglement = getLisTypeReglement;
exports.getLabelTypeReglement = getLabelTypeReglement;
exports.getLabelDelaiPaiement = getLabelDelaiPaiement;
exports.generatePassword = generatePassword;
exports.dynamicSort = dynamicSort;
exports.groupByKey = groupByKey;
exports.getDateEcheanceByDelaiPaiement = getDateEcheanceByDelaiPaiement;
exports.nl2br = nl2br;
exports.toDateTime = toDateTime;
exports.toDate = toDate;
exports.toTime = toTime;
exports.getDateNow = getDateNow;
exports.getFiltreProvisionsByEcran = getFiltreProvisionsByEcran;

var _defineProperty2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _objectSpread3 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _slicedToArray2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

require("core-js/modules/es7.object.entries");

require("core-js/modules/es6.regexp.match");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.object.values");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _toConsumableArray2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/es6.regexp.replace");

var _bcryptjs = _interopRequireDefault(require("bcryptjs"));

var _moment = _interopRequireDefault(require("moment"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

// import Vue from 'vue'
function encodePassword(password) {
  var salt = _bcryptjs.default.genSaltSync(10);

  var hash = _bcryptjs.default.hashSync(password, salt);

  return hash;
}

function ExportCsv(arrData) {
  var csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += [Object.keys(arrData[0]).join(';')].concat((0, _toConsumableArray2.default)(arrData.map(function (item) {
    return Object.values(item).join(';');
  }))).join('\n').replace(/(^\[)|(\]$)/gm, '');
  var data = encodeURI(csvContent);
  var link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', 'export.csv');
  link.click();
}

function toCurrency(value) {
  var formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
}

function toCurrencyFilter(value) {
  var formatter = new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    useGrouping: false
  });
  return String(formatter.format(value)).replace(/,/, '.');
}

function toPercentage(value) {
  if (!isNaN(value)) {
    return Number(value).toFixed(2) + ' %';
  }
}

function dateToSQL(date) {
  if (date && date.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})/)) {
    return (0, _moment.default)(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  } else {
    return date;
  }
}

function getTotal(values) {
  var total = 0;

  if (!values.every(function (value) {
    return isNaN(value);
  })) {
    total = values.reduce(function (prev, curr) {
      var value = Number(curr);

      if (!isNaN(value)) {
        return prev + curr;
      } else {
        return prev;
      }
    }, 0);
  }

  return total;
}

function getListByConstant(dataObject) {
  var list = [];

  for (var _i = 0, _Object$entries = Object.entries(dataObject); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = (0, _slicedToArray2.default)(_Object$entries[_i], 2),
        row = _Object$entries$_i[1];

    list.push({
      text: row.title,
      value: row.value
    });
  }

  return list;
}

function getLabelByConstant(dataObject, value) {
  var title = 'n/c';

  if (value !== undefined) {
    for (var _i2 = 0, _Object$entries2 = Object.entries(dataObject); _i2 < _Object$entries2.length; _i2++) {
      var _Object$entries2$_i = (0, _slicedToArray2.default)(_Object$entries2[_i2], 2),
          row = _Object$entries2$_i[1];

      if (row.value === value) {
        title = row.title;
        break;
      }
    }
  }

  return title;
}

function getListModeReglement() {
  return getListByConstant(CONST.REGLEMENTS.MODE);
}

function getLabelModeReglement(value) {
  return getLabelByConstant(CONST.REGLEMENTS.MODE, value);
}

function getLisTypeReglement() {
  return getListByConstant(CONST.REGLEMENTS.TYPE);
}

function getLabelTypeReglement(value) {
  return getLabelByConstant(CONST.REGLEMENTS.TYPE, value);
}

function getLabelDelaiPaiement(value) {
  return getLabelByConstant(CONST.DELAI_PAIEMENT, value);
} // =========================================================


function generatePassword() {
  // on a enlevé les lettres sujettes à confusion
  var upper = 'ABCDEFGHJKLMNOPQRSTUVWXYZ';
  var lower = 'abcdefghjkmnpqrstvwxyz';
  var digit = '123456789';
  var all = upper + lower + digit;
  /**
   * generate random integer not greater than `max`
  */

  function rand(max) {
    return Math.floor(Math.random() * max);
  }
  /**
   * generate random character of the given `set`
   */


  function random(set) {
    return set[rand(set.length - 1)];
  }
  /**
   * generate an array with the given `length`
   * of characters of the given `set`
   */


  function generate(length, set) {
    var result = [];

    while (length--) {
      result.push(random(set));
    }

    return result;
  }
  /**
   * shuffle an array randomly
   */


  function shuffle(arr) {
    var result = [];

    while (arr.length) {
      result = result.concat(arr.splice(rand[arr.length - 1]));
    }

    return result;
  }

  var result = []; // we need to ensure we have some characters

  result = result.concat(generate(1, upper)); // 1 uppercase

  result = result.concat(generate(1, lower)); // 1 lowercase

  result = result.concat(generate(1, digit)); // 1 digit

  result = result.concat(generate(8, all)); // remaining - whatever

  return shuffle(result).join(''); // shuffle and make a string
} // =========================================================

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */


function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
}
/* Retourne un tableau d'object groupé et trié par une clé */


function groupByKey(list, key) {
  return list.reduce(function (hash, obj) {
    var role = obj[key] !== null ? obj[key] : 'Non classé';
    var array = (0, _objectSpread3.default)({}, hash, (0, _defineProperty2.default)({}, role, (hash[obj[key]] || []).concat(obj)));
    return array;
  }, {});
} // Calcule la date d'échéance d'une facture en fonction de :
// - la date de facture
//   ET
// - du délai de paiement (affaire OU client)


function getDateEcheanceByDelaiPaiement(_ref) {
  var dateFacture = _ref.dateFacture,
      delaiPaiement = _ref.delaiPaiement;
  var dateEcheance = null;
  var date = (0, _moment.default)(dateFacture, 'YYYY/MM/DD');

  if (dateFacture && date.isValid() && delaiPaiement) {
    switch (delaiPaiement) {
      case CONST.DELAI_PAIEMENT.D_COMPTANT.value:
        dateEcheance = date.format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_30J_FIN_MOIS.value:
        dateEcheance = date.add(30, 'days').endOf('month').format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_15J_DATE_FACTURE.value:
        dateEcheance = date.add(15, 'days').format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_30J_DATE_FACTURE.value:
        dateEcheance = date.add(30, 'days').format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_45J_FIN_MOIS.value:
        dateEcheance = date.add(45, 'days').endOf('month').format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_45J_DATE_FACTURE.value:
        dateEcheance = date.add(45, 'days').format('YYYY-MM-DD');
        break;

      case CONST.DELAI_PAIEMENT.D_60J_DATE_FACTURE.value:
        dateEcheance = date.add(60, 'days').format('YYYY-MM-DD');
        break;
    }
  }

  return dateEcheance;
}

function nl2br(val) {
  return val ? val.replace(/\n/g, '<br />') : '';
}

function toDateTime(value) {
  if (value && typeof value !== 'undefined') {
    return _moment.default.parseZone(String(value)).utc().format('DD/MM/YYYY [à] HH[H]mm');
  }
}

function toDate(value) {
  if (value && typeof value !== 'undefined') {
    return (0, _moment.default)(String(value)).format('DD/MM/YYYY');
  }
}

function toTime(value) {
  if (value && typeof value !== 'undefined') {
    return _moment.default.parseZone(String(value)).utc().format('HH[H]mm'); // return moment(String(value)).format('HH[H]mm')
  }
}

function getDateNow() {
  return (0, _moment.default)();
}

function getFiltreProvisionsByEcran(activeMenu) {
  var filtreProvisions;

  switch (activeMenu) {
    case 'objectifsDebourses':
      filtreProvisions = CONST.AFFAIRE.ETAPE.OBJECTIF_DEBOURSE.value;
      break;

    case 'budgetAffaire':
      filtreProvisions = CONST.AFFAIRE.ETAPE.BUDGET_AFFAIRE.value;
      break;

    default:
      filtreProvisions = null;
  }

  return filtreProvisions;
}