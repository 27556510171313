var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Nouvelle ligne d'avenant",
        visible: _vm.visible,
        width: "40%",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        opened: _vm.handleOpened
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ElForm",
          attrs: { model: _vm.formData, rules: _vm.rules, size: "mini" }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Libellé",
                prop: "libelle",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { autocomplete: "no", maxlength: "100", clearable: "" },
                model: {
                  value: _vm.formData.libelle,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "libelle", $$v)
                  },
                  expression: "formData.libelle"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Prestataire",
                prop: "prestataire",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { autocomplete: "no", maxlength: "50", clearable: "" },
                model: {
                  value: _vm.formData.prestataire,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "prestataire", $$v)
                  },
                  expression: "formData.prestataire"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("small", [
            _vm._v(
              "\n      Valider la création de la nouvelle ligne d'avenant en cliquant sur le bouton "
            ),
            _c("div", { staticClass: "txtlib-blue" }, [_vm._v(" Ajouter ")]),
            _c("br"),
            _vm._v(
              "\n      puis indiquer la valeur de cet avenant en cliquant sur le bouton "
            ),
            _c("div", { staticClass: "txtlib-green" }, [
              _vm._v(" + Nouvel avenant ")
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleClose } },
            [_vm._v("Annuler")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v("Ajouter")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }