"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.array.find");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

var _utils = require("@/helpers/utils");

var _moment = _interopRequireDefault(require("moment"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { REGLEMENTS } from '@/helpers/constantes'
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'ReglementList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  inject: ['getFacture' // Permet de récupérer la facture parente éventuellement sélectionnée
  ],
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      CONST: CONST,
      dataLoaded: true,

      /* ---------------------------------------
       *  Forcer l'idFacture parent
       * ---------------------------------------
       *   - pré-filtre de la liste des règlements,
       *   - création d'un nouveau règlement
       */
      idFacture: null,
      facture: null,
      row: [],
      dialogDetailVisible: false,
      multipleSelection: [],
      columns: {
        'facture': {
          key: 0,
          prop: 'facture',
          label: 'Facture',
          width: 150
        },
        'date_facture': {
          key: 1,
          prop: 'date_facture',
          label: 'Date Facture',
          width: 125
        },
        'date_reglement': {
          key: 2,
          prop: 'date_reglement',
          label: 'Date Règlement',
          width: 125
        },
        'mode': {
          key: 3,
          prop: 'mode',
          label: 'Mode',
          width: 100
        },
        'imputation': {
          key: 4,
          prop: 'imputation',
          label: 'Imputation',
          width: 150
        },
        'type': {
          key: 5,
          prop: 'type',
          label: 'Type',
          width: 225
        },
        'montant': {
          key: 6,
          prop: 'montant',
          label: 'Montant',
          width: 150
        },
        'montant_ttc_facture': {
          key: 7,
          prop: 'montant_ttc_facture',
          label: 'Montant TTX facture',
          width: 150
        }
      },
      sortMethod: {
        facture: function facture(a, b) {
          return a.numero < b.numero;
        },
        imputation: function imputation(a, b) {
          if (a === null && b === null) {
            return 0;
          }

          if (a === null) {
            return -1;
          }

          if (b === null) {
            return 1;
          }

          return a.numero < b.numero;
        }
      },
      filters: [{
        value: '',
        prop: ['mode', 'type', 'date_facture', 'date_reglement', 'montant'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'facture':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                var testNumero = String(row[prop].numero).toLowerCase().indexOf(filter.value) > -1;
                var testDateFacture = (0, _moment.default)(String(row[prop].date_facture)).format('DD/MM/YYYY').indexOf(filter.value) > -1;
                return row[prop] !== null && (testNumero || testDateFacture);

              case 'imputation':
                return row[prop] !== null && String(row[prop].numero).toLowerCase().indexOf(filter.value) > -1;

              case 'mode':
                return row[prop] !== null && (0, _utils.getLabelModeReglement)(row[prop]).toLowerCase().indexOf(filter.value) > -1;

              case 'type':
                return row[prop] !== null && (0, _utils.getLabelTypeReglement)(row[prop]).toLowerCase().indexOf(filter.value) > -1;

              case 'date_reglement':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                return (0, _moment.default)(String(row[prop])).format('DD/MM/YYYY').indexOf(filter.value) > -1;

              case 'montant':
                return String((0, _utils.toCurrencyFilter)(row[prop])).toLowerCase().indexOf(filter.value.toLowerCase()) > -1;

              default:
                return false;
            }
          });
        }
      }],
      filterDateFacture: [],
      filterDateReglement: [],
      filterModeReglement: [],
      filterTypeReglement: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['affaire']), {}, (0, _vuex.mapGetters)('facturation', ['getFactureListForCurrentAffaire', 'getReglementList']), {
    data: function data() {
      var data = this.getReglementList(this.idFacture); // ---
      // Initialisation des valeurs du filtre pour les colonnes "date de facture" et "date de règlement"

      var filterDateFacture = [];
      var filterDateReglement = [];
      data.forEach(function (row) {
        if (!filterDateReglement.find(function (x) {
          return x.value === row.date_reglement;
        })) {
          if (row.date_reglement && typeof row.date_reglement !== 'undefined') {
            var item = {
              text: row.date_reglement,
              value: row.date_reglement
            };
            item.text = (0, _moment.default)(String(row.date_reglement)).format('DD/MM/YYYY');
            filterDateReglement.push(item);
          }
        }

        if (row.facture && !filterDateFacture.find(function (x) {
          return x.value === row.facture.date_facture;
        })) {
          if (row.facture.date_facture && typeof row.facture.date_facture !== 'undefined') {
            var _item = {
              text: row.facture.date_facture,
              value: row.facture.date_facture
            };
            _item.text = (0, _moment.default)(String(row.facture.date_facture)).format('DD/MM/YYYY');
            filterDateFacture.push(_item);
          }
        }
      });
      this.setFilter('date_facture', filterDateFacture);
      this.setFilter('date_reglement', filterDateReglement); // ---
      // Initialisation des valeurs du filtre pour la colonne "mode de règlement"

      var filterModeReglement = (0, _utils.getListModeReglement)();
      this.setFilter('mode_reglement', filterModeReglement); // ---
      // Initialisation des valeurs du filtre pour la colonne "type de règlement"

      var filterTypeReglement = (0, _utils.getLisTypeReglement)();
      this.setFilter('type_reglement', filterTypeReglement);
      return data;
    },
    total: function total() {
      return this.data.length;
    },
    disabledNewBtn: function disabledNewBtn() {
      return this.getFactureListForCurrentAffaire.length === 0;
    },
    // Désactiver le règlement de trop-perçu
    disableTP: function disableTP() {
      var solde = 0;

      if (this.facture) {
        solde = this.facture.mt_a_percevoir_ttc - this.facture.montant_regle;
      }

      return solde >= 0;
    }
  }),
  // 9. Évènements
  // --
  created: function created() {
    var vm = this; // Récupération de la facture parente sélectionnée

    vm.getFacture(function (facture) {
      if (facture) {
        vm.facture = facture;
        vm.idFacture = facture.id;
      }
    });
  },
  mounted: function mounted() {
    var _this = this;

    _eventBus.EventBus.$on('REGLEMENT_RESET_LIST', function () {
      _this.idFacture = null;
      _this.facture = null;
      _this.dataLoaded = false;

      _this.initFacturation().then(function (_) {
        _this.dataLoaded = true;
      });
    });
  },
  beforeDestroy: function beforeDestroy() {
    _eventBus.EventBus.$off('REGLEMENT_RESET_LIST');
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)({}, (0, _vuex.mapActions)('facturation', {
    initFacturation: 'init'
  }), {}, (0, _vuex.mapActions)('facturation', ['updateReglement', 'deleteReglement', 'bulkDeleteReglement']), {
    canSelectRow: function canSelectRow(row, index) {
      return !this.isImputedPayment(row);
    },
    // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
    colWidth: function colWidth(index) {
      if (this.columns[index] === undefined) {
        return;
      }

      return this.columns[index].width;
    },
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (column.property === 'type') {
          sums[index] = 'Total';
          return;
        }

        if (!['montant'].includes(column.property)) {
          sums[index] = '';
          return;
        }

        var values = [];
        values = data.map(function (item) {
          // Attention: dans le cas d'un réglement imputé sur la facture actuelle,
          // on prend en compte le montant imputé en valeur négative pour l'enlever
          // au trop perçu de règlement
          if (column.property === 'montant') {
            return Number(item.is_imputed ? item.imputed_amount : item.montant);
          }

          return Number(item[column.property]);
        });
        sums[index] = (0, _utils.getTotal)(values); // Formattage

        if (column.property === 'montant') {
          sums[index] = (0, _utils.toCurrency)(sums[index]);
        }
      });
      return sums;
    },

    /* ---------------------------------------
     *  Liste déroulante des actions
     * ---------------------------------------
     *
     *   - paiement: créer un règlement normal
     *   - trop-perçu: créer un règlement pour un trop-perçu (s'il existe)
     *
     */
    handleCommand: function handleCommand(command) {
      switch (command.action) {
        case 'paiement':
          _eventBus.EventBus.$emit('REGLEMENT_NEW');

          break;

        case 'trop_percu':
          _eventBus.EventBus.$emit('REGLEMENT_NEW', CONST.REGLEMENTS.TYPE.TROP_PERCU.value);

          break;
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.row = val;
      this.dialogDetailVisible = true;
    },
    handleDeleteReglement: function handleDeleteReglement(index, row) {
      var _this2 = this;

      this.$confirm("Voulez-vous supprimer le r\xE8glement ".concat(row.type, " ?"), 'Confirmer la suppression ?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this2.$loader(true);

        _this2.deleteReglement(row).then(function () {
          _this2.$message('Suppression effectuée avec succès');

          _eventBus.EventBus.$emit('REGLEMENT_REFRESH');
        }).catch(function (error) {
          _this2.$message(error);
        }).then(function (_) {
          _this2.$loader(false);
        });
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this3 = this;

      var msg = this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée';
      this.$confirm("Voulez-vous supprimer ".concat(msg, " ?"), 'Confirmer la suppression', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(function () {
        _this3.$loader(true);

        var selection = _this3.multipleSelection.map(function (u) {
          return u.id;
        }).join(',');

        _this3.bulkDeleteReglement(selection).then(function () {
          _this3.$message('Suppression effectuée avec succès');

          _eventBus.EventBus.$emit('REGLEMENT_REFRESH');
        }).catch(function (error) {
          _this3.$message(error);
        }).then(function (_) {
          _this3.$loader(false);
        });
      });
    },
    handleEditReglement: function handleEditReglement(index, row) {
      _eventBus.EventBus.$emit('REGLEMENT_EDIT', row);
    },
    handleFilter: function handleFilter(value, row, column) {
      var property = column['property'];
      return row[property] === value;
    },
    // handleNewReglement () {
    //   EventBus.$emit('REGLEMENT_NEW')
    // },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    isImputedPayment: function isImputedPayment(row) {
      return row.is_imputed; // return row.imputation && this.facture.id === row.imputation.id
    },
    setFilter: function setFilter(prop, filter) {
      switch (prop) {
        case 'date_facture':
          this.filterDateFacture = filter;
          break;

        case 'date_reglement':
          this.filterDateReglement = filter;
          break;

        case 'mode_reglement':
          this.filterModeReglement = filter;
          break;

        case 'type_reglement':
          this.filterTypeReglement = filter;
          break;
      }
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;
      return this.isImputedPayment(row) ? 'imputed-payment' : '';
    }
  }) // 11. Rendu
  // --

};
exports.default = _default;