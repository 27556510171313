"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.object.values");

require("core-js/modules/es6.array.find");

var apiHistorique = _interopRequireWildcard(require("@/api/historique"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'HistoriqueAffaire',
  data: function data() {
    return {
      data: [],
      total: 0,
      filters: [{
        value: '',
        prop: []
      }],
      CONST: CONST
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.$loader(true);
    apiHistorique.getAllByAffaireId(this.$route.params.id).then(function (results) {
      // filtrage pour ne pas afficher les messages avec id_user = -1 mis en place par les traitements auto ou manuel
      // pour les affaires qui avait un status 7,30,31,32,40 au moment du passage du traitement
      _this.data = results.filter(function (x) {
        return x.id_user !== -1;
      });
      _this.data.total = _this.data.length;

      _this.$loader(false);
    }).catch(function (error) {
      _this.$loader(false);

      _this.$message(error);
    });
  },
  methods: {
    handleCurrentChange: function handleCurrentChange(currentRow) {
      this.row = currentRow;
    },
    status: function status(value) {
      var search = Object.values(CONST.AFFAIRE.STATUS).find(function (x) {
        return x.value === value;
      });
      return search ? search.title : '';
    },
    etape: function etape(value) {
      var search = Object.values(CONST.AFFAIRE.ETAPE).find(function (x) {
        return x.value === value;
      });
      return search ? search.title : '';
    }
  }
};
exports.default = _default;