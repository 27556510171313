var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _vm.activeMenu === "reglements_by_facture"
      ? _c(
          "div",
          [
            _c("h1", [_vm._v("Créances clients")]),
            _vm._v(" "),
            _c("reglement-module", { attrs: { "id-facture": _vm.factureId } })
          ],
          1
        )
      : _vm.activeMenu === "creances_liste"
      ? _c("div", [_c("creance-list")], 1)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }