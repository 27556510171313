"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.number.constructor");

var apiTva = _interopRequireWildcard(require("@/api/tva"));

var _utils = require("@/helpers/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'TvaSelectList',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  props: {
    idTvaSelected: {
      type: Number
    },
    tva: {
      type: Object
    },
    showLibelleCompte: {
      type: Boolean,
      // permet d'afficher/masquer le n° de compte dans la liste déroulante, 0=false - 1=true
      default: true
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      tvas: [],
      idTva: this.idTvaSelected
    };
  },
  // 9. Évènements
  // --
  beforeMount: function beforeMount() {
    this.getAll();
  },
  computed: {
    dspLibelleCompte: function dspLibelleCompte() {
      return this.showLibelleCompte;
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    getAll: function getAll() {
      var _this = this;

      apiTva.getAll().then(function (results) {
        _this.tvas = results;

        var tva = _this.tvas.find(function (x) {
          return x.id === _this.idTvaSelected;
        });

        if (tva) {
          _this.$emit('update:tva', tva);
        }
      }).catch(function (error) {
        _this.$message(error);
      });
    },
    onClear: function onClear() {
      this.$emit('update:id-tva-selected', null);
      this.$emit('update:tva', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:id-tva-selected', index);
        this.$emit('update:tva', this.tvas.find(function (x) {
          return x.id === index;
        }));
      }
    },
    toPercentage: function toPercentage(taux) {
      return (0, _utils.toPercentage)(taux);
    }
  } // 11. Rendu
  // --

};
exports.default = _default;