var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sync-scroll-bars" },
    [
      _vm.modeEdit
        ? _c(
            "el-row",
            {
              directives: [
                {
                  name: "can",
                  rawName: "v-can:budget_affaire",
                  value: "editer",
                  expression: "'editer'",
                  arg: "budget_affaire"
                }
              ]
            },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm._v(" \n      "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-plus"
                      },
                      on: { click: _vm.handleAdd }
                    },
                    [_vm._v("Nouvelle ligne")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12, align: "right" } },
                [
                  _vm._v(" \n      "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "warning",
                        size: "mini",
                        icon: "el-icon-close"
                      },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("Annuler")]
                  ),
                  _vm._v(" "),
                  _vm.lotsDetail.length > 0
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            size: "mini",
                            icon: "el-icon-check"
                          },
                          on: { click: _vm.handleSave }
                        },
                        [_vm._v("Enregistrer")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: _vm.refresh,
          ref: "dataTable",
          staticClass: "budaff",
          attrs: {
            size: "mini",
            data: _vm.lotsDetail,
            "data-sync-scrollbar": "main",
            "cell-class-name": _vm.cellClass
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "ordre",
              label: "#",
              fixed: "left",
              width: "40",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      { attrs: { title: "id lot: " + scope.row.id } },
                      [_vm._v(_vm._s(scope.$index + 1))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { fixed: "left", width: "20", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.modeEdit &&
                    scope.row.type_lot_detail ===
                      _vm.CONST.LOT_DETAIL.TYPE_LIGNE.SUPPLEMENTAIRE &&
                    scope.row.validation !== 1
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            staticStyle: { "margin-left": "-8px" }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "text",
                                icon: "el-icon-delete",
                                size: "mini"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleDelete(scope)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _c("div", { staticStyle: { "margin-left": "-5px" } }, [
                          scope.row.type_lot_detail ===
                          _vm.CONST.LOT_DETAIL.TYPE_LIGNE.SUPPLEMENTAIRE
                            ? _c(
                                "span",
                                {
                                  staticClass: "tooltip",
                                  staticStyle: {
                                    "background-color": "#cc9900",
                                    color: "white"
                                  },
                                  attrs: {
                                    title: "Ligne supplémentaire Budget affaire"
                                  }
                                },
                                [_vm._v(" S \n          ")]
                              )
                            : _vm._e()
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "libelle",
              label: "Détail",
              fixed: "left",
              width: "250"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.type_lot_detail ===
                    _vm.CONST.LOT_DETAIL.TYPE_LIGNE.SUPPLEMENTAIRE
                      ? _c("div", [
                          Number(_vm.calculs[scope.$index].validation) === 1
                            ? _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.calculs[scope.$index].libelle) +
                                    "\n          "
                                )
                              ])
                            : _c("div", [
                                _vm.canEdit && _vm.modeEdit
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "can",
                                            rawName: "v-can:budget_affaire",
                                            value: "editer",
                                            expression: "'editer'",
                                            arg: "budget_affaire"
                                          }
                                        ]
                                      },
                                      [
                                        _c("el-input", {
                                          class:
                                            scope.row.libelle === "" &&
                                            scope.row.type_lot_detail !== 0
                                              ? "input-empty"
                                              : "",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "-- à renseigner --",
                                            size: "mini",
                                            tabindex: "1"
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.handleInput(scope)
                                            }
                                          },
                                          model: {
                                            value: scope.row.libelle,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "libelle",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.libelle"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.calculs[scope.$index].libelle
                                          ) +
                                          "\n            "
                                      )
                                    ])
                              ])
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.libelle) +
                              "\n        "
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Objectif affaire", align: "center" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "mtObjDebourseExec",
                  label: "Objectif de déboursé  d'exécution",
                  width: "120",
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.type_lot_detail !==
                        _vm.CONST.LOT_DETAIL.TYPE_LIGNE.SUPPLEMENTAIRE
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      _vm.calculs[scope.$index]
                                        .mtObjDebourseExec
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _c("div", { staticClass: "tb-pattern" }, [
                              _vm._v(" ")
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Budget affaire", align: "center" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "prestataire",
                  label: "Prestataire",
                  width: "190"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        Number(_vm.calculs[scope.$index].validation) === 1
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.calculs[scope.$index].prestataire
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _c("div", [
                              _vm.canEdit && _vm.modeEdit
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "can",
                                          rawName: "v-can:budget_affaire",
                                          value: "editer",
                                          expression: "'editer'",
                                          arg: "budget_affaire"
                                        }
                                      ]
                                    },
                                    [
                                      _c("el-input", {
                                        class:
                                          (!scope.row.prestataire ||
                                            scope.row.prestataire === "") &&
                                          scope.row
                                            .mt_proposition_prestataire &&
                                          scope.row.validation > 0
                                            ? "input-empty"
                                            : "",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          tabindex: "2",
                                          clearable: ""
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.handleInput(scope)
                                          }
                                        },
                                        model: {
                                          value: scope.row.prestataire,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "prestataire",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.prestataire"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.calculs[scope.$index].prestataire
                                        ) +
                                        "\n            "
                                    )
                                  ])
                            ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "mtDernierePropositionPresta",
                  label: "Dernière proposition prestataire",
                  width: "120",
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        Number(_vm.calculs[scope.$index].validation) === 1
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      _vm.calculs[scope.$index]
                                        .mtDernierePropositionPresta
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _c("div", [
                              _vm.canEdit && _vm.modeEdit
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "can",
                                          rawName: "v-can:budget_affaire",
                                          value: "editer",
                                          expression: "'editer'",
                                          arg: "budget_affaire"
                                        }
                                      ]
                                    },
                                    [
                                      _vm.modeEdit
                                        ? _c("el-input", {
                                            directives: [
                                              {
                                                name: "numberonly",
                                                rawName: "v-numberonly"
                                              }
                                            ],
                                            staticClass: "numeric",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              size: "mini",
                                              tabindex: "2"
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.handleInput(
                                                  scope,
                                                  "refresh_calcul"
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                scope.row
                                                  .mt_proposition_prestataire,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "mt_proposition_prestataire",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.mt_proposition_prestataire"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            _vm.calculs[scope.$index]
                                              .mtDernierePropositionPresta
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                            ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "mtEcart",
                  label: "Écart",
                  width: "120",
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.calculs[scope.$index].mtEcart > 0
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      _vm.calculs[scope.$index].mtEcart
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      _vm.calculs[scope.$index].mtEcart
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "mtProvisions",
                  label: "Aléas",
                  width: "120",
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("toCurrency")(
                                _vm.calculs[scope.$index]
                                  .mtTotalLotDetailLigneProvision
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    prop: "validation",
                    label: "",
                    width: "45",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          Number(_vm.calculs[scope.$index].validation) === 1
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "tooltip",
                                    staticStyle: {
                                      "font-weight": "bold",
                                      color: "#0007d5"
                                    },
                                    attrs: { title: "ligne validée" }
                                  },
                                  [_vm._v("✓")]
                                )
                              ])
                            : _c("div", [
                                _vm.canEdit && _vm.modeEdit
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "can",
                                              rawName: "v-can:budget_affaire",
                                              value: "editer",
                                              expression: "'editer'",
                                              arg: "budget_affaire"
                                            }
                                          ]
                                        },
                                        [
                                          _c("el-checkbox", {
                                            attrs: {
                                              "true-label": Number(2),
                                              "false-label": Number(0),
                                              checked:
                                                Number(
                                                  _vm.calculs[scope.$index]
                                                    .validation
                                                ) !== 0
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.handleInput(scope)
                                              }
                                            },
                                            model: {
                                              value: scope.row.validation,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "validation",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.validation"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "cant",
                                              rawName: "v-cant:budget_affaire",
                                              value: "editer",
                                              expression: "'editer'",
                                              arg: "budget_affaire"
                                            }
                                          ]
                                        },
                                        [
                                          Number(
                                            _vm.calculs[scope.$index].validation
                                          ) !== 0
                                            ? _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "tooltip",
                                                    style:
                                                      Number(
                                                        _vm.calculs[
                                                          scope.$index
                                                        ].validation
                                                      ) === 1
                                                        ? "font-weight:bold;color:#0007d5"
                                                        : "",
                                                    attrs: {
                                                      title:
                                                        "ligne " +
                                                        (Number(
                                                          _vm.calculs[
                                                            scope.$index
                                                          ].validation
                                                        ) === 1
                                                          ? "validée"
                                                          : "en attente de validation")
                                                    }
                                                  },
                                                  [_vm._v("✓")]
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ])
                                  : _c("div", [
                                      Number(
                                        _vm.calculs[scope.$index].validation
                                      ) !== 0
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "tooltip",
                                                style:
                                                  Number(
                                                    _vm.calculs[scope.$index]
                                                      .validation
                                                  ) === 1
                                                    ? "font-weight:bold;color:#0007d5"
                                                    : "",
                                                attrs: {
                                                  title:
                                                    "ligne " +
                                                    (Number(
                                                      _vm.calculs[scope.$index]
                                                        .validation
                                                    ) === 1
                                                      ? "validée"
                                                      : "en attente de validation")
                                                }
                                              },
                                              [_vm._v("✓")]
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                              ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm.canEdit && _vm.modeEdit
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:budget_affaire",
                                value: "editer",
                                expression: "'editer'",
                                arg: "budget_affaire"
                              }
                            ]
                          },
                          [
                            _c("el-checkbox", {
                              attrs: { size: "mini" },
                              on: { change: _vm.toggleSelection }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: "TotalLot_" + _vm.refresh,
          staticClass: "budaff",
          attrs: {
            data: _vm.totaux,
            size: "mini",
            "row-class-name": "total",
            border: "",
            "data-sync-scrollbar": "footer"
          }
        },
        [
          _c("el-table-column", {
            attrs: { width: "40", fixed: "left", align: "right" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "", width: "20", fixed: "left", align: "center" }
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { width: "250", fixed: "left", align: "right" } },
            [_vm._v("S/TOTAL")]
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.totaux[scope.$index].mtTotalLotObjDebourseExec
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { "class-name": "separator", width: "15" } },
            [_vm._v(" ")]
          ),
          _vm._v(" "),
          _c("el-table-column", { attrs: { width: "190" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm.totaux[scope.$index]
                            .mtTotalLotDernierePropositionPresta
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.totaux[scope.$index].mtTotalLotEcart > 0
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm.totaux[scope.$index].mtTotalLotEcart
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm.totaux[scope.$index].mtTotalLotEcart
                                )
                              ) +
                              "\n        "
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.totaux[scope.$index].mtTotalLotLigneProvision
                          )
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { width: "45" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }