var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.SidebarMenuBg,
                "text-color": _vm.variables.SidebarMenuText,
                "unique-opened": false,
                "active-text-color": _vm.variables.SidebarMenuActiveText,
                "collapse-transition": true,
                mode: "vertical"
              }
            },
            _vm._l(_vm.permission_routes, function(route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: { item: route, "base-path": route.path }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-menu",
        {
          style: _vm.isCollapse ? " display: none" : "",
          attrs: {
            id: "el-menu-vertical-version",
            "background-color": _vm.variables.SidebarMenuBg,
            "text-color": _vm.variables.SidebarMenuText
          }
        },
        [
          _c("el-menu-item", [
            _c("span", [
              _vm._v("\n        api " + _vm._s(_vm.gitInfo.api.lastTagVersion)),
              _c("br"),
              _vm._v(
                "\n        app " +
                  _vm._s(_vm.gitInfo.app.lastTagVersion) +
                  "\n      "
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }