var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "p",
      [
        _c(
          "el-button",
          {
            directives: [
              {
                name: "can",
                rawName: "v-can:affaire",
                value: "create",
                expression: "'create'",
                arg: "affaire"
              }
            ],
            attrs: { type: "primary", size: "mini" }
          },
          [_vm._v("Créer Affaire")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }