"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var routes = [{
  path: '/reports/synthese',
  name: 'Rapport-Synthese',
  order: 1,
  meta: {
    title: 'Synthèse de gestion',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/reports/synthese'));
    });
  }
}, {
  path: '/reports/recap_annuel',
  name: 'Rapport-RecapAnnuel',
  order: 2,
  meta: {
    title: 'Récap. annuel Synthèse de gestion',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/reports/recap_annuel'));
    });
  }
}, {
  path: '/reports/fae_pca',
  name: 'Rapport-FaePca',
  order: 3,
  meta: {
    title: 'FAE PCA',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/reports/fae_pca'));
    });
  }
}, {
  path: '/reports/debourses_indirects',
  name: 'Rapport-DeboursesIndirects',
  order: 4,
  meta: {
    title: 'Déboursés Indirects',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/reports/debourses_indirects'));
    });
  }
}, {
  path: '/reports/arrets_comptables_di',
  name: 'Rapport-ArretsComptablesDI',
  order: 5,
  meta: {
    title: 'Arrêtés comptables DI',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/reports/arrets_comptables_di'));
    });
  }
}, {
  path: '/reports/aleas',
  name: 'Rapport-Aleas',
  order: 6,
  meta: {
    title: 'Aléas',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/reports/aleas'));
    });
  }
}, {
  path: '/reports/engages',
  name: 'Rapport-Engages',
  order: 7,
  meta: {
    title: 'Engagés',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/reports/engages'));
    });
  }
}, {
  path: '/reports/provisions',
  name: 'Rapport-Provisions',
  order: 8,
  meta: {
    title: 'Provisions',
    icon: ''
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/reports/provisions'));
    });
  }
}];
var _default = routes;
exports.default = _default;