"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.object.entries");

require("core-js/modules/es6.number.constructor");

var _constantes = require("@/helpers/constantes");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ReglementModeSelectList',
  props: {
    modeReglementSelected: {
      type: Number,
      validator: function validator(value) {
        for (var _i = 0, _Object$entries = Object.entries(_constantes.REGLEMENTS.MODE); _i < _Object$entries.length; _i++) {
          var _Object$entries$_i = (0, _slicedToArray2.default)(_Object$entries[_i], 2),
              row = _Object$entries$_i[1];

          if (value === row.value) {
            return true;
          }
        }

        return false;
      }
    }
  },
  data: function data() {
    return {
      modeReglementList: _constantes.REGLEMENTS.MODE,
      modeReglement: this.modeReglementSelected
    };
  },
  beforeMount: function beforeMount() {
    var defaultValue = this.modeReglement ? this.modeReglement : this.getDefaultReglementValue;
    this.onChange(defaultValue);
  },
  computed: {
    getDefaultReglementValue: function getDefaultReglementValue() {
      var defaultValue = null;

      for (var _i2 = 0, _Object$entries2 = Object.entries(this.modeReglementList); _i2 < _Object$entries2.length; _i2++) {
        var _Object$entries2$_i = (0, _slicedToArray2.default)(_Object$entries2[_i2], 2),
            row = _Object$entries2$_i[1];

        if (row.default) {
          defaultValue = row.value;
        }
      }

      return defaultValue;
    }
  },
  methods: {
    onClear: function onClear() {
      this.$emit('update:mode-reglement-selected', null);
    },
    onChange: function onChange(index) {
      if (index > 0) {
        this.$emit('update:mode-reglement-selected', index);
      }
    }
  }
};
exports.default = _default;