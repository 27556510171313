var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.selection.commande
        ? _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("commande-infos", {
                attrs: {
                  commande: _vm.selection.commande,
                  "montant-facture": _vm.montantFacture,
                  "montant-facture-initial": _vm.montantFactureInitial
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { margin: "10px 0 10px 0" } },
        [
          _c("el-col", { attrs: { span: 10 } }, [
            _c("h1", [_vm._v(_vm._s(_vm.title))])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-form",
                {
                  ref: "ElForm",
                  attrs: {
                    "label-width": "250px",
                    size: "mini",
                    model: _vm.formData,
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Commande", prop: "id_commande" } },
                    [
                      _c("commande-select-list", {
                        key: "id_commande_" + _vm.formData.id_commande,
                        attrs: {
                          commande: _vm.selection.commande,
                          disabled: _vm.idCommandeForced,
                          "id-commande-selected": _vm.formData.id_commande
                        },
                        on: {
                          "update:commande": function($event) {
                            return _vm.$set(_vm.selection, "commande", $event)
                          },
                          "update:idCommandeSelected": function($event) {
                            return _vm.$set(_vm.formData, "id_commande", $event)
                          },
                          "update:id-commande-selected": function($event) {
                            return _vm.$set(_vm.formData, "id_commande", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Compte auxiliaire client" } },
                    [_vm._v(_vm._s(_vm.affaire.affaire.compte_auxiliaire))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Type de vente", prop: "id_type_vente" }
                    },
                    [
                      _c("type-vente-select-list", {
                        key: "id_type_vente_" + _vm.formData.id_type_vente,
                        attrs: {
                          "id-type-vente-selected": _vm.formData.id_type_vente
                        },
                        on: {
                          "update:idTypeVenteSelected": function($event) {
                            return _vm.$set(
                              _vm.formData,
                              "id_type_vente",
                              $event
                            )
                          },
                          "update:id-type-vente-selected": function($event) {
                            return _vm.$set(
                              _vm.formData,
                              "id_type_vente",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Numéro", prop: "numero" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "125px" },
                        attrs: {
                          autocomplete: "no",
                          clearable: "",
                          maxlength: "10",
                          type: "string"
                        },
                        model: {
                          value: _vm.formData.numero,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "numero", $$v)
                          },
                          expression: "formData.numero"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Libellé", prop: "libelle" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          autocomplete: "no",
                          clearable: "",
                          maxlength: "45",
                          type: "string"
                        },
                        model: {
                          value: _vm.formData.libelle,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "libelle", $$v)
                          },
                          expression: "formData.libelle"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Émetteur", prop: "emetteur" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "250px" },
                          attrs: {
                            autocomplete: "no",
                            clearable: "",
                            maxlength: "45",
                            type: "string",
                            disabled: _vm.commandeIsSelected
                          },
                          model: {
                            value: _vm.formData.emetteur,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "emetteur", $$v)
                            },
                            expression: "formData.emetteur"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-user",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Date de facturation",
                        prop: "date_facture"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'"
                          }
                        ],
                        staticStyle: { width: "125px" },
                        attrs: {
                          clearable: "",
                          format: "dd/MM/yyyy",
                          placeholder: "Choisissez une date",
                          type: "date",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.formData.date_facture,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "date_facture", $$v)
                          },
                          expression: "formData.date_facture"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Date d'échéance", prop: "date_echeance" }
                    },
                    [
                      _c("el-date-picker", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'"
                          }
                        ],
                        staticStyle: { width: "125px" },
                        attrs: {
                          clearable: "",
                          format: "dd/MM/yyyy",
                          placeholder: "Choisissez une date",
                          type: "date",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.formData.date_echeance,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "date_echeance", $$v)
                          },
                          expression: "formData.date_echeance"
                        }
                      }),
                      _vm._v(" "),
                      _vm.echeanceByPaymentDelay
                        ? _c("span", [
                            _vm._v(
                              "\n             (paiement " +
                                _vm._s(
                                  _vm._f("getLabelDelaiPaiement")(
                                    _vm.affaire.affaire.delai_paiement
                                  )
                                ) +
                                ")\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Montant HT", prop: "montant" } },
                    [
                      _c(
                        "el-input",
                        {
                          directives: [
                            { name: "numberonly", rawName: "v-numberonly" }
                          ],
                          staticClass: "numeric",
                          staticStyle: { width: "125px" },
                          attrs: {
                            type: "string",
                            autocomplete: "no",
                            clearable: ""
                          },
                          model: {
                            value: _vm.formData.montant,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "montant", $$v)
                            },
                            expression: "formData.montant"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-money",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Révision / Actualisation",
                        prop: "rev_actu"
                      }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          directives: [
                            { name: "numberonly", rawName: "v-numberonly" }
                          ],
                          staticClass: "numeric",
                          staticStyle: { width: "125px" },
                          attrs: {
                            autocomplete: "no",
                            clearable: "",
                            type: "string"
                          },
                          model: {
                            value: _vm.helper.rev_actu,
                            callback: function($$v) {
                              _vm.$set(_vm.helper, "rev_actu", $$v)
                            },
                            expression: "helper.rev_actu"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-money",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          })
                        ]
                      ),
                      _vm._v("\n           \n          "),
                      _c("montant-type-select-list", {
                        key:
                          "rev_actu_type_montant_" +
                          _vm.helper.rev_actu_type_montant,
                        staticStyle: { width: "75px" },
                        attrs: {
                          "type-montant-selected":
                            _vm.helper.rev_actu_type_montant
                        },
                        on: {
                          "update:typeMontantSelected": function($event) {
                            return _vm.$set(
                              _vm.helper,
                              "rev_actu_type_montant",
                              $event
                            )
                          },
                          "update:type-montant-selected": function($event) {
                            return _vm.$set(
                              _vm.helper,
                              "rev_actu_type_montant",
                              $event
                            )
                          }
                        }
                      }),
                      _vm._v("\n           \n          "),
                      _vm.showMontantRevActuHt
                        ? [
                            _vm._v(
                              "\n            (soit " +
                                _vm._s(
                                  _vm._f("toCurrency")(_vm.formData.rev_actu)
                                ) +
                                " HT)\n          "
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "Total HT" } }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.montantTotalHt)))
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tva", prop: "id_tva" } },
                    [
                      _c("tva-select-list", {
                        key: "id_tva_" + _vm.formData.id_tva,
                        attrs: { "id-tva-selected": _vm.formData.id_tva },
                        on: {
                          "update:idTvaSelected": function($event) {
                            return _vm.$set(_vm.formData, "id_tva", $event)
                          },
                          "update:id-tva-selected": function($event) {
                            return _vm.$set(_vm.formData, "id_tva", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "Total TVA" } }, [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.montantTotalTva)))
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Montant TTC (avant R.G.)" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.montantAvantRetenueGarantieTtc
                          )
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Retenue de garantie (R.G.)",
                        prop: "retenue_garantie"
                      }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          directives: [
                            { name: "numberonly", rawName: "v-numberonly" }
                          ],
                          staticClass: "numeric",
                          staticStyle: { width: "125px" },
                          attrs: {
                            autocomplete: "no",
                            clearable: "",
                            type: "string"
                          },
                          model: {
                            value: _vm.helper.retenue_garantie,
                            callback: function($$v) {
                              _vm.$set(_vm.helper, "retenue_garantie", $$v)
                            },
                            expression: "helper.retenue_garantie"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-money",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          })
                        ]
                      ),
                      _vm._v("\n           \n          "),
                      _c("montant-type-select-list", {
                        key:
                          "retenue_garantie_type_montant_" +
                          _vm.helper.retenue_garantie_type_montant,
                        staticStyle: { width: "75px" },
                        attrs: {
                          "type-montant-selected":
                            _vm.helper.retenue_garantie_type_montant
                        },
                        on: {
                          "update:typeMontantSelected": function($event) {
                            return _vm.$set(
                              _vm.helper,
                              "retenue_garantie_type_montant",
                              $event
                            )
                          },
                          "update:type-montant-selected": function($event) {
                            return _vm.$set(
                              _vm.helper,
                              "retenue_garantie_type_montant",
                              $event
                            )
                          }
                        }
                      }),
                      _vm._v("\n           \n          "),
                      _vm.showMontantRetenueGarantieTtc
                        ? [
                            _vm._v(
                              "\n            (soit " +
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    _vm.formData.retenue_garantie
                                  )
                                ) +
                                " TTC)\n          "
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.autoCalculRG,
                                callback: function($$v) {
                                  _vm.autoCalculRG = $$v
                                },
                                expression: "autoCalculRG"
                              }
                            },
                            [
                              _vm._v(
                                "\n              cocher la case pour calculer automatiquement " +
                                  _vm._s(
                                    _vm._f("toPercentage")(
                                      _vm.CONST.COEF_CALCUL_AUTO_RG
                                    )
                                  ) +
                                  " du montant TTC (avant R.G.)\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Divers TTC", prop: "divers_ttc" } },
                    [
                      _c(
                        "el-input",
                        {
                          directives: [
                            { name: "numberonly", rawName: "v-numberonly" }
                          ],
                          staticClass: "numeric",
                          staticStyle: { width: "125px" },
                          attrs: {
                            autocomplete: "no",
                            clearable: "",
                            type: "string"
                          },
                          model: {
                            value: _vm.formData.divers_ttc,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "divers_ttc", $$v)
                            },
                            expression: "formData.divers_ttc"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-money",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Montant TTC (après R.G.)" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm.montantApresRetenueGarantieTtc
                          )
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Montant Paiement direct ST TTC",
                        prop: "mt_paiement_direct_st"
                      }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          directives: [
                            { name: "numberonly", rawName: "v-numberonly" }
                          ],
                          staticClass: "numeric",
                          staticStyle: { width: "125px" },
                          attrs: {
                            autocomplete: "no",
                            clearable: "",
                            type: "string"
                          },
                          model: {
                            value: _vm.formData.mt_paiement_direct_st,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formData,
                                "mt_paiement_direct_st",
                                $$v
                              )
                            },
                            expression: "formData.mt_paiement_direct_st"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-money",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Montant à percevoir TTC" } },
                    [
                      _vm._v(
                        _vm._s(_vm._f("toCurrency")(_vm.montantAPercevoirTtc))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Fichier", prop: "fichier" } },
                    [
                      _c("input", {
                        ref: "fichierUploader",
                        staticStyle: { display: "none" },
                        attrs: { type: "file" },
                        on: { change: _vm.onFileChange }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "el-button",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled: _vm.selectedFile != null
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.fichierUploader.click()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-document-add" }),
                          _vm._v(" Choisir un fichier\n          ")
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "el-upload__tip" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "facturation.facture.file_with_limited_weight",
                              {
                                fileType: "PDF",
                                weight:
                                  _vm.CONST.FACTURES.MAX_UPLOAD_SIZE /
                                    1024 /
                                    1000 +
                                  " Mo"
                              }
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _vm.selectedFile
                        ? _c(
                            "div",
                            [
                              _c("i", { staticClass: "el-icon-document" }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedFile
                                      ? _vm.selectedFile.name
                                      : ""
                                  ) +
                                  "\n            "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass:
                                    "el-button el-button--mini el-button--danger",
                                  on: { click: _vm.onFileRemove }
                                },
                                [_c("i", { staticClass: "el-icon-close" })]
                              )
                            ],
                            1
                          )
                        : !_vm.selectedFile && _vm.formData.fichier
                        ? _c(
                            "div",
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "el-button el-button--text",
                                  attrs: {
                                    target: "_FACTURE_PDF",
                                    href: _vm.factureFichierUri
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "el-icon-document" }),
                                  _vm._v(" Voir le fichier\n            ")
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.deleteFichier,
                                    callback: function($$v) {
                                      _vm.deleteFichier = $$v
                                    },
                                    expression: "deleteFichier"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              cocher la case pour supprimer le fichier\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: {
                "text-align": "right",
                margin: "10px 0 10px 0",
                position: "sticky",
                top: "150px"
              },
              attrs: { span: 8 }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-check",
                        size: "mini",
                        type: "primary"
                      },
                      on: { click: _vm.validateForm }
                    },
                    [_vm._v("\n          Enregistrer\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.resetForm } },
                    [_vm._v("\n          Reset\n        ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }