"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventBus = require("@/helpers/event-bus");

var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface
  // --
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      btnExportLoading: {
        excel: false,
        pdf: false
      },
      // A surcharger dans le composant utilisant le mixin
      mixinEvents: {
        export: 'xxx_EXPORT',
        exported: 'xxx_EXPORTED'
      }
    };
  },
  // 9. Évènements
  // --
  created: function created() {
    console.log('[Mixins] exportModuleMixin -> created !');

    if (this.mixinEvents.export === 'xxx_EXPORT') {
      console.warn('[Mixins] exportModuleMixin -> L\'événement \'xxx_EXPORT\' n\'est pas configuré dans le composant utilisant le mixin \'exportModuleMixin\'');
    }

    if (this.mixinEvents.exported === 'xxx_EXPORTED') {
      console.warn('[Mixins] exportModuleMixin -> L\'événement \'xxx_EXPORTED\' n\'est pas configuré dans le composant utilisant le mixin \'exportModuleMixin\'');
    }
  },
  mounted: function mounted() {
    var _this = this;

    console.log('[Mixins] exportModuleMixin -> mounted !');

    _eventBus.EventBus.$on(this.mixinEvents.exported, function (_ref) {
      var format = _ref.format;
      console.log("[Mixins] exportModuleMixin -> Listening to Event '".concat(_this.mixinEvents.exported, "' in order to stop btn '").concat(format, "' loading routine"));
      setTimeout(function () {
        _this.btnExportLoading[format] = false;
      }, 250);
    });
  },
  beforeDestroy: function beforeDestroy() {
    console.log('[Mixins] exportModuleMixin -> beforeDestroy !');

    _eventBus.EventBus.$off(this.mixinEvents.exported);
  },
  // 10. Propriétés non réactives
  // --
  methods: {
    handleExport: function handleExport(format) {
      console.log('[Mixins] exportModuleMixin -> handleExport !');
      console.log("[Mixins] exportModuleMixin -> Emitting Event '".concat(this.mixinEvents.export, "' in order to export data list in format '").concat(format, "'"));

      _eventBus.EventBus.$emit(this.mixinEvents.export, {
        format: format
      });

      this.btnExportLoading[format] = true;
    }
  } // 11. Rendu
  // --

};
exports.default = _default;